import { createSlice } from '@reduxjs/toolkit';

export const tripSlice = createSlice({
	name: 'trip',
	initialState: {
		trip: {},
	},
	reducers: {
		clearState: (state) => {
			state.trip = {};
			return state;
		},
		setTrip: (state, { payload }) => {
			state.trip = payload;
			return state;
		}
	},
	extraReducers: {
	},
})

export const { clearState, setTrip } = tripSlice.actions;

export const tripSelector = state => state.trip;
