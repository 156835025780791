import { subfix_domain } from '../api';

const get_discount_price = async (authAxios, data) => {
  try {
    const res = await authAxios.post(`/api/trips/get-discounted-price/`, {
      ...data,
      subfix_domain,
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const create_booking = async (authAxios, formData) => {
  formData.append('subfix_domain', subfix_domain);
  try {
    const res = await authAxios.post(`/api/trips/book-car/`, formData);
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const cancel_booking = async (authAxios, formData) => {
  formData.append('subfix_domain', subfix_domain);
  try {
    const res = await authAxios.post(`/api/trips/cancelled-bookings/`, formData);
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const update_booking = async (authAxios, id, data) => {
  try {
    const res = await authAxios.patch(
      `/api/trips/${id}/`,
      {...data, subfix_domain}
    );
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_all_cars = async (authAxios, start_date = null, end_date = null, booking_id, page) => {
  console.log(booking_id, 'received booking id');
  try {
    let res;
    if (start_date && end_date) {
      res = await authAxios.get(`/api/cars/`, {params: {subfix_domain, start_date, end_date, page: page || 1, booking_id}});
    } else {
      res = await authAxios.get(`/api/cars/`, {params: {subfix_domain, page: page || 1, booking_id}});
    }
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_car = async (authAxios, id, start_date = null, end_date = null) => {
  try {
    let res;
    res = await authAxios.get(`/api/cars/${id}`, {params: {subfix_domain, start_date, end_date}});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_car_books = async (authAxios, all_bookings, customer, params) => {
  try {
    const res = await authAxios.get(`/api/trips/`, {
      params: { all_bookings, subfix_domain, customer, ...params },
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_bookings_vehicle = async (authAxios, vehicle) => {
  try {
    const res = await authAxios.get(`/api/trips/`, {
      params: { subfix_domain, vehicle },
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_car_booking = async (authAxios, id) => {
  try {
    const res = await authAxios.get(`/api/trips/${id}/`, {
      params: { subfix_domain },
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const create_car_image = async (authAxios, formData) => {
  formData.append('subfix_domain', subfix_domain);
  try {
    const res = await authAxios.post(`/api/cars/create-car-image/`, formData);
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const delete_car_image = async (authAxios, id) => {
  try {
    const res = await authAxios.delete(`/api/cars/delete-car-image/${id}/`, {
      params: {
        subfix_domain,
      }
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const reorder_images = async (authAxios, data) => {
  try {
    const res = await authAxios.post(`/api/cars/reorder-images/`, {
      subfix_domain,
      ...data
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_car_validate_reference = async (authAxios, car, start_date, end_date, booking_id = null) => {
  try {
    const res = await authAxios.post(`/api/trips/car-validate/`, { subfix_domain, car, start_date, end_date, submitter: 'admin', booking_id });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const update_full_booking = async (authAxios, data) => {
  try {
    const res = await authAxios.post(`/api/trips/update-full-booking/`, {  ...data, subfix_domain });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    // return Error;
    console.log("Error", Error);
    throw Error
  }
};

export {
  get_discount_price,
  get_car_booking,
  create_booking,
  update_booking,
  cancel_booking,
  get_all_cars,
  get_car_books,
  create_car_image,
  delete_car_image,
  get_bookings_vehicle,
  get_car_validate_reference,
  update_full_booking,
  reorder_images,
  get_car
};
