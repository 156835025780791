import { subfix_domain } from '../api';

const get_notes = async (authAxios, customer) => {
	try {
		const res = await authAxios.get(`/api/customers/notes/${customer}/`, { params: { subfix_domain } });
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const create_note = async (authAxios, data) => {
	try {
		const res = await authAxios.post(`/api/customers/notes/`, { ...data, subfix_domain });
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const delete_note = async (authAxios, id) => {
	try {
		const res = await authAxios.delete(`/api/customers/notes/delete/${id}/`, { params: { subfix_domain } });
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

export { get_notes, create_note, delete_note };