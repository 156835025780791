import React, { useEffect, useRef, useState, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import './index.css';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import CalenderHeader from '../../components/CalenderHeader';

import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';

import { ReactComponent as Calender } from '../../Assets/svg/calender.svg';
import { ReactComponent as Cloak } from '../../Assets/svg/cloak.svg';

import { format, intervalToDuration, addDays } from 'date-fns';

import { VEHICLE_STATUS } from '../../constants';

import {
  set_car_unavailablity,
  get_car_by_id,
  delete_car_unavailablity,
} from '../../API/index';
import { AuthContext } from '../../contexts/AuthContext';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default function SelectUnavailablity() {
  const navigate = useNavigate();
  const calendarRef = useRef();
  // let calendarApi = calendarRef.current?.getApi();
  const { authAxios } = useContext(AuthContext);
  const { car_id } = useParams();
  const [carDetail, setCarDetail] = useState(null);
  const [carUnavailablityData, setCarUnavailablityData] = useState(null);
  const [calenderType, setCalenderType] = useState('dayGridMonth');
  const [fromDate, setFromDate] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [untilDate, setUntilDate] = useState(null);
  const [untilTime, setUntilTime] = useState(null);
  const [reason, setReason] = useState(null);
  // const [vehicleStatus, setVehicleStatus] = useState('on_rent');
  const [changeVehicleStatus, setChangeVehicleStatus] = useState(false);
  const [vehicleStatus, setVehicleStatus] = useState(carDetail?.vehicle_status);
  // console.log(carDetail);

  function renderEventContent(eventInfo) {
    return (
      <div
        // title={eventInfo.event.extendedProps.reason}
        className='price_per_day_event_block unavailability_event_block'
      >
        <div className='event_block_type'>{eventInfo.event.title}</div>
      </div>
    );
  }
  const [selectedCadData, setSelectedCadData] = useState([]);
  // console.log(carDetail);

  useEffect(() => {
    get_car_by_id(authAxios, car_id).then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 304) {
        setCarDetail(res.data);
        setVehicleStatus(res.data?.vehicle_status);
        setCarUnavailablityData(res.data.unavailabilities);
      } else {
        console.log(res);
      }
    });
  }, []);

  const IncrementDay = (initialDate) => {
    return addDays(initialDate, 1);
  };
  // const getInterval = (fromDate, untilDate) => {

  //   const int = intervalToDuration({
  //     start: fromDate,
  //     end: untilDate,
  //     exact: true,
  //   })
  //   console.log('🚀OUTPUT --> int:', int);
    
  //   return int?.days;
  // };
  const getInterval = (fromDate, untilDate) => {
    const MILLIS_IN_DAY = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    const startDate = new Date(fromDate);
    const endDate = new Date(untilDate);

    const timeDifference = endDate.getTime() - startDate.getTime();
    const daysDifference = Math.floor(timeDifference / MILLIS_IN_DAY);
    console.log('🚀OUTPUT --> timeDifference / MILLIS_IN_DAY:', timeDifference / MILLIS_IN_DAY);
    console.log('🚀OUTPUT --> daysDifference:', daysDifference);

    return daysDifference;
};

  // Setting data in the Calender
  useEffect(() => {
    // customPriceData?.forEach((customPrice) => {
    let carCalenderData = [];
    carUnavailablityData?.forEach((customPrice) => {
      const interval = getInterval(
        new Date(customPrice.start_date),
        new Date(customPrice.end_date)
      );
      console.log('🚀OUTPUT --> interval:', interval);
      let tempDate = new Date(customPrice.start_date);
      let tempData = [];
      for (var i = 0; i <= interval; i++) {
        if (i === 0) {
          tempData.push({
            title: `NA`,
            id: customPrice.id,
            date: format(new Date(customPrice.start_date), 'yyyy-MM-dd'),
            allDay: false,
            classNames: '',
            reason: customPrice.reason ?? '',
            start_date: customPrice.start_date,
            end_date: customPrice.end_date,
          });
        } else {
          tempDate = IncrementDay(tempDate);
          tempData.push({
            title: `NA`,
            id: customPrice.id,
            date: format(tempDate, 'yyyy-MM-dd'),
            allDay: false,
            classNames: '',
            reason: customPrice.reason ?? '',
            start_date: customPrice.start_date,
            end_date: customPrice.end_date,
          });
        }
      }
      carCalenderData.push(...tempData);
      setSelectedCadData(carCalenderData);
    });
  }, [carUnavailablityData]);

  const handleChangeStatus = async (e) => {
    setVehicleStatus(e.target.value);
    // const payload = {
    //   vehicle_status: e.target.value,
    // };

    // let res = await update_vehicle(authAxios, car_id, payload);
    // if (res.status !== 200) {
    //   toast.error(Object.values(res.response.data)[0]);
    //   return;
    // }
  };

  const handleSave = (e) => {
    e.preventDefault();
    const unavailableFrom =
      format(fromDate, 'yyyy-MM-dd') + 'T' + fromTime + ':00';
    const unavailableUntil =
      format(untilDate, 'yyyy-MM-dd') + 'T' + untilTime + ':00';
    const car_unavailablity_data = {
      start_date: unavailableFrom,
      end_date: unavailableUntil,
      reason: reason,
      vehicle_status: vehicleStatus,
      is_available: false,
      car: Number(car_id),
    };

    set_car_unavailablity(authAxios, car_unavailablity_data).then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 304) {
        // const payload = {
        //   vehicle_status: vehicleStatus,
        // };

        // update_vehicle(authAxios, car_id, payload).then((resp) => {
        //   if (
        //     resp.status === 200 ||
        //     resp.status === 201 ||
        //     resp.status === 304
        //   ) {
        //     get_car_by_id(authAxios, car_id).then((res) => {
        //       if (
        //         res.status === 200 ||
        //         res.status === 201 ||
        //         res.status === 304
        //       ) {
        //         setCarUnavailablityData(res.data.unavailabilities);
        //       } else {
        //         console.log(res);
        //       }
        //     });
        //   } else if (resp.status !== 200) {
        //     toast.error(Object.values(resp.response.data)[0]);
        //     return;
        //   }
        // });
        get_car_by_id(authAxios, car_id).then((res) => {
          if (
            res.status === 200 ||
            res.status === 201 ||
            res.status === 304
          ) {
            setCarUnavailablityData(res.data.unavailabilities);
          } else {
            console.log(res);
          }
        });
      }
    });

    setFromDate(null);
    setFromTime(null);
    setUntilDate(null);
    setUntilTime(null);
    setReason(null);
  };

  const UnavailabilityModal = (data) => {
    const handleDeleteUnavailibility = (e, id, onClose) => {
      e.preventDefault();

      delete_car_unavailablity(authAxios, id).then((res) => {
        console.log(res);
        get_car_by_id(authAxios, car_id).then((res) => {
          if (res.status === 200 || res.status === 201 || res.status === 304) {
            setCarUnavailablityData(res.data.unavailabilities);
            onClose();
          } else {
            console.log(res);
          }
        });
      });
    };
    console.log(data);

    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='editModal overviewBooknigModal tripDetailModal unavailabilityModal'>
            <div className=''>
              <div className='reason'>
                Unavailability Reason :{' '}
                <span>
                  {data.extendedProps.reason === ''
                    ? 'Not Given'
                    : data.extendedProps.reason}
                </span>
              </div>
              <div className='tripDetail'>
                <div>
                  Start Date :
                  <span>
                    {format(
                      new Date(data.extendedProps.start_date),
                      "MM/dd/yyyy hh:mm aaaaa'm'"
                    )}
                  </span>
                </div>
                <div>
                  End Date :
                  <span>
                    {format(
                      new Date(data.extendedProps.end_date),
                      "MM/dd/yyyy hh:mm aaaaa'm'"
                    )}
                  </span>
                </div>
              </div>
              <div className='deleteUnavailability'>
                <button
                  className='all_users_secondary_button'
                  onClick={(e) =>
                    handleDeleteUnavailibility(e, data._def.publicId, onClose)
                  }
                  style={{ width: '100% !important ' }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const handleEventClick = ({ event, el }) => {
    // console.log(event);
    // console.log(event.extendedProps);
    UnavailabilityModal(event);
    // calendarApi.gotoDate(e.event._instance.range.start);
    // calendarApi.changeView('custom_day');
    // setCalenderType('custom_day');
  };

  return (
    <div className='dashboard page'>
      <Sidebar page='add_vehicle' />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <div className='booking_wrapper'>
            <div className='go_back' onClick={() => navigate(-1)}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5'
                viewBox='0 0 20 20'
                fill='#46a8c8'
                width={30}
              >
                <path
                  fillRule='evenodd'
                  d='M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z'
                  clipRule='evenodd'
                />
              </svg>
            </div>
            <div className='booking_header'>
              <h4>
                Vehicle :{' '}
                <span style={{ fontSize: '22px', color: '#4ec7e8' }}>
                  {' '}
                  {carDetail?.title} - {carDetail?.year} {carDetail?.make}{' '}
                  {carDetail?.model} {carDetail?.trim}
                </span>{' '}
              </h4>
            </div>
            <div className='dailyOverviewCalenderWrapper selectPricePerDayCalenderWrapper'>
              <CalenderHeader
                calenderType={calenderType}
                setCalenderType={setCalenderType}
                calendarRef={calendarRef}
                noSearch={true}
                noFilters={true}
              />
              <div className='selectPricePerDayContainer'>
                <div className='selectPricePerDayCalenderWrapper'>
                  <FullCalendar
                    ref={calendarRef}
                    height={500}
                    headerToolbar={false}
                    viewClassNames={'dailyOverViewCalender'}
                    dayHeaderClassNames='dayHeaderClassNames'
                    dayCellClassNames={'calender_blocks'}
                    eventClassNames='price_per_day_calender_event'
                    plugins={[dayGridPlugin, timeGridPlugin]}
                    initialView='dayGridMonth'
                    events={selectedCadData}
                    // events={getEventsData(calenderDummyData)}
                    eventContent={renderEventContent}
                    // editable={true}
                    // selectable={true}
                    selectMirror={true}
                    eventClick={handleEventClick}
                    // eventClick={(e) => calendarApi.gotoDate('2019-10-12')}
                    // eventClick={(e) => console.log('helo')}
                    // eventClick={function (arg) {
                    //   alert(arg.event.title);
                    //   alert(arg.event.start);
                    // }}
                    dayMaxEvents={true}
                    // buttonIcons={}
                    slotEventOverlap={false}
                    // slotDuration={{ hour: 2 }}
                    slotLabelInterval={{ hour: 1 }}
                    allDaySlot={false}
                    dayMaxEventRows={true}
                    eventMaxStack={calenderType === 'timeGridWeek' ? 1 : 8}
                  />
                </div>
                <div className='selectPricePerDay'>
                  <div className='selectPricePerDay_header'>
                    <div className='title'>Edit Unavailability</div>
                    <Link
                      to={`/select_price_per_day/${car_id}`}
                      className='outlined_btn'
                    >
                      Change Price Per Day
                    </Link>
                  </div>
                  <form
                    action='#'
                    onSubmit={(e) => handleSave(e)}
                    className='card_details'
                  >
                    <div className='info_wrapper'>
                      <div className='info_row '>
                        <div className='field'>
                          Unavailability Reason
                          <FormControl
                            className='edit_dropdown'
                            sx={{ m: 1, minWidth: 120 }}
                          >
                            <TextField
                              required
                              type={'tel'}
                              className='airport_name'
                              labelid='size'
                              id='demo-simple-select'
                              label='Enter Unavailability Reason'
                              defaultValue={reason}
                              onChange={(e) => setReason(e.target.value)}
                            ></TextField>
                          </FormControl>
                        </div>
                      </div>
                      <div style={{ display: 'none' }} className='info_row '>
                        <div className='field'>
                          <Checkbox
                            // required
                            style={{ width: '20px' }}
                            sx={{
                              color: '#8D94BE',
                              // padding: '0',
                              '&.Mui-checked': {
                                color: '#4FC8E9',
                              },
                              // float: 'right',
                              marginRight: '10px',
                            }}
                            size={'small'}
                            checked={changeVehicleStatus}
                            value={changeVehicleStatus}
                            onClick={(e) =>
                              setChangeVehicleStatus(!changeVehicleStatus)
                            }
                          />
                          Vehicle Status During Selected Period
                        </div>
                      </div>
                      <div className='info_row '>
                        {carDetail ? (
                          <div
                            className='field'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              gap: '10px',
                            }}
                          >
                            <FormControl
                              className='vehicle_status'
                              sx={{ m: 1, width: 120, padding: 5 }}
                            >
                              <Select
                                className={`airport_name ${
                                  vehicleStatus || null
                                }`}
                                displayEmpty
                                value={vehicleStatus}
                                // value={carDetail?.vehicle_status}
                                onChange={handleChangeStatus}
                                inputProps={{ 'aria-label': 'Vehicle Status' }}
                              >
                                {VEHICLE_STATUS.map((status) => (
                                  <MenuItem
                                    className={
                                      'airport_name_menu_item ' + status.name
                                    }
                                    value={status.name}
                                  >
                                    {status.title}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            Vehicle Status During Selected Period
                          </div>
                        ) : (
                          <CircularProgress color='success' />
                        )}
                      </div>
                      <div className='info_row info_row_date'>
                        <div className='field'>
                          From
                          <div className='date_time'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                // shouldDisableDate={(23, 24, 25) => true}
                                disablePast
                                label='Date'
                                value={fromDate}
                                onChange={(newValue) => {
                                  console.log(newValue)
                                  if (
                                    JSON.stringify(newValue) &&
                                    JSON.stringify(newValue) !== 'null'
                                  ) {
                                    if (untilDate && newValue > new Date(untilDate)) {
                                      setFromDate(untilDate);
                                    } else {
                                      setFromDate(newValue);
                                    }
                                  }

                                }}
                                components={{
                                  OpenPickerIcon: Calender,
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    className='time_date_picker'
                                    size='small'
                                    {...params}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <FormControl
                                sx={{ m: 1, minWidth: 120 }}
                                size='small'
                              >
                                <InputLabel
                                  className='time_label'
                                  id='from_time'
                                >
                                  Time
                                </InputLabel>
                                <Select
                                  required
                                  className='timeDropdown'
                                  labelid='from_time'
                                  id='from-time-select'
                                  value={fromTime}
                                  label='From time'
                                  onChange={(e) => setFromTime(e.target.value)}
                                  IconComponent={Cloak}
                                  MenuProps={{
                                    classes: {
                                      paper: 'timeDropdown_paper',
                                    },
                                  }}
                                >
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'00:00'}
                                  >
                                    {' '}
                                    12:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'00:30'}
                                  >
                                    {' '}
                                    12:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'01:00'}
                                  >
                                    {' '}
                                    01:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'01:30'}
                                  >
                                    {' '}
                                    01:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'02:00'}
                                  >
                                    {' '}
                                    02:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'02:30'}
                                  >
                                    {' '}
                                    02:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'03:00'}
                                  >
                                    {' '}
                                    03:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'03:30'}
                                  >
                                    {' '}
                                    03:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'04:00'}
                                  >
                                    {' '}
                                    04:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'04:30'}
                                  >
                                    {' '}
                                    04:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'05:00'}
                                  >
                                    {' '}
                                    05:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'05:30'}
                                  >
                                    {' '}
                                    05:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'06:00'}
                                  >
                                    {' '}
                                    06:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'06:30'}
                                  >
                                    {' '}
                                    06:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'07:00'}
                                  >
                                    {' '}
                                    07:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'07:30'}
                                  >
                                    {' '}
                                    07:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'08:00'}
                                  >
                                    {' '}
                                    08:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'08:30'}
                                  >
                                    {' '}
                                    08:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'09:00'}
                                  >
                                    {' '}
                                    09:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'09:30'}
                                  >
                                    {' '}
                                    09:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'10:00'}
                                  >
                                    {' '}
                                    10:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'10:30'}
                                  >
                                    {' '}
                                    10:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'11:00'}
                                  >
                                    {' '}
                                    11:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'11:30'}
                                  >
                                    {' '}
                                    11:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'12:00'}
                                  >
                                    {' '}
                                    12:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'12:30'}
                                  >
                                    {' '}
                                    12:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'13:00'}
                                  >
                                    {' '}
                                    01:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'13:30'}
                                  >
                                    {' '}
                                    01:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'14:00'}
                                  >
                                    {' '}
                                    02:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'14:30'}
                                  >
                                    {' '}
                                    02:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'15:00'}
                                  >
                                    {' '}
                                    03:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'15:30'}
                                  >
                                    {' '}
                                    03:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'16:00'}
                                  >
                                    {' '}
                                    04:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'16:30'}
                                  >
                                    {' '}
                                    04:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'17:00'}
                                  >
                                    {' '}
                                    05:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'17:30'}
                                  >
                                    {' '}
                                    05:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'18:00'}
                                  >
                                    {' '}
                                    06:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'18:30'}
                                  >
                                    {' '}
                                    06:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'19:00'}
                                  >
                                    {' '}
                                    07:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'19:30'}
                                  >
                                    {' '}
                                    07:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'20:00'}
                                  >
                                    {' '}
                                    08:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'20:30'}
                                  >
                                    {' '}
                                    08:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'21:00'}
                                  >
                                    {' '}
                                    09:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'21:30'}
                                  >
                                    {' '}
                                    09:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'22:00'}
                                  >
                                    {' '}
                                    10:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'22:30'}
                                  >
                                    {' '}
                                    10:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'23:00'}
                                  >
                                    {' '}
                                    11:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'23:30'}
                                  >
                                    {' '}
                                    11:30 pm{' '}
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                      <div className='info_row info_row_date'>
                        <div className='field'>
                          Until
                          <div className='date_time'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                disablePast
                                label='Date'
                                value={untilDate}
                                onChange={(newValue) => {
                                  setUntilDate(newValue);
                                }}
                                components={{
                                  OpenPickerIcon: Calender,
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    size='small'
                                    className='time_date_picker'
                                    {...params}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <FormControl
                                sx={{ m: 1, minWidth: 120 }}
                                size='small'
                              >
                                <InputLabel
                                  className='time_label'
                                  id='until_time'
                                >
                                  Time
                                </InputLabel>
                                <Select
                                  required
                                  className='timeDropdown'
                                  labelid='until_time'
                                  id='until-time-select'
                                  value={untilTime}
                                  label='Until time'
                                  onChange={(e) => setUntilTime(e.target.value)}
                                  IconComponent={Cloak}
                                  MenuProps={{
                                    classes: {
                                      paper: 'timeDropdown_paper',
                                    },
                                  }}
                                >
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'00:00'}
                                  >
                                    {' '}
                                    12:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'00:30'}
                                  >
                                    {' '}
                                    12:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'01:00'}
                                  >
                                    {' '}
                                    01:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'01:30'}
                                  >
                                    {' '}
                                    01:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'02:00'}
                                  >
                                    {' '}
                                    02:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'02:30'}
                                  >
                                    {' '}
                                    02:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'03:00'}
                                  >
                                    {' '}
                                    03:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'03:30'}
                                  >
                                    {' '}
                                    03:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'04:00'}
                                  >
                                    {' '}
                                    04:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'04:30'}
                                  >
                                    {' '}
                                    04:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'05:00'}
                                  >
                                    {' '}
                                    05:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'05:30'}
                                  >
                                    {' '}
                                    05:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'06:00'}
                                  >
                                    {' '}
                                    06:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'06:30'}
                                  >
                                    {' '}
                                    06:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'07:00'}
                                  >
                                    {' '}
                                    07:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'07:30'}
                                  >
                                    {' '}
                                    07:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'08:00'}
                                  >
                                    {' '}
                                    08:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'08:30'}
                                  >
                                    {' '}
                                    08:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'09:00'}
                                  >
                                    {' '}
                                    09:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'09:30'}
                                  >
                                    {' '}
                                    09:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'10:00'}
                                  >
                                    {' '}
                                    10:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'10:30'}
                                  >
                                    {' '}
                                    10:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'11:00'}
                                  >
                                    {' '}
                                    11:00 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'11:30'}
                                  >
                                    {' '}
                                    11:30 am{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'12:00'}
                                  >
                                    {' '}
                                    12:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'12:30'}
                                  >
                                    {' '}
                                    12:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'13:00'}
                                  >
                                    {' '}
                                    01:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'13:30'}
                                  >
                                    {' '}
                                    01:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'14:00'}
                                  >
                                    {' '}
                                    02:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'14:30'}
                                  >
                                    {' '}
                                    02:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'15:00'}
                                  >
                                    {' '}
                                    03:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'15:30'}
                                  >
                                    {' '}
                                    03:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'16:00'}
                                  >
                                    {' '}
                                    04:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'16:30'}
                                  >
                                    {' '}
                                    04:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'17:00'}
                                  >
                                    {' '}
                                    05:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'17:30'}
                                  >
                                    {' '}
                                    05:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'18:00'}
                                  >
                                    {' '}
                                    06:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'18:30'}
                                  >
                                    {' '}
                                    06:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'19:00'}
                                  >
                                    {' '}
                                    07:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'19:30'}
                                  >
                                    {' '}
                                    07:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'20:00'}
                                  >
                                    {' '}
                                    08:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'20:30'}
                                  >
                                    {' '}
                                    08:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'21:00'}
                                  >
                                    {' '}
                                    09:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'21:30'}
                                  >
                                    {' '}
                                    09:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'22:00'}
                                  >
                                    {' '}
                                    10:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'22:30'}
                                  >
                                    {' '}
                                    10:30 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'23:00'}
                                  >
                                    {' '}
                                    11:00 pm{' '}
                                  </MenuItem>
                                  <MenuItem
                                    className='airport_name_menu_item'
                                    value={'23:30'}
                                  >
                                    {' '}
                                    11:30 pm{' '}
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Button className='editPriceBtn' type='submit'>
                      Save
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
