import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import './index.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import toast from 'react-hot-toast';
import {
  set_trip_preferences,
  get_car_by_id,
  edit_trip_preferences_by_id,
} from '../../API/index';

import { get_bookings_vehicle } from '../../API/car_booking';

export default function TripPreferences() {
  const navigate = useNavigate();
  const { authAxios } = useContext(AuthContext);
  const { car_id } = useParams();

  const [carDetail, setCarDetail] = useState(null);
  const [advNoticePeriod, setAdvNoticePeriod] = useState();
  const [advDeliveryLocation, setAdvDeliveryLocation] = useState();
  const [advGuestLocation, setAdvGuestLocation] = useState();

  const [bufferHomeLocation, setBufferHomeLocation] = useState();
  // const [bufferDeliveryLocation, setBufferDeliveryLocation] = useState();
  // const [bufferGuestLocation, setBufferGuestLocation] = useState();

  const [shortestPossibleTrip, setShortestPossibleTrip] = useState();
  const [longestPossibleTrip, setLongestPossibleTrip] = useState();

  const [tripPreferences, setTripPreferences] = useState(null);

  useEffect(() => {
    get_bookings_vehicle(authAxios, car_id).then((res) => {
      if (res.status === 200) {
        console.log(res);
      } else {
      }
    });
  }, []);

  useEffect(() => {
    get_car_by_id(authAxios, car_id).then((res) => {
      if (res.status === 200) {
        setCarDetail(res.data);
        setTripPreferences(res.data.trip_preference);
        setBufferHomeLocation(res.data.trip_preference.trip_buffer_time);
        setShortestPossibleTrip(res.data.trip_preference.min_trip_duration);
        setLongestPossibleTrip(res.data.trip_preference.max_trip_duration);
        setAdvNoticePeriod(res.data.trip_preference.advance_notice_time);
      } else {
        console.log(res);
      }
    });
  }, [car_id]);

  const handleSave = (e) => {
    e.preventDefault();
    const preferenecs = {
      trip_buffer_time: bufferHomeLocation,
      min_trip_duration: shortestPossibleTrip,
      max_trip_duration: longestPossibleTrip,
      advance_notice_time: advNoticePeriod,
      car: car_id,
    };

    if (
      longestPossibleTrip &&
      shortestPossibleTrip &&
      Number(longestPossibleTrip) < Number(shortestPossibleTrip)
    ) {
      toast.error(
        'Shortest Possible Trip length cannot be longer than Longest Possible Trip length.'
      );
      return;
    }

    if (
      !longestPossibleTrip ||
      !shortestPossibleTrip ||
      !bufferHomeLocation ||
      !advNoticePeriod
    ) {
      toast.error('There is an empty field.');
      return;
    }

    tripPreferences
      ? edit_trip_preferences_by_id(
          authAxios,
          tripPreferences?.id,
          preferenecs
        ).then((res) => {
          if (res.status === 200 || res.status === 201 || res.status === 304) {
            toast.success('Trip Preferences Updated Successully');
            navigate('/inventory/all_cars/' + car_id);
          } else {
            console.log(res);
          }
        })
      : set_trip_preferences(authAxios, preferenecs).then((res) => {
          if (res.status === 200 || res.status === 201 || res.status === 304) {
            toast.success('Trip Preferences Updated Successully');
            navigate('/inventory/all_cars/' + car_id);
          } else {
            console.log(res);
          }
        });
  };

  return (
    <div className='dashboard page trip_preferences'>
      <Sidebar page='all_cars' />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <div className='addManualCar'>
            <div className='go_back' onClick={() => navigate(-1)}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5'
                viewBox='0 0 20 20'
                fill='#46a8c8'
                width={30}
              >
                <path
                  fillRule='evenodd'
                  d='M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z'
                  clipRule='evenodd'
                />
              </svg>
            </div>
            <h4>Trip Preferences</h4>

            <h6>
              Vehicle :{' '}
              <span style={{ fontSize: '18px', color: '#4ec7e8' }}>
                {' '}
                {carDetail?.title} - {carDetail?.year} {carDetail?.make}{' '}
                {carDetail?.model} {carDetail?.trim}
              </span>{' '}
            </h6>
            <form
              action='#'
              onSubmit={(e) => handleSave(e)}
              className='card_details'
            >
              <div className='info_wrapper'>
                <div
                  // style={{ display: 'none' }}
                  className='preferences_section'
                >
                  <div className='title'>
                    Advance Notice <br />
                    <span>Set How Soon Guets Can Book</span>
                  </div>
                  <div className='info_row '>
                    <div className='field'>
                      <div>
                        <FormControl
                          className='edit_dropdown'
                          sx={{ m: 1, minWidth: 120 }}
                        >
                          <InputLabel id='airport_name'>
                            Select Advance Notice Period
                          </InputLabel>
                          <Select
                            className='airport_name'
                            labelid='numberOfPassengers'
                            id='demo-simple-select'
                            value={Number(advNoticePeriod)}
                            label='Advance Notice Period'
                            onChange={(e) => setAdvNoticePeriod(e.target.value)}
                          >
                            <MenuItem
                              className='airport_name_menu_item'
                              value={1}
                            >
                              1 hour
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={2}
                            >
                              2 hours
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={3}
                            >
                              3 hours
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={6}
                            >
                              6 hours
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={12}
                            >
                              12 hours
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={24}
                            >
                              1 day
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={48}
                            >
                              2 days
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={72}
                            >
                              3 days
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div style={{ display: 'none' }}>
                        Guest Location
                        <FormControl
                          className='edit_dropdown'
                          sx={{ m: 1, minWidth: 120 }}
                        >
                          <InputLabel id='airport_name'>
                            Select Guest Location
                          </InputLabel>
                          <Select
                            className='airport_name'
                            labelid='mileage'
                            id='mileage'
                            value={Number(advGuestLocation)}
                            label='Select Guest Location'
                            onChange={(e) =>
                              setAdvGuestLocation(e.target.value)
                            }
                          >
                            <MenuItem
                              className='airport_name_menu_item'
                              value={1}
                            >
                              1 hour
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={2}
                            >
                              2 hour
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={3}
                            >
                              3 hour
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div style={{ display: 'none' }} className='field'>
                      <div>
                        Delivery Location
                        <FormControl
                          className='edit_dropdown'
                          sx={{ m: 1, minWidth: 120 }}
                        >
                          <InputLabel id='airport_name'>
                            Select Delivery Location
                          </InputLabel>
                          <Select
                            className='airport_name'
                            labelid='vehicleType'
                            id='demo-simple-select'
                            value={Number(advDeliveryLocation)}
                            label='Select Delivery Location'
                            onChange={(e) =>
                              setAdvDeliveryLocation(e.target.value)
                            }
                          >
                            <MenuItem
                              className='airport_name_menu_item'
                              value={1}
                            >
                              1 hour
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={2}
                            >
                              2 hour
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={3}
                            >
                              3 hour
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='preferences_section'>
                  <div className='title'>
                    Trip Buffer <br />
                    <span>
                      Automatically Block Requests Before & After Every Trip
                    </span>
                  </div>
                  <div className='info_row '>
                    <div className='field'>
                      <div>
                        <FormControl
                          className='edit_dropdown'
                          sx={{ m: 1, minWidth: 120 }}
                        >
                          <InputLabel id='airport_name'>
                            Select Trip Buffer
                          </InputLabel>
                          <Select
                            className='airport_name'
                            labelid='numberOfPassengers'
                            id='demo-simple-select'
                            value={Number(bufferHomeLocation)}
                            label='Select Trip Buffer'
                            onChange={(e) =>
                              setBufferHomeLocation(e.target.value)
                            }
                          >
                            <MenuItem
                              className='airport_name_menu_item'
                              value={1}
                            >
                              1 hour
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={2}
                            >
                              2 hours
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={3}
                            >
                              3 hours
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={6}
                            >
                              6 hours
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={12}
                            >
                              12 hours
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={24}
                            >
                              1 day
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='preferences_section'>
                  <div className='title'>
                    Trip Duration <br />
                    <span>
                      Set The Shortest & Longest Trip That Guets Can Book
                    </span>
                  </div>
                  <div className='info_row '>
                    <div className='field'>
                      <div>
                        Shortest Possible Trip
                        <FormControl
                          className='edit_dropdown'
                          sx={{ m: 1, minWidth: 120 }}
                        >
                          <InputLabel id='airport_name'>
                            Select Shortest Possible Trip
                          </InputLabel>
                          <Select
                            className='airport_name'
                            labelid='mileage'
                            id='mileage'
                            value={Number(shortestPossibleTrip)}
                            label='Select Shortest Possible Trip'
                            onChange={(e) =>
                              setShortestPossibleTrip(e.target.value)
                            }
                          >
                            <MenuItem
                              className='airport_name_menu_item'
                              value={1}
                            >
                              1 days
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={2}
                            >
                              2 days
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={3}
                            >
                              3 days
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={4}
                            >
                              4 days
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={5}
                            >
                              5 days
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={6}
                            >
                              6 days
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={7}
                            >
                              7 days
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={10}
                            >
                              10 days
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={14}
                            >
                              14 days
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className='field'>
                      <div>
                        Longest Possible Trip
                        <FormControl
                          className='edit_dropdown'
                          sx={{ m: 1, minWidth: 120 }}
                        >
                          <InputLabel id='airport_name'>
                            Select Longest Possible Trip
                          </InputLabel>
                          <Select
                            className='airport_name'
                            labelid='vehicleType'
                            id='demo-simple-select'
                            value={String(longestPossibleTrip)}
                            label='Select Longest Possible Trip'
                            onChange={(e) =>
                              setLongestPossibleTrip(e.target.value)
                            }
                          >
                            <MenuItem
                              className='airport_name_menu_item'
                              value={'3'}
                            >
                              3 days
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={'7'}
                            >
                              7 days
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={'14'}
                            >
                              14 days
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={'30'}
                            >
                              1 month
                            </MenuItem>
                            <MenuItem
                              className='airport_name_menu_item'
                              value={'1000'}
                            >
                              Any
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='confirmation_btn'>
                <button type='submit'> Save </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
