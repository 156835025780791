import { subfix_domain } from '../api';

const get_all_delivery_charges = async (authAxios, isUsed) => {
  try {
    const res = await authAxios.get(`/api/settings/delivery-charges/`, { params: {subfix_domain} });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const delete_delivery_charge = async (authAxios, id) => {
  try {
    const res = await authAxios.delete(`/api/settings/delivery-charges/${id}/`, {params: {subfix_domain}});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const update_delivery_charge = async (authAxios, id, data) => {
  try {
    const res = await authAxios.put(`/api/settings/delivery-charges/${id}/`, {...data, subfix_domain});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const create_delivery_charge = async (authAxios, data) => {
  try {
    const res = await authAxios.post(`/api/settings/delivery-charges/`, {...data, subfix_domain});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_features = async (authAxios, isUsed) => {
  try {
    const res = await authAxios.get(`/api/settings/features/`, {
      params: { isUsed, subfix_domain },
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const create_feature = async (authAxios, data) => {
  try {
    const res = await authAxios.post(`/api/settings/features/`, {...data, subfix_domain});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const delete_feature = async (authAxios, id) => {
  try {
    const res = await authAxios.delete(`/api/settings/features/${id}/`, { params: { subfix_domain }});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const create_feature_settings = async (authAxios, data) => {
  try {
    const res = await authAxios.post(`/api/settings/add-feature-setting/`, {...data, subfix_domain});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_all_airlines = async (authAxios) => {
  try {
    const res = await authAxios.get(`/api/settings/airlines/`, { params: {subfix_domain} });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const delete_airline = async (authAxios, id) => {
  try {
    const res = await authAxios.delete(`/api/settings/airlines/${id}/`, {params: {subfix_domain}});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const update_airline = async (authAxios, id, data) => {
  try {
    const res = await authAxios.put(`/api/settings/airlines/${id}/`, {...data, subfix_domain});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const create_airline = async (authAxios, data) => {
  try {
    const res = await authAxios.post(`/api/settings/airlines/`, {...data, subfix_domain});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

export {
  get_features,
  create_feature_settings,
  create_feature,
  delete_feature,
  get_all_delivery_charges,
  create_delivery_charge,
  delete_delivery_charge,
  update_delivery_charge,
  get_all_airlines,
  delete_airline,
  update_airline,
  create_airline
};
