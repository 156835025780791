import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import './index.css';

// import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import toast from 'react-hot-toast';
import { AuthContext } from '../../contexts/AuthContext';
import { get_companies } from '../../API/company';
import { create_style_client, update_style_client } from '../../API/theme';
import { subfix_domain } from '../../api';
import { get_themes } from '../../API/theme';

export default function Companies() {
  const { authAxios } = useContext(AuthContext);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [editTheme, setEditTheme] = useState(null);
  const [editMainText, setEditMainText] = useState(
    ''
  );
  const [editMainName, setEditMainName] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [selectedLogoFile, setSelectedLogoFile] = useState();
  const [selectedMainImageFile, setSelectedMainImageFile] = useState();
  const [themes, setThemes] = useState([]);

  const handleUpdateTheme = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    selectedCompany.id ? formData.append('company', Number(selectedCompany.id)) : formData.append('company', 0); //prettier-ignore
    editTheme ? formData.append('theme', Number(editTheme)) : formData.append('theme', 0); //prettier-ignore
    editMainText ? formData.append('main_text', editMainText) : formData.append('main_text', ''); //prettier-ignore
    selectedLogoFile && formData.append('logo_image', selectedLogoFile); //prettier-ignore
    selectedMainImageFile && formData.append('main_image', selectedMainImageFile); //prettier-ignore

    if (selectedCompany.theme?.id) {
      const res = await update_style_client(
        authAxios,
        formData,
        selectedCompany.theme?.id || 0
      );
      if (res.status === 200) {
        setEditModal(false);
        getCompanies();
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }
    } else {
      const res = await create_style_client(authAxios, formData);
      if (res.status === 201) {
        setEditModal(false);
        getCompanies();
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }
    }
  };

  const getCompanies = async () => {
    let res = await get_companies(authAxios);
    if (res.status === 200) {
      setCompanies(
        res.data.filter((company) =>
          subfix_domain ? company.name == subfix_domain : 'renotahoemanager'
        )
      );
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  const getThemes = async () => {
    let res = await get_themes(authAxios);
    if (res.status === 200) {
      setThemes(res.data);
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    getThemes();
  }, []);

  // console.log(editMainText);

  return (
    <div className='dashboard page'>
      <Sidebar page='customize' />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <div className='booking_wrapper'>
            <div className='booking_header'>
              <h4>Customize</h4>
            </div>
            <div className='allUsers_table'>
              <table>
                <thead>
                  <td>Name</td>
                  <td>Theme</td>
                  <td>Main Name</td>
                  <td>Logo</td>
                  <td>Main</td>
                  <td className='table_actions'></td>
                </thead>
                {companies.map((company) => (
                  <tr id={company.id}>
                    <td>{company.name}</td>
                    <td>{company.theme?.theme?.name}</td>
                    <td>
                      <img src={company.theme?.logo_image[0]?.image} width='50' />
                    </td>
                    <td>
                      <img src={company.theme?.main_image[0]?.image} width='50' />
                    </td>
                    <td className='table_actions'>
                      <button
                        onClick={() => {
                          setEditModal(true);
                          setSelectedCompany(company);
                          setEditMainText(company.theme?.main_text);
                          setEditTheme(company.theme?.theme?.id);
                          setSelectedLogoFile(null);
                          setSelectedMainImageFile(null);
                        }}
                      >
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.02717 11.75C4.05842 11.75 4.08967 11.7469 4.12092 11.7422L6.74905 11.2812C6.7803 11.275 6.80999 11.2609 6.83186 11.2375L13.4553 4.61406C13.4698 4.59961 13.4813 4.58244 13.4891 4.56354C13.497 4.54463 13.501 4.52437 13.501 4.50391C13.501 4.48344 13.497 4.46318 13.4891 4.44428C13.4813 4.42538 13.4698 4.40821 13.4553 4.39375L10.8584 1.79531C10.8287 1.76563 10.7897 1.75 10.7475 1.75C10.7053 1.75 10.6662 1.76563 10.6365 1.79531L4.01311 8.41875C3.98967 8.44219 3.97561 8.47031 3.96936 8.50156L3.50842 11.1297C3.49322 11.2134 3.49865 11.2995 3.52425 11.3807C3.54984 11.4618 3.59482 11.5355 3.6553 11.5953C3.75842 11.6953 3.88811 11.75 4.02717 11.75V11.75ZM5.0803 9.025L10.7475 3.35938L11.8928 4.50469L6.22561 10.1703L4.83655 10.4156L5.0803 9.025V9.025Z'
                            fill='white'
                          />
                          <path
                            d='M13.75 13.0625H2.25C1.97344 13.0625 1.75 13.2859 1.75 13.5625V14.125C1.75 14.1938 1.80625 14.25 1.875 14.25H14.125C14.1938 14.25 14.25 14.1938 14.25 14.125V13.5625C14.25 13.2859 14.0266 13.0625 13.75 13.0625Z'
                            fill='white'
                          />
                        </svg>
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
        <Modal
          open={editModal}
          onClose={() => setEditModal(false)}
          aria-labelledby='conf_modal'
          aria-describedby='modal-modal-description'
        >
          <div className='confModal customzie_modal'>
            <br />
            <h4>Edit Theme</h4>
            <div className='info_wrapper'>
              <div className='info_row '>
                <div className='field'>
                  Theme
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <InputLabel id='airport_name'>Select Theme</InputLabel>
                    <Select
                      className='register_companies'
                      labelid='theme'
                      id='theme'
                      value={editTheme}
                      label='Theme'
                      onChange={(e) => setEditTheme(e.target.value)}
                    >
                      {themes.map((theme) => (
                        <MenuItem
                          className='airport_name_menu_item'
                          value={theme.id}
                        >
                          {theme.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {/* <div className='field'>
                  Main Text
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextareaAutosize
                      value={editMainText}
                      className='airport_name textfield'
                      labelId='size'
                      id='demo-simple-select'
                      aria-label='Main Text'
                      placeholder='Main Text'
                      // style={{ width: 200 }}
                      onChange={(e) => setEditMainText(e.target.value)}
                    />
                  </FormControl>
                </div> */}
              </div>
              <div className='info_row '>
                <div className='field'>
                  Main Text
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name'
                      labelId='size'
                      id='demo-simple-select'
                      value={editMainText}
                      label='Main Text'
                      onChange={(e) =>
                        setEditMainText(e.target.value.slice(0, 50))
                      }
                    ></TextField>
                  </FormControl>
                </div>
              </div>
              <div
                style={{
                  margin: '-0.5rem 1rem',
                  textAlign: 'right',
                  fontSize: '12px',
                }}
              >
                {editMainText?.length}/50
              </div>
              <div className='info_row '>
                <div className='field'>
                  Main Company Name
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name'
                      labelId='size'
                      id='demo-simple-select'
                      value={editMainName}
                      label='Main Text'
                      onChange={(e) => setEditMainName(e.target.value)}
                    ></TextField>
                  </FormControl>
                </div>
              </div>
              <div className='info_row '>
                <div className='field'>
                  Logo
                  <Button
                    required
                    className='user_upload_btn edit_dropdown'
                    variant='contained'
                    component='label'
                  >
                    {selectedLogoFile ? (
                      <div style={{ position: 'relative', margin: '-2rem' }}>
                        <div
                          className='removeImageIcon'
                          onClick={() => setSelectedLogoFile(null)}
                          style={{
                            position: 'absolute',
                            right: '-40px',
                            top: '-12px',
                          }}
                        >
                          <svg
                            width='20'
                            height='20'
                            viewBox='0 0 110 110'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                              stroke='#FF2552'
                              strokeWidth='11.625'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                              stroke='#FF2552'
                              strokeWidth='11.625'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                        <img
                          src={URL.createObjectURL(selectedLogoFile)}
                          alt={selectedLogoFile.name.substring(0, 5)}
                          style={{ maxWidth: '225px', height: '138px' }}
                        />
                      </div>
                    ) : (
                      <>
                        <svg
                          width='36'
                          height='36'
                          viewBox='0 0 36 36'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1.125 22.275C1.42337 22.275 1.70952 22.3935 1.9205 22.6045C2.13147 22.8155 2.25 23.1016 2.25 23.4V29.025C2.25 29.6217 2.48705 30.194 2.90901 30.616C3.33097 31.0379 3.90326 31.275 4.5 31.275H31.5C32.0967 31.275 32.669 31.0379 33.091 30.616C33.5129 30.194 33.75 29.6217 33.75 29.025V23.4C33.75 23.1016 33.8685 22.8155 34.0795 22.6045C34.2905 22.3935 34.5766 22.275 34.875 22.275C35.1734 22.275 35.4595 22.3935 35.6705 22.6045C35.8815 22.8155 36 23.1016 36 23.4V29.025C36 30.2185 35.5259 31.3631 34.682 32.207C33.8381 33.0509 32.6935 33.525 31.5 33.525H4.5C3.30653 33.525 2.16193 33.0509 1.31802 32.207C0.474106 31.3631 0 30.2185 0 29.025V23.4C0 23.1016 0.118526 22.8155 0.329505 22.6045C0.540483 22.3935 0.826631 22.275 1.125 22.275Z'
                            fill='#4FC8E9'
                          />
                          <path
                            d='M17.2039 2.5785C17.3084 2.47373 17.4326 2.39061 17.5693 2.33389C17.7059 2.27718 17.8525 2.24799 18.0004 2.24799C18.1484 2.24799 18.2949 2.27718 18.4316 2.33389C18.5683 2.39061 18.6924 2.47373 18.7969 2.5785L25.5469 9.3285C25.7582 9.53974 25.8769 9.82625 25.8769 10.125C25.8769 10.4237 25.7582 10.7103 25.5469 10.9215C25.3357 11.1327 25.0492 11.2514 24.7504 11.2514C24.4517 11.2514 24.1652 11.1327 23.9539 10.9215L19.1254 6.09075V25.875C19.1254 26.1734 19.0069 26.4595 18.7959 26.6705C18.585 26.8815 18.2988 27 18.0004 27C17.7021 27 17.4159 26.8815 17.205 26.6705C16.994 26.4595 16.8754 26.1734 16.8754 25.875V6.09075L12.0469 10.9215C11.9423 11.0261 11.8182 11.1091 11.6815 11.1657C11.5448 11.2223 11.3984 11.2514 11.2504 11.2514C11.1025 11.2514 10.956 11.2223 10.8194 11.1657C10.6827 11.1091 10.5585 11.0261 10.4539 10.9215C10.3493 10.8169 10.2664 10.6927 10.2098 10.5561C10.1532 10.4194 10.124 10.2729 10.124 10.125C10.124 9.97707 10.1532 9.8306 10.2098 9.69393C10.2664 9.55727 10.3493 9.4331 10.4539 9.3285L17.2039 2.5785Z'
                            fill='#4FC8E9'
                          />
                        </svg>
                        Upload Logo
                      </>
                    )}
                    <input
                      required
                      type='file'
                      name='UserDriverLicense'
                      id='UserDriverLicense'
                      hidden
                      // multiple='multiple'
                      disabled={selectedLogoFile ? true : false}
                      onChange={(e) => setSelectedLogoFile(e.target.files[0])}
                      // onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                  </Button>
                </div>
                <div className='field'>
                  Main Image
                  <Button
                    required
                    className='user_upload_btn edit_dropdown'
                    variant='contained'
                    component='label'
                  >
                    {selectedMainImageFile ? (
                      <div style={{ position: 'relative', margin: '-2rem' }}>
                        <div
                          className='removeImageIcon'
                          onClick={() => setSelectedMainImageFile(null)}
                          style={{
                            position: 'absolute',
                            right: '-40px',
                            top: '-12px',
                          }}
                        >
                          <svg
                            width='20'
                            height='20'
                            viewBox='0 0 110 110'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                              stroke='#FF2552'
                              strokeWidth='11.625'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                              stroke='#FF2552'
                              strokeWidth='11.625'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                        <img
                          src={URL.createObjectURL(selectedMainImageFile)}
                          alt={selectedMainImageFile.name.substring(0, 5)}
                          style={{ maxWidth: '225px', height: '138px' }}
                        />
                      </div>
                    ) : (
                      <>
                        <svg
                          width='36'
                          height='36'
                          viewBox='0 0 36 36'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1.125 22.275C1.42337 22.275 1.70952 22.3935 1.9205 22.6045C2.13147 22.8155 2.25 23.1016 2.25 23.4V29.025C2.25 29.6217 2.48705 30.194 2.90901 30.616C3.33097 31.0379 3.90326 31.275 4.5 31.275H31.5C32.0967 31.275 32.669 31.0379 33.091 30.616C33.5129 30.194 33.75 29.6217 33.75 29.025V23.4C33.75 23.1016 33.8685 22.8155 34.0795 22.6045C34.2905 22.3935 34.5766 22.275 34.875 22.275C35.1734 22.275 35.4595 22.3935 35.6705 22.6045C35.8815 22.8155 36 23.1016 36 23.4V29.025C36 30.2185 35.5259 31.3631 34.682 32.207C33.8381 33.0509 32.6935 33.525 31.5 33.525H4.5C3.30653 33.525 2.16193 33.0509 1.31802 32.207C0.474106 31.3631 0 30.2185 0 29.025V23.4C0 23.1016 0.118526 22.8155 0.329505 22.6045C0.540483 22.3935 0.826631 22.275 1.125 22.275Z'
                            fill='#4FC8E9'
                          />
                          <path
                            d='M17.2039 2.5785C17.3084 2.47373 17.4326 2.39061 17.5693 2.33389C17.7059 2.27718 17.8525 2.24799 18.0004 2.24799C18.1484 2.24799 18.2949 2.27718 18.4316 2.33389C18.5683 2.39061 18.6924 2.47373 18.7969 2.5785L25.5469 9.3285C25.7582 9.53974 25.8769 9.82625 25.8769 10.125C25.8769 10.4237 25.7582 10.7103 25.5469 10.9215C25.3357 11.1327 25.0492 11.2514 24.7504 11.2514C24.4517 11.2514 24.1652 11.1327 23.9539 10.9215L19.1254 6.09075V25.875C19.1254 26.1734 19.0069 26.4595 18.7959 26.6705C18.585 26.8815 18.2988 27 18.0004 27C17.7021 27 17.4159 26.8815 17.205 26.6705C16.994 26.4595 16.8754 26.1734 16.8754 25.875V6.09075L12.0469 10.9215C11.9423 11.0261 11.8182 11.1091 11.6815 11.1657C11.5448 11.2223 11.3984 11.2514 11.2504 11.2514C11.1025 11.2514 10.956 11.2223 10.8194 11.1657C10.6827 11.1091 10.5585 11.0261 10.4539 10.9215C10.3493 10.8169 10.2664 10.6927 10.2098 10.5561C10.1532 10.4194 10.124 10.2729 10.124 10.125C10.124 9.97707 10.1532 9.8306 10.2098 9.69393C10.2664 9.55727 10.3493 9.4331 10.4539 9.3285L17.2039 2.5785Z'
                            fill='#4FC8E9'
                          />
                        </svg>
                        Upload Main Image
                      </>
                    )}
                    <input
                      required
                      type='file'
                      name='UserDriverLicense'
                      id='UserDriverLicense'
                      hidden
                      // multiple='multiple'
                      onChange={(e) =>
                        setSelectedMainImageFile(e.target.files[0])
                      }
                      // onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                  </Button>
                </div>
              </div>
            </div>
            <div className='btns'>
              <button className='primary' onClick={handleUpdateTheme}>
                Save
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
