import React, { createContext, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios, { AxiosInstance } from "axios";

import authHeader from "../services/AuthHeader";
import AuthService from "../services/AuthService";
import { API_URL, MAIN_HOST } from '../api';

const DefaultProps = {
	login: () => null,
	logout: () => null,
	company_from_email: () => null,
  canMutate: () => null,
  canView: () => null,
	authAxios: axios,
	user: null
};

export const AuthContext = createContext(DefaultProps);

export const AuthContextProvider = ({
  children,
}) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(() => AuthService.getCurrentUser());

  async function login(email, password, company) {
    const data = await AuthService.login(email, password, company);
    setUser(data);
    return data;
  }

  async function company_from_email(email) {
    const data = await AuthService.company_from_email(email);
    return data;
  }

  function canMutate(pathname) {
    const perm_pages = user?.access_level.items.filter(item => item.mutate == true).map(item => item.page);

    if (perm_pages.includes(pathname)) {
      return true;
    }

    return false;
  }

  function canView(pathname) {
    const perm_pages = user?.access_level.items.filter(item => item.mutate == false).map(item => item.page);

    if (perm_pages.includes(pathname)) {
      return true;
    }

    return false;
  }

  function logout() {
    AuthService.logout();
    setUser(null);
    window.location.href = MAIN_HOST + "/login"
    // navigate("/login");
  }

  // axios instance for making requests
  const authAxios = axios.create();

  // request interceptor for adding token
  authAxios.interceptors.request.use((config) => {
    // add token to request headers
    config.headers = authHeader();
    config.baseURL = API_URL;
    return config;
  });

  authAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        // logout();
      }
      return Promise.reject(error);
    }
  );

  return (
    <AuthContext.Provider value={{ user, login, logout, company_from_email, canMutate, canView, authAxios }}>
      {children}
    </AuthContext.Provider>
  );
};