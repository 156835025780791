import { subfix_domain } from '../api';

const update_style_client = async (authAxios, formData, id) => {
	formData.append('subfix_domain', subfix_domain)
	try {
		const res = await authAxios.post(`/api/style/${id}/`, formData);
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const create_style_client = async (authAxios, formData) => {
	formData.append('subfix_domain', subfix_domain)
	try {
		const res = await authAxios.post(`/api/style/`, formData);
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const get_themes = async (authAxios, data) => {
	try {
		const res = await authAxios.get(`/api/themes/`, { params: { subfix_domain } });
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const create_theme = async (authAxios, data) => {
	try {
		const res = await authAxios.post(`/api/themes/`, { ...data, subfix_domain });
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const update_theme = async (authAxios, data, id) => {
	try {
		const res = await authAxios.put(`/api/themes/${id}/`, { ...data, subfix_domain });
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const delete_theme = async (authAxios, id) => {
	try {
		const res = await authAxios.delete(`/api/themes/${id}/`, { params: { subfix_domain } });
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

export { update_style_client, create_style_client, get_themes, create_theme, update_theme, delete_theme };