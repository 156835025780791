import React, { useCallback, useContext } from 'react';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import CarPhoto from '../../components/CarPhoto';
import './index.css';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { vehicleSelector, setVehicle } from '../../reducers/vehicleSlice';
import { delete_car_image, create_car_image, reorder_images } from '../../API/car_booking';
import { get_car_by_id } from '../../API/index';
import { AuthContext } from '../../contexts/AuthContext';

const MAX_PHOTO_LENGTH = 6;

export default function CarPhotos() {
  const dispatch = useDispatch();
  const { authAxios } = useContext(AuthContext);
  const { isFetching, vehicle } =
    useSelector(vehicleSelector);
  const navigate = useNavigate();

  const moveImage = React.useCallback(async (dragIndex, hoverIndex) => {
    // setImages((prevCards) => {
    //   const clonedCards = [...prevCards];
    //   const removedItem = clonedCards.splice(dragIndex, 1)[0];
    //   clonedCards.splice(hoverIndex, 0, removedItem);
    //   return clonedCards;
    // });
    const drag_id = vehicle.car_images[dragIndex]?.id;
    const hover_id = vehicle.car_images[hoverIndex]?.id;

    const res = await reorder_images(authAxios, {
      car_id: vehicle.id,
      drag_id: drag_id,
      hover_id: hover_id,
    });

    if (res.status === 200) {
      const resCar = await get_car_by_id(authAxios, vehicle.id)
      if (resCar.status === 200) {
        dispatch(
          setVehicle({
            ...vehicle,
            car_images: resCar.data.car_images
          })
        );
      } 
    } else {
      if (res.response.data?.detail != 'Not found.') {
        toast.error(Object.values(res.response.data)[0]);
      }
    }
  }, [vehicle.car_images]);

  const handleSave = async (file, car_image) => {
    const imageFile = file;
    let res = await delete_car_image(authAxios, car_image.id);

    if (res.status === 204 || res.response.data?.detail == 'Not found.') {
      const formData = new FormData();
      formData.append('car', vehicle.id);
      formData.append('image', imageFile);

      res = await create_car_image(authAxios, formData);
      if (res.status === 201) {
        const resCar = await get_car_by_id(authAxios, vehicle.id)
        if (resCar.status === 200) {
          dispatch(
            setVehicle({
              ...vehicle,
              car_images: resCar.data.car_images
            })
          );
        } 
      } else {
        if (res.response.data?.detail != 'Not found.') {
          toast.error(Object.values(res.response.data)[0]);
        }
      }
    }
  };

  const handleDelete = async (e, car_image) => {
    e.preventDefault();

    const res = await delete_car_image(authAxios, car_image.id);

    if (res.status === 204) {
      dispatch(
        setVehicle({
          id: vehicle.id,
          car_images: (vehicle.car_images || []).filter(
            (file) => file.id != car_image.id
          ),
        })
      );
    } else {
      if (res.response.data?.detail != 'Not found.') {
        toast.error(Object.values(res.response.data)[0]);
      }
    }
  };

  const getPhotoHolder = useCallback(() => {
    const length = vehicle.car_images?.length || 0;
    let photos = length > 0 ? vehicle.car_images : [];
    for (let i = 0; i < MAX_PHOTO_LENGTH - length; i++) {
      photos = photos.concat([{ id: 0, image: null, car: vehicle.id }]);
    }
    return photos;
  }, [vehicle.car_images]);

  return (
    <div className='dashboard page'>
      <Sidebar page='add_vehicle' />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />
        <div className='editWrapper pagewrapper add_vehicle'>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isFetching}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
          <div className='editContainer'>
            <div className='header'>
              <h5>{vehicle.vehicleName}</h5>
              <div className='confirmation_btn'>
                <button onClick={(e) => navigate('/inventory/all_cars')}>
                  <ArrowBackIcon />
                </button>
              </div>
            </div>
            <form action='#' className='card_details'>
              <div className='info_wrapper photo_wrapper'>
                {getPhotoHolder().map((car_image, index) => (
                  <CarPhoto 
                    index={index} 
                    car_image={car_image} 
                    handleSave={handleSave} 
                    handleDelete={handleDelete}
                    moveImage={moveImage}
                  ></CarPhoto>
                ))}
              </div>
            </form>
            {/* <Link to='/inventory/all_cars'>
              <div className='confirmation_btn'>
                <button>Save</button>
              </div>
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}
