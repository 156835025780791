import { subfix_domain } from '../api';

const create_reimbursement = async (authAxios, formData) => {
  formData.append('subfix_domain', subfix_domain)
  try {
    const res = await authAxios.post(`/api/reimbursements/`, formData);
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const create_trip_information = async (authAxios, formData) => {
  formData.append('subfix_domain', subfix_domain)
  try {
    const res = await authAxios.post(`/api/trip-informations/`, formData);
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const delete_trip_information= async (authAxios, id) => {
  try {
    const res = await authAxios.delete(`/api/trip-informations/${id}/`, {subfix_domain});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const update_trip_information= async (authAxios, formData, id) => {
  formData.append('subfix_domain', subfix_domain)
  try {
    const res = await authAxios.post(`/api/trip-informations/trip-informations-update/${id}/`, formData);
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const create_trip_image = async (authAxios, formData) => {
  formData.append('subfix_domain', subfix_domain)
  try {
    const res = await authAxios.post(`/api/trip-informations/trip-information-image/`, formData);
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const delete_trip_image = async (authAxios, id) => {
  try {
    const res = await authAxios.delete(`/api/trip-informations/trip-information-image/${id}/`, {params: {subfix_domain}});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_trip_reimbursements = async (authAxios, id) => {
  try {
    const res = await authAxios.get(`/api/reimbursements/trip-reimbursement/`, {params: {booking_id: id, subfix_domain}});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

export { get_trip_reimbursements, create_reimbursement, create_trip_information, delete_trip_information, update_trip_information, create_trip_image, delete_trip_image };
