import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import './index.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { AuthContext } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { get_admins } from '../../API/user';

export default function Settings() {
  const { authAxios, user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState({});

  useEffect(() => {
    get_admins(authAxios).then((res) => {
      if (res.status === 200) {
        const companyUser = res.data.find(item => item.user.username == user.username)
        setUserDetail({
          id: companyUser.id,
          first_name: companyUser.name.trim().split(' ')[0],
          last_name: companyUser.name.trim().split(' ')[1],
          name: companyUser.name,
          email: companyUser.user.email,
          phone: companyUser.phone,
          role: companyUser.role,
          username: companyUser.user.username,
        });
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }
    });
  }, [user.user_id]);

  return (
    <div className='dashboard page'>
      <Sidebar page={'settings'} />
      <div className='hero_content theme_content'>
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <div className='booking_wrapper'>
            <div className='booking_header'>
              <h4>Profile</h4>
              <div className='allUsers_header_actions'>
                <button onClick={() => logout()}>
                  Log out
                </button>
              </div>
            </div>
            <div className='userBasicDetail'>
              <div className='detailContent'>
                <div className='leftDetailContent'>
                  <div className='basicDetailRow'>
                    <img src={userDetail?.license_image_store}></img>
                  </div>
                </div>
                <div className='rightDetailContent'>
                  <div className='basicDetailRow'>
                    <div className={`basicDetail`}>
                      <label>First name :</label>
                      <input
                        type='text'
                        name='first_name'
                        value={userDetail?.first_name}
                        disabled={true}
                      />
                    </div>
                    <div className={`basicDetail`}>
                      <label>Phone no :</label>
                      <input
                        type='text'
                        name='phone'
                        value={userDetail?.phone}
                        disabled={true}
                      />
                    </div>
                    <div className={`basicDetail`}>
                      <label>Role</label>
                      <input
                        type='text'
                        name='role'
                        value={userDetail?.role}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className='basicDetailRow'>
                    <div className={`basicDetail`}>
                      <label>Last name :</label>
                      <input
                        type='text'
                        name='last_name'
                        value={userDetail?.last_name}
                        disabled={true}
                      />
                    </div>
                    <div className={`basicDetail`}>
                      <label>Email :</label>
                      <input
                        type='text'
                        name='email'
                        value={userDetail?.email}
                        disabled={true}
                      />
                    </div>
                    <div className={`basicDetail`}>
                      <label>Username :</label>
                      <input
                        type='text'
                        name='username'
                        value={userDetail?.username}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
