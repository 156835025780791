import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import './index.css';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ReactComponent as Calender } from '../../Assets/svg/calender.svg';
import { create_coupons } from '../../API/coupon';
import toast from 'react-hot-toast';
import { AuthContext } from '../../contexts/AuthContext';
import { sub, isValid } from 'date-fns';

export default function CreateCoupon() {
  const { authAxios } = useContext(AuthContext);
  const navigate = useNavigate();
  const [couponCode, setCouponCode] = useState(null);
  const [couponStartDate, setCouponStartDate] = useState(null);
  const [couponEndDate, setCouponEndDate] = useState(null);
  const [couponType, setCouponType] = useState(null);
  const [couponMode, setCouponMode] = useState(null);
  const [couponAmount, setCouponAmount] = useState(null);
  const [couponDescription, setCouponDescription] = useState(null);
  const handleSave = async (e) => {
    e.preventDefault();

    if (couponStartDate > couponEndDate) {
      toast.error('Coupon start date can not be greater than coupon end date');
      return;
    }

    const payload = {
      // name: codeName,
      code: couponCode || '',
      description: couponDescription,
      is_active: 1,
      start_date: couponStartDate,
      end_date: couponEndDate,
      coupon_type: couponType,
      amount_type: couponMode,
      amount: couponAmount,
    };

    const res = await create_coupons(authAxios, payload);
    if (res.status === 201) {
      toast.success('Created Successfully.');
      // setCodeName(null);
      setCouponCode(null);
      setCouponStartDate(null);
      setCouponEndDate(null);
      setCouponType(null);
      setCouponMode('amount');
      setCouponAmount(null);
      setCouponDescription(null);
      navigate('/discount_coupon');
    } else {
      // toast.error(Object.values(res.response.data));
      toast.error('Invalid input data or duplicated copon code.');
    }
  };
  return (
    <div className='dashboard page create_coupon'>
      <Sidebar page='create_coupon' />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <div className='go_back' onClick={() => navigate(-1)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5'
              viewBox='0 0 20 20'
              fill='#46a8c8'
              width={30}
            >
              <path
                fillRule='evenodd'
                d='M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z'
                clipRule='evenodd'
              />
            </svg>
          </div>
          <div className='addManualCar'>
            <h5>Create Coupon Code</h5>
            <form
              action='#'
              onSubmit={(e) => handleSave(e)}
              className='card_details'
            >
              <div className='info_wrapper'>
                <div className='info_row '>
                  <div className='field fieldName'>
                    Coupon Code <br />
                    <span>Enter Coupon Code</span>
                  </div>
                  <div className='field'>
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        className='airport_name'
                        labelid='size'
                        id='demo-simple-select'
                        value={couponCode}
                        label='Enter Coupon Code'
                        onChange={(e) => setCouponCode(e.target.value)}
                      ></TextField>
                    </FormControl>
                  </div>
                </div>
                {/* <div className='info_row '>
                  <div className='field fieldName'>
                    Coupon Code <br />
                    <span>Enter Coupon Code</span>
                  </div>
                  <div className='field'>
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        required
                        className='airport_name'
                        labelid='size'
                        id='demo-simple-select'
                        value={couponCode}
                        label='FDDSFF'
                        onChange={(e) => setCouponCode(e.target.value)}
                      ></TextField>
                    </FormControl>
                  </div>
                </div> */}
                <div className='info_row info_row_date '>
                  <div className='field fieldName'>
                    Coupon Date
                    <br />
                    <span>Select Coupon Dates</span>
                  </div>
                  <div className='field'>
                    <div className='date_time'>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disablePast
                          label='Start Date'
                          value={couponStartDate}
                          onChange={(newValue) => {
                            setCouponStartDate(newValue);
                          }}
                          components={{
                            OpenPickerIcon: Calender,
                          }}
                          renderInput={(params) => (
                            <TextField
                              required
                              size='small'
                              className='time_date_picker'
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disablePast
                          label='End Date'
                          value={couponEndDate}
                          onChange={(newValue) => {
                            setCouponEndDate(newValue);
                          }}
                          components={{
                            OpenPickerIcon: Calender,
                          }}
                          renderInput={(params) => (
                            <TextField
                              required
                              size='small'
                              className='time_date_picker'
                              {...params}
                            />
                          )}
                          shouldDisableDate={(dateParam) => {
                            //your_condition here
                            //return true to disabled and false to enable
                            // console.log(fromDate);
                            if (!isValid(couponStartDate)) return true;

                            const fromFormatted = sub(couponStartDate, {
                              days: 0,
                            })
                              ?.toISOString()
                              .split('T')[0];
                            const currentDate = dateParam
                              .toISOString()
                              .split('T')[0];
                            // console.log(fromFormatted);

                            return fromFormatted > currentDate ? true : false;
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field fieldName'>
                    Coupon Type
                    <br />
                    <span>Select Coupon or </span>
                  </div>
                  <div className='field'>
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>
                        Select Single or Multi use coupon
                        {/* Select Coupon */}
                      </InputLabel>
                      <Select
                        required
                        className='airport_name'
                        labelid='numberOfPassengers'
                        id='demo-simple-select'
                        value={couponType}
                        label='Select Coupon Type'
                        onChange={(e) => setCouponType(e.target.value)}
                      >
                        <MenuItem
                          className='airport_name_menu_item'
                          value={'D'}
                        >
                          Multi Use
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item'
                          value={'S'}
                        >
                          Single Use
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row'>
                  <div className='field fieldName'>
                    Coupon Amount
                    <br />
                    <span>Enter the Coupon Amount</span>
                  </div>
                  <div className='field couponAmount_row'>
                    <FormControl
                      className='edit_dropdown couponMode'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>Type</InputLabel>
                      <Select
                        className='airport_name'
                        labelid='numberOfPassengers'
                        id='demo-simple-select'
                        value={couponMode}
                        label='Type'
                        onChange={(e) => setCouponMode(e.target.value)}
                      >
                        <MenuItem
                          className='airport_name_menu_item'
                          value={'A'}
                        >
                          Amount
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item'
                          value={'P'}
                        >
                          Percentage
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        required
                        type={'number'}
                        className='airport_name'
                        labelid='size'
                        id='demo-simple-select'
                        value={couponAmount}
                        InputProps={{
                          // startAdornment: '$',
                          inputProps: {
                            min: 0,
                          },
                        }}
                        label={
                          couponMode === 'A'
                            ? 'Enter Coupon Amount'
                            : couponMode === 'P'
                            ? 'Enter Coupon Percentage'
                            : 'Enter Coupon Amount'
                        }
                        onChange={(e) => setCouponAmount(e.target.value)}
                      ></TextField>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field fieldName'>
                    Coupon Description <br />
                    <span>Write the Coupon Description</span>
                  </div>
                  <div className='field'>
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextareaAutosize
                        required
                        className='airport_name couponDescription'
                        labelid='size'
                        id='demo-simple-select'
                        value={couponDescription}
                        placeholder='Write .........'
                        onChange={(e) => setCouponDescription(e.target.value)}
                      ></TextareaAutosize>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className='confirmation_btn save_coupon'>
                <button type='submit'> Save Coupon </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
