import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get_vehicle, delete_vehicle } from '../API/vehicle';

export const getVehicles = createAsyncThunk(
  'vehicle/getVehicles',
  async ({ booked, q, authAxios, orderName, callback, filters }, thunkAPI) => {
    try {
      const response = await get_vehicle(
        authAxios,
        booked,
        q,
        orderName,
        filters
      );
      let data = await response.data;
      if (response.status === 201 || response.status === 200) {
        callback && callback();
        return {
          data: data?.results,
          pagination: {
            count: data?.count,
            previous: data?.previous,
            next: data?.next,
            total_pages: data?.total_pages,
            page_number: data?.page_number,
          },
        };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({
        message: JSON.stringify(e.response.data),
      });
    }
  }
);

export const deleteVehicle = createAsyncThunk(
  'vehicle/deleteVehicle',
  async ({ id, authAxios, callback }, thunkAPI) => {
    try {
      const response = await delete_vehicle(authAxios, id);
      if (response.status === 204) {
        callback();
        return [];
      } else {
        return thunkAPI.rejectWithValue([]);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({
        message: JSON.stringify(e.response.data),
      });
    }
  }
);

const initVehicle = {
  id: 0,
  vehicleName: '',
  vehiclePrice: '',
  vehicleStatus: '',
  mileagePerDay: 0,
  mileagePerWeek: 0,
  mileagePerMonth: 0,
  vehicleType: '',
  noOfPass: 0,
  driveTrain: '',
  model: '',
  vehicleTrim: '',
  vehicleMake: '',
  mileageOverageFee: '',
  vehicleColor: '',
  fuelType: '',
  size: '',
  car_features: [],
  selectedFile: null,
  selectedFiles: [],
  carDescription: '',
  licensePlate: '',
  interiorColor: '',
  vinNumber: '',
  orderName: 'title',
};

export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState: {
    isFetching: false,
    isSuccess: true,
    errorMessage: '',
    isError: false,
    vehicles: [],
    vehicle: initVehicle,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.vehicle = initVehicle;

      return state;
    },
    setVehicle: (state, { payload }) => {
      delete payload.authAxios;
      state.vehicle = {
        ...state.vehicle,
        ...payload,
      };
      return state;
    },
  },
  extraReducers: {
    [getVehicles.fulfilled]: (state, { payload }) => {
      delete payload.authAxios;
      state.vehicles = payload;
      state.isFetching = false;
      state.isSuccess = true;
    },
    [getVehicles.pending]: (state) => {
      state.isFetching = true;
    },
    [getVehicles.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = 'Error';
    },
  },
});

export const { clearState, setVehicle } = vehicleSlice.actions;

export const vehicleSelector = (state) => state.vehicle;
