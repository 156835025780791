import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import './index.css';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { get_packages, create_package, delete_package } from '../../API/package';
import toast from 'react-hot-toast';
import { AuthContext } from "../../contexts/AuthContext";
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import { pages } from '../../constants';

export default function Packages() {
  const { authAxios } = useContext(AuthContext);
  const [permissions, setPermissions] = useState([]);
  const [selectedPacakage, setSelectedPacakage] = useState();
  const [selectedSname, setSelectedSname] = useState();
  const [createModal, setCreateModal] = useState(false);
  const [createPackName, setCreatePackName] = useState('');
  const [createPackLevel, setCreatePackLevel] = useState(0);
  const [createPackPrice, setCreatePackPrice] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);

  // const packages = ['Trial', 'Standard', 'Premium', 'Ultimate'];

  const getPermissions = async () => {
    let res = await get_packages(authAxios);
    if (res.status === 200) {
      setPermissions(res.data.filter(item => item.name != 'Owner' && item.name != 'Free').map(pack => ({...pack, items: pack.items.map(item => item.page)})))
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  const getActivePages = (pack) => {
    const _package = permissions.find(pack_ => pack_.name == pack);
    let items = [];
    pages.forEach(page => {
      if(_package &&  _package.items.includes(page.link)) {
        items.push({...page, isUsed: true});
      }
      else items.push({...page, isUsed: false});
    });
    return items;
  }

  const checked = function(link, pack) {
    const _package = permissions.find(pack_ => pack_.name == pack);
    return _package && _package.items.includes(link);
  }

  const handleChange = (e, link, pack) => {
    setPermissions(permissions.map(perm => {
      if(perm.name == pack) {
        return {
          ...perm,
          items: checked(link, pack)? perm.items.filter(perm => perm != link): perm.items.concat(link)
        }
      }
      else return perm;
    }));
  }

  const handleSave = async (pack) => {
    const _package = permissions.find(pack_ => pack_.name == pack);
    if(_package) {
      const formData = new FormData();
      formData.append('name', _package.name);
      formData.append('sname', _package.sname);
      formData.append('level', _package.level);
      formData.append('price', _package.price);
      for(let item of _package.items) {
        formData.append('items', item);
      }
      let res = await delete_package(authAxios, _package.id);
      if (res.status !== 204) {
        return;
      }
      res = await create_package(authAxios, formData);
      if (res.status === 201) {
        await getPermissions();
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }
    }
  }

  const handleCreatePackage = async () => {
    const formData = new FormData();
    formData.append('name', createPackName);
    formData.append('sname', createPackName);
    formData.append('level', createPackLevel);
    formData.append('price', createPackPrice);
    const res = await create_package(authAxios, formData);
    if (res.status === 201) {
      await getPermissions();
      setCreateModal(false);
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  }

  const handleUpdatePackage = async () => {
    const formData = new FormData();
    const _package = permissions.find(pack_ => pack_.id == selectedPacakage);
    formData.append('name', createPackName);
    formData.append('sname', selectedSname);
    formData.append('level', createPackLevel);
    formData.append('price', createPackPrice);
    for(let item of _package.items) {
      formData.append('items', item);
    }

    let res = await delete_package(authAxios, selectedPacakage);
    if (res.status === 204) {
      res = await create_package(authAxios, formData);
      if (res.status === 201) {
        await getPermissions();
        setCreateModal(false);
        setSelectedPacakage(null)
      } else {
        toast.error(Object.values(res.response.data)[0]);
        setSelectedPacakage(null)
      }
    }
    else {
      toast.error(Object.values(res.response.data)[0]);
    }
  }

  const handleDelete = async () => {
    const res = await delete_package(authAxios, selectedPacakage);
    if (res.status === 204) {
      await getPermissions();
      setDeleteModal(false);
      setSelectedPacakage(null)
    } else {
      toast.error(Object.values(res.response.data)[0]);
      setSelectedPacakage(null)
    }
  }

  useEffect(() => {
    getPermissions();
  }, []);

  return (
    <div className='dashboard page'>
      <Sidebar page='packages' />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <div className='booking_header'>
            <h4>All Packages</h4>
            <div className='allUsers_header_actions'>
              <button onClick={() => setCreateModal(true) }>Add Package</button>
            </div>
          </div>
          <div className='booking_wrapper'>
            {permissions.map((pack, index) => <div className='carFeatures' key={index}>
              <div className='carFeatures_header'>
                <h5>{pack.name}</h5>
                <div className='car_select_btn'>
                  <button 
                    className='secondary margin-right10' 
                    onClick={(e) => handleSave(pack.name)
                  }> Save </button>
                  <button 
                    className='primary margin-right10' 
                    onClick={(e) => {
                      setCreateModal(true);
                      setCreatePackLevel(pack.level);
                      setCreatePackName(pack.name);
                      setCreatePackPrice(pack.price);
                      setSelectedPacakage(pack.id);
                      setSelectedSname(pack.sname)
                    }
                  }> Edit </button>
                  <button 
                    className='secondary delete-package-btn margin-right10' 
                    onClick={(e) => {
                      setDeleteModal(true);
                      setSelectedPacakage(pack.id);
                    }
                  }> Delete </button>
                </div>
              </div>
              <div className='features'>
                <FormGroup className='feature_checkboxs'>
                  {getActivePages(pack.name).map((permission, index) => (
                    <FormControlLabel
                      className='feature_checkbox_wrapper'
                      key={index}
                      control={
                        <Checkbox
                          className='feature_checkbox'
                          checked={permission.isUsed}
                          onChange={(e) =>
                            handleChange(e, permission.link, pack.name)
                          }
                        />
                      }
                      label={(
                        <div className='feature_checkbox_label_wrapper'>
                          <div className="feature_checkbox_label">{permission.name}</div>
                        </div>
                      )}
                    />
                  ))}
                </FormGroup>
              </div>
            </div>)}
          </div>
          <Modal
            open={createModal}
            onClose={() => setCreateModal(false)}
            aria-labelledby='conf_modal'
            aria-describedby='modal-modal-description'
          >
            <div className='confModal create_feature_modal'>
              <br />
              <h4>Create a Package</h4>
              <form
                className='add_new_airport'
                onSubmit={(e) => {
                  e.preventDefault();
                  if(selectedPacakage) {
                    handleUpdatePackage();
                  }
                  else handleCreatePackage();
                }}
              >
                <div className='info_wrapper'>
                  <div className='info_row '>
                    <div className='field'>
                      Package Name
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <TextField
                          className='airport_name'
                          labelId='size'
                          id='demo-simple-select'
                          defaultValue={createPackName}
                          label='Enter Pacakge Name'
                          onChange={(e) => setCreatePackName(e.target.value)}
                        ></TextField>
                      </FormControl>
                    </div>
                  </div>
                  <div className='info_row '>
                    <div className='field'>
                      Package Level
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <TextField
                          className='airport_name'
                          labelId='size'
                          required
                          id='demo-simple-select'
                          type={'number'}
                          defaultValue={createPackLevel}
                          label='Enter Package Level'
                          onChange={(e) =>
                            setCreatePackLevel(e.target.value)
                          }
                        ></TextField>
                      </FormControl>
                    </div>
                  </div>
                  <div className='info_row '>
                    <div className='field'>
                      Package Price
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <TextField
                          className='airport_name'
                          labelId='size'
                          required
                          id='demo-simple-select'
                          type={'number'}
                          defaultValue={createPackPrice}
                          label='Enter Package Price'
                          onChange={(e) =>
                            setCreatePackPrice(e.target.value)
                          }
                        ></TextField>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className='btns'>
                  <button
                    onClick={() => setCreateModal(false)}
                    className='secondary'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='primary'
                    // onClick={handleCreateDeliveryCharges}
                  >
                    {selectedPacakage? 'Save': 'Create'}
                  </button>
                </div>
              </form>
            </div>
          </Modal>
          <Modal
            open={deleteModal}
            onClose={() => setDeleteModal(false)}
            aria-labelledby='conf_modal'
            aria-describedby='modal-modal-description'
          >
            <div className='confModal'>
              <svg
                width='110'
                height='110'
                viewBox='0 0 110 110'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>{' '}
              <br />
              <h4>Are You Sure?</h4>
              <p>
                Do you really want to cancel this booking. This process cannot be undo.
              </p>
              <div className='btns'>
                <button onClick={() => setDeleteModal(false)} className='secondary'>
                  Cancel
                </button>
                <button
                  className='primary'
                  onClick={(e) => handleDelete(e)}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}
