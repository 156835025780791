import React, { useState, useEffect, useContext, useCallback } from 'react';
import './index.css';
import Modal from '@mui/material/Modal';
import Sidebar from '../../components/Sidebar';
import MobileNavbar from '../../components/MobileNavbar';
import Header from '../../components/Header';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { bookingSelector, clearState } from '../../reducers/bookingSlice';
import { create_booking, update_full_booking, get_discount_price } from '../../API/car_booking';
import { get_system_configurations } from '../../API/system_configurations';
import { create_conversation } from '../../API/messages';
import { get_car_by_id } from '../../API/index';
import { get_admins } from '../../API/user';
import { setBooking } from '../../reducers/bookingSlice';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useStripe, useElements, CardNumberElement, CardExpiryElement ,CardCvcElement} from '@stripe/react-stripe-js';
import { AuthContext } from '../../contexts/AuthContext';
import { Socket_URL, subfix_domain } from '../../api';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { PaymentElement} from '@stripe/react-stripe-js';
import { confirmAlert } from 'react-confirm-alert';


let __message__ = '';
let __booking_id__ = 0;

export default function PaymentDetails({}) {
  const navigate = useNavigate();
  const { user, authAxios } = useContext(AuthContext);
  const [carDetail, setCarDetail] = useState(null);
  const [toggleCvv, setToggleCvv] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [cardCvv, setCardCvv] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [confModal, setConfModal] = useState(false);
  const { wrapperProps, getCardImageProps, getCardNumberProps } =
    usePaymentInputs();
  const [discountCode, setDiscountCode] = useState('');
  const [discountPrice, setDiscountPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [systemConfigurations, setSystemConfigurations] = useState(null);
  const [disableConfirmButton, setDisableConformButton] = useState(false);
  // const [allowCheckoutTax, setAllowCheckoutTax] = useState(false);
  const { booking } = useSelector(bookingSelector);
  const [disableValidPeriod, setDisableValidPeriod] = useState(
    booking.isByPassed || false
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  console.log(booking.carCurrentPrice)

  const [receivedPriceObject, setReceivedPriceObject] = useState({});

  useEffect(() => {
      const receivedFinalPrice = localStorage.getItem('finalPrice');
      const receivedDeliveryCharges = localStorage.getItem('deliveryCharge');
      const receivedTaxAmount = localStorage.getItem('taxAmount');
      const receivedYongerFee = localStorage.getItem('yonger_fee');

      setReceivedPriceObject({
        receivedFinalPrice,
        receivedDeliveryCharges,
        receivedTaxAmount,
        receivedYongerFee
      });
  }, [])

  const numtoFixed = (num) => (Math.round(num * 100) / 100).toFixed(2);

  const stripe = useStripe();
  const elements = useElements();
  const formatDiscountDate = (date = '') => {
    return (
      date.substring(0, 10) +
      'T' +
      (date.substring(11, 16) === 'null' ? '00:00' : date.substring(11, 16))
    );
  };
  console.log(booking, '<-- booking object')

  // console.log(formatDiscountDate(booking.end_date));
  // console.log(formatDiscountDate(booking.start_date));

  useEffect(() => {
    booking &&
      booking?.car &&
      get_car_by_id(authAxios, booking?.car).then((res) => {
        if (res.status === 200 || res.status === 201 || res.status === 304) {
          setCarDetail(res.data);
        } else {
          console.log(res);
        }
      });
  }, [booking]);

  useEffect(() => {
    get_system_configurations(authAxios).then((res) => {
      setSystemConfigurations(res.data[0] || {});
    });
  }, []);
  useEffect(() => {
    // console.log('discount Date', formatDiscountDate(booking.start_date));
    // console.log(booking.start_date);
    const discount_data = {
      car_id: booking.car,
      start_date: formatDiscountDate(booking.start_date),
      end_date: formatDiscountDate(booking.end_date),
    };
    systemConfigurations &&
      get_discount_price(authAxios, discount_data).then((res) => {


        if(res.data.discount + booking?.coupon_amount > res.data.total_price)
          {
            setDiscountPrice(res.data.total_price);
            setDiscountedPrice(res.data.total_price - booking?.coupon_amount);
          }else{
            setDiscountPrice(res.data.discount + booking?.coupon_amount);
            setDiscountedPrice(res.data.total_price - booking?.coupon_amount);
          }
        // console.log(res);

      });
  }, [systemConfigurations]);


  const conversationName = booking?.phone &&booking?.phone
    .replaceAll(' ', '')
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll('-', '')
    .replace('+', '');

  const { readyState, sendJsonMessage } = useWebSocket(Socket_URL + `chats/`, {
    queryParams: {
      token: user ? user.token : 'anomynous',
      subfix_domain,
    },
    onOpen: () => {
    },
    onClose: () => {
      console.log('Disconnected!');
    },
    onMessage: (e) => {
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const handleConfirmStripe = async (event) => {
    event.preventDefault();

    // if (!allowCheckoutTax) {
    //   toast.error('You should incldue Tax.');
    //   return;
    // }

    if (!stripe || !elements) {
      return;
    }

    let result;
    try {
      result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      });
    } catch(err) {
      console.log(err);
      toast.error("An error occured while trying to connect the card to stripe")
      return;
    }

    if (!result.paymentMethod?.id) {
      toast.error("An error occured while trying to connect the card to stripe")
      return;
    }


    if (!booking.price) {
      toast.error('Price is invalid.');
      return;
    }
    if (!booking.email) {
      toast.error('Email is invalid.');
      return;
    }

    setDisableConformButton(true);

    const formData = new FormData();
    formData.append('customer', booking.customer);
    formData.append('start_date', booking.start_date);
    formData.append('end_date', booking.end_date);
    formData.append('car', booking.car);
    formData.append('flight_number', booking.flight_number);
    formData.append('airline_name', booking.airline_name);
    formData.append('destination_location', booking.dropoffLocation?.airport);
    formData.append('pickup_location', booking.pickupLocation?.airport);
    formData.append('address', booking.address);
    formData.append('customer_name', booking.customerName);
    formData.append('booked_by', user.first_name + ' ' + user.last_name);
    formData.append('card_holder', cardHolderName);
    formData.append('email', booking.email);
    formData.append('username', booking.username);
    formData.append('phone', booking.phone);
    formData.append('postal_code', booking.postal_code);
    formData.append('discount_price', discountPrice);
    formData.append('disable_valid_period', disableValidPeriod);
    formData.append('submitter', 'admin');
    formData.append('age', parseInt(booking.age));
    formData.append('daily_price', booking?.daily_price);
    formData.append('send_confirmation', booking.send_confirmation || 0);
    formData.append('payment_method_id', result.paymentMethod?.id);
    // formData.append(
    //   'price',
    //   numtoFixed(
    //     bookingAmount +
    //       Number(booking?.deliveryCharge || 0) +
    //       taxAmount -
    //       ((booking?.previous_amount || 0) + (discountPrice ?? 0))
    //   )
    //   // numtoFixed(Number(discountedPrice) * rate ??Number(booking.price + Number(booking?.deliveryCharge)) * rate)
    // ); // you can ignore this field * 100
    formData.append('price', receivedPriceObject.receivedFinalPrice);
      console.log(formData)
    // if (booking.payment_type) {
    //   formData.append('method', booking.payment_type);
    // }

    if (booking.coupon_code) {
      formData.append('coupon_code', booking.coupon_code);
    }

    setLoading(true);

    let res;
    if (booking.id) {
      res = await update_full_booking(authAxios, {
        ...booking,
        destination_location: booking.dropoffLocation?.airport,
        pickup_location: booking.pickupLocation?.airport,
        customer_name: booking.customerName,
        card_holder: cardHolderName,
        account_number: cardNumber,
        exp_month: expiryDate,
        exp_year: expiryYear,
        cvc: cardCvv,
        method: 'manual',
        after_price: numtoFixed(
          receivedPriceObject.receivedFinalPrice
        ),
        diff_price: numtoFixed(
          receivedPriceObject.receivedFinalPrice -
            ((booking?.previous_amount || 0) + (discountPrice ?? 0))
        ),
        discount_price: discountPrice,
        payment_type: booking.payment_type || 'auto',
        chargeBill: booking.chargeBill || 'notCharge',
        editor: user.first_name + ' ' + user.last_name,
        daily_price: booking?.daily_price
      });
    }
    else res = await create_booking(authAxios, formData);

    if (res.status === 201 || // create_booking
        res.status === 200)  // update_booking_details
    {
      toast.success(booking.id? 'Updated Successfully.': 'Created Successfully.');
      dispatch(clearState({}));
      setConfModal(false);

      setDiscountCode('');
      setDiscountPrice('');
      setCardNumber('');
      setCardCvv('');
      setExpiryDate('');
      setExpiryYear('');
      setCardHolderName('');
      setPostalCode('');

      navigate(booking.id? '/trips': '/add_booking');
      __message__ = `customer: ${cardHolderName}, email: ${
        booking.email
      }, period: ${booking.start_date.split('T')[0]}-${
        booking.end_date.split('T')[0]
      }, price: $${booking.price * (1 + (systemConfigurations.tax || 0))}`;
      __booking_id__ = res.data.id;

      const apiRes = await create_conversation(authAxios, {
        phone: booking.phone
      });

      if (apiRes.status === 201 || apiRes.status === 200) {
        setLoading(false);
        setDisableConformButton(false);
        navigate('/add_booking');
      }

      sendJsonMessage({
        type: 'chat_message',
        message: __message__,
        message_type: 'CAR_BOOKED',
        reference_id: __booking_id__,
        direction: 'OUTBOUND',
        name: user.username,
        conversation: conversationName
      });
    } else {
      console.dir(res.response)
      Object.keys(res.response.data).forEach((key) => {
        toast.error(stripeErrorMesage(res.response.data[key]));
      });
      setDisableConformButton(false);
      setLoading(false);
    }
  };

  const stripeErrorMesage = (msg) => {
    if (msg.includes('insufficient funds')) {
      return 'Your card has insufficient funds.'
    }
    if (msg.includes('Your card was declined')) {
      return 'Your card was declined; please contact your card issuer.'
    }
    if (msg.includes('while processing your card')) {
      return 'An error occurred while processing your card. Try again later.'
    }
    if (msg.includes('Your card has expired')) {
      return 'Your card has expired.'
    }
    if (msg.includes("Your card's security code is incorrect")) {
      return "Your card's security code is incorrect."
    }
    if (msg.includes('The entered number')) {
      return 'The entered number is not a valid credit card number, please check that the number entered matches the number on your card.'
    }
    if (msg.includes('Stripe is not connected.')) {
      return 'Stripe is not connected.'
    }
    if (msg.includes('No such product')) {
      return 'An error occurred while processing the subscription. Please use the other plan.'
    }
    if (msg.includes('stripe error')) {
      return ' Your card was declined; please contact your card issuer.'
    }
    return msg;
  }
  const bookingAmount =
    Number(discountedPrice) ?? booking?.daily_price * booking?.days;

  const taxAmount =
    ((systemConfigurations?.tax * 100).toFixed(2) / 100) *
    (Number(discountedPrice) ?? booking?.daily_price * booking?.days);

  const total_price_without_young_fee = (
    bookingAmount +
      Number(booking?.deliveryCharge || 0) +
      taxAmount -
      (booking?.previous_amount || 0)
  )

  const young_fee = booking.isYoung? total_price_without_young_fee * (systemConfigurations?.young_renter_fee ?? 0)/100: 0;

  console.log("bookingprice", booking)

  return (
    <div className='dashboard page paymentDetails_wrapper'>
      <Sidebar page='payment' />
      {/* <CardElement id='card-element' className='card-element' /> */}
      <div className='hero_content paymentDetails'>
        <MobileNavbar />
        <Header />
        <form
          action='#'
          onSubmit={(e) => {
            e.preventDefault();
            setConfModal(true);
          }}
          className='card_details paymentPage'
          autocomplete='off'
        >
          <div className='formContainer'>
            <div className='go_back' onClick={() => {
              // dispatch(setBooking({ ...booking, car: null }));
              navigate(-1);
            }}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5'
                viewBox='0 0 20 20'
                fill='#46a8c8'
                width={30}
              >
                <path
                  fillRule='evenodd'
                  d='M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z'
                  clipRule='evenodd'
                />
              </svg>
            </div>
            <div className='pagewrapper paymentDetails_heading'>
              Payment Details
            </div>

            <div className='totalPrice'>
              Total Price: $
              {receivedPriceObject.receivedFinalPrice}
            </div>
            
            <div className='card_number' style={{marginBottom: '1rem'}}>
              <div className='card_number_header'>
                <div className='card_label'>
                  Card Number <br />
                  <span>Enter the 16-digit card number on the card</span>
                </div>
              </div>
              <div className='card-num-element'>
                <div className='stripe-card-svg'>
                  <svg {...getCardImageProps({ images })} />
                </div>
                <CardNumberElement options={
                    {
                      style: {
                        base: {
                          color: '#fff',
                          fontSize: '16px',
                          padding: '24px',
                        },
                      },
                      classes: {
                        base: 'StripeElement StripeElement-rtcar-card-num'
                      }
                    }
                  }/>
              </div>
            </div>
            <div className='cardRow cvv_number'>
              <div className='cardRow_text  cvv_number_text'>
                <div className='card_label'>
                  CVC Number <br />
                  <span>Enter 3 or 4 digit number on the card</span>
                </div>
              </div>
              <div className='cardRow_input cvv_number_input'>
                <CardCvcElement options={
                  {
                    style: {
                      base: {
                        color: '#fff',
                        fontSize: '16px',
                        padding: '24px',
                      },
                    },
                  }
                } />
              </div>
            </div>
            <div className='cardRow cvv_number'>
              <div className='cardRow_text  cvv_number_text'>
                <div className='card_label'>
                Expiry Date <br />
                  <span>Enter the expiry date on the card</span>
                </div>
              </div>
              <div className='cardRow_input cvv_number_input'>
                <CardExpiryElement  options={
                    {
                      style: {
                        base: {
                          color: '#fff',
                          fontSize: '16px',
                          padding: '24px',
                          textAlign: 'center'
                        },
                      },
                    }
                  } />
              </div>
            </div>
            <div className='cardRow nameOnCard'>
              <div className='cardRow_text nameOnCard_text'>
                <div className='card_label'>
                  Name on Card <br />
                  <span>Enter the name on card</span>
                </div>
              </div>
              <div className='cardRow_input nameOnCard_input'>
                <input
                  value={cardHolderName}
                  onChange={(e) => setCardHolderName(e.target.value)}
                  required
                  type='text'
                  placeholder='Name'
                  name='cardHolderName'
                  id='cardHolderName'
                />
              </div>
            </div>
            <div className='cardRow postalCode'>
              <div className='cardRow_text postalCode_text'>
                <div className='card_label'>
                  Postal Code <br />
                  <span>Enter the postal code</span>
                </div>
              </div>
              <div className='cardRow_input postalCode_input'>
                <input
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  required
                  type='number'
                  placeholder='Postal code'
                  name='cardHolderName'
                  id='cardHolderName'
                />
              </div>
            </div>

            <div className='confirmation_btn'>
              <button
                //   disabled={cardNumber !== '' &&cardCvv !== '' &&expiryDate !== '' &&expiryYear !== '' &&cardHolderName !== '' &&postalCode !== ''? false: true}
                type='submit'
                disabled={!stripe}
              >
                Confirmation
              </button>
              <Modal
                open={confModal}
                onClose={() => setConfModal(false)}
                aria-labelledby='conf_modal'
                aria-describedby='modal-modal-description'
              >
                <div style={{ height: 'auto' }} className='confModal'>
                  <div className='conf_hero_img'>
                    <img src={booking?.img} alt='car_1' />
                  </div>
                  <div className='conf_detail'>
                    <div className='conf_detail_header'>Payment Detail</div>
                    <div className='payment_row total_payment_row '>
                      <span>Total Price:</span>
                      <span>
                        ${' '}
                        {receivedPriceObject.receivedFinalPrice}
                      </span>
                    </div>
                    <div className='payment_row'>
                      <span>Tax</span>
                      <span>
                        {numtoFixed(systemConfigurations?.tax * 100) || 0}%
                      </span>
                    </div>
                    <div className='payment_row'>
                      <span>
                        {carDetail?.make} {carDetail?.model}
                      </span>
                      <span>$ {numtoFixed(booking?.price)}</span>
                    </div>
                    <div className='payment_row'>
                      <span>Discount</span>
                      <span>$ {numtoFixed(discountPrice) ?? '00.00'}</span>
                    </div>
                    <div className='payment_row'>
                      <span>Delivery Charges</span>
                      <span>
                        $ {numtoFixed(booking?.deliveryCharge || 0) ?? 0}
                      </span>
                    </div>
                    {booking?.previous_amount && (
                      <div className='payment_row'>
                        <span>Init Amount </span>
                        <span>
                          $ {numtoFixed(booking?.previous_amount) ?? 0}
                        </span>
                      </div>
                    )}
                    {/* <div className='payment_row'>
                      <span>Allow for checkout with tax</span>
                      <span>
                        <Checkbox
                          required
                          style={{ width: '20px' }}
                          sx={{
                            color: '#8D94BE',
                            // padding: '0',
                            '&.Mui-checked': {
                              color: '#4FC8E9',
                            },
                            float: 'right',
                          }}
                          size={'small'}
                          checked={allowCheckoutTax}
                          value={allowCheckoutTax}
                          onClick={(e) =>
                            setAllowCheckoutTax(!allowCheckoutTax)
                          }
                        />
                      </span>
                    </div> */}
                    <div className='payment_row'>
                      <span>"Disable Minimum days, Maximum days"</span>
                      <span>
                        <Checkbox
                          required
                          style={{ width: '20px' }}
                          sx={{
                            color: '#8D94BE',
                            // padding: '0',
                            '&.Mui-checked': {
                              color: '#4FC8E9',
                            },
                            float: 'right',
                          }}
                          size={'small'}
                          checked={disableValidPeriod}
                          value={disableValidPeriod}
                          onClick={(e) =>
                            setDisableValidPeriod(!disableValidPeriod)
                          }
                        />
                      </span>
                    </div>
                  </div>
                  <div className='confirm_modal_btn'>
                    <button
                      onClick={handleConfirmStripe}
                      disabled={disableConfirmButton}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
          <div className='formDetails_card'>
            <div className='paymentDetails_reviewCard'>
              <div className='paymentDetails_reviewCard_Header'>
                <img
                  src={carDetail?.car_images && carDetail?.car_images[0]?.image}
                  alt={carDetail?.make}
                />
              </div>
              <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
                {carDetail?.make} {carDetail?.model}
              </div>

              <div className='bookingTimings'>
                <div className='bookingFromDate'>
                  <div>{moment(booking?.start_date).format('ddd, MMM DD')}</div>
                  <div>{moment(booking?.end_date).format('ddd, MMM DD')}</div>
                </div>
                <div className='bookingFromDate'>
                  <div>
                    {moment(booking?.fromTime, 'HHmm').format('hh:mm A')}
                  </div>
                  <div>
                    {moment(booking?.untilTime, 'HHmm').format('hh:mm A')}{' '}
                  </div>
                </div>
              </div>
              <div className='bookingDiliveryDetail'>
                <div style={{ fontSize: '18px', fontWeight: 'bold' }}>
                  Locations
                  <div className='bookingAirport'>
                    Pickup: {booking?.pickupLocation?.code} 
                      {Number(booking?.pickupLocation?.delivery_charge) > 0 && 
                        <span>{` (+$${booking?.pickupLocation?.delivery_charge})`}</span>
                      }
                  </div>
                  <div className='bookingAirport'>
                    Dropoff: {booking?.dropoffLocation?.code} 
                        {Number(booking?.dropoffLocation?.delivery_charge) > 0 && 
                          <span>{` (+$${booking?.dropoffLocation?.delivery_charge})`}</span>
                        }
                  </div> 
                </div>
              </div>
              <div className='bookingPaymentDetail'>
                <div className='conf_detail'>
                  <div className='conf_detail_header'>Payment Detail</div>
                  <div className='payment_row'>
                    <span>
                      {/* ${carDetail?.daily_price} * {variables?.bookingPeriod} days */}
                      $ {(booking?.daily_price || 0).toFixed(2)} * {booking?.days} days
                    </span>
                    <span>
                       ${/* {carCheckoutPrice} */}
                      {(booking.daily_price * booking?.days).toFixed(2)}
                    </span>
                  </div>
                  <div className='payment_row'>
                    <span>Discount</span>
                    <span style={{ color: 'var(--light-blue)' }}>
                      $ {discountPrice ?? 0}
                    </span>
                  </div>
                  <div className='payment_row'>
                    <span>Delivery fee</span>
                    <span>
                      $ {numtoFixed(booking?.deliveryCharge || 0) ?? '00.00'}
                      {/* {variables.deliveryCharge ?? '00.00'} */}
                    </span>
                  </div>
                  <div className='payment_row'>
                    <span>
                      Tax ( {(systemConfigurations?.tax * 100).toFixed(2)}% ){' '}
                    </span>
                    <span>$ {numtoFixed(taxAmount || 0)}</span>
                  </div>
                  {booking?.previous_amount && (
                    <div className='payment_row'>
                      <span>Init Amount </span>
                      <span>$ {(booking?.previous_amount).toFixed(2)}</span>
                    </div>
                  )}
                  {systemConfigurations?.young_renter_fee && (
                    <div className='payment_row'>
                      <span>
                        Young_fee ( {(systemConfigurations?.young_renter_fee ?? 0 )}% ){' '}
                      </span>
                      <span>$ {numtoFixed(young_fee || 0)}</span>
                    </div>
                  )}
                  <div className='payment_row total_payment_row '>
                    <span>Total</span>
                    <span>
                      ${' '}{booking?.diff_price ? booking.diff_price : receivedPriceObject.receivedFinalPrice}
                    </span>
                    {/* <span>$ {carCheckoutPrice - discountPrice}</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* <div className='paymentDetails_card'>
        <img src='/assets/imgs/credit_card.png' alt='credit_card' />
      </div> */}
      {loading && <Box className='mainLoader'>
        <CircularProgress color='primary' />
      </Box>}
    </div>
  );
}
