import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import { create_company_employee } from '../../API/user';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { AuthContext } from '../../contexts/AuthContext';
import { useParams } from 'react-router';
import './index.css';

export default function Register() {
  const { authAxios, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [company, setCompany] = useState();
  const [confPassword, setConfPassword] = useState();
  const { pack } = useParams();
  const [togglePass, setTogglePass] = useState(false);
  const [toggleConfPass, setToggleConfPass] = useState(false);

  const handleSubmit = async () => {
    let payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      re_password: confPassword,
      access_level: pack,
      subfix_domain: company,
      phone: phoneNo,
    };

    let res = await create_company_employee(authAxios, payload);
    if (res.status !== 200) {
      toast.error(
        Object.keys(res.response.data)[0] +
          ': ' +
          Object.values(res.response.data)[0][0]
      );
      return;
    } else navigate('/access_level_user');
  };

  //   console.log(id);
  return (
    <div>
      {/* <NavBar loginPage={true} /> */}
      <div className='login_page'>
        <div className='login_background'>
          <img src='/assets/imgs/bg_info.png' alt='bg_car' />
          <div className='bg_wrapper'></div>
        </div>
        <div className='login_wrapper'>
          <div className='loginDetails'>
            <div className='loginDetails_heading_wrapper'>
              <div className='paymentDetails_heading'>Get’s Started</div>

              {/* 
          <div className='paymentDetailMsgs'>{errorMsg && errorMsg}</div> */}
            </div>
            <div className='social_logins'>
              <Button className='social_btn' onClick={() => handleSubmit()}>
                <svg
                  width='20'
                  viewBox='0 0 35 35'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M34.1817 17.8272C34.1817 16.3932 34.0654 15.3469 33.8136 14.2617H17.4395V20.7338H27.0507C26.857 22.3422 25.8106 24.7644 23.4852 26.392L23.4526 26.6087L28.6298 30.6194L28.9885 30.6552C32.2827 27.6129 34.1817 23.1366 34.1817 17.8272Z'
                    fill='#4285F4'
                  />
                  <path
                    d='M17.44 34.8793C22.1487 34.8793 26.1017 33.329 28.9891 30.655L23.4858 26.3918C22.0131 27.4188 20.0366 28.1358 17.44 28.1358C12.8282 28.1358 8.91392 25.0936 7.5186 20.8887L7.31407 20.906L1.93075 25.0722L1.86035 25.2679C4.72821 30.9649 10.619 34.8793 17.44 34.8793Z'
                    fill='#34A853'
                  />
                  <path
                    d='M7.51845 20.8891C7.15029 19.8039 6.93721 18.6412 6.93721 17.4398C6.93721 16.2384 7.15029 15.0757 7.49908 13.9906L7.48933 13.7595L2.03855 9.52637L1.86021 9.6112C0.678225 11.9753 0 14.6301 0 17.4398C0 20.2496 0.678225 22.9043 1.86021 25.2684L7.51845 20.8891Z'
                    fill='#FBBC05'
                  />
                  <path
                    d='M17.44 6.74338C20.7148 6.74338 22.9238 8.15794 24.1834 9.34006L29.1053 4.53437C26.0825 1.72462 22.1487 0 17.44 0C10.619 0 4.72821 3.91426 1.86035 9.61124L7.49923 13.9906C8.91392 9.78571 12.8282 6.74338 17.44 6.74338Z'
                    fill='#EB4335'
                  />
                </svg>
                Sign up with google
              </Button>
              <Button className='social_btn'>
                <svg
                  width='10'
                  viewBox='0 0 14 28'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M9.08712 27.9963V15.2271H13.2148L13.8327 10.2507H9.08712V7.07349C9.08712 5.63275 9.47245 4.6508 11.4623 4.6508L14 4.64959V0.198805C13.5609 0.138379 12.0546 0.00292969 10.3021 0.00292969C6.64324 0.00292969 4.13831 2.32193 4.13831 6.58089V10.2509H0V15.2272H4.13818V27.9964L9.08712 27.9963Z'
                    fill='#5F80C9'
                  />
                </svg>
                Sign up with facebook
              </Button>
            </div>
            <div className='saperator'>
              <div className='saperator_line'></div>
              OR
              <div className='saperator_line'></div>
            </div>
            <form className='login_inputs'>
              <div className='input_row input_row_register'>
                <div className='register_field'>
                  First Name
                  <div className='login_input'>
                    <input
                      defaultValue={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                      type='text'
                      placeholder='Enter First Name'
                      name='firstName'
                      id='firstName'
                    />
                  </div>
                </div>
                <div className='register_field'>
                  Last Name
                  <div className='login_input'>
                    <input
                      defaultValue={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                      type='text'
                      placeholder='Enter Last Name'
                      name='lastName'
                      id='lastName'
                    />
                  </div>
                </div>
              </div>
              <div className='input_row input_row_register'>
                <div className='register_field'>
                  Phone Number
                  <div className='login_input'>
                    <input
                      defaultValue={phoneNo}
                      onChange={(e) => setPhoneNo(e.target.value)}
                      required
                      type='number'
                      placeholder='Enter Phone Number'
                      name='phoneNo'
                      id='phoneNo'
                    />
                  </div>
                </div>
                <div className='register_field'>
                  Email Address
                  <div className='login_input'>
                    <input
                      defaultValue={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      type='text'
                      placeholder='Enter Email Address'
                      name='email'
                      id='email'
                    />
                  </div>
                </div>
              </div>
              <div className='input_row input_row_register'>
                <div className='register_field'>
                  Password
                  <div className='login_input'>
                    <input
                      defaultValue={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      type={!togglePass ? 'password' : 'text'}
                      placeholder='Enter Password'
                      name='password'
                      id='password'
                    />

                    <svg
                      className={`w-6 h-6 ${
                        !togglePass ? 'cvv_visible' : 'cvv_hidded'
                      }`}
                      onClick={() => setTogglePass(!togglePass)}
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      width={20}
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                      />
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                      />
                    </svg>
                  </div>
                </div>
                <div className='register_field'>
                  Confirm Password
                  <div className='login_input'>
                    <input
                      defaultValue={confPassword}
                      onChange={(e) => setConfPassword(e.target.value)}
                      required
                      placeholder='Enter Confirm Password'
                      name='password'
                      type={!toggleConfPass ? 'password' : 'text'}
                      id='password'
                    />

                    <svg
                      className={`w-6 h-6 ${
                        !toggleConfPass ? 'cvv_visible' : 'cvv_hidded'
                      }`}
                      onClick={() => setToggleConfPass(!toggleConfPass)}
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      width={20}
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                      />
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className='confirmation_btn'>
                <Button
                  type='submit'
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  Sign up
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
