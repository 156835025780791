import React, { useMemo, useState } from 'react';
import './index.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import moment from 'moment';

export default function TimeDropdown({ selectedTime, setSelectedTime }) {
  // const containsText = (text, searchText) =>
  //   text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  const containsText = (text, searchText) => {
    if (searchText == '') return true;
    else
      return (
        moment(text.toLowerCase(), 'HHmm').format('hh:mm') ==
          moment(searchText.toLowerCase(), 'HHmm').format('hh:mm') ||
        moment(text.toLowerCase(), 'HHmm').format('hh:mm') ==
          moment(searchText.toLowerCase() + ':30', 'HHmm').format('hh:mm')
      );
  };

  const timeData = [
    { name: '00:00', title: '12:00 am' },
    { name: '00:30', title: '12:30 am' },
    { name: '01:00', title: '01:00 am' },
    { name: '01:30', title: '01:30 am' },
    { name: '02:00', title: '02:00 am' },
    { name: '02:30', title: '02:30 am' },
    { name: '03:00', title: '03:00 am' },
    { name: '03:30', title: '03:30 am' },
    { name: '04:00', title: '04:00 am' },
    { name: '04:30', title: '04:30 am' },
    { name: '05:00', title: '05:00 am' },
    { name: '05:30', title: '05:30 am' },
    { name: '06:00', title: '06:00 am' },
    { name: '06:30', title: '06:30 am' },
    { name: '07:00', title: '07:00 am' },
    { name: '07:30', title: '07:30 am' },
    { name: '08:00', title: '08:00 am' },
    { name: '08:30', title: '08:30 am' },
    { name: '09:00', title: '09:00 am' },
    { name: '09:30', title: '09:30 am' },
    { name: '10:00', title: '10:00 am' },
    { name: '10:30', title: '10:30 am' },
    { name: '11:00', title: '11:00 am' },
    { name: '11:30', title: '11:30 am' },
    { name: '12:00', title: '12:00 pm' },
    { name: '12:30', title: '12:30 pm' },
    { name: '13:00', title: '01:00 pm' },
    { name: '13:30', title: '01:30 pm' },
    { name: '14:00', title: '02:00 pm' },
    { name: '14:30', title: '02:30 pm' },
    { name: '15:00', title: '03:00 pm' },
    { name: '15:30', title: '03:30 pm' },
    { name: '16:00', title: '04:00 pm' },
    { name: '16:30', title: '04:30 pm' },
    { name: '17:00', title: '05:00 pm' },
    { name: '17:30', title: '05:30 pm' },
    { name: '18:00', title: '06:00 pm' },
    { name: '18:30', title: '06:30 pm' },
    { name: '19:00', title: '07:00 pm' },
    { name: '19:30', title: '07:30 pm' },
    { name: '20:00', title: '08:00 pm' },
    { name: '20:30', title: '08:30 pm' },
    { name: '21:00', title: '09:00 pm' },
    { name: '21:30', title: '09:30 pm' },
    { name: '22:00', title: '10:00 pm' },
    { name: '22:30', title: '10:30 pm' },
    { name: '23:00', title: '11:00 pm' },
    { name: '23:30', title: '11:30 pm' },
  ];

  const [searchText, setSearchText] = useState('');
  const displayedOptions = useMemo(
    () => timeData?.filter(({ title }) => containsText(title, searchText)),
    [searchText, timeData]
  );

  return (
    <FormControl
      className='edit_dropdown'
      sx={{ m: 1, minWidth: 120 }}
      size='small'
    >
      <InputLabel className='dropdown_label' id='airport_name'>
        Select Time
      </InputLabel>
      <Select
        className='airport_name'
        labelid='mileage'
        id='mileage'
        value={selectedTime}
        label='Select Time'
        style={{ height: '27px' }}
        onChange={(e) => setSelectedTime(e.target.value)}
        onClose={() => setSearchText('')}
        MenuProps={{
          autoFocus: false,
          classes: {
            paper: 'car_dropdown_paper time_dropdown_paper',
            root: 'car_dropdown_root',
          },
          variant: 'selectedMenu',
          //setting variant to menu makes it appear below the element
        }}
      >
        <div className='dropdown_search' style={{ marginTop: '10px' }}>
          <input
            type='text'
            name='dropdown_search'
            id='dropdown_search'
            placeholder='Search Time'
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
          />
        </div>
        {displayedOptions.map((option, i) => (
          <MenuItem
            className='airport_name_menu_item'
            value={option.name}
            key={i}
          >
            {' '}
            {option.title}{' '}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
