import { subfix_domain } from '../api';

const get_system_configurations = async (authAxios) => {
  try {
    const res = await authAxios.get(`/api/system-configurations/`, { params: { subfix_domain }});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};
const get_system_configurations_by_id = async (authAxios, id) => {
  try {
    const res = await authAxios.get(`/api/system-configurations/${id}`, { params: { subfix_domain }});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const create_system_configurations = async (authAxios, data) => {
  try {
    const res = await authAxios.post(`/api/system-configurations/`, {...data, subfix_domain});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const edit_system_configurations = async (authAxios, id, data) => {
  try {
    const res = await authAxios.put(`/api/system-configurations/${id}/`, {...data, subfix_domain});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

export {
  get_system_configurations,
  create_system_configurations,
  edit_system_configurations,
  get_system_configurations_by_id,
};
