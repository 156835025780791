import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react';
import './index.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import { format } from 'date-fns';
import { get_system_configurations } from './../../API/system_configurations';
import { get_car } from './../../API/car_booking';
import { AuthContext } from '../../contexts/AuthContext';
import { useCarsDropdown } from '../../hooks/useCarsDropdown';
import { confirmAlert } from 'react-confirm-alert';
import { InView } from 'react-intersection-observer';
import { getVehicles } from '../../reducers/vehicleSlice';
import { useDispatch, useSelector } from 'react-redux';
import { searchSelector } from '../../reducers/searchSlice';


const today = format(new Date(), 'yyyy-MM-dd');
// const getUnavailable = (carDetail) => {
//   let bool = false;
//   if (carDetail?.unavailabilities?.length > 0) {
//     carDetail?.unavailabilities?.forEach((car) => {
//       const start_date = car.start_date.substring(0, 10);
//       const end_date = car.end_date.substring(0, 10);
//       if (today >= start_date && today <= end_date) {
//         bool = true;
//       }
//     });
//   }
//   return bool;
// };

const CAR_AVAILABILITY_STATUS = {
  UNAVAILABLE: 'notAvailable',
  AVAILABLE: 'available'
}

const getTotalAmount = (
  carCurrentPrice,
  bookingPeriod,
  discountedPrice = 0,
  deliveryCharge,
  systemConfigurations,
  isYoung,
  carId,
  selectedCarId
) => {
  const _tax = systemConfigurations?.tax ?? 0;
  const yonger_fee_rate = isYoung
    ? Number(systemConfigurations?.young_renter_fee) / 100 ?? 0
    : 0;
  const carCheckoutPrice =
    carCurrentPrice && Number(carCurrentPrice || 0) * bookingPeriod;

  const taxAmount =
    _tax * (Number(discountedPrice || 0) + Number(deliveryCharge || 0)) ;

  const totalBookingAmount =
    (Number(discountedPrice) != 0
      ? Number(discountedPrice || 0) + Number(deliveryCharge || 0)
      : Number(deliveryCharge || 0)) +
    taxAmount;

    if (carId === selectedCarId) {
      localStorage.setItem('finalPrice', (totalBookingAmount * (1 + yonger_fee_rate)).toFixed(2));
      localStorage.setItem('deliveryCharge', deliveryCharge);
      localStorage.setItem('taxAmount', taxAmount);
      localStorage.setItem('yonger_fee', yonger_fee_rate * totalBookingAmount);

      console.log(localStorage.getItem('finalPrice'), 'what is final price', carId, selectedCarId);
      console.log(totalBookingAmount, deliveryCharge, taxAmount, discountedPrice, 'adjusted 2')
    }


    
  return (totalBookingAmount * (1 + yonger_fee_rate)).toFixed(2);
};

export default function CarDropdown({
  bookingId,
  selectedCar,
  setSelectedCar,
  setSelectedCarDetail,
  fromDate,
  untilDate,
  bookingPeriod,
  deliveryCharge,
  coupon,
  isYoung,
}) {
  const { authAxios } = useContext(AuthContext);
  const [carData, setCarData] = useState([]);
  const [page, setPage] = useState(null);
  const [prevData, setPrevData] = useState(null);

  const [carArray] = useCarsDropdown(fromDate, untilDate, null, page, prevData, selectedCar, bookingId);
  const { q } = useSelector(searchSelector);
  const dispatch = useDispatch();
  const containsText = (text = '', searchText = '') => {
    return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  };

  const [searchText, setSearchText] = useState('');
  const displayedOptions = useMemo(
    () =>
      carData?.data?.filter(({ make, model }) =>
        containsText(make + ' ' + model, searchText)
      ),
    [searchText, carData, bookingPeriod]
  );

  const [systemConfigurations, setSystemConfigurations] = useState(null);

  useEffect(() => {
    get_system_configurations(authAxios).then((res) => {
      setSystemConfigurations(res.data[0]);
    });
  }, []);

  useEffect(() => {
    setCarData({
      ...carArray,
      data: carArray?.data?.map((item) => {
        const coupon_amount =
          coupon?.amount_type != 'A'
            ? coupon?.amount === 100 ? Math.ceil((bookingPeriod *
              Number(item?.daily_price || 0) *
              Math.abs(coupon?.amount || 0)) /
            100) : (bookingPeriod *
              Number(item?.daily_price || 0) *
              Math.abs(coupon?.amount || 0)) /
            100
            : Math.abs(coupon?.amount || 0);


        let adjustedBookingPrice = Number(item.total_price) - (Number(item.discount) + (coupon_amount || 0))
        adjustedBookingPrice = adjustedBookingPrice < 0 ? 0 : adjustedBookingPrice;

        return {
          ...item,
          carCurrentPrice: item.daily_price,
          totalAmt: getTotalAmount(
            item.daily_price,
            bookingPeriod,
            adjustedBookingPrice,
            +deliveryCharge,
            systemConfigurations,
            isYoung,
            item.id,
            selectedCar
          ),
          discountPrice: Number(item.discount) + (coupon_amount || 0),
        };
      }),
    });
    // if(selectedCar && !carArray?.data?.some((car) => car.id === selectedCar)) {
    //   getCar(selectedCar)
    // }
  }, [carArray, bookingPeriod, deliveryCharge, isYoung, coupon]);

  const handleChange = (e) => {
    e.preventDefault();
    const selectedVehicle = displayedOptions?.find(
      (item) => item.id == e.target.value
    );

    const minimumDuration = selectedVehicle?.trip_preference?.min_trip_duration;
    let meetsMinimumDuration = !(bookingPeriod < minimumDuration);

    if (!meetsMinimumDuration) {
      return confirmAlert({
        overlayClassName: 'errorModal',
        customUI: ({ onClose }) => {
          return (
            <div
              className='confModal'
              style={{
                height: '220px',
                marginTop: '50%',
                position: 'relative',
                paddingTop: '55px',
              }}
            >
              <div
                className='close_icon'
                style={{ top: '.55rem', right: '.25rem' }}
                onClick={() => onClose()}
              >
                <svg
                  width='25'
                  height='25'
                  viewBox='0 0 110 110'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                    stroke='#FF2552'
                    strokeWidth='11.625'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                    stroke='#FF2552'
                    strokeWidth='11.625'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
              <p style={{ color: 'red' }}>
                {`The car you have selected has a minimum trip length of ${minimumDuration} days. Are you sure you want to continue?`}
              </p>
              <div className='btns'>
                <button
                  onClick={() => {
                    setSelectedCar(e.target.value);
                    setSelectedCarDetail(
                      displayedOptions?.find(
                        (item) => item.id == e.target.value
                      )
                    );
                    onClose();
                  }}
                  className='secondary'
                  style={{ width: '120px', paddingTop: '10px' }}
                >
                  Confirm
                </button>
                <button
                  className='primary'
                  onClick={() => {
                    onClose();
                  }}
                  style={{ width: '120px', paddingTop: '10px' }}
                >
                  Cancel
                </button>
              </div>
            </div>
          );
        },
      });
    } else {
      setSelectedCar(e.target.value);
      setSelectedCarDetail(
        displayedOptions?.find((item) => item.id == e.target.value)
      );
    }
  };

  console.log('🚀OUTPUT --> displayedOptions:', displayedOptions);
  const selectedVehicle = displayedOptions?.find(
    (item) => item.id == selectedCar
  );

  const getCar = async (id) => {
    const res = await get_car(authAxios,id);
    if (res.status === 200) {
      const item = res.data
      const coupon_amount =
        coupon?.amount_type != 'A'
          ? coupon?.amount === 100 ? Math.ceil((bookingPeriod *
            Number(item?.daily_price || 0) *
            Math.abs(coupon?.amount || 0)) /
          100) : (bookingPeriod *
            Number(item?.daily_price || 0) *
            Math.abs(coupon?.amount || 0)) /
          100
          : Math.abs(coupon?.amount || 0);
      setCarData({
        ...carData,
        data: [...carData?.data, 
          {
           ...item,
           carCurrentPrice: item.daily_price,
           totalAmt: getTotalAmount(
             item.daily_price,
             bookingPeriod,
             Number(item.total_price) - (Number(item.discount) + (coupon_amount || 0)),
             deliveryCharge,
             systemConfigurations,
             isYoung
           ),
           discountPrice: Number(item.discount) + (coupon_amount || 0),
         }]
      
      })
      
    }
   }

  return (
    <div className='info_row '>
      <div className='field'>
        <FormControl className='edit_dropdown' sx={{ m: 1, minWidth: 120 }}>
          <InputLabel className='dropdown_label' id='airport_name'>
            Select Car
          </InputLabel>
          <Select
            className='carDropdown'
            labelid='mileage'
            id='mileage'
            value={selectedCar}
            label='Select Car'
            onChange={handleChange}
            onClose={() => setSearchText('')}
            // renderValue={}
            MenuProps={{
              autoFocus: false,
              classes: {
                paper: 'car_dropdown_paper car_full_dropdown_paper ',
                root: 'car_dropdown_root',
              },
              variant: 'selectedMenu',
              //setting variant to menu makes it appear below the element
            }}
          >
            <div className='dropdown_search'>
              <input
                type='text'
                name='dropdown_search'
                id='dropdown_search'
                placeholder='Search Car'
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation();
                  }
                }}
              />
            </div>
            {displayedOptions?.map((car, i) => {
              return fromDate ? (
                <CarMenuItem
                  key={car.id}
                  value={car.id}
                  index={i}
                  id={car.id}
                  title={car.title}
                  make={car.make}
                  model={car.model}
                  img={car.car_images ? car.car_images[0]?.image : ''}
                  systemConfigurations={systemConfigurations}
                  fromDate={fromDate}
                  untilDate={untilDate}
                  bookingPeriod={bookingPeriod}
                  deliveryCharge={deliveryCharge}
                  carDetail={car}
                  coupon={coupon}
                  isYoung={isYoung}
                >
                  {selectedVehicle && selectedVehicle.carCurrentPrice ? (
                    <>
                      <div className='card_img'>
                        <img
                          src={
                            selectedVehicle.car_images
                              ? selectedVehicle.car_images[0]?.image
                              : ''
                          }
                          alt={selectedVehicle.title}
                        />
                      </div>
                      <div className='drpdwn_car_details'>
                        <div className='car_name'>
                          {selectedVehicle.make} {selectedVehicle.model}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            gap: '10px',
                          }}
                        >
                          <div className='price_tag'>
                            Daily Cost: $ {selectedVehicle.carCurrentPrice}
                          </div>
                          <div className='price_tag'>
                            Discount : ${' '}
                            {selectedVehicle.discountPrice ?? '0.00'}
                          </div>
                          <div className='price_tag'>
                            Total Trip Cost: ${' '}
                            {Number(selectedVehicle.totalAmt).toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <CircularProgress color='success' />
                  )}
                </CarMenuItem>
              ) : (
                <CarSimpleMenuItem
                  key={car.id}
                  value={car.id}
                  index={i}
                  id={car.id}
                  make={car.make}
                  model={car.model}
                  title={car.title}
                  img={car.car_images[0]?.image}
                  dailyPrice={car?.daily_price || 0}
                  isYoung={isYoung}
                >
                  <div className='card_img'>
                    <img src={car.car_images[0]?.image} alt={car.title} />
                  </div>
                  <div className='drpdwn_car_details'>
                    <div className='car_name'>
                      {car.make} {car.model}
                    </div>
                  </div>
                </CarSimpleMenuItem>
              );
            })}
            {carData?.pagination?.page_number <
              carData?.pagination?.total_pages && (
              <InView
                as='div'
                triggerOnce={false}
                onChange={(inView, entry) => {
                  if (inView) {
                    setPrevData(carArray?.data)
                    setPage(carData?.pagination?.page_number + 1)
                    // dispatch(
                    //   getVehicles({
                    //     booked: false,
                    //     q,
                    //     orderName: 'title',
                    //     authAxios,
                    //     callback: null,
                    //     filters: {
                    //       page: carData?.pagination?.page_number + 1,
                    //     },
                    //   })
                    // );
                  }
                }}
              >
                <MenuItem value='disabled' disabled>
                  <CircularProgress color='success' />
                </MenuItem>
              </InView>
            )}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

const CarMenuItem = ({
  index,
  id,
  title,
  make,
  model,
  img,
  value,
  fromDate,
  untilDate,
  bookingPeriod,
  deliveryCharge,
  carDetail,
  systemConfigurations,
  coupon,
  isYoung,
  ...props
}) => {

  const getCarAvailabilityStatus = useCallback(() => {
    const { available_status: availabilityTextStr } = carDetail;
    const isAvailable = availabilityTextStr === CAR_AVAILABILITY_STATUS.AVAILABLE;

    return {
      availabilityTextStr,
      isAvailable
    }

  }, [carDetail]);

  const { availabilityTextStr, isAvailable } = getCarAvailabilityStatus();

  const unavailable = !isAvailable;

  const minimumDuration = carDetail?.trip_preference?.min_trip_duration;
  let meetsMinimumDuration = !(bookingPeriod < minimumDuration);

  return (
    <MenuItem
      disabled={unavailable}
      key={index}
      className='dropdown_search_menu_item airport_name_menu_item'
      {...props}
    >
      {/* {console.log(props)} */}
      <div className='card_img carCard_menuItem'>
        <img src={img} alt={title} />
        {/* <img src={'http://localhost:8000/media/cars/car_ljLt96h.png'} alt='car_1' /> */}
        {/* <img src={'http://localhost:8000/media/cars/IMG_8835.jpg'} alt='car_1' /> */}
        {!meetsMinimumDuration ? (
          <div className='unavailableMark'>
            {`${carDetail?.trip_preference?.min_trip_duration} Day Minimum`}
          </div>
        ) : unavailable ? (
          <div className='unavailableMark'>Unavailable</div>
        ) : (
          ''
        )}
      </div>
      <div className='drpdwn_car_details'>
        <div className='car_name'>
          {make} {model}
        </div>
        {unavailable ? (
          <div className='price_tag' style={{ color: '#bb2d2d' }}>
            Unavailable
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <div className='price_tag'>
              Daily Cost: $ {Number(carDetail.carCurrentPrice).toFixed(2)}
            </div>
            <div className='price_tag'>
              Discount: $ {Number(carDetail.discountPrice).toFixed(2)}
            </div>
            <div className='price_tag'>
              Total Trip Cost: $ {Number(carDetail.totalAmt).toFixed(2)}
            </div>
          </div>
        )}
      </div>
    </MenuItem>
  );
};

const CarSimpleMenuItem = ({
  index,
  id,
  title,
  img,
  dailyPrice,
  value,
  make,
  model,
  ...props
}) => {
  return (
    <MenuItem
      key={index}
      className='dropdown_search_menu_item airport_name_menu_item'
      {...props}
    >
      {/* {console.log(props)} */}
      <div className='card_img'>
        <img src={img} alt={title} />
      </div>
      <div className='drpdwn_car_details'>
        <div className='car_name'>
          {make} {model}
        </div>
      </div>
    </MenuItem>
  );
};
