import { subfix_domain } from '../api';

const get_companies = async (authAxios, isUsed) => {
	try {
		const res = await authAxios.get(`/api/company/`, {params: {isUsed, subfix_domain}});
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const update_company = async (authAxios, data, id) => {
	try {
		const res = await authAxios.post(`/api/company/${id}/`, {...data, subfix_domain});
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const get_company_users = async (authAxios) => {
	try {
		const res = await authAxios.get(`/api/company-user/`, { params: {subfix_domain: subfix_domain} });
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

export { get_companies, update_company, get_company_users };