import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import toast from 'react-hot-toast';
import moment from 'moment';
import {
  get_notes,
  create_note,
  delete_note,
  update_note,
} from '../../API/trip_note';

export default function TripNotes({ authAxios, id, user }) {
  const [notes, setNotes] = useState(null);
  const [noteHeadLine, setNoteHeadLine] = useState('');
  const [noteContent, setNoteContent] = useState('');
  const [addNew, setAddNew] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);

  const getNotes = () => {
    get_notes(authAxios, id).then((res) => {
      if (res.status === 200) {
        console.log(res.data);
        setNotes(res.data);
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }
    });
  };

  useEffect(() => {
    getNotes();
  }, []);

  const handleDeleteNote = async (e, id) => {
    e.preventDefault();
    let res = await delete_note(authAxios, id);
    if (res.status === 204) {
      toast.success('Deleted Successfully');
      await getNotes();
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };
  const handleAddNote = async (e) => {
    e.preventDefault();
    const payload = {
      headline: noteHeadLine,
      content: noteContent,
      trip_id: id,
      author: user?.user_id,
    };
    if (!noteHeadLine || !noteContent || !id || !user.user_id) {
      toast.error('Invalid Note');
      return;
    }

    let res = await create_note(authAxios, payload);
    if (res.status === 200) {
      toast.success('Created Successfully');
      setNoteHeadLine('');
      setNoteContent('');
      await getNotes();
      setAddNew(false);
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  const handleEditNote = (e, note) => {
    e.preventDefault();
    setEditNote(true);
    setNoteHeadLine(note.headline);
    setNoteContent(note.content);
    setSelectedNote(note.id);
  };

  const handleUpdateNote = async (e) => {
    e.preventDefault();
    const payload = {
      headline: noteHeadLine,
      content: noteContent,
      edit_author: user?.user_id,
    };
    // if (!noteHeadLine || !noteContent || !id || !user.user_id) {
    //   toast.error('Invalid Note');
    //   return;
    // }

    let res = await update_note(authAxios, payload, selectedNote);
    if (res.status === 200) {
      toast.success('Created Successfully');
      setNoteHeadLine('');
      setNoteContent('');
      await getNotes();
      setEditNote(false);
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  return (
    <div className='notesTab'>
      <div className='notesTabHeader'>
        <div>Trip Notes</div>
        <button
          onClick={(e) => {
            e.preventDefault();
            setAddNew((addNew) => !addNew);
          }}
        >
          Add New
        </button>
      </div>
      <div
        className={`note addNoteContainer ${
          addNew ? 'showAddNew' : 'hideAddNew'
        }`}
      >
        <div className='headline'>
          <input
            required
            type='text'
            name='headline'
            placeholder='Headline'
            value={noteHeadLine}
            onChange={(e) => setNoteHeadLine(e.target.value)}
          />
          <button onClick={(e) => handleAddNote(e)}>Save</button>
        </div>
        <div style={{ width: '100%', marginTop: '1rem' }}>
          <textarea
            required
            type='text'
            name='content'
            placeholder='Description'
            value={noteContent}
            onChange={(e) => setNoteContent(e.target.value)}
          />
        </div>
      </div>

      <div
        className={`note addNoteContainer ${
          editNote ? 'showAddNew' : 'hideAddNew'
        }`}
      >
        <div className='headline'>
          <input
            required
            type='text'
            name='headline'
            placeholder='Headline'
            value={noteHeadLine}
            onChange={(e) => setNoteHeadLine(e.target.value)}
          />
          <button onClick={(e) => handleUpdateNote(e)}>Update</button>
        </div>
        <div style={{ width: '100%', marginTop: '1rem' }}>
          <textarea
            required
            type='text'
            name='content'
            placeholder='Description'
            value={noteContent}
            onChange={(e) => setNoteContent(e.target.value)}
          />
        </div>
      </div>

      <div className='notes'>
        {!notes ? (
          <div>
            <CircularProgress color='primary' />
          </div>
        ) : notes.length === 0 ? (
          <div>No Notes Availavble</div>
        ) : (
          notes.map((note) => (
            <div className='note'>
              <div className='text'>
                <h3>{note.headline}</h3>
                <span>{note.content}</span>
              </div>
              <div
                style={{
                  color: '#d4d4d4',
                  fontSize: '14px',
                  textAlign: 'right',
                }}
              >
                <div className='btns'>
                  <div>
                    <svg
                      onClick={(e) => handleEditNote(e, note)}
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                      class='w-6 h-6'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10'
                      />
                    </svg>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-6 h-6'
                      onClick={(e) => handleDeleteNote(e, note.id)}
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                      />
                    </svg>
                  </div>
                </div>
                <div>Created By: {note.trip.booked_by}</div>
                {note.edit_author && (
                  <div>
                    Edited By: {note.edit_author.first_name}{' '}
                    {note.edit_author.last_name}
                  </div>
                )}
                <div>
                  {moment(note?.created_at).format('MM-DD-YYYY HH:MM A')}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
