import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { deleteVehicle, setVehicle } from '../../reducers/vehicleSlice';
import './index.css';

// import { get_car_by_id } from './../../API/index';
import { AuthContext } from '../../contexts/AuthContext';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import toast from 'react-hot-toast';
import { update_vehicle } from '../../API/vehicle';
import { VEHICLE_STATUS } from '../../constants';

export default function CarRemoteCard({ ...props }) {
  const { authAxios, canMutate, canView } = useContext(AuthContext);
  const [deleteModal, setDeleteModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [carDetail, setCarDetail] = useState(null);

  const [carCurrentPrice, setCarCurrentPrice] = useState(props?.dailyRate);
  const [unavailable, setUnavailable] = useState(false);
  unavailable && console.log('Id', props.id, unavailable);
  unavailable && console.log('length', props?.car.unavailabilities.length);
  const today = format(new Date(), 'yyyy-MM-dd');

  // Setting Car Current Price
  useEffect(() => {
    const getCarPrice = () => {
      const basePrice = props?.dailyRate;
      return (props?.car.custom_prices || []).length === 0
        ? setCarCurrentPrice(basePrice)
        : props?.car.custom_prices?.every((customPrice) => {
            if (
              today >= customPrice.start_date &&
              today <= customPrice.end_date
            ) {
              setCarCurrentPrice(customPrice.price);
              return false;
            } else {
              setCarCurrentPrice(basePrice);
              return true;
            }
          });
    };
    const getCarUnavailablity = () => {
      return (
        props?.car.unavailabilities.length > 0 &&
        props?.car.unavailabilities?.every((car) => {
          const start_date = car.start_date.substring(0, 10);
          const end_date = car.end_date.substring(0, 10);
          if (today >= start_date && today <= end_date) {
            setUnavailable(true);
            return false;
          } else {
            setUnavailable(false);
            return true;
          }
        })
      );
    };

    getCarPrice();
    props?.car.unavailabilities?.length > 0 && getCarUnavailablity();
  }, [props]);

  const getVehicleProps = useCallback(() => {
    const features = props.car.features.map((feat) => feat.id);
    return {
      id: props.id,
      vehicleName: props.title,
      vehiclePrice: props.dailyRate,
      vehicleStatus: props.status,
      mileagePerDay: props.car.daily_max_miles,
      mileagePerWeek: props.car.weekly_max_miles,
      mileagePerMonth: props.car.monthly_max_miles,
      mileageOverageFee: props.car.mileage_overage_fee,
      licensePlate: props.car.license_plate,
      interiorColor: props.car.interior_color,
      vinNumber: props.car.vin_number,
      vehicleType: props.type,
      carDescription: props.car.description,
      noOfPass: props.noOfPass,
      driveTrain: props.driveTrain,
      model: props.car.model,
      vehicleColor: props.car.vechicle_color,
      fuelType: props.car.fuel_type,
      vehicleMake: props.car.make,
      vehicleTrim: props.car.trim,
      size: props.noOfPeople,
      car_features: props.CarFeatures.map((feat) => ({
        ...feat,
        active: features.includes(feat.id),
      })),
      selectedFile: null,
    };
  }, [props]);

  const handleChangeStatus = async (e) => {
    const payload = {
      vehicle_status: e.target.value,
    };

    let res = await update_vehicle(authAxios, props.id, payload);
    if (res.status !== 200) {
      toast.error(Object.values(res.response.data)[0]);
      return;
    } else {
      await props.refresh();
    }
  };

  return (
    <Link to={'#'} key={props?.key} className='carCard_wrapper'>
      <div className='carCard_body'>
        <div className='carCard_hero_img'>
          <img src={props?.img} alt='car_1' />
          {/* <img src={'http://localhost:8000/media/cars/car_ljLt96h.png'} alt='car_1' /> */}
          {/* <img src={'http://localhost:8000/media/cars/IMG_8835.jpg'} alt='car_1' /> */}
        </div>
        <div className='carCard_detail'>
          <div className='carCard_title'>
            {props?.title} - {props?.car?.make}{' '}
            {props?.car?.model} {props?.car?.trim}
          </div>
          <div className='car_details'>
            <div className='car_similar_to'>{props?.similarTo}</div>
            <div style={{ display: 'none' }} className='price_details'>
              Features & Price Details
              <svg
                width='8'
                viewBox='0 0 8 14'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M-8.74228e-08 12L5 7L-5.24537e-07 2L0.999999 -4.37114e-08L8 7L1 14L-8.74228e-08 12Z'
                  fill='#4FC8E9'
                />
              </svg>
            </div>
          </div>
          <div className='car_tags'>
            <span>
              <img src='/assets/imgs/automatic.png' alt='' />
              {props?.driveTrain}
            </span>
            <span>
              <img src='/assets/imgs/people.png' alt='' />
              {props?.noOfPeople}
            </span>
            <span>
              <img src='/assets/imgs/bag.png' alt='' />
              {props?.noOfPass}{' '}
              {props?.noOfPass === 1 ? ' Passenger' : ' Passengers'}
            </span>
          </div>

          <div className='car_costs'>
            <div className='total_cost'>
              {/* Total Cost: CAD {props?.totalCost}.00 */}
            </div>
            <div className='daily_rate'>
              {/* Daily Rate: CAD {props?.id === carDetail?.id && carCurrentPrice}
              .00 */}
              {/* Daily Rate: CAD {props?.dailyRate}.00 */}
            </div>
          </div>
          <div className='left_bottom_layout'>
            {canMutate('all_cars') && (
              <div>
                <Checkbox
                  required
                  sx={{
                    color: '#8D94BE',
                    // padding: '0',
                    '&.Mui-checked': {
                      color: '#4FC8E9',
                    },
                  }}
                  checked={props.car?.is_featured}
                  value={props.car?.is_featured}
                  onClick={(e) =>
                    props.featureCar(e, {
                      ...props.car,
                      is_featured: !props.car?.is_featured,
                    })
                  }
                />
                <span className='car_card_feature'>Featured</span>
              </div>
            )}
            <Button
              size='small'
              variant='outlined'
              onClick={(e) => {
                e.preventDefault();
                navigate(`/car_bookings/${props.car?.id}`);
              }}
            >
              Car bookings
            </Button>
          </div>
        </div>
      </div>
      <div className='card_actions'>
        <div>
          <div className='car_costs'>
            <div className='daily_rate daily_rate_action'>
              Daily Rate: $ {carCurrentPrice}
              {/* Daily Rate: CAD {props?.dailyRate}.00 */}
            </div>
          </div>
        </div>

        <div>
          <div
            className='car_select_btn'
            style={{ justifyContent: 'flex-end' }}
          >
            {/* {canView('daily_overview') && (
              <Link
                to={`/car_report/${props.id}`}
                className='outlined_btn outlined_btn_secondary'
              >
                View Report
              </Link>
            )} */}
            <FormControl
              className='vehicle_status'
              sx={{ m: 1, width: 120, height: 40, padding: 5 }}
            >
              <Select
                className={`airport_name ${props.status || null}`}
                displayEmpty
                value={props.status}
                onChange={handleChangeStatus}
                inputProps={{ 'aria-label': 'Vehicle Status' }}
              >
                {VEHICLE_STATUS.map((status) => (
                  <MenuItem
                    className={'airport_name_menu_item ' + status.name}
                    value={status.name}
                    // disabled={props.statusDisabled && status.name == 'prepped'}
                  >
                    {status.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {canMutate('all_cars') && (
              <Link
                to={`/trip_preferences/${props.id}`}
                className='outlined_btn'
              >
                Trip Preference
              </Link>
            )}
          </div>

          {canMutate('all_cars') && (
            <div className='car_select_btn'>
              <Link
                to={`/select_price_per_day/${props.id}`}
                className='outlined_btn outlined_btn_secondary'
              >
                Price Per Day
              </Link>
              <button
                style={{ display: 'none' }}
                onClick={(e) => {
                  e.preventDefault();
                  setDeleteModal(true);
                }}
                className='secondary'
                type='submit'
              >
                Delete
              </button>
              <Modal
                open={deleteModal}
                onClose={() => setDeleteModal(false)}
                aria-labelledby='conf_modal'
                aria-describedby='modal-modal-description'
              >
                <div className='confModal'>
                  <svg
                    width='110'
                    height='110'
                    viewBox='0 0 110 110'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                      stroke='#FF2552'
                      strokeWidth='11.625'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                      stroke='#FF2552'
                      strokeWidth='11.625'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>{' '}
                  <br />
                  <h4>Are You Sure?</h4>
                  <p>
                    Do you really want to delete this info. This process cannot
                    be undo.
                  </p>
                  <div className='btns'>
                    <button
                      onClick={() => setDeleteModal(false)}
                      className='secondary'
                    >
                      Cancel
                    </button>
                    <button
                      className='primary'
                      onClick={() => {
                        dispatch(
                          deleteVehicle({
                            id: props.id,
                            authAxios,
                            callback: () => {
                              setDeleteModal(false);
                              props.refresh();
                            },
                          })
                        );
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </Modal>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setVehicle(getVehicleProps()));
                  props.editModal();
                }}
                className='primary'
                type='submit'
              >
                Edit
              </button>
              <button
                className='secondary'
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/car_photos');
                  props.editPhotos(
                    props.id,
                    props.car.car_images,
                    props.car.title
                  );
                }}
              >
                Edit Photos
              </button>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}
