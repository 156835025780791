import { subfix_domain } from '../api';

const get_user = async (authAxios, id) => {
	try {
		const res = await authAxios.get(`/api/customers/${id}/`, { params: {subfix_domain} });
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const get_customers = async (authAxios, q) => {
	try {
		const res = await authAxios.get(`/api/customers/`, { params: {subfix_domain, q} });
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const create_user = async (authAxios, formData) => {
	formData.append('subfix_domain', subfix_domain)
	try {
		const res = await authAxios.post(`/api/customers/customer-register/`, formData);
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const create_company_user = async (authAxios, data) => {
	try {
		const res = await authAxios.post(`/api/company-user/register/`, data);
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const create_company_employee = async (authAxios, data) => {
	try {
		const res = await authAxios.post(`/api/company-user/employee-register/`, { ...data, subfix_domain });
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;customers
		return Error;
	}
};

const update_company_user = async (authAxios, data) => {
	try {
		const res = await authAxios.post(`/api/company-user/update/`, data);
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const update_customer = async (authAxios, formData) => {
	formData.append('subfix_domain', subfix_domain)
	try {
		const res = await authAxios.post(`/api/customers/customer-update/`, formData);
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const delete_customer = async (authAxios, id) => {
	try {
	  const res = await authAxios.delete(`/api/customers/${id}`, {
		params: { subfix_domain },
	  });
	  return res;
	} catch (Error) {
	  // const err = Error.response.data.message;
	  return Error;
	}
  };

const delete_company_user = async (authAxios, data) => {
	try {
		const res = await authAxios.post(`/api/company-user/delete/`, data);
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const get_admins = async (authAxios) => {
	try {
		const res = await authAxios.get(`/api/company-user/`, {params: {subfix_domain}});
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};


const get_company_user = async (authAxios, id) => {
	try {
		const res = await authAxios.get(`/api/company-user/${id}`, {params: {subfix_domain}});
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const update_company_user_level = async (authAxios, data) => {
	try {
		const res = await authAxios.post(`/api/company-user/level-update/`, {...data, subfix_domain});
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};


export { 
	get_user, 
	create_user, 
	create_company_user, 
	get_admins, 
	get_customers, 
	delete_customer,
	delete_company_user, 
	update_company_user, 
	update_customer,
	get_company_user,
	update_company_user_level,
	create_company_employee
};