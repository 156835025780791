import { configureStore } from '@reduxjs/toolkit'
// import { authSlice } from './pages/auth/authSlice'
import { vehicleSlice } from './reducers/vehicleSlice'
import { searchSlice } from './reducers/searchSlice'
import { bookingSlice } from './reducers/bookingSlice'
import { tripSlice } from './reducers/tripSlice'

export default configureStore({
  reducer: {
    // auth: authSlice.reducer,
    vehicle: vehicleSlice.reducer,
    search: searchSlice.reducer,
    booking: bookingSlice.reducer,
    trip: tripSlice.reducer,
  }
})