import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import './index.css';
import { Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import TextareaAutosize from '@mui/material/TextareaAutosize';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import toast from 'react-hot-toast';

import { useDispatch, useSelector } from 'react-redux';
import { vehicleSelector } from '../../reducers/vehicleSlice';
import { get_features } from '../../API/setting';
import { AuthContext } from '../../contexts/AuthContext';
import { add_vehicle, add_features } from '../../API/vehicle';
import Modal from '@mui/material/Modal';
import {
  DRIVE_TRAIN,
  FUEL_TYPES,
  NUM_OF_BAGS,
  VEHICLE_COLORS,
  VEHICLE_SIZES,
} from '../../constants';

export default function AddVehicles() {
  const navigate = useNavigate();

  // const [selectedFile, setSelectedFile] = useState();
  const { authAxios } = useContext(AuthContext);
  const [selectedFiles, setSelectedFiles] = useState();
  const dispatch = useDispatch();
  const { isFetching, isError, errorMessage, isSuccess } =
    useSelector(vehicleSelector);

  // console.log(selectedFiles);
  const [vehicleName, setVehicleName] = useState(null);
  const [vehicleMake, setVehicleMake] = useState(null);
  const [vehicleTrim, setVehicleTrim] = useState(null);
  const [vehiclePrice, setVehiclePrice] = useState(null);
  const [vehicleStatus, setVehicleStatus] = useState(null);
  const [mileagePerDay, setMileagePerDay] = useState(null);
  const [mileagePerWeek, setMileagePerWeek] = useState(null);
  const [mileagePerMonth, setMileagePerMonth] = useState(null);
  const [mileageOverageFee, setMileageOverageFee] = useState(null); // new
  const [vehicleColor, setVehicleColor] = useState(null); // new
  const [fuelType, setFuelType] = useState(null); // new
  const [vehicleType, setVehicleType] = useState(null);
  const [driveTrain, setDriveTrain] = useState(null);
  const [noOfPass, setNoOfPass] = useState(null);
  const [model, setModel] = useState(null);
  const [size, setSize] = useState(null);
  const [car_features, setCarFeatures] = useState([]);
  const [carDescription, setCarDescription] = useState(null);
  const [licensePlate, setLicensePlate] = useState(null);
  const [interiorColor, setInteriorColor] = useState(null);
  const [vinNumber, setVinNumber] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [errorEmpty, setErrorEmpty] = useState('');
  let CarFeatures = [];

  const handleSave = async (e) => {
    e.preventDefault();
    let errorEmptyFields = [];
    if (!selectedFiles) errorEmptyFields.push('Car Image');
    if (!vehicleName) errorEmptyFields.push('Vehicle Name');
    if (!vehiclePrice) errorEmptyFields.push('Vehicle Price');
    if (!mileagePerDay) errorEmptyFields.push('Mileage Per Day');
    if (!mileagePerWeek) errorEmptyFields.push('Mileage Per Week');
    if (!mileagePerMonth) errorEmptyFields.push('Mileage Per Month');
    if (!vehicleType) errorEmptyFields.push('Vehicle Type');
    if (!noOfPass || !NUM_OF_BAGS.includes(noOfPass))
      errorEmptyFields.push('Number of Passengers');
    if (!driveTrain || !DRIVE_TRAIN.includes(driveTrain))
      errorEmptyFields.push('Drive Train');
    if (!model) errorEmptyFields.push('Model');
    if (!vinNumber) errorEmptyFields.push('Vin Number');
    if (!size || !VEHICLE_SIZES.includes(size))
      errorEmptyFields.push('Vehicle Class');
    if (!carDescription) errorEmptyFields.push('Description');
    if (!vehicleMake) errorEmptyFields.push('Vehicle Make');
    if (!vehicleTrim) errorEmptyFields.push('Vehicle Trim');
    if (!mileageOverageFee) errorEmptyFields.push('Mileage Overage Fee');
    if (!fuelType || !FUEL_TYPES.includes(fuelType))
      errorEmptyFields.push('Fuel Type');
    if (!licensePlate) errorEmptyFields.push('License Plate');
    if (!interiorColor || !VEHICLE_COLORS.includes(interiorColor))
      errorEmptyFields.push('Interior Color');
    if (!vehicleColor || !VEHICLE_COLORS.includes(vehicleColor))
      errorEmptyFields.push('Vehicle Color');
    const error = 'Fill out these fields: ' + errorEmptyFields.join(', ');

    if (
      !vehicleName ||
      !vehiclePrice ||
      !vinNumber ||
      !mileagePerDay ||
      !mileagePerWeek ||
      !mileagePerMonth ||
      !vehicleType ||
      !noOfPass ||
      !NUM_OF_BAGS.includes(noOfPass) ||
      !driveTrain ||
      !DRIVE_TRAIN.includes(driveTrain) ||
      !model ||
      !size ||
      !carDescription ||
      !car_features ||
      !vehicleMake ||
      !vehicleTrim ||
      !mileageOverageFee ||
      !fuelType ||
      !FUEL_TYPES.includes(fuelType) ||
      !licensePlate ||
      !interiorColor ||
      !vehicleColor ||
      !VEHICLE_SIZES.includes(size) ||
      !VEHICLE_COLORS.includes(interiorColor) ||
      !VEHICLE_COLORS.includes(vehicleColor)
    ) {
      setConfirmModal(true);
      setErrorEmpty(error);
    } else {
      handleConfirm();
    }
  };
  const handleConfirm = async () => {
    setConfirmModal(false);
    setErrorEmpty('');
    if (!vehicleName) {
      toast.error('Input Vehicle Name');
      return;
    }
    if (!vehiclePrice) {
      toast.error('Input Vehicle Price Per Day');
      return;
    }
    if (!vehicleStatus) {
      toast.error('Input Vehicle Status');
      return;
    }
    if (!mileagePerDay) {
      toast.error('Input Mileage Per Day');
      return;
    }
    if (!mileagePerWeek) {
      toast.error('Input Mileage Per Week');
      return;
    }
    if (!mileagePerMonth) {
      toast.error('Input Mileage Per Month');
      return;
    }
    if (!vehicleType) {
      toast.error('Input Vehicle Type');
      return;
    }
    if (!noOfPass) {
      toast.error('Input Number of Passengers');
      return;
    }
    if (!driveTrain) {
      toast.error('Input Drive Train');
      return;
    }
    if (!model) {
      toast.error('Input Model');
      return;
    }
    if (!size) {
      toast.error('Input Vehicle Class');
      return;
    }
    if (mileagePerDay >= mileagePerWeek) {
      toast.error('Invalid mileage per week.');
      return;
    }
    if (mileagePerWeek >= mileagePerMonth) {
      toast.error('Invalid mileage per month.');
      return;
    }
    if (vinNumber?.length < 17) {
      toast.error('VIN number is minimun 17 characters.');
      return;
    }

    // check if both checked soft top and hard top
    const hardTop = car_features.find(
      (feat) => feat.name == 'Hard Top Convertible'
    )?.active;
    const softTop = car_features.find(
      (feat) => feat.name == 'Soft Top Convertible'
    )?.active;
    if (hardTop && softTop) {
      toast.error('Invalid features selected.');
      return;
    }

    const formData = new FormData();
    formData.append('title', vehicleName);
    formData.append('daily_price', vehiclePrice);
    formData.append('total_price', vehiclePrice);
    formData.append('vehicle_status', vehicleStatus);
    formData.append('type', vehicleType);
    formData.append('daily_max_miles', mileagePerDay);
    formData.append('weekly_max_miles', mileagePerWeek);
    formData.append('monthly_max_miles', mileagePerMonth);
    formData.append('mileage_overage_fee', mileageOverageFee);
    formData.append('number_of_seats', size);
    formData.append('number_of_bags', noOfPass);
    formData.append('drive_train', driveTrain);
    formData.append('year', new Date().getFullYear());
    formData.append('model', model);
    formData.append('available_status', 'available');
    formData.append('description', carDescription);
    formData.append('vechicle_color', vehicleColor);
    formData.append('trim', vehicleTrim);
    formData.append('make', vehicleMake);
    formData.append('fuel_type', fuelType);
    formData.append('license_plate', licensePlate);
    formData.append('interior_color', interiorColor);
    formData.append('vin_number', vinNumber);

    if (selectedFiles)
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('car_images', selectedFiles[i]);
      }

    let res = await add_vehicle(authAxios, formData);
    let data = await res.data;
    if (res.status !== 201) {
      toast.error(Object.values(res.response.data)[0]);
      return;
    }

    const car_id = data.id;
    res = await add_features(
      authAxios,
      car_id,
      car_features.filter((feat) => feat.active).map((feat) => feat.id)
    );
    if (res.status === 200) {
      toast.success('Created Successfully!');
      clearState();
      navigate(-1);
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };
  const handleChangeFeature = (e, id) => {
    setCarFeatures(
      car_features.map((feat) => {
        if (feat.id == id) {
          return {
            ...feat,
            active: e.target.checked,
          };
        } else return feat;
      })
    );
  };
  const clearState = () => {
    setVehicleName(null);
    setVehicleStatus(null);
    setVehiclePrice(null);
    setMileagePerDay(null);
    setMileagePerWeek(null);
    setMileagePerMonth(null);
    setNoOfPass(null);
    setVehicleType(null);
    setModel(null);
    setSize(null);
    setCarFeatures(CarFeatures);
    setCarDescription(null);
    setLicensePlate(null);
    setInteriorColor(null);
    setVinNumber(null);
  };
  React.useEffect(() => {
    if (isError) {
      console.log(errorMessage);
      toast.error(errorMessage);
      clearState();
    }
    if (isSuccess) {
      clearState();
    }
  }, [isError, isSuccess]);

  React.useEffect(() => {
    const getFeatures = async () => {
      let res = await get_features(authAxios, true);
      if (res.status === 200) {
        CarFeatures = res.data;
        setCarFeatures(res.data);
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }
    };

    getFeatures();
  }, []);
  console.log(selectedFiles);
  // console.log([1, 2, 3, 4, 5].map((i) => (selectedFiles[i] ? true : false)));
  return (
    <div className='dashboard page'>
      <Sidebar page='add_vehicle' />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />

        <div className='go_back' onClick={() => navigate(-1)}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-5 w-5'
            viewBox='0 0 20 20'
            fill='#46a8c8'
            width={30}
          >
            <path
              fillRule='evenodd'
              d='M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z'
              clipRule='evenodd'
            />
          </svg>
        </div>
        <div className='editWrapper pagewrapper add_vehicle'>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isFetching}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
          <div className='editContainer'>
            <h5>Add Vehicle</h5>
            <form
              action='#'
              onSubmit={(e) => handleSave(e)}
              className='card_details'
            >
              <div className='info_wrapper'>
                <div className='info_row '>
                  <div className='field'>
                    Vehicle Code
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        className='airport_name'
                        labelid='size'
                        id='demo-simple-select'
                        defaultValue={vehicleName}
                        label='Enter Vehicle Code'
                        onChange={(e) => setVehicleName(e.target.value)}
                      ></TextField>
                    </FormControl>
                  </div>
                  <div className='field'>
                    Make
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        className='airport_name'
                        labelid='size'
                        id='demo-simple-select'
                        defaultValue={vehicleMake}
                        label='Enter Vehicle Make'
                        onChange={(e) => setVehicleMake(e.target.value)}
                      ></TextField>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field'>
                    Model
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        className='airport_name'
                        labelid='size'
                        id='demo-simple-select'
                        defaultValue={model}
                        label='Enter Model'
                        onChange={(e) => setModel(e.target.value)}
                      ></TextField>
                    </FormControl>
                  </div>
                  <div className='field'>
                    Trim
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        className='airport_name'
                        labelId='size'
                        id='demo-simple-select'
                        defaultValue={vehicleTrim}
                        label='Enter Vehicle Trim'
                        onChange={(e) => setVehicleTrim(e.target.value)}
                      ></TextField>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field'>
                    Vehicle Class
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>
                        Select Vehicle Class
                      </InputLabel>
                      <Select
                        className='airport_name'
                        labelid='size'
                        id='demo-simple-select'
                        defaultValue={size}
                        label='Size'
                        onChange={(e) => setSize(e.target.value)}
                      >
                        {VEHICLE_SIZES.map((item) => (
                          <MenuItem
                            className='airport_name_menu_item'
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className='field'>
                    Vehicle Fuel Type
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>
                        Select Vehicle Fuel Type
                      </InputLabel>
                      <Select
                        className='airport_name'
                        labelid='numberOfPassengers'
                        id='demo-simple-select'
                        defaultValue={fuelType}
                        label='Number of Passengers'
                        onChange={(e) => setFuelType(e.target.value)}
                      >
                        {FUEL_TYPES.map((item) => (
                          <MenuItem
                            className='airport_name_menu_item'
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field'>
                    Transmission Type
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>
                        Select Transmission Type
                      </InputLabel>
                      <Select
                        className='airport_name'
                        labelid='vehicleType'
                        id='demo-simple-select'
                        defaultValue={vehicleType}
                        label='Vehicle Type'
                        onChange={(e) => setVehicleType(e.target.value)}
                      >
                        <MenuItem
                          className='airport_name_menu_item'
                          value={'manual'}
                        >
                          Manual
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item'
                          value={'automatic'}
                        >
                          Automatic
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className='field'>
                    Drive Train
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>
                        Select Drive Train
                      </InputLabel>
                      <Select
                        className='airport_name'
                        labelid='driveTrain'
                        id='demo-simple-select'
                        defaultValue={driveTrain}
                        label='Select Drive Train'
                        onChange={(e) => setDriveTrain(e.target.value)}
                      >
                        {DRIVE_TRAIN.map((item) => (
                          <MenuItem
                            key={item}
                            className='airport_name_menu_item'
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field'>
                    Vehicle Color
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>
                        Select Vehicle Color
                      </InputLabel>
                      <Select
                        className='airport_name'
                        labelid='vehicleType'
                        id='demo-simple-select'
                        defaultValue={vehicleColor}
                        label='Vehicle Color'
                        onChange={(e) => setVehicleColor(e.target.value)}
                      >
                        {VEHICLE_COLORS.map((item) => (
                          <MenuItem
                            className='airport_name_menu_item'
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className='field'>
                    Vehicle Interior Color
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>
                        Select Vehicle Interior Color
                      </InputLabel>
                      <Select
                        className='airport_name'
                        labelid='vehicleType'
                        id='demo-simple-select'
                        value={interiorColor}
                        label='Interior Color'
                        onChange={(e) => setInteriorColor(e.target.value)}
                      >
                        {VEHICLE_COLORS.map((item) => (
                          <MenuItem
                            className='airport_name_menu_item'
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field'>
                    License Plate
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        className='airport_name'
                        labelId='size'
                        id='demo-simple-select'
                        defaultValue={licensePlate}
                        label='Enter License Plate'
                        onChange={(e) => setLicensePlate(e.target.value)}
                      ></TextField>
                    </FormControl>
                  </div>
                  <div className='field'>
                    Vin Number
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        className='airport_name'
                        labelId='size'
                        id='demo-simple-select'
                        defaultValue={vinNumber}
                        label='Enter Vin Number'
                        onChange={(e) => setVinNumber(e.target.value)}
                      ></TextField>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field'>
                    Mileage Per Day
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>
                        Select Mileage Per Day
                      </InputLabel>
                      <Select
                        className='airport_name'
                        labelid='mileage'
                        id='mileage'
                        defaultValue={mileagePerDay}
                        label='Select Mileage Per Day'
                        onChange={(e) => setMileagePerDay(e.target.value)}
                      >
                        <MenuItem className='airport_name_menu_item' value={50}>
                          50
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={75}>
                          75
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item'
                          value={100}
                        >
                          100
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item'
                          value={150}
                        >
                          150
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item'
                          value={200}
                        >
                          200
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className='field'>
                    Mileage Per Week
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>
                        Select Mileage Per Week
                      </InputLabel>
                      <Select
                        className='airport_name'
                        labelid='vehicleType'
                        id='demo-simple-select'
                        defaultValue={mileagePerWeek}
                        label='Select Mileage Per Week'
                        onChange={(e) => setMileagePerWeek(e.target.value)}
                      >
                        <MenuItem
                          className='airport_name_menu_item'
                          value={250}
                        >
                          250
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item'
                          value={500}
                        >
                          500
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item'
                          value={750}
                        >
                          750
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item'
                          value={1000}
                        >
                          1000
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item'
                          value={1500}
                        >
                          1500
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field'>
                    Mileage Per Month
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>
                        Select Mileage Per Month
                      </InputLabel>
                      <Select
                        className='airport_name'
                        labelid='mileage'
                        id='mileage'
                        defaultValue={mileagePerMonth}
                        label='Select Mileage Per Month'
                        onChange={(e) => setMileagePerMonth(e.target.value)}
                      >
                        <MenuItem
                          className='airport_name_menu_item'
                          value={1000}
                        >
                          1000
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item'
                          value={1500}
                        >
                          1500
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item'
                          value={2000}
                        >
                          2000
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item'
                          value={2500}
                        >
                          2500
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className='field'>
                    Mileage Overage Fee
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <span className='dollor_sign'>$</span>
                      <TextField
                        className='airport_name'
                        labelid='size'
                        id='demo-simple-select'
                        defaultValue={mileageOverageFee}
                        type='number'
                        label='$ 00.00'
                        inputProps={{
                          step: '.01',
                        }}
                        onChange={(e) => setMileageOverageFee(e.target.value)}
                      ></TextField>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field'>
                    Vehicle Price Per Day
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        className='airport_name'
                        labelid='size'
                        id='demo-simple-select'
                        defaultValue={vehiclePrice}
                        label='Enter Vehicle Price Per Day'
                        onChange={(e) => setVehiclePrice(e.target.value)}
                      ></TextField>
                    </FormControl>
                  </div>
                  <div className='field'>
                    Number of Passengers
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>
                        Select Number of Passengers
                      </InputLabel>
                      <Select
                        className='airport_name'
                        labelid='numberOfPassengers'
                        id='demo-simple-select'
                        defaultValue={noOfPass}
                        label='Number of Passengers'
                        onChange={(e) => setNoOfPass(e.target.value)}
                      >
                        {NUM_OF_BAGS.map((item) => (
                          <MenuItem
                            key={item}
                            className='airport_name_menu_item'
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field'>
                    Vehicle Status
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>Select Car Type</InputLabel>
                      <Select
                        className={`airport_name ${vehicleStatus || null}`}
                        labelid='carType'
                        id='demo-simple-select'
                        defaultValue={vehicleStatus}
                        label='Car Type'
                        onChange={(e) => setVehicleStatus(e.target.value)}
                      >
                        <MenuItem
                          className='airport_name_menu_item refuel'
                          value={'refuel'}
                        >
                          Refuel
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item wash'
                          value={'refuel_wash'}
                        >
                          Refuel/Wash
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item prepped'
                          value={'wash'}
                        >
                          Wash
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item repair'
                          value={'detail'}
                        >
                          Detail
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item wash'
                          value={'rinse'}
                        >
                          Rinse
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item prepped'
                          value={'prepped'}
                        >
                          Prepped
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item repair'
                          value={'repair'}
                        >
                          Repair/Maintenance
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item prepped'
                          value={'employee'}
                        >
                          With Employee
                        </MenuItem>
                        <MenuItem
                          className='airport_name_menu_item refuel'
                          value={'on_rent'}
                        >
                          On Rent
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field'>
                    Car Description
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextareaAutosize
                        required
                        className='airport_name couponDescription'
                        labelid='size'
                        id='demo-simple-select'
                        defaultValue={carDescription}
                        placeholder='Write .........'
                        onChange={(e) => setCarDescription(e.target.value)}
                      ></TextareaAutosize>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field'>
                    Add Car Image
                    <Button
                      required
                      className='user_upload_btn edit_dropdown'
                      variant='contained'
                      component='label'
                    >
                      {selectedFiles ? (
                        <>
                          <div
                            className='removeImageIcon'
                            onClick={() =>
                              setSelectedFiles(
                                selectedFiles?.filter((e, index) => index !== 0)
                              )
                            }
                          >
                            <svg
                              width='20'
                              height='20'
                              viewBox='0 0 110 110'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                                stroke='#FF2552'
                                strokeWidth='11.625'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                                stroke='#FF2552'
                                strokeWidth='11.625'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </div>
                          <img
                            src={URL.createObjectURL(selectedFiles[0])}
                            alt={selectedFiles[0].name.substring(0, 5)}
                          />
                        </>
                      ) : (
                        <>
                          <svg
                            width='36'
                            height='36'
                            viewBox='0 0 36 36'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M1.125 22.275C1.42337 22.275 1.70952 22.3935 1.9205 22.6045C2.13147 22.8155 2.25 23.1016 2.25 23.4V29.025C2.25 29.6217 2.48705 30.194 2.90901 30.616C3.33097 31.0379 3.90326 31.275 4.5 31.275H31.5C32.0967 31.275 32.669 31.0379 33.091 30.616C33.5129 30.194 33.75 29.6217 33.75 29.025V23.4C33.75 23.1016 33.8685 22.8155 34.0795 22.6045C34.2905 22.3935 34.5766 22.275 34.875 22.275C35.1734 22.275 35.4595 22.3935 35.6705 22.6045C35.8815 22.8155 36 23.1016 36 23.4V29.025C36 30.2185 35.5259 31.3631 34.682 32.207C33.8381 33.0509 32.6935 33.525 31.5 33.525H4.5C3.30653 33.525 2.16193 33.0509 1.31802 32.207C0.474106 31.3631 0 30.2185 0 29.025V23.4C0 23.1016 0.118526 22.8155 0.329505 22.6045C0.540483 22.3935 0.826631 22.275 1.125 22.275Z'
                              fill='#4FC8E9'
                            />
                            <path
                              d='M17.2039 2.5785C17.3084 2.47373 17.4326 2.39061 17.5693 2.33389C17.7059 2.27718 17.8525 2.24799 18.0004 2.24799C18.1484 2.24799 18.2949 2.27718 18.4316 2.33389C18.5683 2.39061 18.6924 2.47373 18.7969 2.5785L25.5469 9.3285C25.7582 9.53974 25.8769 9.82625 25.8769 10.125C25.8769 10.4237 25.7582 10.7103 25.5469 10.9215C25.3357 11.1327 25.0492 11.2514 24.7504 11.2514C24.4517 11.2514 24.1652 11.1327 23.9539 10.9215L19.1254 6.09075V25.875C19.1254 26.1734 19.0069 26.4595 18.7959 26.6705C18.585 26.8815 18.2988 27 18.0004 27C17.7021 27 17.4159 26.8815 17.205 26.6705C16.994 26.4595 16.8754 26.1734 16.8754 25.875V6.09075L12.0469 10.9215C11.9423 11.0261 11.8182 11.1091 11.6815 11.1657C11.5448 11.2223 11.3984 11.2514 11.2504 11.2514C11.1025 11.2514 10.956 11.2223 10.8194 11.1657C10.6827 11.1091 10.5585 11.0261 10.4539 10.9215C10.3493 10.8169 10.2664 10.6927 10.2098 10.5561C10.1532 10.4194 10.124 10.2729 10.124 10.125C10.124 9.97707 10.1532 9.8306 10.2098 9.69393C10.2664 9.55727 10.3493 9.4331 10.4539 9.3285L17.2039 2.5785Z'
                              fill='#4FC8E9'
                            />
                          </svg>
                          Upload Car Images
                        </>
                      )}
                      <input
                        type='file'
                        name='UserDriverLicense'
                        id='UserDriverLicense'
                        // multiple
                        disabled={
                          selectedFiles && (selectedFiles[0] ? true : false)
                        }
                        hidden
                        onChange={(e) => {
                          selectedFiles?.length > 0
                            ? setSelectedFiles([
                                ...selectedFiles,
                                ...e.target.files,
                              ])
                            : setSelectedFiles([e.target.files[0]]);
                        }}
                        // onChange={(e) => setSelectedFiles(e.target.files)}
                        // onChange={(e) => setSelectedFile(e.target.files[0])}
                      />
                    </Button>
                  </div>
                </div>
                <div className='info_row additionalImages '>
                  <div className='field'>
                    <div>Add Car Additional Images</div>
                    <div className='additionalImgsBtns'>
                      {[1, 2, 3, 4, 5].map((i) => (
                        <Button
                          required
                          className='user_upload_btn edit_dropdown'
                          variant='contained'
                          component='label'
                        >
                          {selectedFiles?.length > i ? (
                            <>
                              <div
                                className='removeImageIcon'
                                onClick={() =>
                                  setSelectedFiles(
                                    selectedFiles?.filter(
                                      (e, index) => index !== i
                                    )
                                  )
                                }
                              >
                                <svg
                                  width='20'
                                  height='20'
                                  viewBox='0 0 110 110'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                                    stroke='#FF2552'
                                    strokeWidth='11.625'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <path
                                    d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                                    stroke='#FF2552'
                                    strokeWidth='11.625'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                </svg>
                              </div>
                              <img
                                src={URL.createObjectURL(selectedFiles[i])}
                                alt={selectedFiles[i].name.substring(0, 5)}
                              />
                            </>
                          ) : (
                            <>
                              <svg
                                width='36'
                                height='36'
                                viewBox='0 0 36 36'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M1.125 22.275C1.42337 22.275 1.70952 22.3935 1.9205 22.6045C2.13147 22.8155 2.25 23.1016 2.25 23.4V29.025C2.25 29.6217 2.48705 30.194 2.90901 30.616C3.33097 31.0379 3.90326 31.275 4.5 31.275H31.5C32.0967 31.275 32.669 31.0379 33.091 30.616C33.5129 30.194 33.75 29.6217 33.75 29.025V23.4C33.75 23.1016 33.8685 22.8155 34.0795 22.6045C34.2905 22.3935 34.5766 22.275 34.875 22.275C35.1734 22.275 35.4595 22.3935 35.6705 22.6045C35.8815 22.8155 36 23.1016 36 23.4V29.025C36 30.2185 35.5259 31.3631 34.682 32.207C33.8381 33.0509 32.6935 33.525 31.5 33.525H4.5C3.30653 33.525 2.16193 33.0509 1.31802 32.207C0.474106 31.3631 0 30.2185 0 29.025V23.4C0 23.1016 0.118526 22.8155 0.329505 22.6045C0.540483 22.3935 0.826631 22.275 1.125 22.275Z'
                                  fill='#4FC8E9'
                                />
                                <path
                                  d='M17.2039 2.5785C17.3084 2.47373 17.4326 2.39061 17.5693 2.33389C17.7059 2.27718 17.8525 2.24799 18.0004 2.24799C18.1484 2.24799 18.2949 2.27718 18.4316 2.33389C18.5683 2.39061 18.6924 2.47373 18.7969 2.5785L25.5469 9.3285C25.7582 9.53974 25.8769 9.82625 25.8769 10.125C25.8769 10.4237 25.7582 10.7103 25.5469 10.9215C25.3357 11.1327 25.0492 11.2514 24.7504 11.2514C24.4517 11.2514 24.1652 11.1327 23.9539 10.9215L19.1254 6.09075V25.875C19.1254 26.1734 19.0069 26.4595 18.7959 26.6705C18.585 26.8815 18.2988 27 18.0004 27C17.7021 27 17.4159 26.8815 17.205 26.6705C16.994 26.4595 16.8754 26.1734 16.8754 25.875V6.09075L12.0469 10.9215C11.9423 11.0261 11.8182 11.1091 11.6815 11.1657C11.5448 11.2223 11.3984 11.2514 11.2504 11.2514C11.1025 11.2514 10.956 11.2223 10.8194 11.1657C10.6827 11.1091 10.5585 11.0261 10.4539 10.9215C10.3493 10.8169 10.2664 10.6927 10.2098 10.5561C10.1532 10.4194 10.124 10.2729 10.124 10.125C10.124 9.97707 10.1532 9.8306 10.2098 9.69393C10.2664 9.55727 10.3493 9.4331 10.4539 9.3285L17.2039 2.5785Z'
                                  fill='#4FC8E9'
                                />
                              </svg>
                              Car Images
                            </>
                          )}
                          <input
                            type='file'
                            name='UserDriverLicense'
                            id='UserDriverLicense'
                            multiple
                            hidden
                            // disabled={[1, 2, 3, 4, 5].map((i) => (selectedFiles[i] ? true : false))}
                            disabled={
                              selectedFiles && (selectedFiles[i] ? true : false)
                            }
                            onChange={(e) => {
                              selectedFiles?.length < 6 &&
                                (selectedFiles?.length > 0
                                  ? setSelectedFiles([
                                      ...selectedFiles,
                                      ...e.target.files,
                                    ])
                                  : setSelectedFiles([...e.target.files]));
                            }}
                            // onChange={(e) => setSelectedFile(e.target.files[0])}
                          />
                        </Button>
                      ))}
                    </div>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field' style={{ fontSize: '14px' }}>
                    Choose Vehicle Features
                    <div className='carFeatures' style={{ margin: '0' }}>
                      <div className='features'>
                        <FormGroup className='feature_checkboxs'>
                          {car_features.map((feat) => (
                            <FormControlLabel
                              className='feature_checkbox_wrapper'
                              control={
                                <Checkbox
                                  className='feature_checkbox'
                                  defaultChecked={feat.active ? true : false}
                                  onChange={(e) =>
                                    handleChangeFeature(e, feat.id)
                                  }
                                  // disabled={edit}
                                />
                              }
                              label={feat.name}
                            />
                          ))}
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='confirmation_btn'>
                <button type='submit'> Save </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        open={confirmModal}
        onClose={() => {
          setConfirmModal(false);
          setErrorEmpty('');
        }}
        aria-labelledby='conf_modal'
        aria-describedby='modal-modal-description'
      >
        <div className='confModal car_confirm_remote'>
          <svg
            width='110'
            height='110'
            viewBox='0 0 110 110'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
              stroke='#FF2552'
              strokeWidth='11.625'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
              stroke='#FF2552'
              strokeWidth='11.625'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>{' '}
          <br />
          <h4>Are You Sure?</h4>
          <p>
            Not all fields have been entered. Are you sure you want to proceed?
          </p>
          <p>{errorEmpty}</p>
          <div className='btns'>
            <button
              onClick={() => {
                setConfirmModal(false);
                setErrorEmpty('');
              }}
              className='secondary'
            >
              Cancel
            </button>
            <button className='primary' onClick={(e) => handleConfirm(e)}>
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
