import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import './index.css';

import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { get_company_users } from '../../API/company';
import { get_packages } from '../../API/package';
import toast from 'react-hot-toast';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import Modal from '@mui/material/Modal';
// import TextField from '@mui/material/TextField';
import { AuthContext } from "../../contexts/AuthContext";
import { MAIN_HOST } from '../../api';
import { delete_company_user, update_company_user } from '../../API/user';
import { subfix_domain } from '../../api';

export default function Companies() {
  const { authAxios } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [company_users, setCompanyUsers] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const open = Boolean(anchorEl);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editUserEmail, setEditUserEmail] = useState(null);
  const [editUserFirstName, setEditUserFirstName] = useState(null);
  const [editUserLastName, setEditUserLastName] = useState(null);
  const [editUserPhone, setEditUserPhone] = useState(null);
  const [editUserModal, setEditUserModal] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))();

  const handlePackage = (pack) => {
    handleClose();

    // navigate(`/register/${pack}`)
    window.location.href = `${MAIN_HOST}/register/${pack}`;
  }

  const handleDeleteUser = async (e) => {
    e.preventDefault();
    if(selectedUser.role == 'Owner') return;
    let res = await delete_company_user(authAxios, { email: selectedUser.user.email, id: selectedUser.id, subfix_domain: '' });
    if (res.status === 200) {
      toast.success('Deleted Successfully');
      setDeleteUserModal(false);
      await getCompanyUsers();
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  }
  
  const handleUpdateUser = async (e) => {
    e.preventDefault();
    const payload = {
      id: selectedUser.id,
      email: editUserEmail,
      first_name: editUserFirstName,
      last_name: editUserLastName || '',
      name: editUserLastName? editUserFirstName + ' ' + editUserLastName: editUserFirstName,
      phone: editUserPhone,
      username: selectedUser.user.username
    }

    let res = await update_company_user(authAxios, { ...payload, subfix_domain });
    if (res.status === 200) {
      toast.success('Updated Successfully');
      setEditUserModal(false);
      await getCompanyUsers();
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  }

  const getCompanyUsers = async () => {
    const res = await get_company_users(authAxios);
    if (res.status === 200) {
      setCompanyUsers(res.data.filter(user => user.role != 'Free' && user.role != 'Owner' && user.role).map(user => {
        const pack = permissions.find(perm => perm.sname == user.role);
        return {
          ...user,
          role_name: pack?.name
        }
      }));
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  }

  const getPackages = async () => {
    const res = await get_packages(authAxios);
    if (res.status === 200) {
      setPermissions(res.data.filter(item => item.name != 'Owner' && item.name != 'Free'))
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  }

  useEffect(() => {
    getPackages();
  }, []);

  useEffect(() => {
    getCompanyUsers();
  }, [permissions]);

  return (
    <div className='dashboard page'>
      <Sidebar page='companies' />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <div className='booking_wrapper'>
            <div className='booking_header'>
              <h4>All Companies</h4>
              <div className='allUsers_header_actions'>
                {/* <button>Add Admin</button> */}
                <div>
                  <Button
                    id='demo-customized-button'
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    variant='contained'
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    Add Admin
                  </Button>
                  <StyledMenu
                    id='demo-customized-menu'
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    className='add_admin_dropdown'
                  >
                    {permissions.map(perm => <MenuItem key={perm.id} onClick={(e) => handlePackage(perm.sname)} disableRipple>
                      {perm.name}
                    </MenuItem>)}
                  </StyledMenu>
                </div>
                {/* <button>Log Out</button> */}
              </div>
            </div>
            <div className='allUsers_table'>
              <table>
                <thead>
                  <td>Profile</td>
                  <td>Full Name</td>
                  <td>Company</td>
                  <td>Email Address</td>
                  <td>Package</td>
                  <td>Phone</td>
                  <td className='table_actions'></td>
                </thead>
                {company_users.map((user) => (
                  <tr id={user.id}>
                    <td className='profile'>
                      <img src={user.img} alt={user.name} />
                    </td>
                    <td>{user.name}</td>
                    <td>{user.company.name}</td>
                    <td>{user.user.email}</td>
                    <td className='role'>{user.role_name}</td>
                    <td>{user.phone}</td>
                    <td className='table_actions'>
                      <button onClick={() => {
                        setEditUserModal(true); 
                        setSelectedUser(user);
                        setEditUserEmail(user.user.email)
                        setEditUserFirstName(user.name.split(' ')[0])
                        setEditUserLastName(user.name.split(' ')[1])
                        setEditUserPhone(user.phone)
                      }}>
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.02717 11.75C4.05842 11.75 4.08967 11.7469 4.12092 11.7422L6.74905 11.2812C6.7803 11.275 6.80999 11.2609 6.83186 11.2375L13.4553 4.61406C13.4698 4.59961 13.4813 4.58244 13.4891 4.56354C13.497 4.54463 13.501 4.52437 13.501 4.50391C13.501 4.48344 13.497 4.46318 13.4891 4.44428C13.4813 4.42538 13.4698 4.40821 13.4553 4.39375L10.8584 1.79531C10.8287 1.76563 10.7897 1.75 10.7475 1.75C10.7053 1.75 10.6662 1.76563 10.6365 1.79531L4.01311 8.41875C3.98967 8.44219 3.97561 8.47031 3.96936 8.50156L3.50842 11.1297C3.49322 11.2134 3.49865 11.2995 3.52425 11.3807C3.54984 11.4618 3.59482 11.5355 3.6553 11.5953C3.75842 11.6953 3.88811 11.75 4.02717 11.75V11.75ZM5.0803 9.025L10.7475 3.35938L11.8928 4.50469L6.22561 10.1703L4.83655 10.4156L5.0803 9.025V9.025Z'
                            fill='white'
                          />
                          <path
                            d='M13.75 13.0625H2.25C1.97344 13.0625 1.75 13.2859 1.75 13.5625V14.125C1.75 14.1938 1.80625 14.25 1.875 14.25H14.125C14.1938 14.25 14.25 14.1938 14.25 14.125V13.5625C14.25 13.2859 14.0266 13.0625 13.75 13.0625Z'
                            fill='white'
                          />
                        </svg>
                        Edit
                      </button>
                      <button onClick={() => {setDeleteUserModal(true); setSelectedUser(user)}}>
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M9.2 3.2002C9.2 2.88194 9.07357 2.57671 8.84853 2.35167C8.62348 2.12662 8.31826 2.0002 8 2.0002C7.68174 2.0002 7.37652 2.12662 7.15147 2.35167C6.92643 2.57671 6.8 2.88194 6.8 3.2002H6C6 2.66976 6.21071 2.16105 6.58579 1.78598C6.96086 1.41091 7.46957 1.2002 8 1.2002C8.53043 1.2002 9.03914 1.41091 9.41421 1.78598C9.78929 2.16105 10 2.66976 10 3.2002H13.6C13.7061 3.2002 13.8078 3.24234 13.8828 3.31735C13.9579 3.39237 14 3.49411 14 3.6002C14 3.70628 13.9579 3.80802 13.8828 3.88304C13.8078 3.95805 13.7061 4.0002 13.6 4.0002H13.1568L12.12 12.9842C12.0749 13.3741 11.888 13.7338 11.5949 13.9949C11.3018 14.256 10.9229 14.4002 10.5304 14.4002H5.4696C5.07708 14.4002 4.69823 14.256 4.40513 13.9949C4.11204 13.7338 3.92514 13.3741 3.88 12.9842L2.8424 4.0002H2.4C2.30638 4.00023 2.21571 3.96742 2.14379 3.90748C2.07187 3.84755 2.02325 3.76429 2.0064 3.6722L2 3.6002C2 3.49411 2.04214 3.39237 2.11716 3.31735C2.19217 3.24234 2.29391 3.2002 2.4 3.2002H9.2ZM12.3504 4.0002H3.6488L4.6744 12.8922C4.69698 13.0872 4.79048 13.2671 4.93712 13.3977C5.08375 13.5282 5.27327 13.6003 5.4696 13.6002H10.5304C10.7266 13.6001 10.9159 13.528 11.0624 13.3974C11.2088 13.2669 11.3022 13.0871 11.3248 12.8922L12.3504 4.0002Z'
                            fill='white'
                          />
                          <path
                            d='M6.79844 6C6.99444 6 7.15844 6.124 7.19204 6.2872L7.19844 6.3504V11.2504C7.19844 11.4432 7.01924 11.6 6.79844 11.6C6.60244 11.6 6.43844 11.476 6.40484 11.3128L6.39844 11.2496V6.3512C6.39844 6.1576 6.57764 6.0008 6.79844 6.0008V6ZM9.19844 6C9.39444 6 9.55844 6.124 9.59204 6.2872L9.59844 6.3504V11.2504C9.59844 11.4432 9.41924 11.6 9.19844 11.6C9.00244 11.6 8.83844 11.476 8.80484 11.3128L8.79844 11.2496V6.3512C8.79844 6.1576 8.97764 6.0008 9.19844 6.0008V6Z'
                            fill='white'
                          />
                        </svg>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
        <Modal
          open={deleteUserModal}
          onClose={() => setDeleteUserModal(false)}
          aria-labelledby='conf_modal'
          aria-describedby='modal-modal-description'
        >
          <div className='confModal'>
            <svg
              width='110'
              height='110'
              viewBox='0 0 110 110'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                stroke='#FF2552'
                strokeWidth='11.625'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                stroke='#FF2552'
                strokeWidth='11.625'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>{' '}
            <br />
            <h4>Are You Sure?</h4>
            <p>
              Do you really want to delete this info. This process
              cannot be undo.
            </p>
            <div className='btns'>
              <button
                onClick={() => setDeleteUserModal(false)}
                className='secondary'
              >
                Cancel
              </button>
              <button
                className='primary'
                onClick={(e) => handleDeleteUser(e)}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          open={editUserModal}
          onClose={() => setEditUserModal(false)}
          aria-labelledby='conf_modal'
          aria-describedby='modal-modal-description'
        >
          <div className='confModal edit_user_modal'>
            <br />
            <h4>Edit User</h4>
            <div className='info_wrapper'>
              <div className='info_row '>
                <div className='field'>
                  First Name
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name'
                      labelId='size'
                      id='demo-simple-select'
                      value={editUserFirstName}
                      label='First Name'
                      onChange={(e) => setEditUserFirstName(e.target.value)}
                    ></TextField>
                  </FormControl>
                </div>
              </div>
              <div className='info_row '>
                <div className='field'>
                  Last Name
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name'
                      labelId='size'
                      id='demo-simple-select'
                      value={editUserLastName}
                      label='Last Name'
                      onChange={(e) => setEditUserLastName(e.target.value)}
                    ></TextField>
                  </FormControl>
                </div>
              </div>
              <div className='info_row '>
                <div className='field'>
                  Email
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name'
                      labelId='size'
                      id='demo-simple-select'
                      value={editUserEmail}
                      label='Email'
                      onChange={(e) => setEditUserEmail(e.target.value)}
                    ></TextField>
                  </FormControl>
                </div>
              </div>
              <div className='info_row '>
                <div className='field'>
                  Phone
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name'
                      labelId='size'
                      id='demo-simple-select'
                      value={editUserPhone}
                      label='Phone'
                      onChange={(e) => setEditUserPhone(e.target.value)}
                    ></TextField>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className='btns'>
              <button className='primary' onClick={handleUpdateUser}>
                Save
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
