import React, { useEffect, useState, useContext } from 'react';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import { useParams } from 'react-router';
import { get_car_report } from '../../API/car_report';
import './index.css';
import toast from 'react-hot-toast';
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from 'react-router-dom';

export default function CarReportDetail() {
  const [carReportData, setCarReportData] = useState([]);
  const { id } = useParams();
  const { authAxios } = useContext(AuthContext);
  const navigate = useNavigate();

  const getCarReportData = async () => {
    const res = await get_car_report(authAxios, {car: id, year: 2022 });
    if (res.status === 200) {
      setCarReportData(res.data);
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  useEffect(() => {
    getCarReportData();
  }, [])

  return (
    <div className='dashboard page'>
      <Sidebar page='car_report' />
      <div className='hero_content car_report'>
        <MobileNavbar />
        <Header />

        <div className='admin_row'>
          <div>Car Report</div>
          <div className='carVinNo'>
            VIN : <span>123456789012345TY</span>
          </div>
        </div>
        <div className='dashboard_row car_reports'>
          {carReportData.map((report) =>
            report.quarterAnalysis ? (
              <div className='quarterAnalysis'>
                <div className='title'>Quarter Analysis</div>
                <div className='quarterAnalysisContainer'>
                  <div className='title'>{report.quarter}</div>

                  <div className='reportTable'>
                    <table>
                      <thead>
                        <th>Days Out </th>
                        <th>Total Rate</th>
                        <th>Rate (Actual) </th>
                        <th>Total Revenue </th>
                        <th>Miles </th>
                      </thead>
                      <tbody>
                        <tr>
                          <td className='destination'>
                            <div>{report.totals.days_out}</div>
                          </td>
                          <td className='destination'>
                            <div>{report.totals.total_rate}</div>
                          </td>
                          <td className='destination'>
                            <div>{report.totals.total_rate_actual}</div>
                          </td>
                          <td className='destination'>
                            <div>{report.totals.total_revenue}</div>
                          </td>
                          <td className='destination'>
                            <div>{report.totals.miles}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className='monthAnalysis quartrUtilization'>
                    <div className='title'>Totals</div>
                    <div className='totals'>
                      <div>
                        Days in Quarter :{' '}
                        <span>{report.utilization.days_in_quarter}</span>
                      </div>
                      <div>
                        Fleet Days :{' '}
                        <span>{report.utilization.fleet_days}</span>
                      </div>
                      <div>
                        Days Used : <span>{report.utilization.days_used}</span>
                      </div>
                      <div>
                        Total Rate :{' '}
                        <span>{report.totals.total_rate}</span>
                      </div>
                      <div>
                        Total Rate (Actual) :{' '}
                        <span>{report.totals.total_rate_actual}</span>
                      </div>
                      <div>
                        Additional Fees :{' '}
                        <span>{0}</span>
                      </div>
                      <div>
                        Total Revenue :{' '}
                        <span>{report.totals.total_revenue}</span>
                      </div>
                      <div>
                        Miles :{' '}
                        <span>{report.totals.miles}</span>
                      </div>
                      <div>
                        % Utilization : <span>{report.utilization.total}</span>
                      </div>
                    </div>

                    <div className='title'>Average Per Client</div>
                    <div className='totals'>
                      <div>
                        Days Used : <span>{report.utilization.days_used}</span>
                      </div>
                      <div>
                        Total Rate :{' '}
                        <span>{report.average.total_rate_avg}</span>
                      </div>
                      <div>
                        Total Rate (Actual) :{' '}
                        <span>{report.average.total_rate_actual_avg}</span>
                      </div>
                      <div>
                        Additional Fees :{' '}
                        <span>{0}</span>
                      </div>
                      <div>
                        Total Revenue :{' '}
                        <span>{report.average.total_revenue_avg}</span>
                      </div>
                      <div>
                        Miles :{' '}
                        <span>{report.average.miles_avg}</span>
                      </div>
                    </div>

                    <div className='title'>Utilization</div>
                    <div className='totals'>
                      <div>
                        Days in Quarter :{' '}
                        <span>{report.utilization.days_in_quarter}</span>
                      </div>
                      <div>
                        Fleet Days :{' '}
                        <span>{report.utilization.fleet_days}</span>
                      </div>
                      <div>
                        Days Used : <span>{report.utilization.days_used}</span>
                      </div>
                      <div>
                        % Utilization  : <span>{report.utilization.total}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='dashboard_container'>
                {report.month}
                <div className='section customer_report monthTable'>
                  <div className='reportTable'>
                    <table>
                      <thead>
                        <th>Invoice </th>
                        <th>Customer Name</th>
                        <th>Start Date </th>
                        <th>End Date </th>
                        <th>Length (Days) </th>
                        <th>Daily Rate </th>
                        <th>Daily Rate (Actual) </th>
                        <th>Total Rate </th>
                        <th>Miles In </th>
                        <th>Miles Out </th>
                        <th className='charges delivery'>Delivery </th>
                        <th className='charges'>Miles </th>
                        <th className='charges fuel'>Fuel Reimbursment </th>
                        <th>Discounts </th>
                        <th>Rate (Actual) </th>
                        <th>Total Revenue</th>
                      </thead>
                      <tbody>
                        {report.bookings.map((booking) => (
                          <tr>
                            <td className='destination'>
                              <div>{booking.invoice_array.map(item => (<><a href={item.invoice_url} target="_blank" rel="noopener noreferrer">{item.invoice}</a>{' '}</>))}</div>
                            </td>
                            <td className='destination'>
                              <div style={{ color: '#4fc8e9', cursor: 'pointer' }} onClick={() => navigate(`/user_detail/${booking.customer}`)}>{booking.customer_name}</div>
                            </td>
                            <td className='destination'>
                              <div>{booking.start_date}</div>
                            </td>
                            <td className='destination'>
                              <div>{booking.end_date}</div>
                            </td>
                            <td className='destination'>
                              <div>{booking.length}</div>
                            </td>
                            <td className='destination'>
                              <div>{booking.daily_rate}</div>
                            </td>
                            <td className='destination'>
                              <div>{booking.daily_rate_actual}</div>
                            </td>
                            <td className='destination'>
                              <div>{booking.total_rate}</div>
                            </td>
                            <td className='destination'>
                              <div>{booking.miles_in}</div>
                            </td>
                            <td className='destination'>
                              <div>{booking.miles_out}</div>
                            </td>
                            <td className='destination'>
                              <div className='delivery'>{booking.delivery}</div>
                            </td>
                            <td className='destination'>
                              <div>{booking.miles}</div>
                            </td>
                            <td className='destination'>
                              <div>{booking.fuel_reimbursment}</div>
                            </td>
                            <td className='destination'>
                              <div>{booking.discounts}</div>
                            </td>
                            <td className='destination'>
                              <div>{booking.total_rate_actual}</div>
                            </td>
                            <td className='destination'>
                              <div>{booking.total_revenue}</div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className='monthAnalysis'>
                    <div className='title'>Totals</div>
                    <div className='totals'>
                      <div>
                        Days in Month : <span>{report.totals.days_in_month}</span>
                      </div>
                      <div>
                        Fleet Days :{' '}
                        <span>{report.totals.fleet_days}</span>
                      </div>
                      <div>
                        Days Used:{' '}
                        <span>{report.totals.totalBookignDays}</span>
                      </div>
                      <div>
                        Total Rate :{' '}
                        <span>{report.totals.totalBookignAmount}</span>
                      </div>
                      <div>
                        Total Rate (Actual) :
                        <span>{report.totals.totalRateActual}</span>
                      </div>
                      <div>
                        Additional Fees :{' '}
                        <span>{0}</span>
                      </div>
                      <div>
                        Total Revenue : <span>{report.totals.totalRevenue}</span>
                      </div>
                      <div>
                        % Utilization : <span>{report.totals.utilization}%</span>
                      </div>
                      <div>
                        Miles : <span>{report.totals.miles}</span>
                      </div>
                    </div>
                    <div className='title'>Averages</div>
                    <div className='totals'>
                      <div>
                        Trip Length :{' '}
                        <span>{report.averages?.booking_days_avg}</span>
                      </div>
                      <div>
                        Daily Rate :{' '}
                        <span>{report.averages?.daily_rate_avg}</span>
                      </div>
                      <div>
                        Daily Rate (Actual) :{' '}
                        <span>{report.averages?.daily_rate_actual_avg}</span>
                      </div>
                      <div>
                        Total Rate :{' '}
                        <span>{report.averages?.booking_amount_avg}</span>
                      </div>
                      <div>
                        Total Rate (Actual) :{' '}
                        <span>{report.averages?.rate_actual_avg}</span>
                      </div>
                      <div>
                        Additional Fees :{' '}
                        <span>{0}</span>
                      </div>
                      <div>
                        Total Revenue : <span>{report.averages?.revenue_avg}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}
