import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header';
import MobileNavbar from '../../../components/MobileNavbar';
import Sidebar from '../../../components/Sidebar';
import './index.css';
import CarsRemoteWrapper from '../../../components/CarsRemoteWrapper';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export default function OnRent() {
  const navigate = useNavigate();

  const [perPage, setPerPage] = useState(5);

  const Arrow = () => {
    return (
      <svg
        width='8'
        height='14'
        viewBox='0 0 8 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M-8.74228e-08 12L5 7L-5.24537e-07 2L0.999999 -4.37114e-08L8 7L1 14L-8.74228e-08 12Z'
          fill='#4FC8E9'
        />
      </svg>
    );
  };

  return (
    <div className='dashboard page'>
      <Sidebar page={'on_rent'} />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <div className='trip_management_header'>
            <div>
              <h4>On Rent</h4>
            </div>
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
              <div className='calender_type info_row'>
                <div className='field'>
                  <FormControl
                    className=''
                    sx={{ m: 0, minWidth: 100, mt: 0.5 }}
                  >
                    <InputLabel id='calender_type'>Per Page</InputLabel>
                    <Select
                      className='calender_type_select'
                      labelid='calender_type'
                      id='calender_type'
                      defaultValue={perPage}
                      label='Calender Type'
                      onChange={(e) => setPerPage(e.target.value)}
                      IconComponent={Arrow}
                      style={{ height: '27px' }}
                    >
                      <MenuItem className='airport_name_menu_item' value={5}>
                        5
                      </MenuItem>
                      <MenuItem className='airport_name_menu_item' value={10}>
                        10
                      </MenuItem>
                      <MenuItem
                        className='airport_name_menu_item'
                        value={15}
                        timeGridDay
                      >
                        15
                      </MenuItem>
                      <MenuItem
                        className='airport_name_menu_item'
                        value={20}
                        timeGridDay
                      >
                        20
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <button
                className='all_users_secondary_button'
                onClick={() => navigate('/add_vehicle')}
              >
                Add New Vehicle
              </button>
            </div>
          </div>
          <CarsRemoteWrapper per_page={perPage} booked={true} />
        </div>
      </div>
    </div>
  );
}
