import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import './index.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Modal from '@mui/material/Modal';
import { AuthContext } from '../../contexts/AuthContext';
import { get_customers } from '../../API/user';
import { delete_customer } from '../../API/user';
import { delete_conversation } from '../../API/messages';
import { get_car_books } from '../../API/car_booking';
import toast from 'react-hot-toast';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { searchSelector, setQ } from '../../reducers/searchSlice';
import { useSelector, useDispatch } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export default function AllUsers() {
  const { authAxios, user } = useContext(AuthContext);
  const [customers, setCustomers] = useState([]);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [checkDeleteModal, setCheckDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [sortBy, setSortBy] = useState('first_name');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { redirect, trip } = useParams();
  const { q } = useSelector(searchSelector);

  const validDeleteUser = async () => {
    setDeleteUserModal(false);
    let res = await get_car_books(authAxios, 'all_bookings', selectedUser.id);
    if (res.status === 200) {
      if (res.data && res.data.length > 0) {
        // determines if there is a reservation with a valid period
        const bool = res.data.some(data => {
          return (new Date(data.end_date)).getTime() > (new Date()).getTime()
        })
        if (bool) {
          setCheckDeleteModal(true);
          return;
        }
      }
    }
    await handleDeleteUser();
  };

  const handleDeleteUser = async () => {
    let res = await delete_customer(authAxios, selectedUser.id);
    if (res.status === 204) {
      await delete_conversation(authAxios, selectedUser.phone?.replace(' ', '').replace('(', '').replace(')', '').replace('-', '').replace('+', ''))

      toast.success('Deleted Successfully');
      setDeleteUserModal(false);
      setCheckDeleteModal(false);
      await getCustomers();
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    } else return phoneNumberString;
  }

  const handleCreateCustomer = () => {
    navigate('/add_person');
  };

  const getCustomers = async (q) => {
    let res = await get_customers(authAxios, q);
    if (res.status === 200) {
      setCustomers(res.data);
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  useEffect(() => {
    getCustomers(q);
  }, [q]);

  useEffect(() => {
    setCustomers(customers.sort((a, b) => {
      let a_first_name = a.name.trim().split(' ')[0];
      let b_first_name = b.name.trim().split(' ')[0];
      if (sortBy === 'first_name' || sortBy === '-first_name')
        return sortBy === 'first_name'? a_first_name > b_first_name: b_first_name > a_first_name;

      let a_last_name = a.name.trim().split(' ')[1];
      let b_last_name = b.name.trim().split(' ')[1];
      if (sortBy === 'last_name' || sortBy === '-last_name')
        return sortBy === 'last_name'? a_last_name > b_last_name: b_last_name > a_last_name;
    }));
  }, [sortBy])

  const sortArray = (users) => {
    return users.sort(function (a, b) {
      if (sortBy.includes('first_name')) {
        const nameFirstA = a.name.trim().split(' ')[0].toUpperCase();
        const nameFirstB = b.name.trim().split(' ')[0].toUpperCase();
        if (nameFirstA < nameFirstB) {
          return sortBy === 'first_name'? -1: 1;
        }
        if (nameFirstA > nameFirstB) {
          return sortBy === 'first_name'? 1: -1;
        }
        return 0;
      }
      else {
        const nameLastA = (a.name.trim().split(' ')[1] || '').toUpperCase();
        const nameLastB = (b.name.trim().split(' ')[1] || '').toUpperCase();
        if (nameLastA < nameLastB) {
          return sortBy === 'last_name'? -1: 1;
        }
        if (nameLastA > nameLastB) {
          return sortBy === 'last_name'? 1: -1;
        }
        return 0;
      }
    });
  };

  const getUsers = () => {
    return sortArray(
      customers.map((customer) => {
        return {
          id: customer.id,
          img: customer.license_image,
          name: customer.name,
          email: customer.user.email,
          username: customer.user.username,
          role: 'Free',
          role_name: 'Free',
          phone: customer.phone,
          license_number: customer.license_number,
          insurance_policy: customer.insurance_policy,
          insurance_expiry_date: customer.insurance_expiry_date,
          is_customer: true,
        };
      })
    );
  };

  const isSuperValid = () => {
    return user.package?.name == 'Owner';
  };

  return (
    <div className='dashboard page'>
      <Sidebar page={'all_users'} />
      <div className='hero_content theme_content'>
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <div className='booking_wrapper'>
            <div className='booking_header'>
              <h4>All Users</h4>
              <div className='allUsers_header_actions'>
                <div className='sortOptions'>
                  {/* Sort By */}
                  <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                    <InputLabel id='airport_name'>Sort By</InputLabel>
                    <Select
                      className='airport_name'
                      labelId='Select'
                      id='sort_name'
                      value={sortBy}
                      label='SortName'
                      onChange={(e) => setSortBy(e.target.value)}
                    >
                      <MenuItem
                        className='airport_name_menu_item'
                        value={'first_name'}
                      >
                        First Name(Ascending)
                      </MenuItem>
                      <MenuItem
                        className='airport_name_menu_item'
                        value={'-first_name'}
                      >
                        First Name(Descending)
                      </MenuItem>
                      <MenuItem
                        className='airport_name_menu_item'
                        value={'last_name'}
                      >
                        Last Name(Ascending)
                      </MenuItem>
                      <MenuItem
                        className='airport_name_menu_item'
                        value={'-last_name'}
                      >
                        Last Name(Descending)
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className='flex' style={{ alignItems: 'center'}}>
                  <button onClick={() => handleCreateCustomer()}>
                    Create Client
                  </button>
                </div>
              </div>
            </div>
            {!isSuperValid() && (
              <div className='allUsers_table'>
                <table>
                  <thead>
                    <td>First Name</td>
                    <td>Last Name</td>
                    <td>Email Address</td>
                    <td>Phone</td>
                    <td className='table_actions'></td>
                  </thead>
                  <tbody>
                    {getUsers().map((user) => (
                      <tr id={user.id}>
                        <td>
                          <div>{user.name.trim().split(' ')[0]}</div>
                        </td>
                        <td>
                          <div>{user.name.trim().split(' ')[1]}</div>
                        </td>
                        <td>{user.email}</td>
                        <td>
                          {user.phone ? formatPhoneNumber(user.phone) : ''}
                        </td>
                        <td className='table_actions settings'>
                          {redirect && (
                            <button
                              onClick={(e) => {
                                dispatch(setQ(''));
                                navigate(
                                  trip
                                    ? `/${redirect}/${user.id}/${trip}`
                                    : `/${redirect}/${user.id}`
                                );
                              }}
                            >
                              Choose
                            </button>
                          )}
                          <button
                            onClick={(e) => {
                              dispatch(setQ(''));
                              navigate(`/user_detail/${user.id}`);
                            }}
                          >
                            Details
                          </button>
                          {!redirect && (
                            <button
                              onClick={() => {
                                setDeleteUserModal(true);
                                setSelectedUser(user);
                              }}
                            >
                              <svg
                                width='16'
                                height='16'
                                viewBox='0 0 16 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M9.2 3.2002C9.2 2.88194 9.07357 2.57671 8.84853 2.35167C8.62348 2.12662 8.31826 2.0002 8 2.0002C7.68174 2.0002 7.37652 2.12662 7.15147 2.35167C6.92643 2.57671 6.8 2.88194 6.8 3.2002H6C6 2.66976 6.21071 2.16105 6.58579 1.78598C6.96086 1.41091 7.46957 1.2002 8 1.2002C8.53043 1.2002 9.03914 1.41091 9.41421 1.78598C9.78929 2.16105 10 2.66976 10 3.2002H13.6C13.7061 3.2002 13.8078 3.24234 13.8828 3.31735C13.9579 3.39237 14 3.49411 14 3.6002C14 3.70628 13.9579 3.80802 13.8828 3.88304C13.8078 3.95805 13.7061 4.0002 13.6 4.0002H13.1568L12.12 12.9842C12.0749 13.3741 11.888 13.7338 11.5949 13.9949C11.3018 14.256 10.9229 14.4002 10.5304 14.4002H5.4696C5.07708 14.4002 4.69823 14.256 4.40513 13.9949C4.11204 13.7338 3.92514 13.3741 3.88 12.9842L2.8424 4.0002H2.4C2.30638 4.00023 2.21571 3.96742 2.14379 3.90748C2.07187 3.84755 2.02325 3.76429 2.0064 3.6722L2 3.6002C2 3.49411 2.04214 3.39237 2.11716 3.31735C2.19217 3.24234 2.29391 3.2002 2.4 3.2002H9.2ZM12.3504 4.0002H3.6488L4.6744 12.8922C4.69698 13.0872 4.79048 13.2671 4.93712 13.3977C5.08375 13.5282 5.27327 13.6003 5.4696 13.6002H10.5304C10.7266 13.6001 10.9159 13.528 11.0624 13.3974C11.2088 13.2669 11.3022 13.0871 11.3248 12.8922L12.3504 4.0002Z'
                                  fill='white'
                                />
                                <path
                                  d='M6.79844 6C6.99444 6 7.15844 6.124 7.19204 6.2872L7.19844 6.3504V11.2504C7.19844 11.4432 7.01924 11.6 6.79844 11.6C6.60244 11.6 6.43844 11.476 6.40484 11.3128L6.39844 11.2496V6.3512C6.39844 6.1576 6.57764 6.0008 6.79844 6.0008V6ZM9.19844 6C9.39444 6 9.55844 6.124 9.59204 6.2872L9.59844 6.3504V11.2504C9.59844 11.4432 9.41924 11.6 9.19844 11.6C9.00244 11.6 8.83844 11.476 8.80484 11.3128L8.79844 11.2496V6.3512C8.79844 6.1576 8.97764 6.0008 9.19844 6.0008V6Z'
                                  fill='white'
                                />
                              </svg>
                              Delete
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <Modal
            open={deleteUserModal}
            onClose={() => setDeleteUserModal(false)}
            aria-labelledby='conf_modal'
            aria-describedby='modal-modal-description'
          >
            <div className='confModal'>
              <svg
                width='110'
                height='110'
                viewBox='0 0 110 110'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>{' '}
              <br />
              <h4>Are You Sure?</h4>
              <p>
                Do you really want to delete this info. This process cannot be
                undo.
              </p>
              <div className='btns'>
                <button
                  onClick={() => setDeleteUserModal(false)}
                  className='secondary'
                >
                  Cancel
                </button>
                <button className='primary' onClick={() => validDeleteUser()}>
                  Delete
                </button>
              </div>
            </div>
          </Modal>
          <Modal
            open={checkDeleteModal}
            onClose={() => setCheckDeleteModal(false)}
            aria-labelledby='conf_modal'
            aria-describedby='modal-modal-description'
          >
            <div className='confModal' style={{ height: '400px' }}>
              <svg
                width='110'
                height='110'
                viewBox='0 0 110 110'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>{' '}
              <br />
              <h4>Clients still have upcoming bookings</h4>
              <p>
                Do you still want to proceed deleting the client. Deleting
                client will delete all the upcoming booking also.
              </p>
              {/* <p>
                <NavLink to={`/trips?=${selectedUser?.name}`}>
                  Update "{selectedUser?.name}'s" bookings
                </NavLink>
              </p> */}
              <div className='btns'>
                <button
                  onClick={() => setCheckDeleteModal(false)}
                  className='secondary'
                >
                  Cancel
                </button>
                <button className='primary' onClick={() => handleDeleteUser()}>
                  Proceed
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}
