import React from 'react';
import './index.css';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import { useParams } from 'react-router-dom';
import CarDetail from '../../components/CarDetail/carDetail';

import carDummyData from '../../fixtures/carsDummyData.json';

export default function CarDetailPage() {
  const { id } = useParams();
  const data = carDummyData.find((e) => e.id === id);

  return (
    <div className='dashboard page'>
      <Sidebar page='add_vehicle' />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />
        {/* <div className='editWrapper pagewrapper'> */}
        <div className='pagewrapper'>
          <CarDetail data={data} />
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}
