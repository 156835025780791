import React, { useState, useContext, useCallback, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Link } from 'react-router-dom';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';

import './index.css';
import CarRemoteCard from '../CarRemoteCard';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import toast from 'react-hot-toast';
import { searchSelector, setQ } from '../../reducers/searchSlice';
import {
  update_vehicle,
  remove_features,
  add_features,
} from '../../API/vehicle';
import { useDispatch, useSelector } from 'react-redux';
import {
  vehicleSelector,
  getVehicles,
  clearState,
  setVehicle,
  deleteVehicle,
} from '../../reducers/vehicleSlice';
import { get_bookings_vehicle } from '../../API/car_booking';
import { get_features } from '../../API/setting';
import { AuthContext } from '../../contexts/AuthContext';
import {
  DRIVE_TRAIN,
  FUEL_TYPES,
  NUM_OF_BAGS,
  VEHICLE_COLORS,
  VEHICLE_SIZES,
} from '../../constants';

import { getCarStatusDisabled } from '../../helpers/unavailablity';

const Arrow = () => {
  return (
    <svg
      width='8'
      height='14'
      viewBox='0 0 8 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M-8.74228e-08 12L5 7L-5.24537e-07 2L0.999999 -4.37114e-08L8 7L1 14L-8.74228e-08 12Z'
        fill='#4FC8E9'
      />
    </svg>
  );
};

const vehicleSize = [
  // 'All',
  'Exotic',
  'Convertible',
  'Specialty',
  'Small SUV',
  'Midsize SUV',
  'Large SUV',
  'Small Sedan',
  'Large Sedan',
  'Truck',
  'Van',
];

const _colors = [
  // 'All',
  'Black',
  'Gray',
  'White',
  'Red',
  'Blue',
  'Yellow',
  'Orange',
  'Brown',
  'Green',
  'Purple',
];

const _driveTrains = [
  // 'All',
  '4WD/AWD',
  'Front-Wheel Drive',
  'Rear-Wheel Drive',
];

let loading = false;

export default function CarsRemoteWrapper({ per_page, booked, selectedCar }) {
  const { authAxios } = useContext(AuthContext);
  let [page, setPage] = useState(1);
  let [orderName, setOrderName] = useState('title');
  let [currentData, setCurrentData] = useState([]);
  let [errorEmpty, setErrorEmpty] = useState('');
  let [deleteErrorModal, setDeleteErrorModal] = useState(false);

  const [no_of_passengersFilter, setNo_of_passengers] = useState(null);
  const [fuelTypeFilter, setFuelType] = useState(null);
  const [car_ColorFilter, setCar_Color] = useState([]);
  const [driveTrainFilter, setDriveTrain] = useState([]);
  const [sizeFilter, setSize] = useState([]);

  const dispatch = useDispatch();
  const { isFetching, isError, errorMessage, isSuccess, vehicle, vehicles } =
    useSelector(vehicleSelector);
  const { q } = useSelector(searchSelector);
  const [confirmModal, setConfirmModal] = useState(false);

  const {
    vehicleName,
    vehiclePrice,
    mileagePerDay,
    mileagePerWeek,
    mileagePerMonth,
    vehicleType,
    carDescription,
    noOfPass,
    model,
    size,
    car_features,
    vehicleMake,
    vehicleTrim,
    mileageOverageFee,
    vehicleColor,
    fuelType,
    licensePlate,
    interiorColor,
    vinNumber,
    id,
    driveTrain,
  } = vehicle;
  console.log(vehicle);

  const maxPage = Math.ceil(vehicles.length / per_page);
  // const _DATA = usePagination(vehicles, PER_PAGE);

  const [editModal, setEditModal] = useState(false);
  const [carFeatures, setCarFeatures] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [vehicleBookings, setVehicleBookings] = useState([]);

  const today = new Date();

  useEffect(() => {
    get_bookings_vehicle(authAxios, id).then((res) => {
      if (res.status === 200) {
        setVehicleBookings(res.data);
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }
    });
  }, [vehicle]);

  const getCurrentData = () => {
    const begin = (page - 1) * per_page;
    const end = begin + per_page;
    return vehicles.slice(begin, end);
  };

  const jump = (page) => {
    const pageNumber = Math.max(1, page);
    if (maxPage > 0) {
      setPage((currentPage) => Math.min(pageNumber, maxPage));
    }
  };

  const handlePageChange = (page_no) => {
    // jump(p);
    // const pageNumber = Math.min(Math.max(1, p), maxPage);
    // setPage((currentPage) => pageNumber);
    // dispatch(getVehicles({ page: pageNumber, booked, q }));
    dispatch(
      getVehicles({
        booked,
        q,
        orderName,
        authAxios,
        callback: null,
        filters: {
          ...getFilters(),
          page: page_no,
        },
      })
    );
  };

  const handleSave = async (e) => {
    e.preventDefault();
    let errorEmptyFields = [];
    if (!vehicleName) errorEmptyFields.push('Vehicle Name');
    if (!vehiclePrice) errorEmptyFields.push('Vehicle Price');
    if (!mileagePerDay) errorEmptyFields.push('Mileage Per Day');
    if (!mileagePerWeek) errorEmptyFields.push('Mileage Per Week');
    if (!mileagePerMonth) errorEmptyFields.push('Mileage Per Month');
    if (!vehicleType) errorEmptyFields.push('Vehicle Type');
    if (!noOfPass || !NUM_OF_BAGS.includes(noOfPass))
      errorEmptyFields.push('Number of Passengers');
    if (!driveTrain || !DRIVE_TRAIN.includes(driveTrain))
      errorEmptyFields.push('Drive Train');
    if (!model) errorEmptyFields.push('Model');
    if (!vinNumber) errorEmptyFields.push('Vin Number');
    if (!size || !VEHICLE_SIZES.includes(size))
      errorEmptyFields.push('Vehicle Class');
    if (!carDescription) errorEmptyFields.push('Description');
    if (!vehicleMake) errorEmptyFields.push('Vehicle Make');
    if (!vehicleTrim) errorEmptyFields.push('Vehicle Trim');
    if (!mileageOverageFee) errorEmptyFields.push('Mileage Overage Fee');
    if (!fuelType || !FUEL_TYPES.includes(fuelType))
      errorEmptyFields.push('Fuel Type');
    if (!licensePlate) errorEmptyFields.push('License Plate');
    if (!interiorColor || !VEHICLE_COLORS.includes(interiorColor))
      errorEmptyFields.push('Interior Color');
    if (!vehicleColor || !VEHICLE_COLORS.includes(vehicleColor))
      errorEmptyFields.push('Vehicle Color');
    const error = 'Fill out these fields: ' + errorEmptyFields.join(', ');

    if (
      !vehicleName ||
      !vehiclePrice ||
      !vinNumber ||
      !mileagePerDay ||
      !mileagePerWeek ||
      !mileagePerMonth ||
      !vehicleType ||
      !noOfPass ||
      !NUM_OF_BAGS.includes(noOfPass) ||
      !driveTrain ||
      !DRIVE_TRAIN.includes(driveTrain) ||
      !model ||
      !size ||
      !carDescription ||
      !car_features ||
      !vehicleMake ||
      !vehicleTrim ||
      !mileageOverageFee ||
      !fuelType ||
      !FUEL_TYPES.includes(fuelType) ||
      !licensePlate ||
      !interiorColor ||
      !vehicleColor ||
      !VEHICLE_SIZES.includes(size) ||
      !VEHICLE_COLORS.includes(interiorColor) ||
      !VEHICLE_COLORS.includes(vehicleColor)
    ) {
      setConfirmModal(true);
      setErrorEmpty(error);
    } else {
      handleConfirm();
    }
  };

  const handleConfirm = async () => {
    setConfirmModal(false);
    setErrorEmpty('');
    if (!vehicleName) {
      toast.error('Input Vehicle Name');
      return;
    }
    if (!vehiclePrice) {
      toast.error('Input Vehicle Price Per Day');
      return;
    }
    if (!mileagePerDay) {
      toast.error('Input Mileage Per Day');
      return;
    }
    if (!mileagePerWeek) {
      toast.error('Input Mileage Per Week');
      return;
    }
    if (!mileagePerMonth) {
      toast.error('Input Mileage Per Month');
      return;
    }
    if (!vehicleType) {
      toast.error('Input Vehicle Type');
      return;
    }
    if (!noOfPass) {
      toast.error('Input Number of Passengers');
      return;
    }
    if (!driveTrain) {
      toast.error('Input Drive Train');
      return;
    }
    if (!model) {
      toast.error('Input Model');
      return;
    }
    if (!size) {
      toast.error('Input Vehicle Class');
      return;
    }
    if (mileagePerDay >= mileagePerWeek) {
      toast.error('Invalid mileage per week.');
      return;
    }
    if (mileagePerWeek >= mileagePerMonth) {
      toast.error('Invalid mileage per month.');
      return;
    }
    if (vinNumber < 17) {
      toast.error('VIN number is minimun 17 characters.');
      return;
    }

    // check if both checked soft top and hard top
    const hardTop = car_features.find(
      (feat) => feat.name == 'Hard Top Convertible'
    )?.active;
    const softTop = car_features.find(
      (feat) => feat.name == 'Soft Top Convertible'
    )?.active;
    if (hardTop && softTop) {
      toast.error('Invalid features selected.');
      return;
    }

    const payload = {
      title: vehicleName,
      daily_price: vehiclePrice,
      total_price: vehiclePrice,
      type: vehicleType,
      daily_max_miles: mileagePerDay,
      weekly_max_miles: mileagePerWeek,
      monthly_max_miles: mileagePerMonth,
      overmilage: '',
      mileage_overage_fee: mileageOverageFee,
      number_of_seats: size,
      number_of_bags: noOfPass,
      drive_train: driveTrain,
      year: new Date().getFullYear(),
      model: model,
      available_status: 'available',
      vechicle_color: vehicleColor,
      description: carDescription,
      fuel_type: fuelType,
      trim: vehicleTrim,
      make: vehicleMake,
      license_plate: licensePlate,
      interior_color: interiorColor,
      vin_number: vinNumber,
    };

    let res = await update_vehicle(authAxios, id, payload);
    if (res.status !== 200) {
      toast.error(Object.values(res.response.data)[0]);
      return;
    }
    res = await remove_features(authAxios, id);
    if (res.status !== 200) {
      toast.error(Object.values(res.response.data)[0]);
      return;
    }

    res = await add_features(
      authAxios,
      id,
      car_features.filter((feat) => feat.active).map((feat) => feat.id)
    );
    if (res.status === 200) {
      setEditModal(false);
      clearState();
      setEditModal(false);
      toast.success('Successfully Updated.');

      refresh();
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();

    const isCarBooked = vehicleBookings?.some((vehicleBooking) => {
      const bookingEnd = new Date(vehicleBooking.end_date);
      if (today <= bookingEnd) {
        return true;
      } else {
        return false;
      }
    });

    // console.log(isCarBooked);

    if (isCarBooked) {
      setDeleteErrorModal(true);
      setDeleteModal(false);
    } else {
      dispatch(
        deleteVehicle({
          id: id,
          authAxios,
          callback: () => {
            refresh(() => {
              setDeleteModal(false);
              setEditModal(false);
            });
          },
        })
      );
    }
  };

  const getFilters = useCallback(() => {
    let vars = {};
    if (no_of_passengersFilter != 'all')
      vars.number_of_bags = no_of_passengersFilter;
    if (!car_ColorFilter.includes('all'))
      vars.vechicle_color_array = car_ColorFilter;
    if (!driveTrainFilter.includes('all'))
      vars.drive_train_array = driveTrainFilter;
    if (!sizeFilter.includes('all')) vars.number_of_seats_array = sizeFilter;
    if (fuelTypeFilter != 'all') vars.fuel_type = fuelTypeFilter;

    return vars;
  }, [
    no_of_passengersFilter,
    car_ColorFilter,
    driveTrainFilter,
    sizeFilter,
    fuelTypeFilter,
  ]);

  const refresh = useCallback(
    (callback) => {
      dispatch(
        getVehicles({
          booked,
          q,
          orderName,
          authAxios,
          callback,
          filters: getFilters(),
        })
      );
    },
    [booked, q, orderName, getFilters]
  );

  const checkMaxFeatureCar = (data) => {
    const currentFeatures = vehicles?.data?.filter(
      (item) => item.is_featured
    ).length;
    if (data.is_featured && currentFeatures >= 3) {
      return false;
    }
    return true;
  };

  const handleFeatureCar = async (e, data) => {
    const car_id = data.id;
    if (!checkMaxFeatureCar(data)) {
      toast.error('Reached Max Limit Feature Cars');
      return;
    }

    let res = await update_vehicle(authAxios, car_id, {
      is_featured: data.is_featured,
    });
    if (res.status == 200) {
      refresh();
      return;
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  const handleChangeFeature = (e, id) => {
    dispatch(
      setVehicle({
        car_features: car_features.map((feat) => {
          if (feat.id == id) {
            return {
              ...feat,
              active: !feat.active,
            };
          } else return feat;
        }),
      })
    );
  };
  const handleEditPhotos = (id, car_images, vehicleName) => {
    dispatch(
      setVehicle({
        id: id,
        car_images: car_images,
        vehicleName: vehicleName,
      })
    );
  };

  React.useEffect(() => {
    if (isError) {
      // toast.error(errorMessage);
      clearState();
    }
    if (isSuccess) {
      clearState();
    }
  }, [isError, isSuccess]);

  React.useEffect(() => {
    refresh();
  }, [getFilters]);

  React.useEffect(() => {
    jump(1);
    refresh();
  }, [dispatch, q, orderName]);

  React.useEffect(() => {
    dispatch(setQ(''));

    const getFeatures = async () => {
      let res = await get_features(authAxios, true);
      if (res.status === 200) {
        setCarFeatures(res.data);
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }
    };

    getFeatures();
  }, [dispatch]);

  // React.useEffect(() => {
  //   if (vehicles.length > 0) jump(1);
  // }, [per_page]);

  // React.useEffect(() => {
  //   setCurrentData(getCurrentData());
  // }, [vehicles, page, per_page]);

  // React.useEffect(() => {
  //   if (vehicles.length > 0 && loading == false && selectedCar > 0) {
  //     setPage(
  //       Math.floor(
  //         vehicles.findIndex((item) => item.id == selectedCar) / per_page
  //       ) + 1
  //     );
  //     loading = true;
  //   }
  // }, [vehicles]);

  const cur_time =
    today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
  console.log(cur_time);

  return (
    <div>
      <div className='cars_wrapper'>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isFetching}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
        <div className='featuredPage_sortOptions'>
          <div className='attribute_wrapper'>
            <div className='sortOptions_no_of_passenger'>
              <FormControl sx={{ m: 1, minWidth: 180 }} size='small'>
                <InputLabel id='airport_name'>Number of Passengers</InputLabel>
                <Select
                  className='airport_name'
                  labelId='no_of_passengers'
                  id='demo-simple-select'
                  value={no_of_passengersFilter}
                  label='Number of Passengers'
                  onChange={(e) => setNo_of_passengers(e.target.value)}
                >
                  <MenuItem className='airport_name_menu_item' value={'all'}>
                    All
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={2}>
                    2 or more
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={3}>
                    3 or more
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={4}>
                    4 or more
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={5}>
                    5 or more
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={6}>
                    6 or more
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={7}>
                    7 or more
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={8}>
                    8 or more
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className='sortOptions_size'>
              <FormControl sx={{ m: 1, width: 150 }} size='small'>
                <InputLabel id='airport_name'>Vehicle Class</InputLabel>
                <Select
                  className='airport_name'
                  labelId='Size'
                  id='demo-simple-select'
                  value={sizeFilter}
                  label='Size'
                  multiple
                  renderValue={() => 'Vehicle Class'}
                  onChange={(e) =>
                    setSize(
                      typeof e.target.value === 'string'
                        ? e.target.value.split(',')
                        : e.target.value
                    )
                  }
                >
                  {vehicleSize?.map((vSize) => (
                    <MenuItem
                      className='airport_name_menu_item'
                      value={vSize}
                      sx={{
                        display: 'block !important',
                        padding: '6px !important',
                      }}
                    >
                      <Checkbox
                        checked={Boolean(sizeFilter?.find((e) => e === vSize))}
                        sx={{
                          color: '#4fc8e9',
                          '&.Mui-checked': {
                            color: '#4fc8e9',
                          },
                        }}
                      />
                      {vSize}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ display: 'none' }} className='sortOptions_model'>
              <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id='airport_name'>Fuel Type</InputLabel>
                <Select
                  className='airport_name'
                  labelId='Model'
                  id='demo-simple-select'
                  value={fuelTypeFilter}
                  label='Model'
                  onChange={(e) => setFuelType(e.target.value)}
                >
                  <MenuItem className='airport_name_menu_item' value={'all'}>
                    All
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={'Hybrid'}>
                    Hybrid
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={'Gas'}>
                    Gas
                  </MenuItem>
                  <MenuItem
                    className='airport_name_menu_item'
                    value={'Electric'}
                  >
                    Electric
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className='sortOptions_car_type'>
              <FormControl sx={{ m: 1, width: 150 }} size='small'>
                <InputLabel id='airport_name'>Color</InputLabel>
                <Select
                  className='airport_name'
                  labelId='Car_Color'
                  id='demo-simple-select'
                  value={car_ColorFilter}
                  label='Car Type'
                  multiple
                  renderValue={() => 'Color'}
                  onChange={(e) =>
                    setCar_Color(
                      typeof e.target.value === 'string'
                        ? e.target.value.split(',')
                        : e.target.value
                    )
                  }
                >
                  {_colors.map((_color) => (
                    <MenuItem
                      className='airport_name_menu_item'
                      value={_color}
                      sx={{
                        display: 'block !important',
                        padding: '6px !important',
                      }}
                    >
                      <Checkbox
                        checked={Boolean(
                          car_ColorFilter?.find((e) => e === _color)
                        )}
                        sx={{
                          color: '#4fc8e9',
                          '&.Mui-checked': {
                            color: '#4fc8e9',
                          },
                        }}
                      />
                      {_color}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className='sortOptions_car_type'>
              <FormControl sx={{ m: 1, width: 175 }} size='small'>
                <InputLabel id='airport_name'>Drive Train</InputLabel>
                <Select
                  className='airport_name'
                  labelId='driveTrain'
                  id='demo-simple-select'
                  value={driveTrainFilter}
                  label='Drive Train'
                  renderValue={() => 'Drive Train'}
                  multiple
                  onChange={(e) =>
                    setDriveTrain(
                      typeof e.target.value === 'string'
                        ? e.target.value.split(',')
                        : e.target.value
                    )
                  }
                >
                  {_driveTrains?.map((_driveTrain) => (
                    <MenuItem
                      className='airport_name_menu_item'
                      value={_driveTrain}
                      sx={{
                        display: 'block !important',
                        padding: '6px !important',
                      }}
                    >
                      <Checkbox
                        checked={Boolean(
                          driveTrainFilter?.find((e) => e === _driveTrain)
                        )}
                        sx={{
                          color: '#4fc8e9',
                          '&.Mui-checked': {
                            color: '#4fc8e9',
                          },
                        }}
                      />
                      {_driveTrain}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className='sortOptions_arrange'>
            <FormControl className='' sx={{ m: 1, width: 175 }} size='small'>
              <Select
                className='calender_type_select'
                value={orderName}
                onChange={(e) => setOrderName(e.target.value)}
                IconComponent={Arrow}
              >
                <MenuItem
                  className='airport_name_menu_item'
                  value={'title'}
                  timeGridDay
                >
                  Name A-Z
                </MenuItem>
                <MenuItem
                  className='airport_name_menu_item'
                  value={'-title'}
                  timeGridDay
                >
                  Name Z-A
                </MenuItem>
                <MenuItem
                  className='airport_name_menu_item'
                  value={'number_of_seats'}
                  timeGridDay
                >
                  Class A-Z
                </MenuItem>
                <MenuItem
                  className='airport_name_menu_item'
                  value={'-number_of_seats'}
                  timeGridDay
                >
                  Class Z-A
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {/* {_DATA.currentData().map((car, index) => ( */}
        {vehicles?.data?.map((car, index) => (
          <CarRemoteCard
            key={index}
            id={car.id}
            img={car.car_images[0]?.image}
            title={car.title}
            similarTo={car.similarTo}
            type={car.type}
            noOfPeople={car.number_of_seats}
            noOfPass={car.number_of_bags}
            driveTrain={car.drive_train}
            dailyRate={car.daily_price}
            totalCost={car.total_price}
            status={car.vehicle_status}
            // statusDisabled={getCarStatusDisabled(car.unavailabilities, car.bookings, car.trip_preference, new Date(), new Date(), cur_time, cur_time)}
            cardPath={`/car/${car.id}`}
            selectPath={`/info/${car.id}`}
            car={car}
            CarFeatures={carFeatures}
            editModal={() => {
              setEditModal(true);
            }}
            refresh={refresh}
            editPhotos={handleEditPhotos}
            featureCar={handleFeatureCar}
          />
        ))}
      </div>
      <Pagination
        className='cars_page_pagination'
        // variant='outlined'
        shape='circular'
        // count={maxPage}
        count={vehicles?.pagination?.total_pages}
        page={vehicles?.pagination?.page_number}
        onChange={(event, value) => handlePageChange(value)}
      />
      <Modal
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
          setEditModal(false);
        }}
        aria-labelledby='conf_modal'
        aria-describedby='modal-modal-description'
      >
        <div className='confModal'>
          <svg
            width='110'
            height='110'
            viewBox='0 0 110 110'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
              stroke='#FF2552'
              strokeWidth='11.625'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
              stroke='#FF2552'
              strokeWidth='11.625'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>{' '}
          <br />
          <h4>Are You Sure?</h4>
          <p>
            Do you really want to delete this info. This process cannot be undo.
          </p>
          <div className='btns'>
            <button onClick={() => setDeleteModal(false)} className='secondary'>
              Cancel
            </button>
            <button className='primary' onClick={handleDelete}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={editModal}
        onClose={() => setEditModal(false)}
        aria-labelledby='conf_modal'
        aria-describedby='modal-modal-description'
      >
        <div
          className='editModal'
          style={{ overflow: 'scroll', height: '100%' }}
        >
          <div className='editModal_header'>
            <h5>Edit Vehicle</h5>
            <div
              onClick={() => setEditModal(false)}
              className='editModal_close'
            >
              <svg
                width='25'
                height='25'
                viewBox='0 0 110 110'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </div>
          <form action='#' onSubmit={handleSave} className='card_details'>
            <div className='info_wrapper'>
              <div className='info_row '>
                <div className='field'>
                  Vehicle Code
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name'
                      labelid='size'
                      id='demo-simple-select'
                      value={vehicleName}
                      label='Enter Vehicle Name'
                      onChange={(e) =>
                        dispatch(setVehicle({ vehicleName: e.target.value }))
                      }
                    ></TextField>
                  </FormControl>
                </div>
                <div className='field'>
                  Vehicle Make
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name'
                      labelId='size'
                      id='demo-simple-select'
                      value={vehicleMake}
                      label='Enter Vehicle Make'
                      onChange={(e) =>
                        dispatch(setVehicle({ vehicleMake: e.target.value }))
                      }
                    ></TextField>
                  </FormControl>
                </div>
              </div>
              <div className='info_row '>
                <div className='field'>
                  Vehicle Model
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name'
                      labelid='size'
                      id='demo-simple-select'
                      value={model}
                      label='Enter Model'
                      onChange={(e) =>
                        dispatch(setVehicle({ model: e.target.value }))
                      }
                    ></TextField>
                  </FormControl>
                </div>
                <div className='field'>
                  Trim
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name'
                      labelId='size'
                      id='demo-simple-select'
                      value={vehicleTrim}
                      label='Enter Vehicle Trim'
                      onChange={(e) =>
                        dispatch(setVehicle({ vehicleTrim: e.target.value }))
                      }
                    ></TextField>
                  </FormControl>
                </div>
              </div>
              <div className='info_row '>
                <div className='field'>
                  Vehicle Class
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <InputLabel id='airport_name'>
                      Select Vehicle Class
                    </InputLabel>
                    <Select
                      className='airport_name'
                      labelid='size'
                      id='demo-simple-select'
                      value={size}
                      label='Size'
                      onChange={(e) =>
                        dispatch(setVehicle({ size: e.target.value }))
                      }
                    >
                      {VEHICLE_SIZES.map((item) => (
                        <MenuItem
                          className='airport_name_menu_item'
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className='field'>
                  Vehicle Fuel Type
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <InputLabel id='airport_name'>
                      Select Vehicle Fuel Type
                    </InputLabel>
                    <Select
                      className='airport_name'
                      labelid='numberOfPassengers'
                      id='demo-simple-select'
                      value={fuelType}
                      label='Vehicle Fuel Type'
                      onChange={(e) =>
                        dispatch(setVehicle({ fuelType: e.target.value }))
                      }
                    >
                      {FUEL_TYPES.map((item) => (
                        <MenuItem
                          className='airport_name_menu_item'
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className='info_row '>
                <div className='field'>
                  Transmission Type
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <InputLabel id='airport_name'>
                      Select Vehicle Type
                    </InputLabel>
                    <Select
                      className='airport_name'
                      labelid='vehicleType'
                      id='demo-simple-select'
                      value={vehicleType}
                      label='Vehicle Type'
                      onChange={(e) =>
                        dispatch(setVehicle({ vehicleType: e.target.value }))
                      }
                    >
                      <MenuItem
                        className='airport_name_menu_item'
                        value={'manual'}
                      >
                        Manual
                      </MenuItem>
                      <MenuItem
                        className='airport_name_menu_item'
                        value={'automatic'}
                      >
                        Automatic
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className='field'>
                  Drive Train
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <InputLabel id='airport_name'>
                      Select Drive Train
                    </InputLabel>
                    <Select
                      className='airport_name'
                      labelid='numberOfPassengers'
                      id='demo-simple-select'
                      value={driveTrain}
                      label='Drive Train'
                      onChange={(e) =>
                        dispatch(setVehicle({ driveTrain: e.target.value }))
                      }
                    >
                      {DRIVE_TRAIN.map((item) => (
                        <MenuItem
                          key={item}
                          className='airport_name_menu_item'
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className='info_row '>
                <div className='field'>
                  Vehicle Color
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <Select
                      className='airport_name'
                      labelid='vehicleType'
                      id='demo-simple-select'
                      value={vehicleColor}
                      label='Vehicle Color'
                      onChange={(e) =>
                        dispatch(setVehicle({ vehicleColor: e.target.value }))
                      }
                    >
                      {VEHICLE_COLORS.map((item) => (
                        <MenuItem
                          className='airport_name_menu_item'
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className='field'>
                  Interior Vehicle Color
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <Select
                      className='airport_name'
                      labelid='vehicleType'
                      id='demo-simple-select'
                      value={interiorColor}
                      label='Interior Color'
                      onChange={(e) =>
                        dispatch(setVehicle({ interiorColor: e.target.value }))
                      }
                    >
                      {VEHICLE_COLORS.map((item) => (
                        <MenuItem
                          className='airport_name_menu_item'
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className='info_row '>
                <div className='field'>
                  License Plate
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name'
                      labelId='size'
                      id='demo-simple-select'
                      value={licensePlate}
                      label='Enter License Plate'
                      onChange={(e) =>
                        dispatch(setVehicle({ licensePlate: e.target.value }))
                      }
                    ></TextField>
                  </FormControl>
                </div>
                <div className='field'>
                  Vin Number
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name'
                      labelId='size'
                      id='demo-simple-select'
                      value={vinNumber}
                      label='Enter Vin Number'
                      onChange={(e) =>
                        dispatch(setVehicle({ vinNumber: e.target.value }))
                      }
                    ></TextField>
                  </FormControl>
                </div>
              </div>
              <div className='info_row '>
                <div className='field'>
                  Mileage Per Day
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <InputLabel id='airport_name'>
                      Select Mileage Per Day
                    </InputLabel>
                    <Select
                      className='airport_name'
                      labelid='mileage'
                      id='mileage'
                      value={mileagePerDay}
                      label='Select Mileage Per Day'
                      onChange={(e) =>
                        dispatch(setVehicle({ mileagePerDay: e.target.value }))
                      }
                    >
                      <MenuItem className='airport_name_menu_item' value={50}>
                        50
                      </MenuItem>
                      <MenuItem className='airport_name_menu_item' value={75}>
                        75
                      </MenuItem>
                      <MenuItem className='airport_name_menu_item' value={100}>
                        100
                      </MenuItem>
                      <MenuItem className='airport_name_menu_item' value={150}>
                        150
                      </MenuItem>
                      <MenuItem className='airport_name_menu_item' value={200}>
                        200
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className='field'>
                  Mileage Per Week
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <InputLabel id='airport_name'>
                      Select Mileage Per Week
                    </InputLabel>
                    <Select
                      className='airport_name'
                      labelid='vehicleType'
                      id='demo-simple-select'
                      value={mileagePerWeek}
                      label='Select Mileage Per Week'
                      onChange={(e) =>
                        dispatch(setVehicle({ mileagePerWeek: e.target.value }))
                      }
                    >
                      <MenuItem className='airport_name_menu_item' value={250}>
                        250
                      </MenuItem>
                      <MenuItem className='airport_name_menu_item' value={500}>
                        500
                      </MenuItem>
                      <MenuItem className='airport_name_menu_item' value={750}>
                        750
                      </MenuItem>
                      <MenuItem className='airport_name_menu_item' value={1000}>
                        1000
                      </MenuItem>
                      <MenuItem className='airport_name_menu_item' value={1500}>
                        1500
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className='info_row '>
                <div className='field'>
                  Mileage Per Month
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <InputLabel id='airport_name'>
                      Select Mileage Per Month
                    </InputLabel>
                    <Select
                      className='airport_name'
                      labelid='mileage'
                      id='mileage'
                      value={mileagePerMonth}
                      label='Select Mileage Per Month'
                      onChange={(e) =>
                        dispatch(
                          setVehicle({ mileagePerMonth: e.target.value })
                        )
                      }
                    >
                      <MenuItem className='airport_name_menu_item' value={1000}>
                        1000
                      </MenuItem>
                      <MenuItem className='airport_name_menu_item' value={1500}>
                        1500
                      </MenuItem>
                      <MenuItem className='airport_name_menu_item' value={2000}>
                        2000
                      </MenuItem>
                      <MenuItem className='airport_name_menu_item' value={2500}>
                        2500
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className='field'>
                  Mileage Overage Fee
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <span className='dollor_sign'>$</span>
                    <TextField
                      className='airport_name'
                      labelid='size'
                      id='demo-simple-select'
                      value={mileageOverageFee}
                      type='number'
                      label='$ 00.00'
                      inputProps={{
                        step: '.01',
                      }}
                      onChange={(e) =>
                        dispatch(
                          setVehicle({ mileageOverageFee: e.target.value })
                        )
                      }
                    ></TextField>
                  </FormControl>
                </div>
              </div>
              <div className='info_row '>
                <div className='field'>
                  Vehicle Price Per Day
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name'
                      labelid='size'
                      id='demo-simple-select'
                      value={vehiclePrice}
                      label='Enter Vehicle Price Per Day'
                      onChange={(e) =>
                        dispatch(setVehicle({ vehiclePrice: e.target.value }))
                      }
                    ></TextField>
                  </FormControl>
                </div>
                <div className='field'>
                  Number of Passengers
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <InputLabel id='airport_name'>
                      Select Number of Passengers
                    </InputLabel>
                    <Select
                      className='airport_name'
                      labelid='numberOfPassengers'
                      id='demo-simple-select'
                      value={noOfPass}
                      label='Number of Passengers'
                      onChange={(e) =>
                        dispatch(setVehicle({ noOfPass: e.target.value }))
                      }
                    >
                      {NUM_OF_BAGS.map((item) => (
                        <MenuItem
                          key={item}
                          className='airport_name_menu_item'
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className='info_row '>
                <div className='field'>
                  Car Description
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextareaAutosize
                      required
                      className='airport_name couponDescription'
                      labelid='size'
                      id='demo-simple-select'
                      value={carDescription}
                      placeholder='Write .........'
                      onChange={(e) =>
                        dispatch(setVehicle({ carDescription: e.target.value }))
                      }
                    ></TextareaAutosize>
                  </FormControl>
                </div>
              </div>
              <div className='info_row '>
                <div className='field' style={{ fontSize: '14px' }}>
                  Choose Vehicle Features
                  <div className='carFeatures' style={{ margin: '0' }}>
                    <div className='features'>
                      <FormGroup className='feature_checkboxs'>
                        {car_features.map((feat) => (
                          <FormControlLabel
                            className='feature_checkbox_wrapper'
                            control={
                              <Checkbox
                                className='feature_checkbox'
                                defaultChecked={feat.active ? true : false}
                                value={feat.active}
                                onChange={(e) =>
                                  handleChangeFeature(e, feat.id)
                                }
                                // disabled={edit}
                              />
                            }
                            label={feat.name}
                          />
                        ))}
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='confirmation_btn'>
              <button type='submit'> Save </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setDeleteModal(true);
                }}
                className='delete_button'
              >
                Delete
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        open={confirmModal}
        onClose={() => {
          setConfirmModal(false);
          setErrorEmpty('');
        }}
        aria-labelledby='conf_modal'
        aria-describedby='modal-modal-description'
      >
        <div className='confModal car_confirm_remote'>
          <svg
            width='110'
            height='110'
            viewBox='0 0 110 110'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
              stroke='#FF2552'
              strokeWidth='11.625'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
              stroke='#FF2552'
              strokeWidth='11.625'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>{' '}
          <br />
          <h4>Are You Sure?</h4>
          <p>
            Not all fields have been entered. Are you sure you want to proceed?
          </p>
          <p>{errorEmpty}</p>
          <div className='btns'>
            <button
              onClick={() => {
                setConfirmModal(false);
                setErrorEmpty('');
              }}
              className='secondary'
            >
              Cancel
            </button>
            <button className='primary' onClick={(e) => handleConfirm(e)}>
              Confirm
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={deleteErrorModal}
        onClose={() => {
          setDeleteErrorModal(false);
        }}
        aria-labelledby='conf_modal'
        aria-describedby='modal-modal-description'
      >
        <div className='confModal delete-error-modal'>
          <svg
            width='110'
            height='110'
            viewBox='0 0 110 110'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
              stroke='#FF2552'
              strokeWidth='11.625'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
              stroke='#FF2552'
              strokeWidth='11.625'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>{' '}
          <p>
            <Link
              to={`/car_bookings/${id}`}
              className={`sidebar_link  car_bookings_link`}
            >
              This Vehicle still has future bookings. To see those bookings
              click here.
            </Link>
          </p>
          <div className='btns'>
            <button
              onClick={() => {
                setDeleteErrorModal(false);
              }}
              className='secondary'
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
