import React from 'react';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import './index.css';
import Airline from './components/Airline'
import Airport from './components/Airport'
import Settings from './components/Settings'
import Features from './components/Features'
// import { taxes } from '../../constants';

export default function SystemConfigurations() {
  return (
    <div className='dashboard page trip_preferences'>
      <Sidebar page='' />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <Settings />
          <Airport />
          <Airline />
          <Features />
        </div>
      </div>
    </div>
  );
}
