import React from 'react';
import './WeekCalender.css';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import styled  from '@mui/material/styles/styled';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { buttonClasses, iconButtonClasses, typographyClasses } from '@mui/material';


dayjs.extend(isBetweenPlugin);

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  color: '#fff',
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));

function Day(props) {
  const { day, selectedDay, ...other } = props;
  // console.log('🚀OUTPUT --> selectedDay:', selectedDay);

  if (selectedDay == null) {
    return <PickersDay day={day} {...other} />;
  }

  const start = selectedDay.startOf('week');
  const end = (selectedDay).endOf('week'); //.add(7, 'day');

  const dayIsBetween = day.isBetween(start, end, null, '[]');
  const isFirstDay = day.isSame(start, 'day');
  const isLastDay = day.isSame(end, 'day');

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
    />
  );
}

const WeekCalender = ({ value, handleChange }) => {
  // console.log('🚀OUTPUT --> value:', value);
  // console.log(props.admissions);

  // const [value, setValue] = useState(dayjs());
  // console.log('🚀OUTPUT --> value:', { value, start, end });

  // useEffect(() => {
  //   const start = value.startOf('week');
  //   const end = value.endOf('week');
  //   setCurrentWeek({ start, end });
  // }, [value]);

  // console.log('🚀OUTPUT --> value:', { value, start, end });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        showDaysOutsideCurrentMonth
        value={value}
        onChange={(newValue) => handleChange(newValue.startOf('week'), newValue.endOf('week'))}
        slots={{ day: Day }}
        slotProps={{
          day: {
            selectedDay: value,
          },
        }}
        classes={{ root: 'date_cal_root', }}
        sx={{
          [`& .${typographyClasses.root}`]: {
            color: '#fff',
          },
          [`& .${iconButtonClasses.root}`]: {
            color: '#fff',
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default WeekCalender;
