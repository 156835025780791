import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import './index.css';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  get_access_levels,
  create_access_level,
  update_access_level,
  delete_access_level,
} from '../../API/access_level';
import { get_package } from '../../API/package';
import toast from 'react-hot-toast';
import { AuthContext } from '../../contexts/AuthContext';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import { pages } from '../../constants';
import AuthService from "../../services/AuthService";

export default function AccessLevels() {
  const { authAxios } = useContext(AuthContext);
  const [permissions, setPermissions] = useState([]);
  const [selectedPacakage, setSelectedPacakage] = useState();
  const [createModal, setCreateModal] = useState(false);
  const [createPackName, setCreatePackName] = useState('');
  const [createPackLevel, setCreatePackLevel] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [packagePages, setPackagePages] = useState([]);

  // const access_levels = ['Trial', 'Standard', 'Premium', 'Ultimate'];

  // get access levels for this company
  const getPermissions = async () => {
    let res = await get_access_levels(authAxios);
    if (res.status === 200) {
      setPermissions(
        res.data.map((pack) => ({
          ...pack,
          items: (pack.items || []),
        }))
      );
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  const getPage = (link) => {
    return pages.find(page => page.link == link);
  }

  const checked = function (link, mutate, pack_name) {
    const _access_level = permissions.find((pack_) => pack_.name == pack_name);
    return _access_level && _access_level.items.findIndex(page => page.page == link && page.mutate == mutate) >= 0;
  };

  const handleChange = (e, link, pack, mutate) => {
    setPermissions(
      permissions.map((perm) => {
        if (perm.name == pack) {
          return {
            ...perm,
            items: checked(link, mutate, pack)
              ? perm.items.filter((perm) => !(perm.page == link && perm.mutate == mutate))
              : perm.items.concat({ page: link, mutate: mutate}),
          };
        } else return perm;
      })
    );
  };

  const handleSave = async (id) => {
    const _access_level = permissions.find((pack_) => pack_.id == id);
    if (_access_level) {
      const formData = new FormData();
      formData.append('id', id);
      if (createPackName) formData.append('name', createPackName);
      if (createPackLevel)  formData.append('level', createPackLevel);
      for (let item of _access_level.items) {
        formData.append('pages', item.page);
        formData.append('mutates', Number(item.mutate));
      }
      let res = await update_access_level(authAxios, formData);
      if (res.status === 200) {
        toast.success('Saved Successfully!')
        // await getPermissions();
        await AuthService.resetPermissions();
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }
    }
  };

  const handleCreateAccessLevel = async () => {
    const formData = new FormData();
    formData.append('name', createPackName);
    formData.append('level', createPackLevel);
    const res = await create_access_level(authAxios, formData);
    if (res.status === 201) {
      await getPermissions();
      setCreateModal(false);
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  const handleDelete = async () => {
    const res = await delete_access_level(authAxios, selectedPacakage);
    if (res.status === 204) {
      await getPermissions();
      setDeleteModal(false);
      setSelectedPacakage(null);
    } else {
      toast.error(Object.values(res.response.data)[0]);
      setSelectedPacakage(null);
    }
  };

  const getPackage = async () => {
    const res = await get_package(authAxios);
    if (res.status === 200) {
      setPackagePages(res.data.pages);
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  useEffect(() => {
    getPermissions();
    getPackage();
  }, []);

  return (
    <div className='dashboard page'>
      <Sidebar page='access_level' />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <div className='booking_header'>
            <h4>All Access Levels</h4>
            <div className='allUsers_header_actions'>
              <button onClick={() => setCreateModal(true)}>
                Add Access Level
              </button>
            </div>
          </div>
          <div className='booking_wrapper'>
            {permissions.map((pack, index) => (
              <div className='carFeatures' key={index}>
                <div className='carFeatures_header'>
                  <h5>{pack.name} (Level - {pack.level})</h5>
                  <div className='car_select_btn'>
                    <button
                      className='secondary margin-right10'
                      onClick={(e) => handleSave(pack.id)}
                    >
                      {' '}
                      Save{' '}
                    </button>
                    <button
                      className='primary margin-right10'
                      onClick={(e) => {
                        setCreateModal(true);
                        setCreatePackLevel(pack.level);
                        setCreatePackName(pack.name);
                        setSelectedPacakage(pack.id);
                      }}
                    >
                      {' '}
                      Edit{' '}
                    </button>
                    <button
                      className='secondary delete-access_level-btn margin-right10'
                      onClick={(e) => {
                        setDeleteModal(true);
                        setSelectedPacakage(pack.id);
                      }}
                    >
                      {' '}
                      Delete{' '}
                    </button>
                  </div>
                </div>
                <div className='features'>
                  <div className='row'>
                    {packagePages.filter(packagePage => pages.findIndex(item => item.link == packagePage.page) >= 0).map((packagePage, index) => (
                      <div className='col-md-4'>
                        {getPage(packagePage.page)?.name}
                        <FormGroup className='feature_checkboxs' sx={{ gap: '50px'}}>
                          <FormControlLabel
                            className='feature_checkbox_wrapper'
                            key={index}
                            control={
                              <Checkbox
                                className='feature_checkbox'
                                checked={checked(packagePage.page, false, pack.name)}
                                onChange={(e) =>
                                  handleChange(e, packagePage.page, pack.name, false)
                                }
                              />
                            }
                            label={
                              <div className='feature_checkbox_label_wrapper'>
                                <div className='feature_checkbox_label'>
                                  {/* {getPage(packagePage.page)?.mutate == true? "View": 'View/Mutate'} */}
                                  View
                                </div>
                              </div>
                            }
                          />
                          {/* {getPage(packagePage.page)?.mutate == true && (<FormControlLabel
                            className='feature_checkbox_wrapper'
                            key={index}
                            control={
                              <Checkbox
                                className='feature_checkbox'
                                checked={checked(packagePage.page, true, pack.name)}
                                onChange={(e) =>
                                  handleChange(e, packagePage.page, pack.name, true)
                                }
                              />
                            }
                            label={
                              <div className='feature_checkbox_label_wrapper'>
                                <div className='feature_checkbox_label'>
                                  Mutate
                                </div>
                              </div>
                            }
                          />)} */}
                        </FormGroup>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Modal
            open={createModal}
            onClose={() => setCreateModal(false)}
            aria-labelledby='conf_modal'
            aria-describedby='modal-modal-description'
          >
            <div className='confModal create_feature_modal'>
              <br />
              <h4>Create a Access Level</h4>
              <form
                className='add_new_airport'
                onSubmit={(e) => {
                  e.preventDefault();
                  if (selectedPacakage) {
                    handleSave(selectedPacakage);
                  } else handleCreateAccessLevel();
                }}
              >
                <div className='info_wrapper'>
                  <div className='info_row '>
                    <div className='field'>
                      Access Level Name
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <TextField
                          className='airport_name'
                          labelId='size'
                          id='demo-simple-select'
                          defaultValue={createPackName}
                          label='Enter Pacakge Name'
                          onChange={(e) => setCreatePackName(e.target.value)}
                        ></TextField>
                      </FormControl>
                    </div>
                  </div>
                  <div className='info_row '>
                    <div className='field'>
                      Sort Order Position
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <TextField
                          className='airport_name'
                          labelId='size'
                          required
                          id='demo-simple-select'
                          type={'number'}
                          defaultValue={createPackLevel}
                          label='Enter Access Level Level'
                          onChange={(e) => setCreatePackLevel(e.target.value)}
                        ></TextField>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className='btns'>
                  <button
                    onClick={() => setCreateModal(false)}
                    className='secondary'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='primary'
                    // onClick={handleCreateDeliveryCharges}
                  >
                    {selectedPacakage ? 'Save' : 'Create'}
                  </button>
                </div>
              </form>
            </div>
          </Modal>
          <Modal
            open={deleteModal}
            onClose={() => setDeleteModal(false)}
            aria-labelledby='conf_modal'
            aria-describedby='modal-modal-description'
          >
            <div className='confModal'>
              <svg
                width='110'
                height='110'
                viewBox='0 0 110 110'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>{' '}
              <br />
              <h4>Are You Sure?</h4>
              <p>
                Do you really want to cancel this booking. This process cannot
                be undo.
              </p>
              <div className='btns'>
                <button
                  onClick={() => setDeleteModal(false)}
                  className='secondary'
                >
                  Cancel
                </button>
                <button className='primary' onClick={(e) => handleDelete(e)}>
                  Confirm
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}
