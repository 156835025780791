import { subfix_domain } from '../api';

const get_coupons = async (authAxios) => {
  try {
    const res = await authAxios.get(`/api/discounts/coupons/`, {params: {subfix_domain}});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const create_coupons = async (authAxios, data) => {
  try {
    const res = await authAxios.post(`/api/discounts/coupons/`, {...data, subfix_domain});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const delete_coupons = async (authAxios, id) => {
  try {
    const res = await authAxios.delete(`/api/discounts/coupons/${id}/`, { params: {subfix_domain} });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const update_coupons = async (authAxios, id, data) => {
  try {
    const res = await authAxios.patch(`/api/discounts/coupons/${id}/`, {...data, subfix_domain});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_coupon = async (authAxios, id) => {
  try {
    const res = await authAxios.get(`/api/discounts/coupons/${id}/`, {params: {subfix_domain}});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

export { get_coupons, create_coupons, delete_coupons, update_coupons, get_coupon };