export const pages = [
  { link: 'daily_overview', name: 'Daily Overview', mutate: false },
  { link: 'messages', name: 'Messages', mutate: false },
  { link: 'on_rent', name: 'Inventory(On Rent)', mutate: false },
  { link: 'all_cars', name: 'Inventory(All Cars)', mutate: true },
  { link: 'all_users', name: 'Clients', mutate: true },
  { link: 'discount_coupon', name: 'Discount Coupon', mutate: false },
  { link: 'car_report', name: 'Car Report', mutate: false },
  { link: 'trips', name: 'Trip Management', mutate: true },
  { link: 'notification_history', name: 'Notification History', mutate: false },
  {
    link: 'system-configurations',
    name: 'System Configurations',
    mutate: false,
  },
  
  {link:'access_level',name:'Access Level',mutate:false},
  {
    link:'themes',name:'Theme',mutate:false
  },
  {link:'customize',name:'Customize',mutate:false},
  {link:'access_level_user',name:'Users',mutate:false},
];

export const FUEL_TYPES = [
  'Regular',
  'Mid-Grade',
  'Premium',
  'Diesel',
  'Hybrid',
  'Electric',
];

export const DRIVE_TRAIN = [
  '4WD/AWD',
  'Front-Wheel Drive',
  'Rear-Wheel Drive',
];
export const NUM_OF_BAGS = [2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 15];
export const VEHICLE_COLORS = [
  'Black',
  'Gray',
  'White',
  'Red',
  'Blue',
  'Yellow',
  'Orange',
  'Brown',
  'Green',
  'Purple',
  'Tan',
];
export const VEHICLE_SIZES = [
  'Exotic',
  'Van',
  'Specialty',
  'Small SUV',
  'Midsize SUV',
  'Large SUV',
  'Small Sedan',
  'Large Sedan',
  'Convertible',
  'Truck',
];
export const themes = ['Theme1', 'Theme2', 'Theme3'];

export const taxes = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
    tax: 0.135,
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
    tax: 0.135,
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
    tax: 0.135,
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
    tax: 0.135,
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
    tax: 0.135,
  },
  {
    name: 'California',
    abbreviation: 'CA',
    tax: 0.135,
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
    tax: 0.135,
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
    tax: 0.135,
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
    tax: 0.135,
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
    tax: 0.135,
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM',
    tax: 0.135,
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
    tax: 0.135,
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
    tax: 0.135,
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
    tax: 0.135,
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
    tax: 0.135,
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
    tax: 0.135,
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
    tax: 0.135,
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
    tax: 0.135,
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
    tax: 0.135,
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
    tax: 0.135,
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
    tax: 0.135,
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
    tax: 0.135,
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
    tax: 0.135,
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH',
    tax: 0.135,
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
    tax: 0.135,
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
    tax: 0.135,
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
    tax: 0.135,
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
    tax: 0.135,
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
    tax: 0.135,
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
    tax: 0.135,
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
    tax: 0.135,
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
    tax: 0.135,
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
    tax: 0.135,
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
    tax: 0.135,
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
    tax: 0.135,
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
    tax: 0.135,
  },
  {
    name: 'New York',
    abbreviation: 'NY',
    tax: 0.135,
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
    tax: 0.135,
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
    tax: 0.135,
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
    tax: 0.135,
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
    tax: 0.135,
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
    tax: 0.135,
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
    tax: 0.135,
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
    tax: 0.135,
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
    tax: 0.135,
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
    tax: 0.135,
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
    tax: 0.135,
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
    tax: 0.135,
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
    tax: 0.135,
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
    tax: 0.135,
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
    tax: 0.135,
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
    tax: 0.135,
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
    tax: 0.135,
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
    tax: 0.135,
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
    tax: 0.135,
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
    tax: 0.135,
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
    tax: 0.135,
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
    tax: 0.135,
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
    tax: 0.135,
  },
];

export const VEHICLE_STATUS = [
  {
    name: 'refuel_wash',
    title: 'Refuel/Wash',
  },
  {
    name: 'refuel',
    title: 'Refuel',
  },
  {
    name: 'wash',
    title: 'Wash',
  },
  {
    name: 'detail',
    title: 'Detail',
  },
  {
    name: 'rinse',
    title: 'Rinse',
  },
  {
    name: 'prepped',
    title: 'Prepped',
  },
  {
    name: 'repair',
    title: 'Repair/Maintenance',
  },
  {
    name: 'employee',
    title: 'With Employee',
  },
  {
    name: 'on_rent',
    title: 'On Rent',
  },
];
