import { subfix_domain } from '../api';

const get_notifications = async (authAxios) => {
	try {
		const res = await authAxios.get(`/api/chats/notifications/`, {params: {subfix_domain}});
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

export { get_notifications };