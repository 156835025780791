import { subfix_domain } from '../api';

const get_money_collect_month = async (authAxios) => {
  try {
    const res = await authAxios.get(`/api/statistic/money-month/`, { params: {subfix_domain} });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_cars_statis = async (authAxios) => {
  try {
    const res = await authAxios.get(`/api/statistic/dashboard-cars-statis/`, { params: {subfix_domain} });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

export { get_money_collect_month, get_cars_statis };
