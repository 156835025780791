import React, { useEffect, useState, useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import toast from 'react-hot-toast';
import {
  create_system_configurations,
  edit_system_configurations,
  get_system_configurations,
} from '../../../../API/system_configurations';
import { AuthContext } from '../../../../contexts/AuthContext';
import { ReactComponent as Cloak } from '../../../../Assets/svg/cloak.svg';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

export default function Settings() {
  const exampleToggle =
    'Booking for longer than <base discount days> days will get an additional <base discount>% for every <base discount days> days booked. (Max discount: <max discount>%)';

  const { authAxios } = useContext(AuthContext);
  const [systemConfigurations, setSystemConfigurations] = useState(null);
  const [fromTime, setFromTime] = useState([]);
  const [endTime, setEndTime] = useState([]);
  const [baseDiscountDays, setBaseDiscountDays] = useState();
  const [baseDiscount, setBaseDiscount] = useState();
  const [maxDiscount, setMaxDiscount] = useState(50);
  const [baseTax, setBaseTax] = useState('');
  const [youngRenterFee, setYoungRenterFee] = useState('');
  const [baseToggle, setBaseToggle] = useState(exampleToggle);

  useEffect(() => {
    get_system_configurations(authAxios).then((res) => {
      if (res.status === 200) {
        setSystemConfigurations(res.data[0]);
        let from_time = [];
        let to_time = [];
        for (let i = 0; i <= 6; i++) {
          from_time.push(res.data[0]['break_start_time_' + i]);
          to_time.push(res.data[0]['break_end_time_' + i]);
        }
        setFromTime(from_time);
        setEndTime(to_time);
        setBaseDiscountDays(res.data[0].days_without_discount);
        setBaseDiscount(res.data[0].discount_percentage);
        setMaxDiscount(res.data[0].max_discount_price);
        setBaseTax(res.data[0].tax);
        setYoungRenterFee(res.data[0].young_renter_fee);
        setBaseToggle(res.data[0].toggle);
      } else {
        if (res.response.data) {
          toast.error(res.response.data[0]);
        }
      }
    });
  }, []);

  let config = {
    days_without_discount: Number(baseDiscountDays),
    discount_percentage: Number(baseDiscount),
    max_discount_price: Number(maxDiscount),
    tax: Number(baseTax),
    young_renter_fee: Number(youngRenterFee),
    toggle: baseToggle,
  };

  for (let i = 0; i <= 6; i++) {
    config['break_start_time_' + i] = fromTime[i];
    config['break_end_time_' + i] = endTime[i];
  }

  const handleSave = (e) => {
    e.preventDefault();
    if (Number(config.tax) < 0 || Number(config.tax) > 1) {
      toast.error('Invalid tax');
      return;
    }

    systemConfigurations && systemConfigurations.id
      ? edit_system_configurations(
          authAxios,
          systemConfigurations.id,
          config
        ).then((res) => {
          if (res.status === 200 || res.status === 201 || res.status === 304) {
            toast.success('System Configurations Updated Successully');
          } else {
            if (res.response.data) {
              Object.keys(res.response.data).forEach((key) => {
                const var_name =
                  key == 'days_without_discount' ? 'Base Discount Days' : '';
                toast.error(var_name + ': ' + res.response.data[key]);
              });
            }
          }
        })
      : create_system_configurations(authAxios, config).then((res) => {
          if (res.status === 200 || res.status === 201 || res.status === 304) {
            toast.success('System Configurations Created Successully');
          } else {
          }
        });
  };

  function sanitize(html) {
    var doc = document.createElement('div');
    doc.innerHTML = html;
    return doc.innerHTML;
  }

  return (
    <div className='addManualCar'>
      <h5>System Configurations</h5>
      <form action='#' onSubmit={(e) => handleSave(e)} className='card_details'>
        <div className='info_wrapper'>
          <div className='preferences_section'>
            <div className='title'>
              Daily Availability <br />
              <span>Set Operating Hours</span>
            </div>
            {[
              'Monday',
              'Tuesday',
              'Wedensday',
              'Thursday',
              'Friday',
              'Saturday',
              'Sunday',
            ].map((item, index) => (
              <div className='info_row info_row_date info_row_date_sys_conf '>
                <div className='label'>{item}</div>
                <div className='field'>
                  <div className='date_time'>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                        <InputLabel className='time_label' id='from_time'>
                          {fromTime[index] ? '' : 'From time'}
                        </InputLabel>
                        <Select
                          className='timeDropdown'
                          labelid='from_time'
                          id='from-time-select'
                          value={fromTime[index] || ''}
                          label='From time'
                          onChange={(e) => {
                            const temp = JSON.parse(JSON.stringify(fromTime));
                            temp[index] = e.target.value;
                            setFromTime(temp);
                          }}
                          IconComponent={Cloak}
                          MenuProps={{
                            classes: {
                              paper: 'timeDropdown_paper',
                            },
                          }}
                        >
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'00:00:00'}
                          >
                            {' '}
                            12:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'00:30:00'}
                          >
                            {' '}
                            12:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'01:00:00'}
                          >
                            {' '}
                            1:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'01:30:00'}
                          >
                            {' '}
                            1:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'02:00:00'}
                          >
                            {' '}
                            2:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'02:30:00'}
                          >
                            {' '}
                            2:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'03:00:00'}
                          >
                            {' '}
                            3:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'03:30:00'}
                          >
                            {' '}
                            3:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'04:00:00'}
                          >
                            {' '}
                            4:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'04:30:00'}
                          >
                            {' '}
                            4:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'05:00:00'}
                          >
                            {' '}
                            5:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'05:30:00'}
                          >
                            {' '}
                            5:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'06:00:00'}
                          >
                            {' '}
                            6:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'06:30:00'}
                          >
                            {' '}
                            6:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'07:00:00'}
                          >
                            {' '}
                            7:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'07:30:00'}
                          >
                            {' '}
                            7:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'08:00:00'}
                          >
                            {' '}
                            8:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'08:30:00'}
                          >
                            {' '}
                            8:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'09:00:00'}
                          >
                            {' '}
                            9:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'09:30:00'}
                          >
                            {' '}
                            9:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'10:00:00'}
                          >
                            {' '}
                            10:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'10:30:00'}
                          >
                            {' '}
                            10:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'11:00:00'}
                          >
                            {' '}
                            11:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'11:30:00'}
                          >
                            {' '}
                            11:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'12:00:00'}
                          >
                            {' '}
                            12:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'12:30:00'}
                          >
                            {' '}
                            12:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'13:00:00'}
                          >
                            {' '}
                            1:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'13:30:00'}
                          >
                            {' '}
                            1:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'14:00:00'}
                          >
                            {' '}
                            2:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'14:30:00'}
                          >
                            {' '}
                            2:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'15:00:00'}
                          >
                            {' '}
                            3:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'15:30:00'}
                          >
                            {' '}
                            3:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'16:00:00'}
                          >
                            {' '}
                            4:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'16:30:00'}
                          >
                            {' '}
                            4:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'17:00:00'}
                          >
                            {' '}
                            5:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'17:30:00'}
                          >
                            {' '}
                            5:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'18:00:00'}
                          >
                            {' '}
                            6:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'18:30:00'}
                          >
                            {' '}
                            6:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'19:00:00'}
                          >
                            {' '}
                            7:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'19:30:00'}
                          >
                            {' '}
                            7:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'20:00:00'}
                          >
                            {' '}
                            8:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'20:30:00'}
                          >
                            {' '}
                            8:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'21:00:00'}
                          >
                            {' '}
                            9:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'21:30:00'}
                          >
                            {' '}
                            9:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'22:00:00'}
                          >
                            {' '}
                            10:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'22:30:00'}
                          >
                            {' '}
                            10:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'23:00:00'}
                          >
                            {' '}
                            11:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'23:30:00'}
                          >
                            {' '}
                            11:30 pm{' '}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </LocalizationProvider>
                  </div>
                </div>
                <div className='field'>
                  {/* <div>From</div> */}
                  <div className='date_time'>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                        <InputLabel className='time_label' id='from_time'>
                          {endTime[index] ? '' : 'End time'}
                        </InputLabel>
                        <Select
                          className='timeDropdown'
                          labelid='from_time'
                          id='from-time-select'
                          value={endTime[index] || ''}
                          label='From time'
                          onChange={(e) => {
                            const temp = JSON.parse(JSON.stringify(endTime));
                            temp[index] = e.target.value;
                            setEndTime(temp);
                          }}
                          IconComponent={Cloak}
                          MenuProps={{
                            classes: {
                              paper: 'timeDropdown_paper',
                            },
                          }}
                        >
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'17:00:00'}
                          >
                            {' '}
                            5:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'17:30:00'}
                          >
                            {' '}
                            5:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'18:00:00'}
                          >
                            {' '}
                            6:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'18:30:00'}
                          >
                            {' '}
                            6:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'19:00:00'}
                          >
                            {' '}
                            7:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'19:30:00'}
                          >
                            {' '}
                            7:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'20:00:00'}
                          >
                            {' '}
                            8:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'20:30:00'}
                          >
                            {' '}
                            8:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'21:00:00'}
                          >
                            {' '}
                            9:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'21:30:00'}
                          >
                            {' '}
                            9:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'22:00:00'}
                          >
                            {' '}
                            10:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'22:30:00'}
                          >
                            {' '}
                            10:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'23:00:00'}
                          >
                            {' '}
                            11:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'23:30:00'}
                          >
                            {' '}
                            11:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'00:00:00'}
                          >
                            {' '}
                            12:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'00:30:00'}
                          >
                            {' '}
                            12:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'01:00:00'}
                          >
                            {' '}
                            1:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'01:30:00'}
                          >
                            {' '}
                            1:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'02:00:00'}
                          >
                            {' '}
                            2:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'02:30:00'}
                          >
                            {' '}
                            2:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'03:00:00'}
                          >
                            {' '}
                            3:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'03:30:00'}
                          >
                            {' '}
                            3:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'04:00:00'}
                          >
                            {' '}
                            4:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'04:30:00'}
                          >
                            {' '}
                            4:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'05:00:00'}
                          >
                            {' '}
                            5:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'05:30:00'}
                          >
                            {' '}
                            5:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'06:00:00'}
                          >
                            {' '}
                            6:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'06:30:00'}
                          >
                            {' '}
                            6:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'07:00:00'}
                          >
                            {' '}
                            7:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'07:30:00'}
                          >
                            {' '}
                            7:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'08:00:00'}
                          >
                            {' '}
                            8:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'08:30:00'}
                          >
                            {' '}
                            8:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'09:00:00'}
                          >
                            {' '}
                            9:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'09:30:00'}
                          >
                            {' '}
                            9:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'10:00:00'}
                          >
                            {' '}
                            10:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'10:30:00'}
                          >
                            {' '}
                            10:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'11:00:00'}
                          >
                            {' '}
                            11:00 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'11:30:00'}
                          >
                            {' '}
                            11:30 am{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'12:00:00'}
                          >
                            {' '}
                            12:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'12:30:00'}
                          >
                            {' '}
                            12:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'13:00:00'}
                          >
                            {' '}
                            1:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'13:30:00'}
                          >
                            {' '}
                            1:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'14:00:00'}
                          >
                            {' '}
                            2:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'14:30:00'}
                          >
                            {' '}
                            2:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'15:00:00'}
                          >
                            {' '}
                            3:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'15:30:00'}
                          >
                            {' '}
                            3:30 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'16:00:00'}
                          >
                            {' '}
                            4:00 pm{' '}
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={'16:30:00'}
                          >
                            {' '}
                            4:30 pm{' '}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {baseDiscountDays &&
          baseDiscount &&
          baseDiscountDays &&
          maxDiscount ? (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  String(baseToggle)
                    .replaceAll('<', '')
                    .replaceAll('>', '')
                    .replaceAll(
                      'base discount days',
                      `<span class='toogle_light_color'>${baseDiscountDays}</span>`
                    )
                    .replace(
                      'base discount',
                      `<span class='toogle_light_color'>${baseDiscount} </span>`
                    )
                    .replace(
                      'max discount',
                      `<span class='toogle_light_color'>${maxDiscount}</span>`
                    )
                ),
              }}
            ></div>
          ) : (
            ''
          )}
          <div
            // style={{ display: 'none' }}
            className='preferences_section'
          >
            <div className='title'>
              Base Discount Days <br />
              <span>Set No. Of Base Discount Days</span>
            </div>
            <div className='info_row '>
              <div className={baseDiscountDays ? 'field' : 'field empty'}>
                <div>
                  <FormControl
                    className='edit_dropdown discountFields'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name'
                      labelid='size'
                      id='demo-simple-select'
                      type={'number'}
                      InputProps={{
                        endAdornment: baseDiscountDays ? 'days' : '',
                      }}
                      value={baseDiscountDays}
                      placeholder='Enter No. Of Base Discount Days'
                      onChange={(e) => setBaseDiscountDays(e.target.value)}
                    ></TextField>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
          <div className='preferences_section'>
            <div className='title'>
              Base Discount <br />
              <span>Default Base Discount For Each Vehicle</span>
            </div>
            <div className='info_row '>
              <div className={baseDiscount ? 'field' : 'field empty'}>
                <div>
                  <FormControl
                    className='edit_dropdown discountFieldsMaxDiscount'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name'
                      labelid='size'
                      id='demo-simple-select'
                      type={'number'}
                      InputProps={{
                        endAdornment: baseDiscount ? '%' : '',
                      }}
                      value={baseDiscount}
                      placeholder='Enter Base Discount'
                      onChange={(e) => setBaseDiscount(e.target.value)}
                    ></TextField>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
          <div className='preferences_section'>
            <div className='title'>
              Max Discount <br />
              <span>Max Discount For Each Vehicle</span>
            </div>
            <div className='info_row '>
              <div className={maxDiscount ? 'field' : 'field empty'}>
                <div>
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name discountFieldsMaxDiscount'
                      labelid='size'
                      id='demo-simple-select'
                      type={'number'}
                      InputProps={{
                        endAdornment: maxDiscount ? '%' : '',
                      }}
                      value={maxDiscount}
                      placeholder='Enter Max Discount'
                      onChange={(e) => setMaxDiscount(e.target.value)}
                    ></TextField>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
          <div className='preferences_section'>
            <div className='title'>
              Sales Tax <br />
              <span>
                Custom Sales Tax Rate (from 0 to 1, to thousandth percentile.
                Ex: 0.145 = 14.5%)
              </span>
            </div>
            <div className='info_row '>
              <div className='field'>
                <div>
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    {/* <TextField
											className='airport_name'
											labelid='size'
											id='demo-simple-select'
											required
											type={'number'}
											value={baseTax}
											placeholder='Enter Max Discount'
											onChange={(e) => setBaseTax(e.target.value)}
											InputProps={{
												startAdornment: (
													<InputAdornment position="end">
														{baseTax * 100}
													</InputAdornment>
												),
											}}
										></TextField> */}
                    <OutlinedInput
                      id='outlined-adornment-weight'
                      value={baseTax}
                      type={'number'}
                      placeholder='Enter Sales Tax'
                      onChange={(e) => setBaseTax(e.target.value)}
                      endAdornment={
                        <InputAdornment position='end'>
                          <span className={'baseTax_InputAdornment'}>
                            {'Current rate =  '}
                          </span>
                          <span className={'baseTax_InputAdornment'}>
                            {(baseTax * 100).toFixed(1)}%
                          </span>
                        </InputAdornment>
                      }
                      aria-describedby='outlined-weight-helper-text'
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
          <div className='preferences_section'>
            <div className='title'>
              Young Renter Fee <br />
              <span>Charge Young drivers an additional fee(age 21 - 24)</span>
            </div>
            <div className='info_row '>
              <div className={youngRenterFee ? 'field' : 'field empty'}>
                <div>
                  <FormControl
                    className='edit_dropdown discountFieldsMaxDiscount'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name'
                      labelid='size'
                      id='demo-simple-select'
                      type={'number'}
                      InputProps={{
                        endAdornment: youngRenterFee ? '%' : '',
                      }}
                      value={youngRenterFee}
                      placeholder='Enter Base Discount'
                      onChange={(e) => setYoungRenterFee(e.target.value)}
                    ></TextField>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
          <div className='preferences_section'>
            <div className='title'>
              Discount Info Text <br />
              <span>{exampleToggle}</span>
            </div>
            <div className='info_row '>
              <div className='field'>
                <div>
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name'
                      labelid='size'
                      id='demo-simple-select'
                      value={baseToggle}
                      type={'text'}
                      placeholder='Enter Discount Info Text'
                      onChange={(e) => setBaseToggle(e.target.value)}
                    ></TextField>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='confirmation_btn'>
          <button type='submit'> Save </button>
        </div>
      </form>
    </div>
  );
}
