import React, { useEffect, useRef, useState, useContext } from 'react';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import './index.css';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
// import listPlugin from '@fullcalendar/list'; //For List View
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Carousel from 'react-material-ui-carousel';
import Notes from '../../components/TripNote';
import toast from 'react-hot-toast';

import moment from 'moment';

import CustomDayPlugin from '../../components/CalenderCustomView/CustomDayView';

import CalenderHeader from '../../components/CalenderHeader';

import CircularProgress from '@mui/material/CircularProgress';
import { get_bookings } from '../../API/index';
import { get_all_delivery_charges } from '../../API/setting';
import { intervalToDuration, format } from 'date-fns';
import { AuthContext } from '../../contexts/AuthContext';
import { Close } from '@mui/icons-material';

export default function DailyOverview() {
  const { authAxios, user, canView } = useContext(AuthContext);
  const calendarRef = useRef();
  // console.log(calendarRef.current?.getApi().getDate());
  let calendarApi = calendarRef.current?.getApi();

  const [calenderType, setCalenderType] = useState('custom_day');
  const [filterCalender, setFilterCalender] = useState('All');

  const [rawBookings, setRawBookings] = useState(null);
  const [bookings, setBookings] = useState(null);

  const [airports, setAirports] = useState(null);
  const [calenderSearchText, setCalenderSearchText] = useState('');
  const today = format(new Date(), 'yyyy-MM-dd');

  const getAirportDetail = (airportName) => {
    let airportDetail = (airports || []).find(
      ({ airport }) => airport === airportName
    );
    return airportDetail;
  };

  function renderEventContent(eventInfo) {
    console.log(eventInfo);
    // console.log(format(new Date(eventInfo.event.extendedProps.bookingDetail.end_date),"hh:mm aaaaa'm'"));
    const fotmatTime = (date) => {
      return format(new Date(date), "hh:mm aaaaa'm'");
    };

    const bookingStartTime = format(
      new Date(eventInfo?.event?.extendedProps?.bookingDetail?.start_date),
      "MM/dd/yyyy hh:mm aaaaa'm'"
    );
    const bookingEndTime = format(
      new Date(eventInfo?.event?.extendedProps?.bookingDetail?.end_date),
      "MM/dd/yyyy hh:mm aaaaa'm'"
    );
    return (
      <div
        className={`calender_event_block ${
          calenderType === 'dayGridMonth' && 'monthView'
        }`}
      >
        {calenderType === 'dayGridMonth' ? (
          <div className='monthViewTile'>
            {/* <span>{eventInfo.event.extendedProps.time}</span> */}
            <span>{eventInfo?.event?.extendedProps?.carDetails?.name}</span>
            <span>
              {eventInfo?.event?.extendedProps?.bookingDetail.customer.name}
            </span>
            {/* <span> */}
            {eventInfo?.event?.extendedProps?.bookingDetail.trip_notes.length >
            0 ? (
              <span
                style={{
                  color: '#f0f04b',
                  fontSize: '25px',
                  position: 'relative',
                  width: '15px',
                }}
              >
                <svg
                  width={12}
                  // fill='currentColor'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-6 h-6'
                  style={{
                    fill: '#f0f04b',
                    position: 'absolute',
                    top: '-10px',
                    left: '-6px',
                  }}
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
                  />
                </svg>
              </span>
            ) : null}
            {/* </span> */}
            {/* Outbound Location Code */}
            <span
              style={{
                color:
                  eventInfo?.event?.extendedProps?.bookingDetail.pickup_location
                    ?.color ||
                  // For bookings made before pickup_location was implemented
                  eventInfo?.event?.extendedProps?.bookingDetail
                    .destination_location?.color,
              }}
            >
              {eventInfo?.event?.extendedProps?.bookingDetail.pickup_location
                ?.code ||
                eventInfo?.event?.extendedProps?.bookingDetail
                  .destination_location?.code}
            </span>
            &#8594; {/* Right arrow */}
            {/* Return Location Code */}
            <span
              style={{
                color:
                  eventInfo?.event?.extendedProps?.bookingDetail
                    .destination_location?.color,
              }}
            >
              {
                eventInfo?.event?.extendedProps?.bookingDetail
                  .destination_location?.code
              }
            </span>
            <span>
              {bookingStartTime} - {bookingEndTime}
            </span>
            <span>
              {eventInfo?.event?.extendedProps?.bookingDetail.flight_number}
            </span>
          </div>
        ) : (
          <div>
            <div className='event_block_type'>
              {eventInfo.event.extendedProps.come
                ? 'Return'
                : eventInfo.event.extendedProps.out
                ? 'Outbound'
                : ''}
            </div>
            <div className='event_block_status'>
              {eventInfo.event.extendedProps.come ? (
                <div>
                  {calenderType === 'timeGridWeek' ? (
                    <div style={{ position: 'relative' }}>
                      <div>
                        {eventInfo?.event?.extendedProps?.bookingDetail
                          .trip_notes.length > 0 ? (
                          <span
                            style={{
                              color: '#f0f04b',
                              fontSize: '25px',
                              position: 'absolute',
                              top: '-14px',
                              left: '0px',
                            }}
                          >
                            <svg
                              width={12}
                              // fill='currentColor'
                              viewBox='0 0 24 24'
                              strokeWidth={1.5}
                              stroke='currentColor'
                              className='w-6 h-6'
                              style={{ fill: '#f0f04b' }}
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
                              />
                            </svg>
                          </span>
                        ) : null}
                        {
                          eventInfo?.event?.extendedProps?.bookingDetail
                            .customer.name
                        }
                      </div>
                      <div>
                        {eventInfo?.event?.extendedProps?.carDetails?.name} -{' '}
                        <span
                          style={{
                            color:
                              eventInfo?.event?.extendedProps?.bookingDetail
                                ?.destination_location.color,
                          }}
                        >
                          {
                            eventInfo?.event?.extendedProps?.bookingDetail
                              ?.destination_location.code
                          }
                        </span>
                      </div>
                    </div>
                  ) : (
                    'Return'
                  )}
                </div>
              ) : // Drop Offs
              eventInfo.event.extendedProps.out ? (
                <div>
                  {calenderType === 'timeGridWeek' ? (
                    <div style={{ position: 'relative' }}>
                      <div>
                        {eventInfo?.event?.extendedProps?.bookingDetail
                          .trip_notes.length > 0 ? (
                          <span
                            style={{
                              color: '#f0f04b',
                              fontSize: '25px',
                              position: 'absolute',
                              top: '-14px',
                              left: '0px',
                            }}
                          >
                            <svg
                              width={12}
                              // fill='currentColor'
                              viewBox='0 0 24 24'
                              strokeWidth={1.5}
                              stroke='currentColor'
                              className='w-6 h-6'
                              style={{ fill: '#f0f04b' }}
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
                              />
                            </svg>
                          </span>
                        ) : null}
                        {
                          eventInfo?.event?.extendedProps?.bookingDetail
                            .customer.name
                        }
                      </div>
                      <div>
                        {eventInfo?.event?.extendedProps?.carDetails?.name} -{' '}
                        <span
                          style={{
                            color:
                              eventInfo?.event?.extendedProps?.bookingDetail
                                ?.pickup_location?.color ||
                              // For bookings made before pickup_location was implemented
                              eventInfo?.event?.extendedProps?.bookingDetail
                                ?.destination_location?.color,
                          }}
                        >
                          {` 
                      ${
                        eventInfo?.event?.extendedProps?.bookingDetail
                          ?.pickup_location?.code ||
                        // For bookings made before pickup_location was implemented
                        eventInfo?.event?.extendedProps?.bookingDetail
                          ?.destination_location?.code
                      }
                    `}
                        </span>
                      </div>
                    </div>
                  ) : (
                    'Outbound'
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
            <div className='event_block_time'>
              {/* {eventInfo.event.extendedProps.time} */}
              {eventInfo.event.extendedProps.come
                ? fotmatTime(
                    eventInfo.event.extendedProps.bookingDetail.end_date
                  )
                : eventInfo.event.extendedProps.out
                ? fotmatTime(
                    eventInfo.event.extendedProps.bookingDetail.start_date
                  )
                : eventInfo.event.extendedProps.time}
            </div>
          </div>
        )}
      </div>
    );
  }
  const getEventsData = (data) => {
    return filterCalender === 'All'
      ? data
      : filterCalender === 'Pick_Ups'
      ? data.filter((e) => e.come === true)
      : filterCalender === 'Drop_Offs'
      ? data.filter((e) => e.out === true)
      : filterCalender === 'On_Service'
      ? data.filter((e) => e.on_Service === true)
      : null;
  };

  const getInterval = (fromDate, untilDate) => {
    // return intervalToDuration({
    //   start: fromDate,
    //   end: untilDate,
    // }).days;

      const start = new Date(fromDate);
      const end = new Date(untilDate);
      const diffInMs = end - start;
      const diffInHours = diffInMs / (1000 * 60 * 60);
      const totalDays = Math.ceil(diffInHours / 24);
  
      return totalDays;
    
  };
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  const TripDetailModal = (trip) => {
    const interval = getInterval(
      new Date(trip.start_date),
      new Date(trip.end_date)
    );



    const bookedDate = format(new Date(trip.booked_date), 'MM/dd/yyyy');
    console.log("tripalok", trip);
    return confirmAlert({
      overlayClassName: 'tripDetailModalWrapper',
      customUI: ({ onClose }) => {
        return (
          <div className='editModal tripDetailModal'>
            <div
              className='close_icon'
              style={{ top: '1.5rem', right: '1.5rem' }}
              onClick={() => onClose()}
            >
              <svg
                width='25'
                height='25'
                viewBox='0 0 110 110'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
            <Carousel className='trip_modal_carousal'>
              {trip?.car.car_images.map((img, index) => (
                <img key={index} src={img.image} alt='img' width={'100%'} />
              ))}
            </Carousel>
            <div className='modal_detail_header'>
              <div>
                <div
                  onClick={() => {
                    onClose();
                    // navigate(`/user_detail/${trip.customer.id}`);
                  }}
                  className='title'
                  style={{
                    fontSize: '22px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    color: '#4fc8e9',
                  }}
                >
                  {trip.customer.name}
                </div>
                <div style={{ fontSize: '18px' }}>
                  {formatPhoneNumber(trip.customer.phone)}
                  {/* Booked by{' '}<span style={{ color: '#4fc8e9' }}>{trip.booked_by}</span> */}
                </div>
                <div style={{ fontSize: '18px' }}>$ {trip.price}</div>

                <div>Daily Rate: $ {trip.car.daily_price}</div>
                <div>
                  Discount:{' '}
                  {`${trip.coupon_code ? trip.coupon_code + ' -' : ''} $ ${
                    trip.discount_price
                  }`}
                </div>
              </div>
              <div
                style={{
                  textAlign: 'right',
                }}
              >
                <div
                  style={{
                    display: 'none',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: '1rem',
                  }}
                >
                  <div
                    className='req_reimbursement'
                    onClick={() => {
                      // setReimbursementModal(true);
                    }}
                  >
                    + Request Reimbursement
                  </div>
                  <div
                    className='req_reimbursement'
                    onClick={() => {
                      // EditBookingModal(trip);
                    }}
                    style={{ color: '#4fc8e9' }}
                  >
                    Edit
                  </div>
                </div>
                <div style={{ color: 'rgb(79, 200, 233)', fontSize: '18px' }}>
                  Outbound Location:
                  <span
                    style={{
                      color:
                        trip?.pickup_location?.color ||
                        // For bookings made before pickup_location was implemented
                        trip?.destination_location?.color,
                    }}
                  >
                    {` ${
                      trip?.pickup_location?.code ||
                      trip?.destination_location?.code
                    }`}
                  </span>
                </div>
                <div style={{ color: 'rgb(79, 200, 233)', fontSize: '18px' }}>
                  Return Location:
                  <span
                    style={{
                      color: trip?.destination_location?.color,
                    }}
                  >
                    {` ${trip?.destination_location?.code}`}
                  </span>
                </div>
                <div style={{ fontSize: '18px' }}>
                  <div>
                    From:{' '}
                    {moment(trip?.start_date).format('MMM DD, yyyy hh:mm A')}
                  </div>
                  <div>
                    Until:{' '}
                    {moment(trip?.end_date).format('MMM DD, yyyy hh:mm A')}
                  </div>
                </div>
                <div>Total Days: {interval === 0 ? 1 : interval}</div>
              </div>
            </div>
            <div className='tripDetail'>
              <div style={{ width: '60%' }} className='tripDetail_col'>
                <div>
                  Year/Make/Model/Trim:{' '}
                  <span>
                    {trip.car.year}/{trip.car.make}/{trip.car.model}/
                    {trip.car.trim}
                  </span>
                </div>
                <div>
                  Airline: <span>{trip.airline_name}</span>
                </div>
                <div>
                  Miles Alloted:{' '}
                  <span>
                    {trip.car.daily_max_miles * (interval === 0 ? 1 : interval)}
                  </span>
                </div>
                <div>
                  Booked On: <span>{bookedDate}</span>
                </div>
              </div>
              <div style={{ width: '40%' }} className='tripDetail_col'>
                <div>
                  Car Id: <span>{trip.car.title}</span>
                </div>
                <div>
                  Flight #: <span>{trip.flight_number}</span>
                </div>
                <div>
                  Miles Overage: <span>$ {trip.car.mileage_overage_fee}</span>
                </div>
                <div>
                  Booked By: <span>{trip.booked_by}</span>
                </div>
              </div>
            </div>
            {canView('trips') && (
              <div className='trip_detail_actions'>
                <a
                  href={'/trips/' + trip.id}
                  className='booking_modal_trip_btn'
                  onClick={() => {
                    // navigate('/trip_information');
                    // onClose();
                    // dispatch(setTrip(trip));
                  }}
                >
                  Trip Management
                </a>
              </div>
            )}
            <div style={{ marginTop: '20px' }}>
              <Notes authAxios={trip.authAxios} id={trip.id} user={trip.user} />
            </div>
          </div>
        );
      },
    });
  };

  const getCarUnavailablity = (unavailabilities) => {
    return (
      unavailabilities.length > 0 &&
      unavailabilities?.every((car) => {
        const start_date = new Date(car.start_date.substring(0, 10));
        const end_date = new Date(car.end_date.substring(0, 10));
        if (new Date(today) >= start_date && new Date(today) <= end_date) {
          // setUnavailable(true);
          return false;
        } else {
          // setUnavailable(false);
          return true;
        }
      })
    );
  };

  // const handleEventClickToDayView = (e) => {
  //   // console.log(e);
  //   setCalenderType('custom_day');
  //   if (e.event._def.extendedProps.going_out) {
  //     calendarApi.gotoDate(
  //       new Date(e.event._def.extendedProps.bookingDetail.start_date)
  //     );
  //   } else {
  //     calendarApi.gotoDate(
  //       new Date(e.event._def.extendedProps.bookingDetail.end_date)
  //     );
  //   }
  //   calendarApi.changeView('custom_day');
  // };
  const handleEventClick = (e) => {
    TripDetailModal(e?.event?.extendedProps?.bookingDetail);
  };
  // console.log(calenderType);

  const fetchBookings = (params) => {
    get_bookings(authAxios, params).then((res) => {
      // console.log(res);
      if (res.status === 200 || res.status === 201 || res.status === 304) {
        setRawBookings(res.data);
      } else {
        console.log(res);
      }
    });
  }

  useEffect(() => {
    const _date = window.sessionStorage.getItem('dailyview_date')
    const today = moment().format('MM-DD-yyyy');
    const date = moment(_date).format('MM-DD-yyyy');

    let params = {
      bookings_start_date: _date ? date : today,
      bookings_end_date: _date ? date : today,
    }

    fetchBookings(params)
  }, []);
  // console.log(bookings);

  useEffect(() => {
    const getAllDeliveryCharges = async () => {
      let res = await get_all_delivery_charges(authAxios);
      if (res.status === 200) {
        setAirports(res.data);
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }
    };
    getAllDeliveryCharges();
  }, []);

  useEffect(() => {
    // const result = [{ googleCalendarId: 'shbharat5419@gmail.com' }];
    const result = [];
    rawBookings?.forEach((booking) => {
      // booking.car.id === 24 &&
      // console.log(booking.car.id,getCarUnavailablity(booking.car.unavailabilities));

      let pickupLocation = getAirportDetail(booking.pickup_location);
      let destinationLocation = getAirportDetail(booking.destination_location);

      for (let i = 1; i <= 2; i++) {
        if (i === 1) {
          result.push({
            // ...booking,
            title: booking.destination_location,
            time: moment(booking.start_date.substring(0, 16)).format('hh:mm A'),
            comingIn_time: moment(booking.end_date.substring(0, 16)).format(
              'hh:mm A'
            ),
            date: moment(booking.start_date.substring(0, 16)).format(
              'yyyy-MM-DD'
            ),
            start: moment(booking.start_date.substring(0, 16)).format(
              'yyyy-MM-DDTHH:mm'
            ),
            end:
              calenderType === 'dayGridMonth' &&
              moment(booking.end_date.substring(0, 16)).format(
                'yyyy-MM-DDTHH:mm'
              ),
            // end: moment(booking.end_date.substring(0, 16)).format('yyyy-MM-DDTHH:mm'),
            carDetails: {
              name: booking.car.title,
              total_cost: booking.price,
            },
            allDay: false,
            going_out: true,
            turn: '',
            borderColor: booking?.destination_location?.color,
            classNames: 'calender_event_out test',
            come: false,
            out: true,
            unavailability: getCarUnavailablity(booking.car.unavailabilities),
            bookingDetail: {
              ...booking,
              pickup_location: pickupLocation,
              destination_location: destinationLocation,
              authAxios,
              user,
            },
          });
        } else if (calenderType !== 'dayGridMonth') {
          result.push({
            // ...booking,
            title: booking.destination_location,
            time: moment(booking.start_date.substring(0, 16)).format('hh:mm A'),
            comingIn_time: moment(booking.end_date.substring(0, 16)).format(
              'hh:mm A'
            ),
            date: moment(booking.start_date.substring(0, 16)).format(
              'yyyy-MM-DD'
            ),
            // start: moment(booking.start_date.substring(0, 16)).format('yyyy-MM-DDTHH:mm'),
            start: moment(booking.end_date.substring(0, 16)).format(
              'yyyy-MM-DDTHH:mm'
            ),
            carDetails: {
              name: booking.car.title,
              total_cost: booking.price,
            },
            allDay: false,
            going_out: false,
            turn: '',
            classNames: '',
            borderColor: booking?.destination_location?.color,
            come: true,
            out: false,
            unavailability: getCarUnavailablity(booking.car.unavailabilities),
            bookingDetail: {
              ...booking,
              pickup_location: pickupLocation,
              destination_location: destinationLocation,
              authAxios,
              user,
            },
          });
        }
      }
    });
    console.log(result[0]?.bookingDetail);
    setBookings(
      result.filter((item) => {
        if (
          item.bookingDetail?.customer?.name
            ?.toLowerCase()
            .includes(calenderSearchText)
        )
          return true;
        if (
          item.bookingDetail?.customer?.phone
            ?.toLowerCase()
            .includes(calenderSearchText)
        )
          return true;
        if (
          item.bookingDetail?.car?.model
            ?.toLowerCase()
            .includes(calenderSearchText)
        )
          return true;
        if (
          item.bookingDetail?.car?.title
            ?.toLowerCase()
            .includes(calenderSearchText)
        )
          return true;
        if (
          item.bookingDetail?.destination_location?.code?.includes(
            calenderSearchText
          )
        )
          return true;
        if (
          item.bookingDetail?.flight_number
            ?.toLowerCase()
            .includes(calenderSearchText)
        )
          return true;
        if (
          item.bookingDetail?.car?.vehicle_status
            ?.toLowerCase()
            .includes(calenderSearchText)
        )
          return true;
        return false;
      })
    );
  }, [rawBookings, calenderType, calenderSearchText]);
  // console.log(calendarApi);

  return (
    <div className='dashboard page'>
      <Sidebar page='daily_overview' />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <div className='booking_wrapper'>
            <div className='booking_header'>
              <h4>Daily Overview Calendar</h4>
            </div>
            <div className='dailyOverviewCalenderWrapper'>
              {bookings ? (
                <>
                  <CalenderHeader
                    calenderType={calenderType}
                    setCalenderType={setCalenderType}
                    calendarRef={calendarRef}
                    // noFilters={true}
                    fetchBookings={fetchBookings}
                    filterCalender={filterCalender}
                    setFilterCalender={setFilterCalender}
                    rawBookings={rawBookings}
                    onChangeSearchText={(text) =>
                      setCalenderSearchText(text.toLowerCase())
                    }
                    searchText={calenderSearchText}
                  />
                  {/* {calendarApi ? (
                  ) : (
                    <CircularProgress color='primary' />
                  )} */}
                  {(!rawBookings || !bookings) ?  <CircularProgress color='primary' /> : (
                    <FullCalendar
                      timeZone='America/New_York'
                      ref={calendarRef}
                      height={
                        calenderType === 'dayGridMonth'
                          ? 2000
                          : calenderType === 'custom_day'
                          ? 'auto'
                          : 2200
                      }
                      headerToolbar={false}
                      viewClassNames={'dailyOverViewCalender'}
                      dayHeaderClassNames='dayHeaderClassNames'
                      dayCellClassNames={'calender_blocks'}
                      eventClassNames='calender_event'
                      // eventBorderColor='#000'
                      plugins={[
                        CustomDayPlugin,
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                        googleCalendarPlugin,
                      ]}
                      googleCalendarApiKey={
                        process.env.REACT_APP_GOOGLECALENDARAPIKEY ?? ''
                      }
                      initialView='custom_day'
                      events={getEventsData(bookings)}
                      eventContent={renderEventContent}
                      // editable={true}
                      // selectable={true}
                      selectMirror={true}
                      eventClick={handleEventClick}
                      // eventMouseEnter={() => console.log('enter')}
                      // eventClick={(e) => calendarApi.gotoDate('2019-10-12')}
                      dayMaxEvents={true}
                      // buttonIcons={}
                      slotEventOverlap={false}
                      // slotDuration={{ hour: 2 }}
                      slotLabelInterval={{ hour: 1 }}
                      allDaySlot={false}
                      dayMaxEventRows={true}
                      eventMaxStack={calenderType === 'timeGridWeek' ? 1 : 8}
                    />
                  )}
                </>
              ) : (
                <CircularProgress color='primary' />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
