
import { subfix_domain } from '../api';

const get_car_report = async (authAxios, data) => {
  try {
    const res = await authAxios.get(`/api/statistic/car-report/`, {
      params: {
        ...data,
        subfix_domain,
      }
    });
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

export { get_car_report };