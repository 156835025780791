import React, { useMemo, useState } from 'react';
import './index.css';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import moment from 'moment';

export default function CouponDropdown({ selectedCoupon, setSelectedCoupon, couponData }) {
  const containsText = (text, searchText) =>
    (text || '').toLowerCase().indexOf((searchText || '').toLowerCase()) > -1;

  const [searchText, setSearchText] = useState('');
  const displayedOptions = useMemo(
    () => couponData?.filter(({ name }) => containsText(name, searchText)),
    [searchText, couponData]
  );

  return (
    <div className='info_row '>
      <div className='field'>
        <FormControl className='edit_dropdown' sx={{ m: 1, minWidth: 200 }}>
          <InputLabel className='dropdown_label' id='airport_name'>
            Select Coupon
          </InputLabel>
          <Select
            className='carDropdown'
            labelid='mileage'
            id='mileage'
            value={selectedCoupon}
            label='Select Coupon'
            onChange={(e) => setSelectedCoupon(e.target.value)}
            onClose={() => setSearchText('')}
            MenuProps={{
              autoFocus: false,
              classes: {
                paper: 'car_dropdown_paper coupon_dropdown_paper',
                root: 'car_dropdown_root',
              },
              variant: 'selectedMenu',
              //setting variant to menu makes it appear below the element
            }}
          >
            {/* <div className='dropdown_search'>
              <input
                type='text'
                name='dropdown_search'
                id='dropdown_search'
                placeholder='Search Coupon'
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation();
                  }
                }}
              />
            </div> */}
            {displayedOptions.map((coupon, i) => (
              <MenuItem
                key={i}
                className='dropdown_search_menu_item airport_name_menu_item'
                value={coupon.code}
              >
                <div className='drpdwn_car_details'>
                  <div className='car_name'>{coupon.name}</div>
                  <div className='price_tag'>
                    {moment(coupon.start_date).format('MM/DD/YYYY HH:MM')} - {moment(coupon.end_date).format('MM/DD/YYYY HH:MM')}
                    {' '}<span style={{ color: 'white'}}>Code: {coupon.code}</span> {coupon.amount_type === 'A'? '$' + coupon.amount: coupon.amount + '%'}
                  </div>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
