import React from 'react';
import './index.css';

export default function PreviousCustomer({ ...props }) {
  const { customer } = props;
  return (
    <div className='prev_customer_card'>
      <div className='customer_img'>
        <img
          src={`assets/imgs/prevCustomers/${customer.img}.png`}
          alt={customer.name}
        />
      </div>
      <div className='customer_name'>{customer.name}</div>
      <div className='customer_descp'>{customer.descp}</div>
    </div>
  );
}
