import React, { useContext, useState, useEffect } from 'react';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import { Link } from 'react-router-dom';
import './index.css';
// import {
//   ResponsiveContainer,
//   ComposedChart,
//   Line,
//   Area,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from 'recharts';

import { ReactComponent as Car } from '../../Assets/svg/carDash.svg';
import { ReactComponent as Car2 } from '../../Assets/svg/car_2dash.svg';
import { ReactComponent as Rent } from '../../Assets/svg/rent.svg';
import { ReactComponent as Money } from '../../Assets/svg/money.svg';
import { ReactComponent as CheckRound } from '../../Assets/svg/check_round.svg';
import { ReactComponent as Warning } from '../../Assets/svg/warning.svg';
import { ReactComponent as Calender } from '../../Assets/svg/calender_widget.svg';
import { ReactComponent as PendingRequest } from '../../Assets/svg/edit_document.svg';
import { AuthContext } from '../../contexts/AuthContext';

import { get_money_collect_month, get_cars_statis } from '../../API/dashboard';
import { get_vehicle } from '../../API/vehicle';
import toast from 'react-hot-toast';
import { VEHICLE_STATUS } from '../../constants';

export default function Dashboard() {
  const { authAxios } = useContext(AuthContext);
  // const data = [
  //   {
  //     name: 'Jan',
  //     uv: 10,
  //     pv: 80,
  //     amt: 1400,
  //   },
  // ];

  const [moneyCollect, setMoneyCollect] = useState(0);
  const [vehicles, setVehicles] = useState([]);
  const [onRentCars, setOnRentCars] = useState(0);
  const [onAvaliableCars, setOnAvailiableCars] = useState(0);
  const [carStatus, setCarStatus] = useState([]);

  useEffect(() => {
    const getData = async () => {
      let res = await get_money_collect_month(authAxios);
      if (res.status === 200) {
        setMoneyCollect(res.data);
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }

      res = await get_cars_statis(authAxios);
      if (res.status === 200) {
        setOnRentCars(res.data.on_rent_cars);
        setOnAvailiableCars(res.data.available_cars);
        setCarStatus(
          VEHICLE_STATUS.map((status) => {
            const item = res.data.status_cars.find(
              (item) => item.vehicle_status == status.name
            );
            return item
              ? item
              : {
                  title: '',
                  vehicle_status: status.name,
                  title: '',
                  dcount: 0,
                };
          })
        );
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }

      res = await get_vehicle(authAxios);
      if (res.status === 200) {
        setVehicles(res.data?.results);
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }
    };

    getData();
  }, []);

  return (
    <div className='dashboard page'>
      <Sidebar page='dashboard' />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />

        <div className='dashboard_widgets'>
          <div className='widget money_collected'>
            <div className='icon'>
              <Money />
            </div>
            <div className='title'>30 Days Total</div>
            <div className='amount'>${moneyCollect.toFixed(2)}</div>
          </div>
          <div className='widget car_availability'>
            <div className='icon'>
              <CheckRound />
            </div>
            <div className='title'>Total Vehicles Out</div>
            <div className='amount'>{onRentCars}</div>
          </div>
          <div className='widget tom_car_bookings'>
            <div className='icon'>
              <Calender />
            </div>
            <div className='title'>Total Vehicles Open</div>
            <div className='amount'>{onAvaliableCars}</div>
          </div>
        </div>

        <div className='hero_tag'>
          <div className='hero_tag_text'>
            <div style={{ visibility: 'hidden' }}>
              The World Largest Car <br />
              Shop
            </div>
            <br />
            <Link to='/inventory/all_cars' className='primary_button'>
              View Cars
              <svg
                width='21'
                height='8'
                viewBox='0 0 21 8'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM20.3536 4.35355C20.5488 4.15829 20.5488 3.84171 20.3536 3.64644L17.1716 0.464465C16.9763 0.269203 16.6597 0.269203 16.4645 0.464465C16.2692 0.659727 16.2692 0.976309 16.4645 1.17157L19.2929 4L16.4645 6.82843C16.2692 7.02369 16.2692 7.34027 16.4645 7.53553C16.6597 7.73079 16.9763 7.73079 17.1716 7.53553L20.3536 4.35355ZM1 4.5L20 4.5L20 3.5L1 3.5L1 4.5Z'
                  fill='#4FC8E9'
                />
              </svg>
            </Link>
          </div>
          <img src='/assets/imgs/car.png' alt='car' />
        </div>

        <div className='admin_row'>
          <div>Vehicle Status Dashboard</div>
          <div>
            <button style={{ display: 'none' }} className='primary_button'>
              View Cars
              <svg
                width='21'
                height='8'
                viewBox='0 0 21 8'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM20.3536 4.35355C20.5488 4.15829 20.5488 3.84171 20.3536 3.64644L17.1716 0.464465C16.9763 0.269203 16.6597 0.269203 16.4645 0.464465C16.2692 0.659727 16.2692 0.976309 16.4645 1.17157L19.2929 4L16.4645 6.82843C16.2692 7.02369 16.2692 7.34027 16.4645 7.53553C16.6597 7.73079 16.9763 7.73079 17.1716 7.53553L20.3536 4.35355ZM1 4.5L20 4.5L20 3.5L1 3.5L1 4.5Z'
                  fill='#4FC8E9'
                />
              </svg>
            </button>
          </div>
        </div>

        <div className='chart_row status'>
          {/* <div className='chart'>
            <span className='chart_text'>Sell & Rent Progress</span>
            <div style={{ marginTop: '1rem' }}>
              <ResponsiveContainer className={'chart_wrapper'}>
                <ComposedChart
                  width={'100%'}
                  data={data}
                  margin={{
                    left: -30,
                  }}
                >
                  <XAxis dataKey='name' scale='band' interval={0} />
                  <YAxis />
                  <Bar dataKey='pv' barSize={10} fill='#4FC8E9' />
                  <Line
                    type='monotone'
                    dataKey='uv'
                    stroke='#FFB600'
                    strokeWidth={'2px'}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div> */}

          <div className='counters'>
            {carStatus.map((status) => {
              const list = vehicles.filter(
                (vehicle) => vehicle.vehicle_status === status.vehicle_status
              );
              return (
                <div className='counter'>
                  <div className='head'>
                    {status.vehicle_status === 'refuel_wash'
                      ? status.vehicle_status.replace('_', ' & ').toUpperCase()
                      : status.vehicle_status.replace('_', ' ').toUpperCase()}
                  </div>
                  <div className='car'>
                    <Car id='dash' />
                  </div>
                  <div className='count'>{status.dcount}</div>
                  <ul className='list'>
                    {status?.cars?.map((item) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
