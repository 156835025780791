import React, { useState, useContext, useEffect } from 'react';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import './index.css';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { get_user } from '../../API/user';
import { AuthContext } from '../../contexts/AuthContext';

export default function Profile() {
  const { authAxios, user } = useContext(AuthContext);
  const [selectedFile, setSelectedFile] = useState();
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [email, setEmail] = useState(null);
  const [CNIC_NUM, setCNIC_NUM] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [address, setAddress] = useState(null);

  console.log(user.user_id);

  useEffect(() => {
    get_user(user.user_id, authAxios).then((res) => {
      console.log(res.data);
    });
  }, []);

  return (
    <div className='dashboard page'>
      <Sidebar page='add_person' />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <div className='addPerson'>
            <h5>Profile</h5>
            <div className='add_person_wrapper'>
              <form
                action='#'
                // onSubmit={(e) => handleSave(e)}
                className='card_details'
              >
                <div className='info_wrapper'>
                  <div className='info_row '>
                    <div className='profile_upload'>
                      <div className='profile_img'>
                        <img
                          src={
                            selectedFile
                              ? URL.createObjectURL(selectedFile)
                              : '/assets/imgs/profile.jpg'
                          }
                          alt='profile'
                        />
                        <Button
                          required
                          className='profile_upload_btn'
                          variant='contained'
                          component='label'
                        >
                          <svg
                            width='15'
                            height='15'
                            viewBox='0 0 15 15'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M10.4565 2.90625L12.0931 4.54211L10.4565 2.90625ZM11.5089 1.46511L7.08352 5.89052C6.85486 6.11886 6.69891 6.40978 6.63534 6.72661L6.22656 8.77279L8.27274 8.36325C8.58956 8.29988 8.88011 8.14457 9.10883 7.91584L13.5342 3.49043C13.6672 3.35745 13.7727 3.19957 13.8447 3.02582C13.9167 2.85207 13.9537 2.66584 13.9537 2.47777C13.9537 2.2897 13.9167 2.10348 13.8447 1.92972C13.7727 1.75597 13.6672 1.5981 13.5342 1.46511C13.4013 1.33213 13.2434 1.22664 13.0696 1.15467C12.8959 1.0827 12.7097 1.04565 12.5216 1.04565C12.3335 1.04565 12.1473 1.0827 11.9735 1.15467C11.7998 1.22664 11.6419 1.33213 11.5089 1.46511V1.46511Z'
                              stroke='#4FC8E9'
                              strokeWidth='1.54545'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M12.4093 10.3181V12.6363C12.4093 13.0462 12.2464 13.4392 11.9566 13.7291C11.6668 14.0189 11.2737 14.1817 10.8638 14.1817H2.36381C1.95393 14.1817 1.56084 14.0189 1.27101 13.7291C0.981184 13.4392 0.818359 13.0462 0.818359 12.6363V4.13627C0.818359 3.72639 0.981184 3.3333 1.27101 3.04347C1.56084 2.75364 1.95393 2.59082 2.36381 2.59082H4.682'
                              stroke='#4FC8E9'
                              strokeWidth='1.54545'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>

                          <input
                            required
                            type='file'
                            name='UserDriverLicense'
                            id='UserDriverLicense'
                            hidden
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='info_row '>
                    <div className='field'>
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <TextField
                          className='airport_name'
                          labelid='size'
                          id='demo-simple-select'
                          defaultValue={firstName}
                          label='First Name'
                          onChange={(e) => setFirstName(e.target.value)}
                        ></TextField>
                      </FormControl>
                    </div>
                    <div className='field'>
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <TextField
                          className='airport_name'
                          labelid='size'
                          id='demo-simple-select'
                          defaultValue={lastName}
                          label='Last Name'
                          onChange={(e) => setLastName(e.target.value)}
                        ></TextField>
                      </FormControl>
                    </div>
                  </div>
                  <div className='info_row '>
                    <div className='field'>
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <TextField
                          className='airport_name'
                          labelid='size'
                          id='demo-simple-select'
                          defaultValue={mobile}
                          label='Phone Number'
                          onChange={(e) => setMobile(e.target.value)}
                        ></TextField>
                      </FormControl>
                    </div>
                    <div className='field'>
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <TextField
                          className='airport_name'
                          labelid='size'
                          id='demo-simple-select'
                          defaultValue={email}
                          label='Email Address'
                          onChange={(e) => setEmail(e.target.value)}
                        ></TextField>
                      </FormControl>
                    </div>
                  </div>
                  <div className='info_row '>
                    <div className='field'>
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <TextField
                          className='airport_name'
                          labelid='size'
                          id='demo-simple-select'
                          defaultValue={CNIC_NUM}
                          label='CNIC Number'
                          onChange={(e) => setCNIC_NUM(e.target.value)}
                        ></TextField>
                      </FormControl>
                    </div>
                    <div className='field'>
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <TextField
                          className='airport_name'
                          labelid='size'
                          id='demo-simple-select'
                          defaultValue={postalCode}
                          label='Postal Code'
                          onChange={(e) => setPostalCode(e.target.value)}
                        ></TextField>
                      </FormControl>
                    </div>
                  </div>
                  <div className='info_row '>
                    <div className='field'>
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <TextField
                          className='airport_name'
                          labelid='size'
                          id='demo-simple-select'
                          defaultValue={address}
                          label='Address'
                          onChange={(e) => setAddress(e.target.value)}
                        ></TextField>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className='confirmation_btn'>
                  <button type='submit'> Save </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
