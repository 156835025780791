import { createSlice } from '@reduxjs/toolkit';

export const searchSlice = createSlice({
    name: 'search',
    initialState: {
      q: '',
    },
    reducers: {
      clearState: (state) => {
        state.q = '';
        return state;
      },
      setQ: (state, { payload }) => {
        state.q = payload;
        return state;
      }
    },
    extraReducers: {
    },
  })
  
  export const { clearState, setQ } = searchSlice.actions;
  
  export const searchSelector = state => state.search;
  