import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import './index.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { get_notifications } from '../../API/notifications';
import { get_car_booking } from '../../API/car_booking';
import toast from 'react-hot-toast';
import { AuthContext } from '../../contexts/AuthContext';
import { confirmAlert } from 'react-confirm-alert'; // Import
import Carousel from 'react-material-ui-carousel';

export default function NotificationHistory() {
  const { authAxios } = useContext(AuthContext);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const getHistory = async () => {
      let res = await get_notifications(authAxios);
      if (res.status === 200) {
        setHistory(res.data);
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }
    };

    getHistory();
  }, []);

  async function getCarBooking(id) {
    let res = await get_car_booking(authAxios, id);
    console.log(res);
    CarBookingModal(res.data);
  }

  const CarBookingModal = (trip) => {
    console.log(trip);
    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='editModal tripDetailModal notificationHistoryModal'>
            <div
              className='close_icon'
              style={{ top: '1.5rem', right: '1.5rem' }}
              onClick={() => onClose()}
            >
              <svg
                width='25'
                height='25'
                viewBox='0 0 110 110'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
            <Carousel className='trip_modal_carousal'>
              {trip?.car?.car_images?.map((img, index) => (
                <img key={index} src={img.image} alt='img' width={'100%'} />
              ))}
            </Carousel>
            <div className='tripDetail'>
              <div className='tripDetail_col'>
                <div>
                  Destination: <span>{trip?.destination_location}</span>
                </div>
                <div>
                  Date: <span>{trip?.booked_date?.split('T')[0]}</span>
                </div>
                <div>
                  Price: <span>$ {trip?.price}</span>
                </div>
              </div>
              <div className='tripDetail_col'>
                <div>
                  Start Date: <span>{trip?.start_date?.split('T')[0]}</span>
                </div>
                <div>
                  End Date: <span>{trip?.end_date?.split('T')[0]}</span>
                </div>
                <div>
                  Status: <span>{trip?.status}</span>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  return (
    <div className='dashboard page'>
      <Sidebar page='notification_history' />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />
        <div className='editWrapper pagewrapper trip_management'>
          <div className='trip_management_header'>
            <h4>Notification History</h4>
          </div>
          <div className='editContainer'>
            <div className='section trips_table'>
              <table>
                <thead>
                  <th className='destination_trip'>From User</th>
                  <th>From Email</th>
                  <th>Message</th>
                  <th>Time</th>
                </thead>
                <tbody>
                  {history.map((row) => (
                    <tr
                      key={row.id}
                      style={{ cursor: 'default' }}
                      // onClick={(e) => getCarBooking(row.reference_id)}
                    >
                      <td className='destination_trip'>
                        <div>
                          {/* <img src={trips.desination_img} alt='scene_1' /> */}
                          {row.client.first_name} {row.client.last_name}
                        </div>
                      </td>
                      <td>
                        <div>{row.client.email}</div>
                      </td>
                      <td>
                        <div>{row.content}</div>
                      </td>
                      <td>
                        <div>{row.timestamp.split('T')[0]}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
