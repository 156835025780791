import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { pages } from '../../constants';
import { MAIN_HOST, getCompanyAdminHost } from '../../api';

function NotFoundPage() {
  return (
    <div style={{ margin: '200px auto', textAlign: 'center' }}>
      <h1>
        You do not have access to view this page.
      </h1>
    </div>
  );
}

export default function ProtectedRoute({ children }) {
  const { user } = useContext(AuthContext);
  const pathname = window.location.pathname.replace('/', '');

  if (!user) {
    window.location.href = MAIN_HOST + '/login';
    // return <Navigate to="/login" replace />;
  }

  // only Owner can access packages, companies page.
  if (user.package?.name == 'Owner') {
    if (
      pathname == 'packages' ||
      pathname == 'companies' ||
      pathname == 'settings'
    ) {
      return children;
    } else {
      window.location.href = getCompanyAdminHost() + '/packages';
      return;
    }
  } else {
    if (pathname == 'packages' || pathname == 'companies') {
      window.location.href = getCompanyAdminHost() + '/';
      return;
    }

    const perm_pages = user?.access_level.items;

     if (pathname.startsWith('user_detail/')) {
      const canAccessAllUsers = perm_pages.find(
        (page) => page.page === 'all_users' && page.mutate === false
      );
      if (!canAccessAllUsers) {
        return <NotFoundPage />;
      }
    }

    if (pathname.startsWith('edit_booking/')) {
      const canAccessAllUsers = perm_pages.find(
        (page) => page.page === 'trips' && page.mutate === false
      );
      if (!canAccessAllUsers) {
        return <NotFoundPage />;
      }
    }

    if (pathname.startsWith('access_level_user')) {
      const canAccessAllUsers = perm_pages.find(
        (page) => page.page === pathname && page.mutate === false
      );
      if (!canAccessAllUsers) {
        return <NotFoundPage />;
      }
    }
    // everyone can access some pages.(i.e. dashboard page)
    const searchable_index = pages.findIndex((page) =>
      pathname.includes(page.link)
    );
    if (searchable_index == -1) return children;

    // check package
    if (!user?.access_level.name) {
      return;
    }
    if (!user?.access_level.items) {
      return;
    }
    
    const index = perm_pages.findIndex(
      (page) => pathname.includes(page.page) && page.mutate == false
    );
    // console.log(perm_pages, pathname, index)

    if (index == -1) {
      return <NotFoundPage />;
    }

    return children;
  }
}
