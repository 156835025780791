import React, { useState, useContext, useEffect } from 'react';
import { Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Modal from '@mui/material/Modal';
import toast from 'react-hot-toast';
import { create_reimbursement } from '../../API/trips_management';
import { AuthContext } from '../../contexts/AuthContext';

export default function Reimbursement({
  open,
  onClose,
  selectedTrips,
  totalMiles,
  totalFuelUsed,
}) {
  const { authAxios } = useContext(AuthContext);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [mileageUsage, setMileageUsage] = useState(totalMiles);
  const [fuelUsage, setFuelUsage] = useState();
  const [anyDamage, setAanyDamage] = useState();
  const [amount, setAmount] = useState(0);
  const [reimbursementMessage, setReimbursementMessage] = useState();

  // console.log({ mileageUsage, fuelUsage, totalFuelUsed });
  const handleSave = async (e) => {
    e.preventDefault();
    if (amount <= 0) {
      toast.error(
        'An Invoice Amount of greater than 0 must be entered to submit a reimbursement request.'
      );
      return;
    }
    console.log({
      mileageUsage,
      fuelUsage: Number(fuelUsage / 8),
      totalFuelUsed,
    });
    const formData = new FormData();
    formData.append('car_booking', selectedTrips.id);
    formData.append(
      'mileage_usage',
      Number(mileageUsage) ?? Number(totalMiles)
    );
    formData.append(
      'fuel_usage',
      Number(fuelUsage / 8) ?? Number(totalFuelUsed)
    );
    formData.append('damage', anyDamage);
    formData.append('message', reimbursementMessage);
    formData.append('amount', amount);
    formData.append('customer', selectedTrips.customer.id);
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('images', selectedFiles[i]);
    }

    let res = await create_reimbursement(authAxios, formData);
    if (res.status === 201) {
      toast.success('Created Successfully.');
      onClose();
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };
  //   console.log(Array.from(selectedFiles).map((e) => e.name + ', '));

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby='conf_modal'
      aria-describedby='modal-modal-description'
    >
      <div className='editModal reimbursementModal'>
        <div className='editModal_header'>
          <h5>Request Reimbursement</h5>
          <div
            onClick={() => {
              onClose();
            }}
            className='editModal_close'
          >
            <svg
              width='25'
              height='25'
              viewBox='0 0 110 110'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                stroke='#FF2552'
                strokeWidth='11.625'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                stroke='#FF2552'
                strokeWidth='11.625'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
        </div>
        <form
          action='#'
          onSubmit={(e) => handleSave(e)}
          className='card_details'
        >
          <div className='info_wrapper'>
            <div className='info_row '>
              <div className='field'>
                Total Mileage Usage
                <FormControl
                  className='edit_dropdown'
                  sx={{ m: 1, minWidth: 120 }}
                >
                  <TextField
                    required
                    className='airport_name'
                    labelid='size'
                    id='demo-simple-select'
                    // defaultValue={totalMiles}
                    defaultValue={totalMiles}
                    // value={mileageUsage}
                    placeholder='Enter Total Mileage Usage'
                    onChange={(e) => {
                      e.preventDefault();
                      setMileageUsage(e.target.value);
                    }}
                  ></TextField>
                </FormControl>
              </div>
            </div>
            <div className='info_row '>
              <div className='field'>
                Total Fuel Usage
                <FormControl
                  className='edit_dropdown'
                  sx={{ m: 1, minWidth: 120 }}
                >
                  <TextField
                    required
                    className='airport_name'
                    labelid='size'
                    id='demo-simple-select'
                    // defaultValue={fuelUsage}
                    defaultValue={
                      totalFuelUsed === 0 ? 0 : totalFuelUsed * 8 + '/8'
                    }
                    placeholder='Enter Total Fuel Usage'
                    onChange={(e) =>
                      setFuelUsage(Number(e.target.value.substring(0, 3)))
                    }
                  ></TextField>
                </FormControl>
              </div>
            </div>
            <div className='info_row '>
              <div className='field'>
                Any Damage
                <FormControl
                  className='edit_dropdown'
                  sx={{ m: 1, minWidth: 120 }}
                >
                  <TextField
                    required
                    className='airport_name'
                    labelid='size'
                    id='demo-simple-select'
                    defaultValue={anyDamage}
                    label='Enter Any Damage'
                    onChange={(e) => setAanyDamage(e.target.value)}
                  ></TextField>
                </FormControl>
              </div>
            </div>
            <div className='info_row '>
              <div className='field'>
                Message
                <FormControl
                  className='edit_dropdown'
                  sx={{ m: 1, minWidth: 120 }}
                >
                  <TextareaAutosize
                    required
                    className='airport_name couponDescription'
                    labelid='size'
                    id='demo-simple-select'
                    defaultValue={reimbursementMessage}
                    placeholder='Write Message'
                    onChange={(e) => setReimbursementMessage(e.target.value)}
                  ></TextareaAutosize>
                </FormControl>
              </div>
            </div>
            <div className='info_row '>
              <div className='field'>
                Invoice Amount
                <FormControl
                  className='edit_dropdown'
                  sx={{ m: 1, minWidth: 120 }}
                >
                  <TextField
                    required
                    className='airport_name'
                    labelid='size'
                    id='demo-simple-select'
                    defaultValue={amount}
                    label='Enter Invoice Amount'
                    onChange={(e) => setAmount(e.target.value)}
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                    }}
                  ></TextField>
                </FormControl>
              </div>
            </div>
          </div>
          <div className='confirmation_btn'>
            <Button
              required
              className='user_upload_btn '
              variant='contained'
              component='label'
            >
              <div
                style={{
                  flexDirection: 'column',
                  fontSize: '10px',
                  padding: '0 10px',
                }}
              >
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 36 36'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.125 22.275C1.42337 22.275 1.70952 22.3935 1.9205 22.6045C2.13147 22.8155 2.25 23.1016 2.25 23.4V29.025C2.25 29.6217 2.48705 30.194 2.90901 30.616C3.33097 31.0379 3.90326 31.275 4.5 31.275H31.5C32.0967 31.275 32.669 31.0379 33.091 30.616C33.5129 30.194 33.75 29.6217 33.75 29.025V23.4C33.75 23.1016 33.8685 22.8155 34.0795 22.6045C34.2905 22.3935 34.5766 22.275 34.875 22.275C35.1734 22.275 35.4595 22.3935 35.6705 22.6045C35.8815 22.8155 36 23.1016 36 23.4V29.025C36 30.2185 35.5259 31.3631 34.682 32.207C33.8381 33.0509 32.6935 33.525 31.5 33.525H4.5C3.30653 33.525 2.16193 33.0509 1.31802 32.207C0.474106 31.3631 0 30.2185 0 29.025V23.4C0 23.1016 0.118526 22.8155 0.329505 22.6045C0.540483 22.3935 0.826631 22.275 1.125 22.275Z'
                    fill='#4FC8E9'
                  />
                  <path
                    d='M17.2039 2.5785C17.3084 2.47373 17.4326 2.39061 17.5693 2.33389C17.7059 2.27718 17.8525 2.24799 18.0004 2.24799C18.1484 2.24799 18.2949 2.27718 18.4316 2.33389C18.5683 2.39061 18.6924 2.47373 18.7969 2.5785L25.5469 9.3285C25.7582 9.53974 25.8769 9.82625 25.8769 10.125C25.8769 10.4237 25.7582 10.7103 25.5469 10.9215C25.3357 11.1327 25.0492 11.2514 24.7504 11.2514C24.4517 11.2514 24.1652 11.1327 23.9539 10.9215L19.1254 6.09075V25.875C19.1254 26.1734 19.0069 26.4595 18.7959 26.6705C18.585 26.8815 18.2988 27 18.0004 27C17.7021 27 17.4159 26.8815 17.205 26.6705C16.994 26.4595 16.8754 26.1734 16.8754 25.875V6.09075L12.0469 10.9215C11.9423 11.0261 11.8182 11.1091 11.6815 11.1657C11.5448 11.2223 11.3984 11.2514 11.2504 11.2514C11.1025 11.2514 10.956 11.2223 10.8194 11.1657C10.6827 11.1091 10.5585 11.0261 10.4539 10.9215C10.3493 10.8169 10.2664 10.6927 10.2098 10.5561C10.1532 10.4194 10.124 10.2729 10.124 10.125C10.124 9.97707 10.1532 9.8306 10.2098 9.69393C10.2664 9.55727 10.3493 9.4331 10.4539 9.3285L17.2039 2.5785Z'
                    fill='#4FC8E9'
                  />
                </svg>
                <div>
                  {selectedFiles.length > 0
                    ? // selectedFiles[0].name
                      Array.from(selectedFiles).map(
                        (e) => e.name.substring(0, 5) + '.., '
                      )
                    : //   selectedFiles.map((e) => <span> {e.name} </span>)
                      'Upload Photos'}
                </div>
              </div>
              <input
                // required
                type='file'
                name='UserDriverLicense'
                id='UserDriverLicense'
                hidden
                multiple='multiple'
                onChange={(e) => setSelectedFiles(e.target.files)}
              />
            </Button>
            <button type='submit'> Submit Request </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
