import React, { createContext, useContext, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { AuthContext } from "./AuthContext";
import { Socket_URL, subfix_domain } from '../api';

const DefaultProps = {
  messageData: {},
  connectionStatus: "Uninstantiated",
};

export const NotificationContext =
  createContext(DefaultProps);

export const NotificationContextProvider = ({
  children,
}) => {
  const { user } = useContext(AuthContext);
  const [messageData, setMessageData] = useState({});
  // const [serverTime, setServerTime] = useState();

  const { readyState, sendJsonMessage } = useWebSocket(Socket_URL + `chats/`, {
      queryParams: {
        token: user ? user.token : '',
        subfix_domain,
      },
      onOpen: () => {
        console.log('Connected!');
      },
      onClose: () => {
        console.log('Disconnected!');
      },
      onMessage: (e) => {
        const data = JSON.parse(e.data);
        console.log('notification', data)
        setMessageData(data);
      },
    }
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  return (
    <NotificationContext.Provider
      value={{ messageData, connectionStatus, sendJsonMessage }}
    >
      {children}
    </NotificationContext.Provider>
  );
};