import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MobileNavbar from '../../components/MobileNavbar';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import './index.css';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useSelector, useDispatch } from 'react-redux';
import { tripSelector, setTrip } from '../../reducers/tripSlice';
// import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { VEHICLE_STATUS } from '../../constants';
import { update_vehicle } from '../../API/vehicle';
import { Button } from '@mui/material';
import {
  update_trip_information,
  create_trip_information,
  create_trip_image,
  delete_trip_image,
} from '../../API/trips_management';
import { get_car_booking, update_booking } from '../../API/car_booking';
import { AuthContext } from '../../contexts/AuthContext';
import Reimbursement from '../../components/Reimbursement';
import moment from 'moment';
import { format } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';

export default function TripInformation() {
  const { authAxios } = useContext(AuthContext);
  const navigate = useNavigate();
  const MAX_PHOTO_LENGTH = 4;

  const [loading, setLoading] = useState(false);

  const [before_trip_fuel, setBefore_trip_fuel] = useState(null);
  const [before_trip_odometer, setBefore_trip_odometer] = useState();
  const [before_trip_odometer_image, setBefore_trip_odometer_image] =
    useState();
  const [before_trip_odometer_image_url, setBefore_trip_odometer_image_url] =
    useState();
  const [before_trip_status, setBefore_trip_status] = useState('');

  const [after_trip_fuel, setAfter_trip_fuel] = useState([]);
  const [after_trip_odometer, setAfter_trip_odometer] = useState([]);
  const [after_trip_odometer_image, setAfter_trip_odometer_image] = useState();
  const [after_trip_odometer_image_url, setAfter_trip_odometer_image_url] =
    useState();
  const [after_trip_status, setAfter_trip_status] = useState('');

  const [before_trip_photos, setBefore_trip_photos] = useState([]);
  const [after_trip_photos, setAfter_trip_photos] = useState([]);

  const [milesIn, setMilesIn] = useState([]);
  const [milesOut, setMilesOut] = useState([]);
  const [reimbursementModal, setReimbursementModal] = useState(false);

  const [before_status, setBeforeStatus] = useState('prepped');
  const [after_status, setAfterStatus] = useState('prepped');

  const today = format(new Date(), 'yyyy-MM-dd');

  const { trip } = useSelector(tripSelector);
  const dispatch = useDispatch();

  // console.log(before_trip_fuel + ' - ' + after_trip_fuel);
  // console.log(before_trip_fuel - after_trip_fuel);

  const tripEndDate = format(new Date(trip.end_date), 'yyyy-MM-dd');
  const endTime = moment(trip.end_date).format('HH:MM');
  const startTime = moment(trip.start_date).format('HH:MM');
  const used_miles = Number(after_trip_odometer - before_trip_odometer);
  const days =
    moment(trip.end_date).diff(moment(trip.start_date), 'days') +
    (endTime > startTime ? 1 : 0);

  const get_allowed_miles = () => {
    if (days >= 30) {
      return Number((trip.car?.monthly_max_miles * days) / 30);
    } else if (days >= 7) {
      return Number((trip.car?.weekly_max_miles * days) / 7);
    } else return Number(trip.car?.daily_max_miles * days);
  };
  const allowed_miles = get_allowed_miles();

  const handleChange = async (e, index, info_type, id) => {
    e.preventDefault();

    const imageFile = e.target.files[0];
    const trip_info_id = trip.trip_informations?.find(
      (item) => item.info_type == info_type
    )?.id;

    // updated
    if (trip_info_id) {
      let res = await delete_trip_image(authAxios, id || 0);
      if (res.status === 204 || res.response.data?.detail == 'Not found.') {
        const formData = new FormData();
        formData.append('image', imageFile);
        formData.append('trip_info', trip_info_id);

        res = await create_trip_image(authAxios, formData);

        if (res.status === 201) {
          res = await get_car_booking(authAxios, trip.id);
          if (res.status === 200) {
            dispatch(setTrip(res.data));
          }
        } else {
          if (res.response.data?.detail != 'Not found.') {
            toast.error(Object.values(res.response.data)[0]);
          }
        }
      }
    }
    // created
    else {
      if (info_type == 'beforeTrip') {
        setBefore_trip_photos(
          before_trip_photos.map((photo, index_) => {
            if (index_ == index) {
              return { ...photo, imageFile: imageFile };
            } else return photo;
          })
        );
      } else {
        setAfter_trip_photos(
          after_trip_photos.map((photo, index_) => {
            if (index_ == index) {
              return { ...photo, imageFile: imageFile };
            } else return photo;
          })
        );
      }
    }
  };

  const handleSave = async (e, info_type) => {
    e.preventDefault();
    // console.log(!before_trip_odometer_image_url);

    if (info_type === 'beforeTrip' && !before_trip_fuel) {
      toast.error('Please add a Fuel Level');
      return;
    }
    if (info_type === 'beforeTrip' && !before_trip_odometer) {
      toast.error('Please add an Odometer Reading');
      return;
    }
    if (
      info_type === 'beforeTrip' &&
      !before_trip_odometer_image_url &&
      !before_trip_odometer_image
    ) {
      toast.error('Please add an Odometer Image');
      return;
    }

    if (info_type === 'afterTrip' && !(today >= tripEndDate)) {
      toast.error(
        '‘After Trip’ information can only be filled following the completion of the trip'
      );
      return;
    }
    if (info_type === 'afterTrip' && !after_trip_fuel) {
      toast.error('Please add a Fuel Level');
      return;
    }
    if (info_type === 'afterTrip' && !after_trip_odometer) {
      toast.error('Please add an Odometer Reading');
      return;
    }
    if (
      info_type === 'afterTrip' &&
      !after_trip_odometer_image_url &&
      !after_trip_odometer_image
    ) {
      toast.error('Please add an Odometer Image');
      return;
    }

    // if (new Date(trip.end_date).getTime() > new Date().getTime() && info_type == 'afterTrip') {
    //   toast.error('These should only be able to be edited/added on or after the last day of the rental.');
    //   return;
    // }

    if (info_type == 'afterTrip') {
      return confirmAlert({
        overlayClassName: 'errorModal',
        customUI: ({ onClose }) => {
          return (
            <div
              className='confModal'
              style={{
                height: '120px',
                marginTop: '50%',
              }}
            >
              <p>{'Would you like to mark this trip as complete?'}</p>
              <div className='btns'>
                <button
                  onClick={() => {
                    onClose();
                    handleConfirm(info_type);
                  }}
                  className='secondary'
                  style={{ width: '120px' }}
                >
                  No
                </button>
                {
                  <button
                    className='primary'
                    onClick={() => {
                      onClose();
                      handleCompleteConfirm(info_type);
                    }}
                    style={{ width: '120px' }}
                  >
                    Yes
                  </button>
                }
              </div>
            </div>
          );
        },
      });
    } else handleConfirm(info_type);
  };

  const handleCompleteConfirm = async (info_type) => {
    setLoading(true);
    let res = await update_booking(authAxios, trip.id, {
      completed: true,
    });
    if (res.status === 200) {
      await handleConfirm(info_type);
    } else {
      setLoading(false);
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  const handleConfirm = async (info_type) => {
    setLoading(true);
    const trip_info_id = trip.trip_informations?.find(
      (item) => item.info_type == info_type
    )?.id;

    const formData = new FormData();
    formData.append('car_booking', trip.id);
    formData.append('trip_info_id', trip_info_id);
    formData.append('info_type', info_type);
    formData.append(
      'fuel_used',
      info_type == 'beforeTrip'
        ? before_trip_fuel.toFixed(3)
        : after_trip_fuel.toFixed(3)
    );
    formData.append(
      'odometer_reading',
      info_type == 'beforeTrip' ? before_trip_odometer : after_trip_odometer
    );
    formData.append(
      'status',
      info_type == 'beforeTrip' ? before_trip_status : after_trip_status
    );
    formData.append(
      'car_status',
      info_type == 'beforeTrip' ? before_status : after_status
    );

    const image =
      info_type == 'beforeTrip'
        ? before_trip_odometer_image
        : after_trip_odometer_image;
    if (image) {
      formData.append('odometer_image', image);
    }

    const payload = {
      vehicle_status: info_type == 'beforeTrip' ? before_status : after_status,
      status_description: before_trip_status + '\n' + after_trip_status,
    };

    if (payload.vehicle_status) {
      await update_vehicle(authAxios, trip?.car?.id, payload);
    }

    // updated
    if (trip_info_id) {
      let res = await update_trip_information(
        authAxios,
        formData,
        trip_info_id
      );
      if (res.status === 200) {
        res = await get_car_booking(authAxios, trip.id);
        if (res.status === 200) {
          setLoading(false);
          dispatch(setTrip(res.data));
          toast.success('Saved Successfully');
        }
      } else {
        setLoading(false);
        if (res.response.data?.detail != 'Not found.') {
          toast.error(Object.values(res.response.data)[0]);
        }
      }
    }
    // created
    else {
      const photos =
        info_type == 'beforeTrip' ? before_trip_photos : after_trip_photos;
      for (let photo of photos) {
        if (photo.imageFile) formData.append('images', photo.imageFile);
      }

      let res = await create_trip_information(authAxios, formData);
      if (res.status === 201) {
        res = await get_car_booking(authAxios, trip.id);
        if (res.status === 200) {
          setLoading(false);
          toast.success('Saved Successfully!');
          dispatch(setTrip(res.data));
        }
      } else {
        setLoading(false);
        if (res.response.data?.detail != 'Not found.') {
          toast.error(Object.values(res.response.data)[0]);
        }
      }
    }
  };

  const getPhotoHolder = (position) => {
    const images = trip.trip_informations?.find(
      (item) => item.info_type == position
    )?.trip_images;
    const length = images?.length || 0;
    let photos =
      length > 0
        ? images.map((image, index) => ({
            ...image,
            index: index,
            info_type: position,
          }))
        : [];
    for (let i = 0; i < MAX_PHOTO_LENGTH - length; i++) {
      photos = photos.concat([
        { id: 0, index: i, image: null, info_type: position },
      ]);
    }
    return photos;
  };

  // const handleMilesSave = async () => {
  //   const formData = new FormData();
  //   let res = await update_booking(authAxios, trip.id, {miles_in: milesIn, miles_out: milesOut});
  //   if (res.status === 200) {
  //     res = await get_car_booking(authAxios, trip.id);
  //     if (res.status === 200) {
  //       dispatch(setTrip(res.data));
  //     }
  //   }
  // }

  // const getReimbursementRow = (row) => {
  //   return `damage: ${row.damage}, fuel_usage: ${row.fuel_usage}, mileage_usage: ${row.mileage_usage}, invoice: ${row.amount}, message: ${row.message}`;
  // };

  // const getTripReimbursements = async () => {
  //   let res = await get_trip_reimbursements(authAxios, trip.id);
  //   if (res.status === 200) {
  //     if (!before_trip_status) {
  //       setBefore_trip_status(
  //         res.data.before_reimbursements
  //           .map((row) => getReimbursementRow(row))
  //           .join('\n')
  //       );
  //     }

  //     if (!after_trip_status) {
  //       setAfter_trip_status(
  //         res.data.before_reimbursements
  //           .concat(res.data.booking_reimbursements)
  //           .map((row) => getReimbursementRow(row))
  //           .join('\n')
  //       );
  //     }
  //   } else {
  //   }
  // };

  const getInitTripStatus = async () => {
    if (!before_trip_status) {
      setBefore_trip_status(trip.car.status_description);
    }
  };

  useEffect(() => {
    const before_trip_info = trip.trip_informations?.find(
      (item) => item.info_type == 'beforeTrip'
    );

    setBefore_trip_fuel(Number(before_trip_info?.fuel_used));
    setBefore_trip_odometer(before_trip_info?.odometer_reading);
    setBefore_trip_odometer_image_url(before_trip_info?.odometer_image);
    setBefore_trip_odometer_image();
    setBeforeStatus(before_trip_info?.car_status || 'prepped');

    const after_trip_info = trip.trip_informations?.find(
      (item) => item.info_type == 'afterTrip'
    );
    setAfter_trip_fuel(Number(after_trip_info?.fuel_used));
    setAfter_trip_odometer(after_trip_info?.odometer_reading);
    setAfter_trip_odometer_image_url(after_trip_info?.odometer_image);
    setAfter_trip_odometer_image();
    setAfterStatus(after_trip_info?.car_status || 'prepped');

    setBefore_trip_photos(getPhotoHolder('beforeTrip'));
    setAfter_trip_photos(getPhotoHolder('afterTrip'));

    setMilesIn(trip.miles_in);
    setMilesOut(trip.miles_out);

    if (before_trip_info && before_trip_info.status) {
      setBefore_trip_status(before_trip_info.status);
    }

    if (after_trip_info && after_trip_info.status) {
      setAfter_trip_status(after_trip_info.status);
    }
    // else getTripReimbursements();
    else getInitTripStatus();
  }, [trip]);

  return (
    <div className='dashboard page settings_page trip_inormation'>
      <Sidebar page='settings' />
      <div className='hero_content'>
        {/* <MobileNavbar /> */}
        <MobileNavbar />
        <Header />
        {trip && trip.car && (
          <div className='pagewrapper'>
            <div className='booking_wrapper'>
              <div className='go_back' onClick={() => navigate(-1)}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  viewBox='0 0 20 20'
                  fill='#46a8c8'
                  width={30}
                >
                  <path
                    fillRule='evenodd'
                    d='M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z'
                    clipRule='evenodd'
                  />
                </svg>
              </div>
              <div className='booking_header'>
                <h4>Trip Information</h4>
                <div>
                  <h4>
                    Vehicle Code:{' '}
                    <span
                      style={{
                        fontSize: '22px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        color: '#4fc8e9',
                      }}
                    >
                      {trip.car.title}
                    </span>
                  </h4>
                  <h4>
                    <span
                      style={{
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        color: '#4fc8e9',
                        float: 'right',
                      }}
                    >
                      {trip.customer.name}
                    </span>
                  </h4>
                </div>
              </div>
              <div className='settings_body'>
                {/* <form className='before_trip'>
                <h4>Miles</h4>
                <div className='info_row '>
                  <div className='field'>
                    Miles In
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        className='airport_name'
                        labelid='size'
                        id='demo-simple-select'
                        value={milesIn}
                        label='Enter Miles In'
                        onChange={(e) => setMilesIn(e.target.value)}
                      ></TextField>
                    </FormControl>
                  </div>
                  <div className='field'>
                    Miles Out
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        className='airport_name'
                        labelid='size'
                        id='demo-simple-select'
                        value={milesOut}
                        label='Enter Miles Out'
                        onChange={(e) => setMilesOut(e.target.value)}
                      ></TextField>
                    </FormControl>
                  </div>
                </div>
                <div className='confirmation_btn'>
                  <button
                    type='submit'
                    onClick={(e) => {e.preventDefault(); handleMilesSave();}}
                    // onClick={(e) => handleSave(e, 'beforeTrip')}
                  >
                    {' '}
                    Save{' '}
                  </button>
                </div>
              </form> */}
                <form
                  className='before_trip'
                  // onSubmit={(e) => handleSave(e, 'beforeTrip')}
                >
                  <h4>Before Trip</h4>
                  <div className='info_row '>
                    <div className='field'>
                      Fuel Level
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        {/* <InputLabel id='airport_name'>Select Fuel</InputLabel> */}
                        <Select
                          className='airport_name'
                          // required
                          labelid='mileage'
                          id='mileage'
                          value={before_trip_fuel}
                          label='Select Fuel'
                          onChange={(e) => setBefore_trip_fuel(e.target.value)}
                        >
                          <MenuItem
                            className='airport_name_menu_item'
                            value={0.125}
                          >
                            1/8
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={0.25}
                          >
                            2/8
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={0.375}
                          >
                            3/8
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={0.5}
                          >
                            4/8
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={0.625}
                          >
                            5/8
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={0.75}
                          >
                            6/8
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={0.875}
                          >
                            7/8
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={1}
                          >
                            8/8
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className='field'>
                      Odometer Reading
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <TextField
                          required
                          className='airport_name'
                          labelid='size'
                          id='demo-simple-select'
                          value={before_trip_odometer}
                          placeholder='Enter Odometer Reading'
                          onChange={(e) =>
                            setBefore_trip_odometer(e.target.value)
                          }
                        ></TextField>
                      </FormControl>
                    </div>
                  </div>
                  <div className='info_row '>
                    <div className='field'>
                      Odometer/Fuel Image
                      <div className='uploadBtn'>
                        <Button
                          // required
                          className='user_upload_btn'
                          variant='contained'
                          component='label'
                          style={{ padding: '1rem !important' }}
                        >
                          {before_trip_odometer_image_url ? (
                            <>
                              <div
                                className='removeImageIcon'
                                onClick={() => (
                                  setBefore_trip_odometer_image_url(null),
                                  setBefore_trip_odometer_image(null)
                                )}
                              >
                                <svg
                                  width='20'
                                  height='20'
                                  viewBox='0 0 110 110'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                                    stroke='#FF2552'
                                    strokeWidth='11.625'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <path
                                    d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                                    stroke='#FF2552'
                                    strokeWidth='11.625'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                </svg>
                              </div>
                              <img
                                src={before_trip_odometer_image_url}
                                alt={before_trip_odometer_image?.name}
                                style={{ maxWidth: '200px', height: '75px' }}
                              />
                            </>
                          ) : before_trip_odometer_image ? (
                            <>
                              <div
                                className='removeImageIcon'
                                onClick={() =>
                                  setBefore_trip_odometer_image(null)
                                }
                              >
                                <svg
                                  width='20'
                                  height='20'
                                  viewBox='0 0 110 110'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                                    stroke='#FF2552'
                                    strokeWidth='11.625'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <path
                                    d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                                    stroke='#FF2552'
                                    strokeWidth='11.625'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                </svg>
                              </div>
                              <img
                                src={URL.createObjectURL(
                                  before_trip_odometer_image
                                )}
                                alt={before_trip_odometer_image.name.substring(
                                  0,
                                  5
                                )}
                                style={{ maxWidth: '200px', height: '75px' }}
                              />
                            </>
                          ) : (
                            <>
                              <svg
                                width='36'
                                height='36'
                                viewBox='0 0 36 36'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M1.125 22.275C1.42337 22.275 1.70952 22.3935 1.9205 22.6045C2.13147 22.8155 2.25 23.1016 2.25 23.4V29.025C2.25 29.6217 2.48705 30.194 2.90901 30.616C3.33097 31.0379 3.90326 31.275 4.5 31.275H31.5C32.0967 31.275 32.669 31.0379 33.091 30.616C33.5129 30.194 33.75 29.6217 33.75 29.025V23.4C33.75 23.1016 33.8685 22.8155 34.0795 22.6045C34.2905 22.3935 34.5766 22.275 34.875 22.275C35.1734 22.275 35.4595 22.3935 35.6705 22.6045C35.8815 22.8155 36 23.1016 36 23.4V29.025C36 30.2185 35.5259 31.3631 34.682 32.207C33.8381 33.0509 32.6935 33.525 31.5 33.525H4.5C3.30653 33.525 2.16193 33.0509 1.31802 32.207C0.474106 31.3631 0 30.2185 0 29.025V23.4C0 23.1016 0.118526 22.8155 0.329505 22.6045C0.540483 22.3935 0.826631 22.275 1.125 22.275Z'
                                  fill='#4FC8E9'
                                />
                                <path
                                  d='M17.2039 2.5785C17.3084 2.47373 17.4326 2.39061 17.5693 2.33389C17.7059 2.27718 17.8525 2.24799 18.0004 2.24799C18.1484 2.24799 18.2949 2.27718 18.4316 2.33389C18.5683 2.39061 18.6924 2.47373 18.7969 2.5785L25.5469 9.3285C25.7582 9.53974 25.8769 9.82625 25.8769 10.125C25.8769 10.4237 25.7582 10.7103 25.5469 10.9215C25.3357 11.1327 25.0492 11.2514 24.7504 11.2514C24.4517 11.2514 24.1652 11.1327 23.9539 10.9215L19.1254 6.09075V25.875C19.1254 26.1734 19.0069 26.4595 18.7959 26.6705C18.585 26.8815 18.2988 27 18.0004 27C17.7021 27 17.4159 26.8815 17.205 26.6705C16.994 26.4595 16.8754 26.1734 16.8754 25.875V6.09075L12.0469 10.9215C11.9423 11.0261 11.8182 11.1091 11.6815 11.1657C11.5448 11.2223 11.3984 11.2514 11.2504 11.2514C11.1025 11.2514 10.956 11.2223 10.8194 11.1657C10.6827 11.1091 10.5585 11.0261 10.4539 10.9215C10.3493 10.8169 10.2664 10.6927 10.2098 10.5561C10.1532 10.4194 10.124 10.2729 10.124 10.125C10.124 9.97707 10.1532 9.8306 10.2098 9.69393C10.2664 9.55727 10.3493 9.4331 10.4539 9.3285L17.2039 2.5785Z'
                                  fill='#4FC8E9'
                                />
                              </svg>
                              Upload Odometer Image
                            </>
                          )}
                          <input
                            required
                            type='file'
                            name='UserDriverLicense'
                            id='UserDriverLicense'
                            hidden
                            disabled={
                              before_trip_odometer_image ||
                              before_trip_odometer_image_url
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              setBefore_trip_odometer_image(e.target.files[0])
                            }
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <span style={{ fontSize: '12px' }}>Car Status Images</span>
                    <div className='body_row'>
                      {before_trip_photos?.map((photo, index) => (
                        <div className='uploadBtn' key={index}>
                          <Button
                            required
                            className='user_upload_btn'
                            variant='contained'
                            component='label'
                          >
                            {photo.image ? (
                              <>
                                <div
                                  className='removeImageIcon'
                                  onClick={() =>
                                    setBefore_trip_photos(
                                      before_trip_photos.map((e, i) =>
                                        i === index
                                          ? {
                                              id: e.id,
                                              index: e.index,
                                              image: null,
                                              imageFile: null,
                                              info_type: e.info_type,
                                            }
                                          : e
                                      )
                                    )
                                  }
                                  style={{
                                    position: 'absolute',
                                    right: '1rem',
                                    top: '8px',
                                  }}
                                >
                                  <svg
                                    width='20'
                                    height='20'
                                    viewBox='0 0 110 110'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                                      stroke='#FF2552'
                                      strokeWidth='11.625'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <path
                                      d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                                      stroke='#FF2552'
                                      strokeWidth='11.625'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                  </svg>
                                </div>
                                <img
                                  src={photo.image}
                                  alt={'status-images'}
                                  style={{ maxWidth: '142px', height: '75px' }}
                                />
                              </>
                            ) : photo.imageFile ? (
                              <>
                                <div
                                  className='removeImageIcon'
                                  onClick={() =>
                                    setBefore_trip_photos(
                                      before_trip_photos.map((e, i) =>
                                        i === index
                                          ? {
                                              id: e.id,
                                              index: e.index,
                                              image: e.image,
                                              imageFile: null,
                                              info_type: e.info_type,
                                            }
                                          : e
                                      )
                                    )
                                  }
                                  style={{
                                    position: 'absolute',
                                    right: '1rem',
                                    top: '8px',
                                  }}
                                >
                                  <svg
                                    width='20'
                                    height='20'
                                    viewBox='0 0 110 110'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                                      stroke='#FF2552'
                                      strokeWidth='11.625'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <path
                                      d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                                      stroke='#FF2552'
                                      strokeWidth='11.625'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                  </svg>
                                </div>
                                <img
                                  src={URL.createObjectURL(photo.imageFile)}
                                  alt={photo.imageFile.name.substring(0, 5)}
                                  style={{ maxWidth: '142px', height: '75px' }}
                                />
                              </>
                            ) : (
                              <>
                                <svg
                                  width='36'
                                  height='36'
                                  viewBox='0 0 36 36'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M1.125 22.275C1.42337 22.275 1.70952 22.3935 1.9205 22.6045C2.13147 22.8155 2.25 23.1016 2.25 23.4V29.025C2.25 29.6217 2.48705 30.194 2.90901 30.616C3.33097 31.0379 3.90326 31.275 4.5 31.275H31.5C32.0967 31.275 32.669 31.0379 33.091 30.616C33.5129 30.194 33.75 29.6217 33.75 29.025V23.4C33.75 23.1016 33.8685 22.8155 34.0795 22.6045C34.2905 22.3935 34.5766 22.275 34.875 22.275C35.1734 22.275 35.4595 22.3935 35.6705 22.6045C35.8815 22.8155 36 23.1016 36 23.4V29.025C36 30.2185 35.5259 31.3631 34.682 32.207C33.8381 33.0509 32.6935 33.525 31.5 33.525H4.5C3.30653 33.525 2.16193 33.0509 1.31802 32.207C0.474106 31.3631 0 30.2185 0 29.025V23.4C0 23.1016 0.118526 22.8155 0.329505 22.6045C0.540483 22.3935 0.826631 22.275 1.125 22.275Z'
                                    fill='#4FC8E9'
                                  />
                                  <path
                                    d='M17.2039 2.5785C17.3084 2.47373 17.4326 2.39061 17.5693 2.33389C17.7059 2.27718 17.8525 2.24799 18.0004 2.24799C18.1484 2.24799 18.2949 2.27718 18.4316 2.33389C18.5683 2.39061 18.6924 2.47373 18.7969 2.5785L25.5469 9.3285C25.7582 9.53974 25.8769 9.82625 25.8769 10.125C25.8769 10.4237 25.7582 10.7103 25.5469 10.9215C25.3357 11.1327 25.0492 11.2514 24.7504 11.2514C24.4517 11.2514 24.1652 11.1327 23.9539 10.9215L19.1254 6.09075V25.875C19.1254 26.1734 19.0069 26.4595 18.7959 26.6705C18.585 26.8815 18.2988 27 18.0004 27C17.7021 27 17.4159 26.8815 17.205 26.6705C16.994 26.4595 16.8754 26.1734 16.8754 25.875V6.09075L12.0469 10.9215C11.9423 11.0261 11.8182 11.1091 11.6815 11.1657C11.5448 11.2223 11.3984 11.2514 11.2504 11.2514C11.1025 11.2514 10.956 11.2223 10.8194 11.1657C10.6827 11.1091 10.5585 11.0261 10.4539 10.9215C10.3493 10.8169 10.2664 10.6927 10.2098 10.5561C10.1532 10.4194 10.124 10.2729 10.124 10.125C10.124 9.97707 10.1532 9.8306 10.2098 9.69393C10.2664 9.55727 10.3493 9.4331 10.4539 9.3285L17.2039 2.5785Z'
                                    fill='#4FC8E9'
                                  />
                                </svg>
                                Upload Image
                              </>
                            )}
                            <input
                              required
                              type='file'
                              name='UserDriverLicense'
                              id='UserDriverLicense'
                              hidden
                              disabled={
                                before_trip_photos &&
                                (before_trip_photos[index].imageFile ||
                                before_trip_photos[index].image
                                  ? true
                                  : false)
                              }
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  index,
                                  photo.info_type,
                                  photo.id
                                )
                              }
                            />
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='info_row '>
                    <div className='field'>
                      Car Status Notes
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <TextField
                          className='airport_name multiline'
                          labelid='size'
                          id='demo-simple-select'
                          multiline
                          value={before_trip_status}
                          onChange={(e) =>
                            setBefore_trip_status(e.target.value)
                          }
                        ></TextField>
                      </FormControl>
                    </div>
                  </div>
                  <div className='info_row '>
                    <div className='field'>
                      Car Status
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <InputLabel id='airport_name'>
                          Select Car Status
                        </InputLabel>
                        <Select
                          className={`airport_name`}
                          displayEmpty
                          value={before_status}
                          onChange={(e) => setBeforeStatus(e.target.value)}
                          inputProps={{ 'aria-label': 'Vehicle Status' }}
                        >
                          {VEHICLE_STATUS.map((status) => (
                            <MenuItem
                              className={
                                'airport_name_menu_item ' + status.name
                              }
                              value={status.name}
                            >
                              {status.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className='confirmation_btn'>
                    <button
                      type='submit'
                      disabled={loading}
                      onClick={(e) => handleSave(e, 'beforeTrip')}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '1rem',
                      }}
                    >
                      Save
                      {loading && (
                        <CircularProgress
                          sx={{
                            width: '20px !important',
                            height: '20px !important',
                          }}
                          color='primary'
                        />
                      )}
                    </button>
                  </div>
                </form>
                <form className='after_trip'>
                  <h4>After Trip</h4>

                  <div className='info_row '>
                    <div className='field'>
                      Fuel Level
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        {/* <InputLabel id='airport_name'>Select Fuel</InputLabel> */}
                        <Select
                          className='airport_name'
                          labelid='mileage'
                          id='mileage'
                          value={after_trip_fuel}
                          label='Select Fuel'
                          onChange={(e) => setAfter_trip_fuel(e.target.value)}
                        >
                          <MenuItem
                            className='airport_name_menu_item'
                            value={0.125}
                          >
                            1/8
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={0.25}
                          >
                            2/8
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={0.375}
                          >
                            3/8
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={0.5}
                          >
                            4/8
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={0.625}
                          >
                            5/8
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={0.75}
                          >
                            6/8
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={0.875}
                          >
                            7/8
                          </MenuItem>
                          <MenuItem
                            className='airport_name_menu_item'
                            value={1}
                          >
                            8/8
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className='field'>
                      Odometer Reading
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <TextField
                          className='airport_name'
                          labelid='size'
                          id='demo-simple-select'
                          value={after_trip_odometer}
                          onChange={(e) =>
                            setAfter_trip_odometer(e.target.value)
                          }
                        ></TextField>
                      </FormControl>
                    </div>
                  </div>
                  <div className='info_row '>
                    <div className='field'>
                      Odometer/Fuel Image
                      <div className='uploadBtn'>
                        <Button
                          required
                          className='user_upload_btn'
                          variant='contained'
                          component='label'
                        >
                          {after_trip_odometer_image_url ? (
                            <>
                              <div
                                className='removeImageIcon'
                                onClick={() => (
                                  setAfter_trip_odometer_image_url(null),
                                  setAfter_trip_odometer_image(null)
                                )}
                              >
                                <svg
                                  width='20'
                                  height='20'
                                  viewBox='0 0 110 110'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                                    stroke='#FF2552'
                                    strokeWidth='11.625'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <path
                                    d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                                    stroke='#FF2552'
                                    strokeWidth='11.625'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                </svg>
                              </div>
                              <img
                                src={after_trip_odometer_image_url}
                                alt={after_trip_odometer_image?.name}
                                style={{ maxWidth: '200px', height: '75px' }}
                              />
                            </>
                          ) : after_trip_odometer_image ? (
                            <>
                              <div
                                className='removeImageIcon'
                                onClick={() =>
                                  setAfter_trip_odometer_image(null)
                                }
                              >
                                <svg
                                  width='20'
                                  height='20'
                                  viewBox='0 0 110 110'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                                    stroke='#FF2552'
                                    strokeWidth='11.625'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <path
                                    d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                                    stroke='#FF2552'
                                    strokeWidth='11.625'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                </svg>
                              </div>
                              <img
                                src={URL.createObjectURL(
                                  after_trip_odometer_image
                                )}
                                alt={after_trip_odometer_image.name.substring(
                                  0,
                                  5
                                )}
                                style={{ maxWidth: '200px', height: '75px' }}
                              />
                            </>
                          ) : (
                            <>
                              <svg
                                width='36'
                                height='36'
                                viewBox='0 0 36 36'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M1.125 22.275C1.42337 22.275 1.70952 22.3935 1.9205 22.6045C2.13147 22.8155 2.25 23.1016 2.25 23.4V29.025C2.25 29.6217 2.48705 30.194 2.90901 30.616C3.33097 31.0379 3.90326 31.275 4.5 31.275H31.5C32.0967 31.275 32.669 31.0379 33.091 30.616C33.5129 30.194 33.75 29.6217 33.75 29.025V23.4C33.75 23.1016 33.8685 22.8155 34.0795 22.6045C34.2905 22.3935 34.5766 22.275 34.875 22.275C35.1734 22.275 35.4595 22.3935 35.6705 22.6045C35.8815 22.8155 36 23.1016 36 23.4V29.025C36 30.2185 35.5259 31.3631 34.682 32.207C33.8381 33.0509 32.6935 33.525 31.5 33.525H4.5C3.30653 33.525 2.16193 33.0509 1.31802 32.207C0.474106 31.3631 0 30.2185 0 29.025V23.4C0 23.1016 0.118526 22.8155 0.329505 22.6045C0.540483 22.3935 0.826631 22.275 1.125 22.275Z'
                                  fill='#4FC8E9'
                                />
                                <path
                                  d='M17.2039 2.5785C17.3084 2.47373 17.4326 2.39061 17.5693 2.33389C17.7059 2.27718 17.8525 2.24799 18.0004 2.24799C18.1484 2.24799 18.2949 2.27718 18.4316 2.33389C18.5683 2.39061 18.6924 2.47373 18.7969 2.5785L25.5469 9.3285C25.7582 9.53974 25.8769 9.82625 25.8769 10.125C25.8769 10.4237 25.7582 10.7103 25.5469 10.9215C25.3357 11.1327 25.0492 11.2514 24.7504 11.2514C24.4517 11.2514 24.1652 11.1327 23.9539 10.9215L19.1254 6.09075V25.875C19.1254 26.1734 19.0069 26.4595 18.7959 26.6705C18.585 26.8815 18.2988 27 18.0004 27C17.7021 27 17.4159 26.8815 17.205 26.6705C16.994 26.4595 16.8754 26.1734 16.8754 25.875V6.09075L12.0469 10.9215C11.9423 11.0261 11.8182 11.1091 11.6815 11.1657C11.5448 11.2223 11.3984 11.2514 11.2504 11.2514C11.1025 11.2514 10.956 11.2223 10.8194 11.1657C10.6827 11.1091 10.5585 11.0261 10.4539 10.9215C10.3493 10.8169 10.2664 10.6927 10.2098 10.5561C10.1532 10.4194 10.124 10.2729 10.124 10.125C10.124 9.97707 10.1532 9.8306 10.2098 9.69393C10.2664 9.55727 10.3493 9.4331 10.4539 9.3285L17.2039 2.5785Z'
                                  fill='#4FC8E9'
                                />
                              </svg>
                              Upload Odometer Image
                            </>
                          )}
                          <input
                            required
                            type='file'
                            name='UserDriverLicense'
                            id='UserDriverLicense'
                            hidden
                            disabled={
                              after_trip_odometer_image ||
                              after_trip_odometer_image_url
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              setAfter_trip_odometer_image(e.target.files[0])
                            }
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <span style={{ fontSize: '12px' }}>Car Status Images</span>
                    <div className='body_row'>
                      {after_trip_photos.map((photo, index) => (
                        <div className='uploadBtn' key={index}>
                          <Button
                            required
                            className='user_upload_btn'
                            variant='contained'
                            component='label'
                          >
                            {photo.image ? (
                              <>
                                <div
                                  className='removeImageIcon'
                                  onClick={() =>
                                    setAfter_trip_photos(
                                      after_trip_photos.map((e, i) =>
                                        i === index
                                          ? {
                                              id: e.id,
                                              index: e.index,
                                              image: null,
                                              imageFile: null,
                                              info_type: e.info_type,
                                            }
                                          : e
                                      )
                                    )
                                  }
                                  style={{
                                    position: 'absolute',
                                    right: '1rem',
                                    top: '8px',
                                  }}
                                >
                                  <svg
                                    width='20'
                                    height='20'
                                    viewBox='0 0 110 110'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                                      stroke='#FF2552'
                                      strokeWidth='11.625'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <path
                                      d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                                      stroke='#FF2552'
                                      strokeWidth='11.625'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                  </svg>
                                </div>
                                <img
                                  src={photo.image}
                                  alt={'status-images'}
                                  style={{ maxWidth: '142px', height: '75px' }}
                                />
                              </>
                            ) : photo.imageFile ? (
                              <>
                                <div
                                  className='removeImageIcon'
                                  onClick={() =>
                                    setAfter_trip_photos(
                                      after_trip_photos.map((e, i) =>
                                        i === index
                                          ? {
                                              id: e.id,
                                              index: e.index,
                                              image: e.image,
                                              imageFile: null,
                                              info_type: e.info_type,
                                            }
                                          : e
                                      )
                                    )
                                  }
                                  style={{
                                    position: 'absolute',
                                    right: '1rem',
                                    top: '8px',
                                  }}
                                >
                                  <svg
                                    width='20'
                                    height='20'
                                    viewBox='0 0 110 110'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                                      stroke='#FF2552'
                                      strokeWidth='11.625'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                    <path
                                      d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                                      stroke='#FF2552'
                                      strokeWidth='11.625'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                    />
                                  </svg>
                                </div>
                                <img
                                  src={URL.createObjectURL(photo.imageFile)}
                                  alt={photo.imageFile.name.substring(0, 5)}
                                  style={{ maxWidth: '142px', height: '75px' }}
                                />
                              </>
                            ) : (
                              <>
                                <svg
                                  width='36'
                                  height='36'
                                  viewBox='0 0 36 36'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M1.125 22.275C1.42337 22.275 1.70952 22.3935 1.9205 22.6045C2.13147 22.8155 2.25 23.1016 2.25 23.4V29.025C2.25 29.6217 2.48705 30.194 2.90901 30.616C3.33097 31.0379 3.90326 31.275 4.5 31.275H31.5C32.0967 31.275 32.669 31.0379 33.091 30.616C33.5129 30.194 33.75 29.6217 33.75 29.025V23.4C33.75 23.1016 33.8685 22.8155 34.0795 22.6045C34.2905 22.3935 34.5766 22.275 34.875 22.275C35.1734 22.275 35.4595 22.3935 35.6705 22.6045C35.8815 22.8155 36 23.1016 36 23.4V29.025C36 30.2185 35.5259 31.3631 34.682 32.207C33.8381 33.0509 32.6935 33.525 31.5 33.525H4.5C3.30653 33.525 2.16193 33.0509 1.31802 32.207C0.474106 31.3631 0 30.2185 0 29.025V23.4C0 23.1016 0.118526 22.8155 0.329505 22.6045C0.540483 22.3935 0.826631 22.275 1.125 22.275Z'
                                    fill='#4FC8E9'
                                  />
                                  <path
                                    d='M17.2039 2.5785C17.3084 2.47373 17.4326 2.39061 17.5693 2.33389C17.7059 2.27718 17.8525 2.24799 18.0004 2.24799C18.1484 2.24799 18.2949 2.27718 18.4316 2.33389C18.5683 2.39061 18.6924 2.47373 18.7969 2.5785L25.5469 9.3285C25.7582 9.53974 25.8769 9.82625 25.8769 10.125C25.8769 10.4237 25.7582 10.7103 25.5469 10.9215C25.3357 11.1327 25.0492 11.2514 24.7504 11.2514C24.4517 11.2514 24.1652 11.1327 23.9539 10.9215L19.1254 6.09075V25.875C19.1254 26.1734 19.0069 26.4595 18.7959 26.6705C18.585 26.8815 18.2988 27 18.0004 27C17.7021 27 17.4159 26.8815 17.205 26.6705C16.994 26.4595 16.8754 26.1734 16.8754 25.875V6.09075L12.0469 10.9215C11.9423 11.0261 11.8182 11.1091 11.6815 11.1657C11.5448 11.2223 11.3984 11.2514 11.2504 11.2514C11.1025 11.2514 10.956 11.2223 10.8194 11.1657C10.6827 11.1091 10.5585 11.0261 10.4539 10.9215C10.3493 10.8169 10.2664 10.6927 10.2098 10.5561C10.1532 10.4194 10.124 10.2729 10.124 10.125C10.124 9.97707 10.1532 9.8306 10.2098 9.69393C10.2664 9.55727 10.3493 9.4331 10.4539 9.3285L17.2039 2.5785Z'
                                    fill='#4FC8E9'
                                  />
                                </svg>
                                Upload Image
                              </>
                            )}
                            <input
                              required
                              type='file'
                              name='UserDriverLicense'
                              id='UserDriverLicense'
                              hidden
                              disabled={
                                after_trip_photos &&
                                (after_trip_photos[index].imageFile ||
                                after_trip_photos[index].image
                                  ? true
                                  : false)
                              }
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  index,
                                  photo.info_type,
                                  photo.id
                                )
                              }
                            />
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='info_row '>
                    <div className='field'>
                      Car Status Notes
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <TextField
                          className='airport_name multiline'
                          labelid='size'
                          id='demo-simple-select'
                          multiline
                          value={after_trip_status}
                          onChange={(e) => setAfter_trip_status(e.target.value)}
                        ></TextField>
                      </FormControl>
                    </div>
                  </div>
                  <div className='info_row '>
                    <div className='field'>
                      Car Status
                      <FormControl
                        className='edit_dropdown'
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <InputLabel id='airport_name'>
                          Select Car Status
                        </InputLabel>
                        <Select
                          className={`airport_name`}
                          value={after_status}
                          onChange={(e) => setAfterStatus(e.target.value)}
                          inputProps={{ 'aria-label': 'Vehicle Status' }}
                        >
                          {VEHICLE_STATUS.map((status) => (
                            <MenuItem
                              className={
                                'airport_name_menu_item ' + status.name
                              }
                              value={status.name}
                            >
                              {status.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className='confirmation_btn'>
                    <button
                      type='submit'
                      disabled={loading}
                      onClick={(e) => handleSave(e, 'afterTrip')}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '1rem',
                      }}
                    >
                      Save
                      {loading && (
                        <CircularProgress
                          sx={{
                            width: '20px !important',
                            height: '20px !important',
                          }}
                          color='primary'
                        />
                      )}
                    </button>
                  </div>
                </form>
                <div className='info_row '>
                  <div className='field'>
                    Miles Used
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        className={
                          'airport_name ' + used_miles > allowed_miles
                            ? 'red'
                            : ''
                        }
                        labelid='size'
                        id='demo-simple-select'
                        value={Number(used_miles).toFixed(2)}
                        disabled
                      ></TextField>
                    </FormControl>
                  </div>
                  <div className='field'>
                    Miles Allowed
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        className='airport_name'
                        labelid='size'
                        id='demo-simple-select'
                        value={Number(allowed_miles).toFixed(2)}
                        disabled
                      ></TextField>
                    </FormControl>
                  </div>
                </div>
                <a
                  className='reimbursement_link'
                  onClick={() => setReimbursementModal(true)}
                >
                  {' '}
                  + Request Reimbursement
                </a>
              </div>
            </div>
          </div>
        )}
        {trip && (
          <Reimbursement
            selectedTrips={trip}
            open={reimbursementModal}
            onClose={() => setReimbursementModal(false)}
            totalMiles={used_miles}
            totalFuelUsed={Number(before_trip_fuel) - Number(after_trip_fuel)}
          />
        )}
      </div>
    </div>
  );
}
