import { subfix_domain } from '../api';

const get_access_levels = async (authAxios) => {
	try {
		const res = await authAxios.get(`/api/access_levels/`, {params: {subfix_domain}});
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const create_access_level = async (authAxios, formData) => {
	formData.append('subfix_domain', subfix_domain)
	try {
		const res = await authAxios.post(`/api/access_levels/`, formData);
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const update_access_level = async (authAxios, formData) => {
	formData.append('subfix_domain', subfix_domain)
	try {
		const res = await authAxios.post(`/api/access_levels/access_levels_update/`, formData);
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const delete_access_level = async (authAxios, id) => {
	try {
		const res = await authAxios.delete(`/api/access_levels/${id}/`, {params: {subfix_domain}});
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};
  
export {
	get_access_levels,
	create_access_level,
	delete_access_level,
	update_access_level
}