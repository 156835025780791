import { subfix_domain } from '../api';

const get_conversations = async (authAxios) => {
	try {
		const res = await authAxios.get(`/api/chats/conversations/`, {params: {subfix_domain}});
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const get_conversation = async (authAxios, conversationName) => {
	try {
		const res = await authAxios.get(`/api/chats/conversations/${conversationName}/`, {params: {subfix_domain}});
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const get_messages = async (authAxios, conversationName, page) => {
	try {
		const res = await authAxios.get(`/api/chats/messages/list/`, {params: {conversation: conversationName, page, subfix_domain}});
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const create_conversation = async (authAxios, data) => {
	try {
		const res = await authAxios.post(`/api/chats/conversations/create/`, {...data, subfix_domain});
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const delete_conversation = async (authAxios, conversationName) => {
	try {
		const res = await authAxios.delete(`/api/chats/messages/delete/?conversation=${conversationName}`, {params: {subfix_domain}});
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

export { get_conversations, get_conversation, get_messages, create_conversation, delete_conversation };