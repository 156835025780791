import React, { useState } from 'react';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import PrevCustomerCard from '../../components/PrevCustomerCard';
import Pagination from '@mui/material/Pagination';
import usePagination from '../../hooks/usePagination';
import prevCustomerData from '../../fixtures/prevCustomerData.json';
import './index.css';

export default function PreviousCustomer() {
  let [page, setPage] = useState(1);
  const PER_PAGE = 8;

  const count = Math.ceil(prevCustomerData.length / PER_PAGE);
  const _DATA = usePagination(prevCustomerData, PER_PAGE);

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  return (
    <div className='dashboard page'>
      <Sidebar page='prev_customer' />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <div className='addPerson'>
            <h5>Previous Customers</h5>
            <div className='prev_customer_wrapper'>
              {_DATA.currentData().map((customer) => (
                <PrevCustomerCard customer={customer} />
              ))}
            </div>
            <Pagination
              className='cars_page_pagination'
              // variant='outlined'
              shape='circular'
              count={count}
              page={page}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
