import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import './index.css';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Carousel from 'react-material-ui-carousel';
import { get_car_books } from '../../API/car_booking';
import toast from 'react-hot-toast';
import moment from 'moment';
import { update_booking, cancel_booking } from '../../API/car_booking';
import { get_all_airlines, get_all_delivery_charges } from '../../API/setting';
import { useDispatch } from 'react-redux';
import { setTrip } from '../../reducers/tripSlice';
import { AuthContext } from '../../contexts/AuthContext';
import Checkbox from '@mui/material/Checkbox';
import Reimbursement from '../../components/Reimbursement';
import Notes from '../../components/TripNote';
import { clearState as clearBookingState } from '../../reducers/bookingSlice';
import { format } from 'date-fns';
import { Close } from '@mui/icons-material';
import { Backdrop, Button, Menu } from '@mui/material';
import WeekCalender from '../../components/WeekCalender/WeekCalender';
import dayjs from 'dayjs';

export default function TripManagement() {
  const { authAxios, user, canMutate } = useContext(AuthContext);
  const navigate = useNavigate();
  // const location = useLocation();
  // const searchString = location?.search?.slice(2).replaceAll('%20', ' ');

  const [filterTrips, setFilterTrips] = useState('');

  const [tripsData, setTripsData] = useState(null);
  const [selectedTrips, setSelectedTrips] = useState(null);
  const [reimbursementModal, setReimbursementModal] = useState(false);
  const [totalMiles, setTotalMiles] = useState(false);
  const [totalFuelUsed, setTotalFuelUsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [weekCalDate, setWeekCalDate] = useState(dayjs().startOf('week')) 

  // Edit Booking

  const [airports, setAirports] = useState([]);
  const [airlines, setAirlines] = useState([]);

  const dispatch = useDispatch();
  const { trip_id } = useParams();

  const Arrow = () => {
    return (
      <svg
        width='8'
        height='14'
        viewBox='0 0 8 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M-8.74228e-08 12L5 7L-5.24537e-07 2L0.999999 -4.37114e-08L8 7L1 14L-8.74228e-08 12Z'
          fill='#4FC8E9'
        />
      </svg>
    );
  };

  

  
  const default_params = {
    bookings_start_date: weekCalDate.startOf('week').format('MM-DD-YYYY'),
    bookings_end_date: weekCalDate.endOf('week').format('MM-DD-YYYY'),
  };

  const getTripsData = async (cb, params) => {
    let res = await get_car_books(authAxios, filterTrips, null, params);
    if (res.status === 200) {
      const data = res.data.map((trips) => {
        const endTime = moment(trips.end_date).format('HH:MM');
        const startTime = moment(trips.start_date).format('HH:MM');

        const getInterval = (fromDate, untilDate) => {
          // return intervalToDuration({
          //   start: fromDate,
          //   end: untilDate,
          // }).days;
      
          const start = new Date(fromDate);
          const end = new Date(untilDate);
          const diffInMs = end - start;
          const diffInHours = diffInMs / (1000 * 60 * 60);
          const totalDays = Math.ceil(diffInHours / 24);
      
          return totalDays;
        };

        const days = getInterval(trips.start_date, trips.end_date)
          // moment(trips.end_date).diff(moment(trips.start_date), 'days') +
          // (endTime > startTime ? 1 : 0);

      
          
        let carImages = trips.car.car_images.map(
          (car_image) => car_image.image
        );
        let pickupLocation = getAirportDetail(trips.pickup_location);
        let destinationLocation = getAirportDetail(trips.destination_location);
        return {
          ...trips,
          pickup_location: pickupLocation,
          destination_location: destinationLocation,
          booked_date: moment(trips.booked_date).format('MM/DD/YYYY'),
          days: days,
          carImages: carImages,
        };
      });
      setTripsData(data);
      setLoading(false)
      if (cb) cb(data);
    }
  };

  const handleWeekChange = (from, to) => {
    setLoading(true)
    setWeekCalDate(from);
    setTripsData(null);
    getTripsData(null, {
      bookings_start_date: from.format('MM-DD-YYYY'),
      bookings_end_date: to.format('MM-DD-YYYY'),
    });
  }

  useEffect(() => {
    getTripsData((data) => {
      if (trip_id && data && data.length > 0) {
        const trips = data.find((item) => item.id == trip_id);
        trips && TripModal(trips, authAxios, user);
      }
    }, default_params);
  }, []);

  useEffect(() => {
    getTripsData(null, default_params);
  }, [filterTrips, airports]);

  useEffect(() => {
    const getAllDeliveryCharges = async () => {
      let res = await get_all_delivery_charges(authAxios);
      if (res.status === 200) {
        setAirports(res.data);
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }
    };
    const getAirlines = async () => {
      let res = await get_all_airlines(authAxios);
      if (res.status === 200) {
        setAirlines(res.data);
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }
    };
    getAllDeliveryCharges();
    getAirlines();
  }, []);

  const getAirportDetail = (airportName) => {
    let airportDetail = airports.find(({ airport }) => airport === airportName);
    return airportDetail;
  };

  // useEffect(() => {
  //   const getUser = async () => {
  //     let res = await get_user(authAxios, selectedTrips.customer.id);
  //     if (res.status === 200) {
  //       setEmail(res.data.user.email);
  //     } else {
  //       toast.error(Object.values(res.response.data)[0]);
  //     }
  //   };

  //   if (selectedTrips?.customer?.id) getUser();
  // }, [selectedTrips, reimbursementModal]);

  const handleCompleteBooking = async (e, booking, checked) => {
    e.preventDefault();
    if (moment().diff(moment(booking.end_date)) < 0) {
      toast.error('This trip is in Progress.');
      return;
    }

    let res = await update_booking(authAxios, booking.id, {
      completed: !checked,
    });
    if (res.status === 200) {
      await getTripsData(null, default_params);
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  const haveMissingCustomerData = (trip) => {
    return !Object.values(trip.customer).some((v) => v === null || v === '');
  };

  const getInterval = (fromDate, untilDate) => {
    // return intervalToDuration({
    //   start: fromDate,
    //   end: untilDate,
    // }).days;

      const start = new Date(fromDate);
      const end = new Date(untilDate);
      const diffInMs = end - start;
      const diffInHours = diffInMs / (1000 * 60 * 60);
      const totalDays = Math.ceil(diffInHours / 24);
  
      return totalDays;
    
  };


  const TripModal = (trip) => {

    const interval = getInterval(
      new Date(trip.start_date),
      new Date(trip.end_date)
    );
    setSelectedTrips(trip);
    console.log("trip", trip);
    console.log('haveMissingCustomerData', haveMissingCustomerData(trip));

    const getCarPrice = () => {
      const today = format(new Date(), 'yyyy-MM-dd');
      const basePrice = trip.car?.daily_price;
      let dailyPrice = basePrice;

      if (trip.car?.custom_prices?.length == 0) {
        return Number(dailyPrice);
      } else {
        trip.car?.custom_prices?.forEach((customPrice) => {
          if (
            today >= customPrice.start_date &&
            today <= customPrice.end_date
          ) {
            dailyPrice = customPrice.price;
          }
        });
      }

      return Number(dailyPrice);
    };
    console.log(trip);

    return confirmAlert({
      
      overlayClassName: 'tripDetailModalWrapper',
      onClickOutside: () => navigate('/trips'),
      onKeypressEscape: () => navigate('/trips'),
      customUI: ({ onClose }) => {
        
        return (
          <div
            className='editModal tripDetailModal'
            style={{
              maxHeight: '830px',
              overflow: 'auto',
              position: 'relative',
            }}
          >
            <div
              className='close_icon'
              style={{ top: '1.5rem', right: '1.5rem' }}
              onClick={() => onClose()}
            >
              <svg
                width='25'
                height='25'
                viewBox='0 0 110 110'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
            <Carousel className='trip_modal_carousal'>
              {trip?.carImages.map((img, index) => (
                <img key={index} src={img} alt='img' width={'100%'} />
              ))}
            </Carousel>
            <div className='modal_detail_header'>
              <div>
                <div
                  onClick={() => {
                    onClose();
                    navigate(`/user_detail/${trip.customer.id}`);
                  }}
                  className='title'
                  style={{
                    fontSize: '22px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    color: '#4fc8e9',
                  }}
                >
                  {trip.customer.name}
                </div>
                <div style={{ fontSize: '18px' }}>
                  {formatPhoneNumber(trip.customer.phone)}
                  {/* Booked by{' '}<span style={{ color: '#4fc8e9' }}>{trip.booked_by}</span> */}
                </div>
                <div style={{ fontSize: '18px' }}>$ {trip.price}</div>

                <div>Daily Rate: $ {trip.daily_price}</div>
                <div>
                  Discount:{' '}
                  {`${trip.coupon_code ? trip.coupon_code + ' -' : ''} $ ${
                    trip.discount_price
                  }`}
                </div>
              </div>
              <div
                style={{
                  textAlign: 'right',
                }}
              >
                <div className='flex align-center gap-2'>
                  {canMutate('trips') && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: '1rem',
                      }}
                    >
                      <div
                        className='req_reimbursement'
                        onClick={() => {
                          if (trip.trip_informations.length > 1) {
                            let before_trip = trip.trip_informations.find((trip_information) => trip_information.info_type == "beforeTrip");
                            let after_trip = trip.trip_informations.find((trip_information) => trip_information.info_type == "afterTrip");
                            setTotalMiles(
                              Number(
                                after_trip.odometer_reading
                              ) -
                                Number(
                                  before_trip.odometer_reading
                                )
                            );
                            setTotalFuelUsed(
                              Number(before_trip.fuel_used) -
                                Number(after_trip.fuel_used)
                            );
                          }
                          setReimbursementModal(true);
                        }}
                      >
                        + Request Reimbursement
                      </div>
                      <div
                        className='req_reimbursement'
                        onClick={() => {
                          onClose();
                          dispatch(clearBookingState());
                          navigate(
                            `/edit_booking/${trip.customer.id}/${trip.id}`
                          );
                        }}
                        style={{ color: '#4fc8e9' }}
                      >
                        Edit
                      </div>
                    </div>
                  )}
                  <div className='clientInfoNotAvailable'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      width={28}
                      strokeWidth={2}
                      stroke='red'
                      className='w-6 h-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z'
                      />
                    </svg>
                  </div>
                </div>
                <div style={{ color: 'rgb(79, 200, 233)', fontSize: '18px' }}>
                  Outbound Location:
                  <span
                    style={{
                      color:
                        trip?.pickup_location?.color ||
                        // For bookings made before pickup_location was implemented
                        trip?.destination_location?.color,
                    }}
                  >
                    {` ${
                      trip?.pickup_location?.code ||
                      trip?.destination_location?.code
                    }`}
                  </span>
                </div>
                <div style={{ color: 'rgb(79, 200, 233)', fontSize: '18px' }}>
                  Return Location:
                  <span
                    style={{
                      color: trip?.destination_location?.color,
                    }}
                  >
                    {` ${trip?.destination_location?.code}`}
                  </span>
                </div>
                <div style={{ fontSize: '18px' }}>
                  <div>
                    From:{' '}
                    {moment(trip?.start_date).format('MMM DD, yyyy hh:mm A')}
                  </div>
                  <div>
                    Until:{' '}
                    {moment(trip?.end_date).format('MMM DD, yyyy hh:mm A')}
                  </div>
                </div>
                <div>Total Days: {interval === 0 ? 1 : interval}</div>
              </div>
            </div>
            <div className='tripDetail'>
              <div style={{ width: '60%' }} className='tripDetail_col'>
                <div>
                  Year/Make/Model/Trim:{' '}
                  <span>
                    {trip.car.year} {trip.car.make} {trip.car.model}{' '}
                    {trip.car.trim}
                  </span>
                </div>
                <div>
                  Airline: <span>{trip.airline_name}</span>
                </div>
                <div>
                  Miles Alloted:{' '}
                  <span>{trip.car.daily_max_miles * trip.days}</span>
                </div>
                <div>
                  Booked On:{' '}
                  <span>{moment(trip.booked_date).format('MM-DD-YYYY')}</span>
                </div>
              </div>
              <div style={{ width: '40%' }} className='tripDetail_col'>
                <div>
                  Car Id: <span>{trip.car.title}</span>
                </div>
                <div>
                  Flight #: <span>{trip.flight_number}</span>
                </div>
                <div>
                  Miles Overage:{' '}
                  <span>${trip.car.mileage_overage_fee}/mile</span>
                </div>
                <div>
                  Booked By: <span>{trip.booked_by}</span>
                </div>
              </div>
            </div>
            {canMutate('trips') && (
              <div className='trip_detail_actions'>
                <button
                  className='all_users_primary_button'
                  onClick={() => {
                    dispatch(setTrip(trip));
                    navigate('/trip_information');
                    onClose();
                  }}
                >
                  Track Mileage / Fuel Used
                </button>
                <button
                  className='all_users_secondary_button'
                  onClick={() => {
                    navigate('/messages');
                    onClose();
                  }}
                >
                  Message
                </button>
              </div>
            )}
            <div style={{ marginTop: '20px' }}>
              <Notes authAxios={authAxios} id={trip.id} user={user} />
            </div>
          </div>
        );
      },
    });
  };

  const DeleteBookingModal = (tripId) => {
    return confirmAlert({
      overlayClassName: 'tripDetailModalWrapper',
      customUI: ({ onClose }) => {
        return (
          <div className='confModal'>
            <svg
              width='110'
              height='110'
              viewBox='0 0 110 110'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                stroke='#FF2552'
                strokeWidth='11.625'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                stroke='#FF2552'
                strokeWidth='11.625'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>{' '}
            <br />
            <h4>Are You Sure?</h4>
            <p>
              Do you really want to delete this booking. This process cannot be
              undo.
            </p>
            <div className='btns'>
              <button onClick={() => onClose()} className='secondary'>
                Cancel
              </button>
              <button
                className='primary'
                onClick={(e) => deleteBooking(tripId, onClose)}
              >
                Delete
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const deleteBooking = async (tripId, onClose) => {
    const formData = new FormData();
    formData.append('booked_car', tripId);
    let res = await cancel_booking(authAxios, formData);
    if (res.status === 200 || res.status === 201) {
      setTripsData(null);
      getTripsData(null, default_params);
      onClose();
      toast.success('Trip Deleted Successfully ');
    } else {
      toast.error(Object.values(res.response.data)[0]);
      onClose();
    }
  };

   return (
    <div className='dashboard page'>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color='inherit' />
        </Backdrop> 
      <Sidebar page='trips' />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />
        <div className='editWrapper pagewrapper trip_management'>
          {canMutate('trips') && (
            <div className='trip_management_header'>
              <h4>Trip Management</h4>
              <button
                className='all_users_secondary_button'
                onClick={() => {
                  dispatch(clearBookingState());
                  navigate('/add_booking');
                }}
              >
                Add New Trip
              </button>
            </div>
          )}

          <div className='calender_type info_row'>
            <div className='field' style={{ display:'flex', justifyContent:'space-between' }}>
              <FormControl
                className=''
                sx={{ m: 1, width: '300px' }}
                style={{ margin: '0 0 1rem 0' }}
              >
                <InputLabel id='calender_type'>Filter Trips</InputLabel>
                <Select
                  className='calender_type_select'
                  labelid='calender_type'
                  id='calender_type'
                  defaultValue={filterTrips}
                  label='Calender Type'
                  onChange={(e) => setFilterTrips(e.target.value)}
                  IconComponent={Arrow}
                >
                  <MenuItem className='airport_name_menu_item' value={''}>
                    All
                  </MenuItem>
                  <MenuItem
                    className='airport_name_menu_item'
                    value={'today_bookings'}
                    timeGridDay
                  >
                    Current
                  </MenuItem>
                  <MenuItem
                    className='airport_name_menu_item'
                    value={'upcoming_bookings'}
                    timeGridDay
                  >
                    Future
                  </MenuItem>
                  <MenuItem
                    className='airport_name_menu_item'
                    value={'awaiting_payment'}
                    timeGridDay
                  >
                    Completed - Awaiting Payment
                  </MenuItem>
                  <MenuItem
                    className='airport_name_menu_item'
                    value={'completed_payment'}
                    timeGridDay
                  >
                    Completed - Payment Received
                  </MenuItem>
                </Select>
              </FormControl>
              <WeekSelectCalendar value={weekCalDate} handleChange={handleWeekChange} />
            </div>
          </div>
          <div className='editContainer'>
            <div className='section trips_table'>
              <table>
                <thead>
                  <th style={{ width: '12%' }}>Outbound/Return</th>
                  <th style={{ width: '15%' }}>Client Name</th>
                  <th style={{ width: '12%' }}>Vehicle Id</th>
                  <th style={{ width: '18%' }}>Vehicle Make/Model</th>
                  {/* <th>Price per day</th>
                  <th>Milage</th> */}
                  <th style={{ width: '18%' }}>Rental Period</th>
                  <th style={{ width: '12%' }}>Booked Date</th>
                  <th style={{ width: '10%' }}>Days</th>
                  {/* <th>Car Images</th> */}
                  {/* <th>Paid</th> */}
                  {canMutate('trips') && (
                    <th style={{ width: '5%' }}>Completed</th>
                  )}
                  {/* <th className='reimbursement_th'>Reimbursemen</th> */}
                  {canMutate('trips') && <th></th>}
                </thead>
                <tbody>
                  {tripsData ? (
                    tripsData?.length === 0 ? (
                      <div style={{ position: 'absolute', width: '100%', left: 0, textAlign:'center' }} >
                        No Trips in selected period
                      </div>
                    ) : tripsData.map((trips) => (
                        <tr key={trips.id}>
                          <td
                            style={{ width: '12%' }}
                            onClick={(e) => {
                              setReimbursementModal(false);
                              navigate(`/trips/${trips.id}`);
                              TripModal(trips, authAxios, user);
                            }}
                          >
                            <div>
                              {/* Outbound Location Code */}
                              <span
                                className='outboundCode'
                                style={{
                                  color:
                                    trips.pickup_location?.color ||
                                    // For bookings made before pickup_location was implemented
                                    trips.destination_location?.color,
                                }}
                              >
                                {trips.pickup_location?.code ||
                                  // For bookings made before pickup_location was implemented
                                  trips.destination_location?.code}
                              </span>
                              <span>&#8594;</span> {/* Right arrow */}
                              {/* Return Location Code */}
                              <span
                                className='returnCode'
                                style={{
                                  color: trips.destination_location?.color,
                                }}
                              >
                                {trips.destination_location?.code}
                              </span>
                            </div>
                          </td>
                          <td
                            style={{ width: '15%' }}
                            onClick={(e) => {
                              setReimbursementModal(false);
                              navigate(`/trips/${trips.id}`);
                              TripModal(trips, authAxios, user);
                            }}
                          >
                            <div>{trips.customer?.name}</div>
                          </td>
                          <td
                            style={{ width: '12%' }}
                            onClick={(e) => {
                              setReimbursementModal(false);
                              navigate(`/trips/${trips.id}`);
                              TripModal(trips, authAxios, user);
                            }}
                          >
                            <div>{trips.car.title}</div>
                          </td>
                          <td
                            style={{ width: '18%' }}
                            onClick={(e) => {
                              setReimbursementModal(false);
                              navigate(`/trips/${trips.id}`);
                              TripModal(trips, authAxios, user);
                            }}
                          >
                            <div>
                              {trips.car.year} {trips.car.make}{' '}
                              {trips.car.model} {trips.car.trim}
                            </div>
                          </td>
                          <td
                            style={{ width: '18%' }}
                            onClick={(e) => {
                              setReimbursementModal(false);
                              TripModal(trips, authAxios, user);
                            }}
                          >
                            <div>
                              {moment(
                                trips.start_date.replace('T', ' ').slice(0, 16)
                              ).format('MM/DD/YYYY hh:mm a')}{' '}
                              -{' '}
                              {moment(
                                trips.end_date.replace('T', ' ').slice(0, 16)
                              ).format('MM/DD/YYYY hh:mm a')}
                            </div>
                          </td>
                          <td
                            style={{ width: '12%' }}
                            onClick={(e) => {
                              setReimbursementModal(false);
                              navigate(`/trips/${trips.id}`);
                              TripModal(trips, authAxios, user);
                            }}
                          >
                            <div>
                              {/* {trips.booked_date}  */}
                              {moment(trips.booked_date).format('MM/DD/YYYY')}
                            </div>
                          </td>
                          <td
                            style={{ width: '10%' }}
                            onClick={(e) => {
                              setReimbursementModal(false);
                              navigate(`/trips/${trips.id}`);
                              TripModal(trips, authAxios, user);
                            }}
                          >
                            <div>{trips.days} Days</div>
                          </td>
                          {/* <td
                          className='car_images'
                          onClick={(e) => {
                            setReimbursementModal(false);
                            TripModal(trips, authAxios, user);
                          }}
                        >
                          <div>
                            <svg
                              className='h-6 w-6'
                              width={20}
                              fill='none'
                              viewBox='0 0 24 24'
                              stroke='#4FC8E9'
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M15 19l-7-7 7-7'
                              />
                            </svg>
                            <div className='images'>
                              {trips.carImages.map((img, index) => (
                                <img key={index} src={img} alt='car' />
                              ))}
                            </div>
                            <svg
                              className='h-6 w-6'
                              width={20}
                              fill='none'
                              viewBox='0 0 24 24'
                              stroke='#4FC8E9'
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M9 5l7 7-7 7'
                              />
                            </svg>
                          </div>
                        </td> */}
                          {/* <td>
                          <div>
                            <Checkbox
                              required
                              sx={{
                                color: '#4FC3E9',
                                // padding: '0',
                                '&.Mui-checked': {
                                  color: '#A0C3E9',
                                },
                              }}
                              checked={trips.paid && trips.reimbursement_paid}
                              value={trips.paid && trips.reimbursement_paid}
                            />
                          </div>
                        </td> */}
                          {canMutate('trips') && (
                            <td style={{ width: '5%' }}>
                              <div>
                                <Checkbox
                                  required
                                  sx={{
                                    color: '#8D94BE',
                                    // padding: '0',
                                    '&.Mui-checked': {
                                      color: '#4FC8E9',
                                    },
                                  }}
                                  name={'complete_checkbox'}
                                  checked={trips.completed}
                                  value={trips.completed}
                                  onClick={(e) =>
                                    handleCompleteBooking(
                                      e,
                                      trips,
                                      trips.completed
                                    )
                                  }
                                />
                              </div>
                            </td>
                          )}
                          {canMutate('trips') && (
                            <td className='menu'>
                              <div>
                                <span
                                  onClick={(e) => {
                                    e.preventDefault();
                                    DeleteBookingModal(trips.id);
                                  }}
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth={1.5}
                                    stroke='currentColor'
                                    className='w-6 h-6'
                                    width={20}
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                    />
                                  </svg>
                                </span>
                                <span
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate(
                                      '/user_detail/' + trips.customer.id
                                    );
                                  }}
                                  style={{ display: 'none' }}
                                >
                                  <svg
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M12 20C11.4696 20 10.9609 19.7893 10.5858 19.4142C10.2107 19.0391 10 18.5304 10 18C10 17.4696 10.2107 16.9609 10.5858 16.5858C10.9609 16.2107 11.4696 16 12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18C14 18.5304 13.7893 19.0391 13.4142 19.4142C13.0391 19.7893 12.5304 20 12 20ZM12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14ZM12 8C11.4696 8 10.9609 7.78929 10.5858 7.41421C10.2107 7.03914 10 6.53043 10 6C10 5.46957 10.2107 4.96086 10.5858 4.58579C10.9609 4.21071 11.4696 4 12 4C12.5304 4 13.0391 4.21071 13.4142 4.58579C13.7893 4.96086 14 5.46957 14 6C14 6.53043 13.7893 7.03914 13.4142 7.41421C13.0391 7.78929 12.5304 8 12 8Z'
                                      fill='white'
                                    />
                                  </svg>
                                </span>
                              </div>
                            </td>
                          )}
                        </tr>
                      ))
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Reimbursement
          selectedTrips={selectedTrips}
          open={reimbursementModal}
          totalMiles={totalMiles}
          totalFuelUsed={totalFuelUsed}
          onClose={() => setReimbursementModal(false)}
        />
      </div>
    </div>
  );
}



const WeekSelectCalendar = ({value, handleChange}) => {
  // console.log('🚀OUTPUT --> currentWeek:', currentWeek);
  const [anchorEl, setAnchorEl] = useState(null);

  const openWeekSelect = Boolean(anchorEl);

  const handleCloseWeekSelect = () => {
    setAnchorEl(null);
  };
  const handleOpenWeekSelect = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (value) {
      handleCloseWeekSelect()
      // handleChange(currentWeek.start['$d'], currentWeek.end['$d']);
    }
  }, [value]);
    const startDate = value.startOf('week');
    // const endDate = value.endOf('week');

  return (
    <>
      <Button
        variant='text' 
        onClick={handleOpenWeekSelect}
        sx={{
          fontWeight: 600,
          textTransform: 'capitalize',
        }}
      >
        <span style={{ color: '#fff', marginRight: '5px', fontWeight: 500, textTransform: 'capitalize' }}>
          For the week starting {' '}
        </span>
        {startDate.format('ddd MMM DD YYYY')}
        {/* <CalIcon /> */}

      </Button>
      <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openWeekSelect}
            onClose={handleCloseWeekSelect}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
        <WeekCalender {...{ value: value, handleChange : handleChange }} />
      </Menu>  
    </>
  )
}