import axios from "axios";
import { API_URL } from '../api';

class AuthService {
	setUserInLocalStorage(data) {
		localStorage.setItem("user", JSON.stringify(data));
	}

	async login(email, password, company) {
		const response = await axios.post(API_URL + "api/auth/auth-token/", { email, password, subfix_domain: company });
		if (!response.data.token) {
			return response.data;
		}

		if (!response.data.token) {
			return response.data;
		}
		const fomrData = {
			email: email,
			subfix_domain: ''
		}
		const package_res = await axios.post(API_URL + "api/auth/permissions/", fomrData);
		this.setUserInLocalStorage({...response.data, ...package_res.data});
		return {...response.data, ...package_res.data};
	}

	async company_from_email(email) {
		const response = await axios.post(API_URL + "api/auth/company-from-email/", { email: email.trim() });
		return response.data;
	}

	async resetPermissions() {
		let user = localStorage.getItem("user");
		const fomrData = {
			email: JSON.parse(user)?.email,
			subfix_domain: ''
		}
		const package_res = await axios.post(API_URL + "api/auth/permissions/", fomrData);
		this.setUserInLocalStorage({...JSON.parse(user), ...package_res.data});

		window.location.reload(false);
	}

	logout() {
		localStorage.removeItem("user");
	}

	getCurrentUser() {
		const user = localStorage.getItem("user");
		return JSON.parse(user);
	}
}

export default new AuthService();