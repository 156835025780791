import React, { useContext } from 'react';
import './index.css';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import { setQ } from '../../reducers/searchSlice';
import { ReactComponent as Inventory } from '../../Assets/svg/inventory.svg';
import { ReactComponent as Car } from '../../Assets/svg/car.svg';
import { ReactComponent as Car2 } from '../../Assets/svg/car_2.svg';
import { ReactComponent as ManualCar } from '../../Assets/svg/manual_car.svg';
import { ReactComponent as Bookings } from '../../Assets/svg/bookings.svg';
import { ReactComponent as DailyCalender } from '../../Assets/svg/dailyCalender.svg';
import { ReactComponent as Trip } from '../../Assets/svg/trip.svg';
import { clearState as clearBookingState } from '../../reducers/bookingSlice';
import { AuthContext } from '../../contexts/AuthContext';

export default function Sidebar({ ...props }) {
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();

  const isValid = (page, mutate = false) => {
    // console.log(user.access_level)
    // if (user.package && user.package.name == 'Owner') return true;
    if (user.access_level && user?.access_level?.items) {
      return (
        (user?.access_level?.items || []).findIndex(
          (perm) => perm.page == page && perm.mutate == mutate
        ) > -1
      );
    }
    return false;
    // return (
    //   (user?.package?.items || []).findIndex((perm) => perm.page == page) > -1
    // );
  };
  const isSuperValid = () => {
    if (user.package && user.package.name == 'Owner') return true;
    return false;
  };

  const isOwnerValid = () => {
    if (
      user.package &&
      user.package.name != 'Owner' &&
      user.package.name != 'Free' &&
      user.package.name != ''
    )
      return true;
    return false;
  };

  return (
    <div className='sidebar'>
      <div>
        <div className='sidebar_logo'>
          <img src='/assets/imgs/logo.png' alt='logo' />
        </div>
        <div className='sidebar_hero'>
          <div className='sidebar_links' onClick={() => dispatch(setQ(''))}>
            {!isSuperValid() && (
              <Link
                to='/'
                className={`sidebar_link ${
                  props.page === 'dashboard' && 'sidebar_link_inverted'
                }`}
              >
                <svg
                  viewBox='0 0 15 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M5.23279 14.5866V12.2864C5.23278 11.7036 5.70674 11.23 6.29382 11.2263H8.44944C9.03914 11.2263 9.51719 11.701 9.51719 12.2864V14.5799C9.51717 15.0854 9.928 15.4963 10.4372 15.5H11.9079C12.5947 15.5017 13.254 15.2321 13.7404 14.7505C14.2267 14.2689 14.5 13.615 14.5 12.9331V6.39938C14.5 5.84853 14.254 5.32603 13.8284 4.97262L8.83222 1.0057C7.95887 0.311842 6.71152 0.334256 5.86403 1.05904L0.975258 4.97262C0.529555 5.31561 0.263164 5.83966 0.25 6.39938V12.9265C0.25 14.3478 1.41053 15.5 2.84213 15.5H4.27921C4.52437 15.5017 4.76011 15.4063 4.9341 15.2348C5.10809 15.0633 5.20594 14.83 5.20593 14.5866H5.23279Z'
                    fill='#9D9FA2'
                  />
                </svg>
                Dashboard
              </Link>
            )}
            {isValid('daily_overview') && (
              <Link
                to='/daily_overview'
                className={`sidebar_link ${
                  props.page === 'daily_overview' &&
                  'sidebar_link_inverted add_booking_svg'
                }`}
              >
                <DailyCalender />
                Daily Overview
              </Link>
            )}
            {isValid('messages') && (
              <Link
                to='/messages'
                className={`sidebar_link ${
                  props.page === 'messages' &&
                  'sidebar_link_inverted add_booking_svg'
                }`}
              >
                <svg width='16' height='15' viewBox='0 0 16 15' fill='none'>
                  <path
                    d='M2.678 10.894C2.7818 10.9982 2.86133 11.124 2.91094 11.2625C2.96055 11.4009 2.97901 11.5486 2.965 11.695C2.89472 12.3726 2.76148 13.0421 2.567 13.695C3.962 13.372 4.814 12.998 5.201 12.802C5.4205 12.6908 5.67329 12.6645 5.911 12.728C6.59243 12.9097 7.29477 13.0011 8 13C11.996 13 15 10.193 15 7C15 3.808 11.996 1 8 1C4.004 1 1 3.808 1 7C1 8.468 1.617 9.83 2.678 10.894ZM2.185 14.799C1.94807 14.846 1.71037 14.889 1.472 14.928C1.272 14.96 1.12 14.752 1.199 14.566C1.28779 14.3566 1.36918 14.1441 1.443 13.929L1.446 13.919C1.694 13.199 1.896 12.371 1.97 11.6C0.743 10.37 0 8.76 0 7C0 3.134 3.582 0 8 0C12.418 0 16 3.134 16 7C16 10.866 12.418 14 8 14C7.20765 14.0011 6.41859 13.8982 5.653 13.694C5.133 13.957 4.014 14.436 2.185 14.799Z'
                    fill='#9D9FA2'
                  />
                </svg>
                Messages
              </Link>
            )}
            {(isValid('on_rent') || isValid('all_cars')) && (
              <Accordion
                className='sidebar_Accordian '
                flush
                defaultActiveKey={
                  props.page === 'on_rent' || props.page === 'all_cars'
                    ? '1'
                    : null
                }
              >
                <Accordion.Item className='sidebar_Accordian_Item' eventKey='1'>
                  <Accordion.Header className='sidebar_Accordian_Header'>
                    <div>
                      <span className='sidebar_Accordian_Header_linkedIn_svg'>
                        {/* <img src={Inventory} alt='inventory' /> */}
                        <Inventory />
                      </span>
                      Inventory
                    </div>
                    <span className='sidebar_Accordian_Header_expand_svg'>
                      <svg viewBox='0 0 24 24' width='20px' fill='#fff'>
                        <path d='M24 24H0V0h24v24z' fill='none' opacity='.87' />
                        <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z' />
                      </svg>
                    </span>
                  </Accordion.Header>
                  {isValid('on_rent') && (
                    <Accordion.Body className={`sidebar_Accordian_Body`}>
                      <Link
                        className={`${
                          props.page === 'on_rent' && 'sidebar_link_inverted'
                        } `}
                        to='/inventory/on_rent'
                      >
                        <Car2 className='car' />
                        On Rent
                      </Link>
                    </Accordion.Body>
                  )}
                  {isValid('all_cars') && (
                    <Accordion.Body className={`sidebar_Accordian_Body`}>
                      <Link
                        className={`${
                          props.page === 'all_cars' && 'sidebar_link_inverted'
                        } `}
                        to='/inventory/all_cars'
                      >
                        <Car2 className='car' />
                        All Cars
                      </Link>
                    </Accordion.Body>
                  )}
                </Accordion.Item>
              </Accordion>
            )}
            {isValid('trips', false) && (
              <Link
                to='/add_booking'
                className={`sidebar_link ${
                  props.page === 'add_booking' &&
                  'sidebar_link_inverted add_booking_svg'
                }  `}
                onClick={() => dispatch(clearBookingState())}
              >
                <ManualCar /> Add Booking
              </Link>
            )}
            {isValid('all_users') && (
              <Link
                to='/all_users'
                className={`sidebar_link ${
                  props.page === 'all_users' &&
                  'sidebar_link_inverted add_booking_svg'
                }  `}
              >
                <svg viewBox='0 0 16 16'>
                  <path
                    d='M7.9987 5.33337V8.00004M7.9987 8.00004V10.6667M7.9987 8.00004H10.6654M7.9987 8.00004H5.33203'
                    stroke={props.page === 'all_users' ? '#4fc8e9' : '#9D9FA2'}
                    style={{ fill: 'none' }}
                    strokeWidth='1.33333'
                    strokeLinecap='round'
                  />
                  <path
                    d='M7.9987 14.6667C11.6806 14.6667 14.6654 11.6819 14.6654 8.00004C14.6654 4.31814 11.6806 1.33337 7.9987 1.33337C4.3168 1.33337 1.33203 4.31814 1.33203 8.00004C1.33203 11.6819 4.3168 14.6667 7.9987 14.6667Z'
                    stroke={props.page === 'all_users' ? '#4fc8e9' : '#9D9FA2'}
                    style={{ fill: 'none' }}
                    strokeWidth='1.33333'
                  />
                </svg>
                Clients
              </Link>
            )}
            {isValid('discount_coupon') && (
              <Link
                to='/discount_coupon'
                className={`sidebar_link ${
                  props.page === 'discount_coupon' &&
                  'sidebar_link_inverted add_booking_svg'
                }  `}
              >
                <svg
                  version='1.1'
                  id='Layer_1'
                  x='0px'
                  y='0px'
                  viewBox='0 0 248.774 248.774'
                  fill='#9D9FA2'
                >
                  <g>
                    <g>
                      <g>
                        <path
                          d='M219.179,51.502H29.6C13.277,51.502,0,63.539,0,78.335v92.104c0,14.796,13.277,26.833,29.6,26.833h189.577
				c16.318,0,29.597-12.037,29.597-26.833V78.335C248.774,63.539,235.495,51.502,219.179,51.502z M233.557,170.439
				c0,6.404-6.455,11.616-14.38,11.616H29.6c-7.931,0-14.383-5.212-14.383-11.616V78.335c0-6.404,6.452-11.616,14.383-11.616
				h189.577c7.926,0,14.38,5.212,14.38,11.616V170.439z'
                        />
                        <rect
                          x='67.622'
                          y='104.742'
                          width='15.217'
                          height='13.097'
                        />
                        <rect
                          x='67.622'
                          y='157.127'
                          width='15.217'
                          height='13.097'
                        />
                        <rect
                          x='67.622'
                          y='78.548'
                          width='15.217'
                          height='13.097'
                        />
                        <rect
                          x='67.622'
                          y='130.936'
                          width='15.217'
                          height='13.094'
                        />
                        <path
                          d='M152.026,103.507c0-11.874-9.858-21.611-21.616-21.611c-11.874,0-21.613,9.62-21.613,21.611
				c0,12.115,9.741,21.613,21.613,21.613C142.168,125.12,152.026,115.503,152.026,103.507z M121.029,103.507
				c0-5.225,4.276-9.379,9.379-9.379s9.379,4.157,9.379,9.379c0,5.227-4.276,9.381-9.379,9.381
				C125.305,112.888,121.029,108.731,121.029,103.507z'
                        />
                        <path
                          d='M186.189,124.41c-11.874-0.043-21.651,9.544-21.697,21.537c-0.041,12.11,9.665,21.649,21.542,21.695
				c11.76,0.043,21.646-9.546,21.692-21.537C207.767,134.227,197.947,124.45,186.189,124.41z M186.108,155.407
				c-5.103,0-9.376-4.162-9.376-9.384c0-5.227,4.276-9.381,9.376-9.381c5.108,0,9.381,4.157,9.381,9.381
				C195.492,151.248,191.216,155.407,186.108,155.407z'
                        />

                        <rect
                          x='109.054'
                          y='117.595'
                          transform='matrix(0.4696 -0.8829 0.8829 0.4696 -26.7873 205.8196)'
                          width='97.7'
                          height='15.217'
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                Discount Coupon
              </Link>
            )}
            {isValid('car_report') && (
              <Link
                to='/car_report'
                className={`sidebar_link ${
                  props.page === 'car_report' &&
                  'sidebar_link_inverted add_booking_svg'
                }  `}
              >
                <ManualCar />
                Car Reports
              </Link>
            )}
            {isValid('trips') && (
              <Link
                to='/trips'
                className={`sidebar_link ${
                  props.page === 'trips' &&
                  'sidebar_link_inverted add_booking_svg'
                }  `}
              >
                <Trip />
                Trip Management
              </Link>
            )}
            {/* {!isSuperValid() && (
              <Link
                to='/notification_history'
                className={`sidebar_link ${
                  props.page === 'notification_history' &&
                  'sidebar_link_inverted add_booking_svg'
                }  `}
              >
                <Trip />
                Notification History
              </Link>
            )} */}
            {isValid('themes') && (
              <Link
                to='/themes'
                className={`sidebar_link ${
                  props.page === 'themes' &&
                  'sidebar_link_inverted add_booking_svg'
                }  `}
              >
                <Bookings />
                Theme
              </Link>
            )}
            {isValid('customize')&& (
              <Link
                to='/customize'
                className={`sidebar_link ${
                  props.page === 'customize' &&
                  'sidebar_link_inverted add_booking_svg'
                }  `}
              >
                <Bookings />
                Customize
              </Link>
            )}
            {isSuperValid() && (
              <Link
                to='/companies'
                className={`sidebar_link ${
                  props.page === 'companies' &&
                  'sidebar_link_inverted add_booking_svg'
                }  `}
              >
                <Bookings />
                Companies
              </Link>
            )}
            {isSuperValid() && (
              <Link
                to='/packages'
                className={`sidebar_link ${
                  props.page === 'packages' &&
                  'sidebar_link_inverted add_booking_svg'
                }  `}
              >
                <Bookings />
                Packages
              </Link>
            )}
            {isValid('access_level')&& (
              <Link
                to='/access_level'
                className={`sidebar_link ${
                  props.page === 'access_level' &&
                  'sidebar_link_inverted add_booking_svg'
                }  `}
              >
                <Bookings />
                Access Levels
              </Link>
            )}
            {isValid('access_level_user')&& (
              <Link
                to='/access_level_user'
                className={`sidebar_link ${
                  props.page === 'access_level_user' &&
                  'sidebar_link_inverted add_booking_svg'
                }  `}
              >
                <Bookings />
                Users
              </Link>
            )}
          </div>
        </div>
      </div>
      <footer>
        <svg
          className='menu'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12 20C11.4696 20 10.9609 19.7893 10.5858 19.4142C10.2107 19.0391 10 18.5304 10 18C10 17.4696 10.2107 16.9609 10.5858 16.5858C10.9609 16.2107 11.4696 16 12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18C14 18.5304 13.7893 19.0391 13.4142 19.4142C13.0391 19.7893 12.5304 20 12 20ZM12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14ZM12 8C11.4696 8 10.9609 7.78929 10.5858 7.41421C10.2107 7.03914 10 6.53043 10 6C10 5.46957 10.2107 4.96086 10.5858 4.58579C10.9609 4.21071 11.4696 4 12 4C12.5304 4 13.0391 4.21071 13.4142 4.58579C13.7893 4.96086 14 5.46957 14 6C14 6.53043 13.7893 7.03914 13.4142 7.41421C13.0391 7.78929 12.5304 8 12 8Z'
            fill='white'
          />
        </svg>
        <svg
          width='25'
          height='25'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M20.59 22L15 16.41V7H17V15.58L22 20.59L20.59 22Z'
            fill='#4FC8E9'
          />
          <path
            d='M16 2C14.135 2.00026 12.2889 2.37478 10.5712 3.10136C8.85354 3.82795 7.29911 4.89183 6 6.23V2H4V10H12V8H7.08C8.95603 5.91529 11.503 4.55353 14.2785 4.1512C17.0541 3.74888 19.8828 4.33142 22.2736 5.79764C24.6643 7.26386 26.466 9.52115 27.3657 12.1775C28.2654 14.8338 28.2063 17.7213 27.1987 20.3386C26.191 22.9559 24.2985 25.1375 21.8497 26.5047C19.401 27.8719 16.5507 28.3381 13.794 27.8226C11.0372 27.307 8.54814 25.8422 6.759 23.6824C4.96985 21.5227 3.99367 18.8045 4 16H2C2 18.7689 2.82109 21.4757 4.35943 23.778C5.89777 26.0803 8.08427 27.8747 10.6424 28.9343C13.2006 29.9939 16.0155 30.2712 18.7313 29.731C21.447 29.1908 23.9416 27.8574 25.8995 25.8995C27.8574 23.9416 29.1908 21.447 29.731 18.7313C30.2712 16.0155 29.9939 13.2006 28.9343 10.6424C27.8747 8.08427 26.0803 5.89777 23.778 4.35943C21.4757 2.82109 18.7689 2 16 2Z'
            fill='#4FC8E9'
          />
        </svg>{' '}
        <br />
        <span>History Available</span>
        <br />
        <div>Check Your Recent Updates</div>
      </footer>
    </div>
  );
}
