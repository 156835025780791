import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import './index.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../../contexts/AuthContext';
import { get_bookings_vehicle } from '../../API/car_booking';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import TripCard from '../../components/TripCard';

export default function CarBookings() {
  const { authAxios, user } = useContext(AuthContext);
  const { car_id } = useParams();
  const navigate = useNavigate();

  return (
    <div className='dashboard page'>
      <Sidebar page='all_cars' />
      <div className='hero_content theme_content'>
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <div className='booking_wrapper'>
            <div className='booking_header'>
              <div className='go_back' onClick={() => navigate(-1)}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  viewBox='0 0 20 20'
                  fill='#46a8c8'
                  width={30}
                >
                  <path
                    fillRule='evenodd'
                    d='M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z'
                    clipRule='evenodd'
                  />
                </svg>
              </div>
              <h4>Car Bookings</h4>
            </div>
            <Trips authAxios={authAxios} id={car_id} />
          </div>
        </div>
      </div>
    </div>
  );
}

const Trips = ({ authAxios, id }) => {
  const [trips, setTrips] = useState(null);

  const filterTrips = (rows,property) => {
    return rows.sort((a, b) => new Date(a[property]) - new Date(b[property]));
  }

  const getTrips = () => {
    get_bookings_vehicle(authAxios, id).then((res) => {
      if (res.status === 200) {
        setTrips(filterTrips(res.data, 'start_date'));
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }
    });
  };

  useEffect(() => {
    getTrips();
  }, []);

  return (
    <div className='tripsCards'>
      {!trips ? (
        <div>
          <CircularProgress color='primary' />
        </div>
      ) : trips.length === 0 ? (
        <div>No Trips Availavble</div>
      ) : (
        trips.map((trip) => <TripCard trip={trip} carBooking={true}></TripCard>)
      )}
    </div>
  );
};
