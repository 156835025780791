import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

import styled from '@mui/material/styles/styled';
import Menu from '@mui/material/Menu';
import './index.css';
import AddToGoogleCalendar from '../AddToGoogleCalendar';
import { format } from 'date-fns';
import { outlinedInputClasses } from '@mui/material';

export default function CalenderHeader({
  calendarRef,
  calenderType,
  setCalenderType,
  setFilterCalender,
  noFilters,
  rawBookings,
  searchText,
  onChangeSearchText,
  noSearch,
  fetchBookings
}) {
  let calendarApi = calendarRef.current?.getApi();
  const calDate = new Date(
    calendarRef.current?.getApi().currentDataManager.data.viewTitle
  );
  const [date, setDate] = useState(calendarRef.current?.getApi().getDate());
  const [gotoDate, setGotoDate] = useState(
    calenderType === 'custom_day' && calDate
  );
  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }
  // console.log(calenderType);
  useEffect(() => {
    calenderType === 'custom_day' && setGotoDate(calDate);
  }, [calendarApi]);

  useEffect(() => {
    calenderType === 'custom_day' && setGotoDate(calDate);
  }, [date]);

  useEffect(() => {
    gotoDate && calendarApi?.gotoDate(gotoDate);
  }, [gotoDate]);

  // Filter Dropdown Starts -------
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSetFilter = (filter) => {
    setFilterCalender(filter);
    setAnchorEl(null);
  };
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))();
  // Filter Dropdown Ends -------
  // console.log(gotoDate, gotoDate && format(gotoDate, 'LLLL dd, yyyy'), );

  useEffect(() => {
    const calApi = calendarRef.current?.getApi();

    if (calApi) {
      // console.log(calApi.getDate())
      setDate(new Date(calApi.getDate()));
      // calApi.changeView(calenderType);
    }
  }, [calendarRef]);

  const handleDateChange = (direction) => {
    const calApi = calendarRef.current?.getApi();

    if (calApi) {
      if (direction === 'prev') {
        calApi.prev();
      } else if (direction === 'next') {
        calApi.next();
      } else {
        calApi.today();
      }

      let params = {
        bookings_start_date: moment(calApi.getDate()).utc().format('MM-DD-yyyy'),
        // bookings_end_date: moment(calApi.getDate()).utc().format('MM-DD-yyyy')
      }
      
      if(calenderType === 'custom_day'){
        params.bookings_end_date = moment(calApi.getDate()).utc().format('MM-DD-yyyy')
      } else if(calenderType === 'timeGridWeek'){
        params.bookings_end_date = moment(calApi.getDate()).add(6, 'days').utc().format('MM-DD-yyyy')
      } else if(calenderType === 'dayGridMonth'){
        params.bookings_end_date = moment(calApi.getDate()).add(1, 'months').utc().format('MM-DD-yyyy')
      }
      // console.log('🚀OUTPUT --> params:', params);
      fetchBookings?.(params)


      window.sessionStorage.setItem(
        'dailyview_date',
        moment(calApi.getDate()).utc().format('yyyy-MM-DD')
      );
      setDate(new Date(calApi.getDate()));
    }
  };

  const handleCalenderTypeChange = (e) => {
    const calApi = calendarRef.current?.getApi();
    setCalenderType(e.target.value);
    if (calApi) {
      calApi.changeView(e.target.value);
      setDate(new Date(calApi.getDate()));
      // console.log('🚀OUTPUT --> e.target.value:', e.target.value);
      // console.log('🚀OUTPUT --> ', moment(calApi?.getDate()).utc().format('MM-DD-yyyy'))

      let parmas = {}

      if (e.target.value === 'custom_day') {
        parmas = {
          bookings_start_date: moment(calApi.getDate()).utc().format('MM-DD-yyyy'),
          bookings_end_date: moment(calApi.getDate()).utc().format('MM-DD-yyyy')
        }
      } else if (e.target.value === 'timeGridWeek') {
        parmas = {
          bookings_start_date: moment(calApi.getDate()).clone().startOf('week').utc().format('MM-DD-yyyy'),
          bookings_end_date: moment(calApi.getDate()).clone().endOf('week').utc().format('MM-DD-yyyy')
        }
      } else if (e.target.value === 'dayGridMonth') {
        console.log('🚀OUTPUT --> dayGridMonth: Hereee');
        parmas = {
          bookings_start_date: moment(calApi.getDate()).clone().startOf('month').utc().format('MM-DD-yyyy'),
          bookings_end_date: moment(calApi.getDate()).clone().endOf('month').utc().format('MM-DD-yyyy')
        }
      }

      // console.log('🚀OUTPUT --> parmas:', parmas);
      fetchBookings?.(parmas)
    }
  }

  useEffect(() => {
    const selected_date = window.sessionStorage.getItem('dailyview_date');
    if (selected_date && calendarRef.current) {
      const calApi = calendarRef.current?.getApi();
      setDate(moment(selected_date));
      calApi.gotoDate(selected_date);
    }
  }, [calendarRef && calendarRef.current, calendarApi]);

  const Arrow = () => {
    return (
      <svg
        width='8'
        height='14'
        viewBox='0 0 8 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M-8.74228e-08 12L5 7L-5.24537e-07 2L0.999999 -4.37114e-08L8 7L1 14L-8.74228e-08 12Z'
          fill='#4FC8E9'
        />
      </svg>
    );
  };

  // console.log(isValidDate(gotoDate)); //.currentDataManager.data.viewTitle
  // console.log(calendarApi && format(gotoDate, 'LLLL dd, yyyy')); //.currentDataManager.data.viewTitle
  // console.log(new Date(calendarRef.current?.getApi().currentDataManager.data.viewTitle));

  return (
    <div className='calender_header'>
      <div className='calender_section'>
        {!noSearch && (<div className='calender_search'>
          <input
            type='text'
            id='calender_search'
            name='Calender Search'
            placeholder='Calender Search'
            onChange={(e) => onChangeSearchText(e.target.value)}
            value={searchText}
          />
            <svg
              viewBox='0 0 26 26'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              width={20}
            >
              <path
                d='M11.7932 3.64724C13.3576 3.64581 14.8873 4.10841 16.1888 4.97652C17.4903 5.84463 18.505 7.07925 19.1047 8.52418C19.7044 9.96911 19.862 11.5594 19.5577 13.094C19.2533 14.6285 18.5007 16.0383 17.395 17.1451C16.2893 18.2518 14.8802 19.0057 13.3459 19.3114C11.8116 19.6172 10.2212 19.461 8.7757 18.8626C7.33022 18.2643 6.09468 17.2506 5.22538 15.95C4.35608 14.6493 3.89208 13.12 3.89208 11.5556C3.90156 9.46233 4.73682 7.45741 6.21631 5.97657C7.6958 4.49572 9.69995 3.65864 11.7932 3.64724ZM11.7932 2.16669C9.93624 2.16669 8.121 2.71734 6.577 3.749C5.03301 4.78066 3.82961 6.24701 3.11899 7.9626C2.40837 9.6782 2.22243 11.566 2.58471 13.3873C2.94698 15.2085 3.84118 16.8815 5.15424 18.1945C6.4673 19.5076 8.14024 20.4018 9.96151 20.7641C11.7828 21.1263 13.6706 20.9404 15.3862 20.2298C17.1018 19.5192 18.5681 18.3158 19.5998 16.7718C20.6314 15.2278 21.1821 13.4125 21.1821 11.5556C21.1821 9.06549 20.1929 6.67739 18.4321 4.91663C16.6714 3.15587 14.2833 2.16669 11.7932 2.16669Z'
                fill='#4FC8E9'
              />
              <path
                d='M25.278 24.0428L19.9552 18.6839L18.9297 19.7022L24.2525 25.0611C24.3193 25.1285 24.3988 25.182 24.4863 25.2186C24.5739 25.2552 24.6678 25.2742 24.7627 25.2746C24.8576 25.2749 24.9516 25.2566 25.0394 25.2205C25.1272 25.1845 25.2071 25.1316 25.2744 25.0647C25.3417 24.9979 25.3953 24.9184 25.4319 24.8308C25.4685 24.7433 25.4875 24.6494 25.4879 24.5545C25.4882 24.4596 25.4698 24.3656 25.4338 24.2778C25.3978 24.19 25.3449 24.1101 25.278 24.0428Z'
                fill='#4FC8E9'
              />
            </svg>
          </div>
        )}
        <div className='calender_year'>
          <div className='prev' onClick={() => handleDateChange('prev')}>
            <svg
              width='8'
              height='14'
              viewBox='0 0 8 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M-8.74228e-08 12L5 7L-5.24537e-07 2L0.999999 -4.37114e-08L8 7L1 14L-8.74228e-08 12Z'
                fill='#4FC8E9'
              />
            </svg>
          </div>
          <div className='year' style={{ width: '160px', textAlign: 'center' }}>
            {
              //   getFormattedDate()
              calendarApi &&
                (calenderType === 'custom_day'
                  ? isValidDate(gotoDate) && format(gotoDate, 'LLLL dd, yyyy')
                  : calendarRef.current?.getApi().currentDataManager.data
                      .viewTitle)
            }
          </div>
          <div className='next' onClick={() => handleDateChange('next')}>
            <svg
              width='8'
              height='14'
              viewBox='0 0 8 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M-8.74228e-08 12L5 7L-5.24537e-07 2L0.999999 -4.37114e-08L8 7L1 14L-8.74228e-08 12Z'
                fill='#4FC8E9'
              />
            </svg>
          </div>
        </div>
      </div>
      {noFilters ? null : (
        <div className='calender_section'>
          {calenderType === 'custom_day' && (
            <div className='calenderGoto'>
              <div className='info_row info_row_date '>
                <div className='field'>
                  <div className=''>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        // shouldDisableDate={(23, 24, 25) => true}
                        // disablePast
                        label='Date'
                        // readOnly
                        value={gotoDate}
                        onChange={(newValue) => {
                          if (newValue != 'Invalid Date')
                            setGotoDate(newValue);
                            let params = {
                              bookings_start_date: moment(newValue).utc().format('MM-DD-yyyy'),
                              bookings_end_date: moment(newValue).utc().format('MM-DD-yyyy')
                            }
                            fetchBookings?.(params)
                        }}
                        slotProps={{
                          textField: { 
                            sx: {
                              [`& .${outlinedInputClasses.notchedOutline}`]: {
                                border: '1px solid #8d94be !important',
                              }
                            }
                           },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
          )}
          <AddToGoogleCalendar rawBookings={rawBookings} />
          <div className='filter' onClick={handleClick}>
            <svg
              width='32'
              height='32'
              viewBox='0 0 32 32'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M30 4V7.34L20 16.858V28H12V16.858L2 7.338V4H30ZM14 16V26H18V16L28 6.48V6H4V6.48L14 16Z'
                fill='#8D94BE'
              />
            </svg>
          </div>
          <StyledMenu
            id='demo-customized-menu'
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            className='add_admin_dropdown'
          >
            <MenuItem onClick={() => handleSetFilter('All')} disableRipple>
              All
            </MenuItem>
            <MenuItem onClick={() => handleSetFilter('Pick_Ups')} disableRipple>
              Return
            </MenuItem>
            <MenuItem
              onClick={() => handleSetFilter('Drop_Offs')}
              disableRipple
            >
              Outbound
            </MenuItem>
            <MenuItem
              onClick={() => handleSetFilter('On_Service')}
              disableRipple
            >
              On Service
            </MenuItem>
          </StyledMenu>
          <div className='calender_type info_row'>
            <div className='field'>
              <FormControl
                className='edit_dropdown'
                sx={{ m: 1, minWidth: 120 }}
              >
                <InputLabel id='calender_type'>Calender Type</InputLabel>
                <Select
                  className='calender_type_select'
                  labelid='calender_type'
                  id='calender_type'
                  defaultValue={calenderType}
                  label='Calender Type'
                  onChange={handleCalenderTypeChange}
                  IconComponent={Arrow}
                >
                  <MenuItem
                    className='airport_name_menu_item'
                    value={'dayGridMonth'}
                  >
                    Month
                  </MenuItem>
                  <MenuItem
                    className='airport_name_menu_item'
                    value={'timeGridWeek'}
                  >
                    Week
                  </MenuItem>
                  <MenuItem
                    className='airport_name_menu_item'
                    value={'custom_day'}
                    timeGridDay
                  >
                    Day
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
