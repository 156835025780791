import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';

import Accordion from 'react-bootstrap/Accordion';

import { ReactComponent as Inventory } from '../../Assets/svg/inventory_nav.svg';
import { ReactComponent as Car } from '../../Assets/svg/car_nav.svg';
import { ReactComponent as Car2 } from '../../Assets/svg/car_2nav.svg';
import { ReactComponent as ManualCar2 } from '../../Assets/svg/manual_car2.svg';
import { ReactComponent as Bookings } from '../../Assets/svg/bookings2.svg';

// import {  } from "@mui/";
// import Search from ''
import './index.css';

export default function NavBar() {
  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      //   bg='dark'
      variant='dark'
      className='mobile_nav_bar'
    >
      {/* <Container> */}
      <Navbar.Brand className='logo'>
        <img src='/assets/imgs/logo.png' alt='logo' />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />
      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav className='nav_buttons'>
          <div className='sidebar_links'>
            <Link to='/' className={`sidebar_link`}>
              <svg
                viewBox='0 0 15 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M5.23279 14.5866V12.2864C5.23278 11.7036 5.70674 11.23 6.29382 11.2263H8.44944C9.03914 11.2263 9.51719 11.701 9.51719 12.2864V14.5799C9.51717 15.0854 9.928 15.4963 10.4372 15.5H11.9079C12.5947 15.5017 13.254 15.2321 13.7404 14.7505C14.2267 14.2689 14.5 13.615 14.5 12.9331V6.39938C14.5 5.84853 14.254 5.32603 13.8284 4.97262L8.83222 1.0057C7.95887 0.311842 6.71152 0.334256 5.86403 1.05904L0.975258 4.97262C0.529555 5.31561 0.263164 5.83966 0.25 6.39938V12.9265C0.25 14.3478 1.41053 15.5 2.84213 15.5H4.27921C4.52437 15.5017 4.76011 15.4063 4.9341 15.2348C5.10809 15.0633 5.20594 14.83 5.20593 14.5866H5.23279Z'
                  fill='#9D9FA2'
                />
              </svg>
              Dashboard
            </Link>
            <Accordion
              className='sidebar_Accordian '
              flush
              // defaultActiveKey={
              //   // props.page === 'top_rented' ||
              //   // props.page === 'in_stock' ||
              //   // props.page === 'on_rent' ||
              //   // props.page === 'all_cars'
              //     // ? '1'
              //     // : null
              // }
            >
              <Accordion.Item className='sidebar_Accordian_Item' eventKey='1'>
                <Accordion.Header className='sidebar_Accordian_Header'>
                  <div>
                    <span className='sidebar_Accordian_Header_linkedIn_svg'>
                      {/* <img src={Inventory} alt='inventory' /> */}
                      <Inventory />
                    </span>
                    Inventory
                  </div>
                  <span className='sidebar_Accordian_Header_expand_svg'>
                    <svg viewBox='0 0 24 24' width='20px' fill='#fff'>
                      <path d='M24 24H0V0h24v24z' fill='none' opacity='.87' />
                      <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z' />
                    </svg>
                  </span>
                </Accordion.Header>
                <Accordion.Body className={`sidebar_Accordian_Body `}>
                  {/* sidebar_link_inverted */}
                  <Link className={``} to='/inventory/top_rented'>
                    <Car className='car' />
                    Top Rented Car
                  </Link>
                </Accordion.Body>
                {/* <Accordion.Body className={`sidebar_Accordian_Body`}>
                  <Link to='/inventory/in_stock'>
                    <Car className='car' />
                    In Stock
                  </Link>
                </Accordion.Body> */}
                <Accordion.Body className={`sidebar_Accordian_Body`}>
                  <Link to='/inventory/on_rent'>
                    <Car2 className='car' />
                    On Rent
                  </Link>
                </Accordion.Body>
                <Accordion.Body className={`sidebar_Accordian_Body`}>
                  <Link to='/inventory/all_cars'>
                    <Car2 className='car' />
                    All Cars
                  </Link>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Link to='/add_vehicle' className={`sidebar_link   `}>
              <svg viewBox='0 0 16 16' fill='none'>
                <path
                  d='M7.9987 5.33337V8.00004M7.9987 8.00004V10.6667M7.9987 8.00004H10.6654M7.9987 8.00004H5.33203'
                  stroke='#9D9FA2'
                  strokeWidth='1.33333'
                  strokeLinecap='round'
                />
                <path
                  d='M7.9987 14.6667C11.6806 14.6667 14.6654 11.6819 14.6654 8.00004C14.6654 4.31814 11.6806 1.33337 7.9987 1.33337C4.3168 1.33337 1.33203 4.31814 1.33203 8.00004C1.33203 11.6819 4.3168 14.6667 7.9987 14.6667Z'
                  stroke='#9D9FA2'
                  strokeWidth='1.33333'
                />
              </svg>
              Add Vehicles
            </Link>
            <Link to='/add_booking' className={`sidebar_link   `}>
              <ManualCar2 />
              Add Booking
            </Link>

            <Link to='/add_person' className={`sidebar_link   `}>
              <svg viewBox='0 0 16 16' fill='none'>
                <path
                  d='M7.9987 5.33337V8.00004M7.9987 8.00004V10.6667M7.9987 8.00004H10.6654M7.9987 8.00004H5.33203'
                  stroke='#9D9FA2'
                  strokeWidth='1.33333'
                  strokeLinecap='round'
                />
                <path
                  d='M7.9987 14.6667C11.6806 14.6667 14.6654 11.6819 14.6654 8.00004C14.6654 4.31814 11.6806 1.33337 7.9987 1.33337C4.3168 1.33337 1.33203 4.31814 1.33203 8.00004C1.33203 11.6819 4.3168 14.6667 7.9987 14.6667Z'
                  stroke='#9D9FA2'
                  strokeWidth='1.33333'
                />
              </svg>
              Add Person
            </Link>
            <Link to='/view_bookings' className={`sidebar_link `}>
              <Bookings />
              View Bookings
            </Link>
            <Link to='/create_coupon' className={`sidebar_link   `}>
              <svg
                version='1.1'
                id='Layer_1'
                x='0px'
                y='0px'
                viewBox='0 0 248.774 248.774'
                fill='#9D9FA2'
              >
                <g>
                  <g>
                    <g>
                      <path
                        d='M219.179,51.502H29.6C13.277,51.502,0,63.539,0,78.335v92.104c0,14.796,13.277,26.833,29.6,26.833h189.577
				c16.318,0,29.597-12.037,29.597-26.833V78.335C248.774,63.539,235.495,51.502,219.179,51.502z M233.557,170.439
				c0,6.404-6.455,11.616-14.38,11.616H29.6c-7.931,0-14.383-5.212-14.383-11.616V78.335c0-6.404,6.452-11.616,14.383-11.616
				h189.577c7.926,0,14.38,5.212,14.38,11.616V170.439z'
                      />
                      <rect
                        x='67.622'
                        y='104.742'
                        width='15.217'
                        height='13.097'
                      />
                      <rect
                        x='67.622'
                        y='157.127'
                        width='15.217'
                        height='13.097'
                      />
                      <rect
                        x='67.622'
                        y='78.548'
                        width='15.217'
                        height='13.097'
                      />
                      <rect
                        x='67.622'
                        y='130.936'
                        width='15.217'
                        height='13.094'
                      />
                      <path
                        d='M152.026,103.507c0-11.874-9.858-21.611-21.616-21.611c-11.874,0-21.613,9.62-21.613,21.611
				c0,12.115,9.741,21.613,21.613,21.613C142.168,125.12,152.026,115.503,152.026,103.507z M121.029,103.507
				c0-5.225,4.276-9.379,9.379-9.379s9.379,4.157,9.379,9.379c0,5.227-4.276,9.381-9.379,9.381
				C125.305,112.888,121.029,108.731,121.029,103.507z'
                      />
                      <path
                        d='M186.189,124.41c-11.874-0.043-21.651,9.544-21.697,21.537c-0.041,12.11,9.665,21.649,21.542,21.695
				c11.76,0.043,21.646-9.546,21.692-21.537C207.767,134.227,197.947,124.45,186.189,124.41z M186.108,155.407
				c-5.103,0-9.376-4.162-9.376-9.384c0-5.227,4.276-9.381,9.376-9.381c5.108,0,9.381,4.157,9.381,9.381
				C195.492,151.248,191.216,155.407,186.108,155.407z'
                      />

                      <rect
                        x='109.054'
                        y='117.595'
                        transform='matrix(0.4696 -0.8829 0.8829 0.4696 -26.7873 205.8196)'
                        width='97.7'
                        height='15.217'
                      />
                    </g>
                  </g>
                </g>
              </svg>
              Create Coupon
            </Link>
          </div>
        </Nav>
      </Navbar.Collapse>
      {/* </Container> */}
    </Navbar>
  );
}
