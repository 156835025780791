import React from 'react';
import './index.css';
import toast from 'react-hot-toast';
import { gapi } from 'gapi-script';

export default function AddToGoogleCalendar({ rawBookings }) {
  // let gapi = window.gapi;
  let CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  let API_KEY = process.env.REACT_APP_GOOGLECALENDARAPIKEY;
  let DISCOVERY_DOCS = process.env.REACT_APP_DISCOVERY_DOCS;
  let SCOPES = process.env.REACT_APP_SCOPES;

  const RemoveDuplicateEvents = (events, prevEvents) => {
    let newEvents = events;
    if (prevEvents.length > 0) {
      for (var i = newEvents.length - 1; i >= 0; i--) {
        for (var j = 0; j < prevEvents.length; j++) {
          if (
            newEvents[i] &&
            newEvents[i].description == prevEvents[j].description
          ) {
            newEvents.splice(i, 1);
          }
        }
      }

      return newEvents;
    } else {
      return newEvents;
    }
  };
  const getISODate = (date) => {
    return new Date(date.getTime() - new Date().getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .split('.')[0];
  };

  const addCalendarEvent = (allEvents) => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      });

      gapi.client.load('calendar', 'v3');
      //time zone list:
      // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      let timeZone = 'Canada/Central';

      //event start time format => 2020-06-28T09:00:00-07:00'

      const modifiedAllEvemts = allEvents.map((allEvent) => ({
        summary: allEvent.summery, // or event name
        location: allEvent.location, //where it would happen
        start: {
          dateTime: getISODate(new Date(allEvent.startDateTime)),
          timeZone: timeZone,
        },
        end: {
          dateTime: getISODate(new Date(allEvent.endDateTime)),
          timeZone: timeZone,
        },
        recurrence: ['RRULE:FREQ=DAILY;COUNT=1'],
        reminders: {
          useDefault: false,
          overrides: [{ method: 'popup', minutes: 20 }],
        },
        description: allEvent.bookingId, //bookingId
      }));

      //sign in with pop up window
      Promise.resolve(gapi.auth2.getAuthInstance().signIn()).then(
        async (res) => {
          //list previous events
          const getOldEventsRes = await gapi.client.calendar.events.list({
            calendarId: 'primary',
            // timeMin: new Date().toISOString(),
            showDeleted: false,
            singleEvents: true,
            maxResults: 2500,
            orderBy: 'startTime',
          });
          const prevEvents = getOldEventsRes.result.items;

          //  removing bookings that are already on the google calendar
          const updatedEvents = RemoveDuplicateEvents(
            modifiedAllEvemts,
            prevEvents
          );
          // console.log('newEvents', modifiedAllEvemts);
          // console.log('prevEvents', prevEvents);
          // console.log('updatedEvents', updatedEvents);

          //end of event listing

          const batch = gapi.client.newBatch();
          updatedEvents.length > 0 &&
            updatedEvents.map((r, j) => {
              batch.add(
                gapi.client.calendar.events.insert({
                  calendarId: 'primary',
                  resource: updatedEvents[j],
                })
              );
            });
          updatedEvents.length > 0
            ? batch.then(function () {
                toast.success('Added Successfully');
              })
            : toast.success('Bookings already in Google Calendar');
        }
      );
    });
  };

  //event time format => 2020-06-28T09:00:00-07:00'
  const events = rawBookings?.map((rawBooking) => ({
    bookingId: rawBooking.id,
    startDateTime: rawBooking.start_date,
    endDateTime: rawBooking.end_date,
    location: rawBooking.destination_location,
    summery: rawBooking.car.make + ' - ' + rawBooking.car.title,
  }));
  // console.log(events);
  return (
    <div
      className=''
      title='Export to Google Calander'
      onClick={() => addCalendarEvent(events)}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        width='48'
        height='48'
        viewBox='0 0 48 48'
        style={{ fill: '#000000', cursor: 'pointer' }}
      >
        <rect width='22' height='22' x='13' y='13' fill='#fff'></rect>
        <polygon
          fill='#1e88e5'
          points='25.68,20.92 26.688,22.36 28.272,21.208 28.272,29.56 30,29.56 30,18.616 28.56,18.616'
        ></polygon>
        <path
          fill='#1e88e5'
          d='M22.943,23.745c0.625-0.574,1.013-1.37,1.013-2.249c0-1.747-1.533-3.168-3.417-3.168 c-1.602,0-2.972,1.009-3.33,2.453l1.657,0.421c0.165-0.664,0.868-1.146,1.673-1.146c0.942,0,1.709,0.646,1.709,1.44 c0,0.794-0.767,1.44-1.709,1.44h-0.997v1.728h0.997c1.081,0,1.993,0.751,1.993,1.64c0,0.904-0.866,1.64-1.931,1.64 c-0.962,0-1.784-0.61-1.914-1.418L17,26.802c0.262,1.636,1.81,2.87,3.6,2.87c2.007,0,3.64-1.511,3.64-3.368 C24.24,25.281,23.736,24.363,22.943,23.745z'
        ></path>
        <polygon
          fill='#fbc02d'
          points='34,42 14,42 13,38 14,34 34,34 35,38'
        ></polygon>
        <polygon
          fill='#4caf50'
          points='38,35 42,34 42,14 38,13 34,14 34,34'
        ></polygon>
        <path
          fill='#1e88e5'
          d='M34,14l1-4l-1-4H9C7.343,6,6,7.343,6,9v25l4,1l4-1V14H34z'
        ></path>
        <polygon fill='#e53935' points='34,34 34,42 42,34'></polygon>
        <path fill='#1565c0' d='M39,6h-5v8h8V9C42,7.343,40.657,6,39,6z'></path>
        <path fill='#1565c0' d='M9,42h5v-8H6v5C6,40.657,7.343,42,9,42z'></path>
      </svg>
    </div>
  );
}
