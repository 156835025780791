import React, { useState, useContext } from 'react';
import './index.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Notes from '../../components/TripNote';
import { AuthContext } from '../../contexts/AuthContext';

export default function TripCard({ trip, carBooking }) {
  const today = new Date();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { authAxios, user, canMutate } = useContext(AuthContext);

  return (
    <div className='tripsCard' key={trip.id}>
      <div className='tripsCardSection'>
        <div className='tripCarImg'>
          <img src={trip.car?.car_images[0]?.image} alt={trip.car?.make} />
        </div>
        <div className='tripDetails'>
          <div>
            {canMutate('all_cars') && (
              <Button
                variant='contained'
                size='small'
                onClick={() => {
                  navigate('/edit_booking/' + trip.customer.id + '/' + trip.id);
                }}
              >
                Edit Booking
              </Button>
            )}
            <Button
              variant='contained'
              size='small'
              style={{ marginLeft: '10px' }}
              onClick={() => {
                setOpen(true);
              }}
            >
              Show Trip Notes
            </Button>
            {carBooking && (
              <div style={{ color: '#4fc8e9' }}>
                {canMutate('all_cars') ? (
                  <Link
                    to={`/user_detail/${trip.customer.id}`}
                    style={{ fontSize: '25px' }}
                  >
                    {trip.customer.name}
                  </Link>
                ) : (
                  <span style={{ fontSize: '25px' }}>{trip.customer.name}</span>
                )}
              </div>
            )}
            <div
              style={{
                fontSize: carBooking ? '22px' : '25px',
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              {trip.car.title}
            </div>
            <div style={{ fontSize: '20px' }}>
              {trip.car.make} - {trip.car.model}
            </div>
          </div>
          <div>
            <div
              style={{
                color:
                  (trip.destination_location === 'null' ||
                    trip.destination_location === '_') &&
                  'red',
              }}
            >
              Airport:{' '}
              <span style={{ color: '#4fc8e9' }}>
                {trip.destination_location}
              </span>
            </div>
            <div
              style={{
                color:
                  (trip.airline_name === 'null' || trip.airline_name === '_') &&
                  'red',
              }}
            >
              Airline:{' '}
              <span style={{ color: '#4fc8e9' }}>{trip.airline_name}</span>
            </div>
            <div
              style={{
                color:
                  (trip.flight_number === 'null' ||
                    trip.flight_number === '_') &&
                  'red',
              }}
            >
              Flight No:{' '}
              <span style={{ color: '#4fc8e9' }}>{trip.flight_number}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='tripBookingDetail'>
        <div>
          {new Date(trip?.start_date) > today ? (
            <div className='tripTag completed'>Upcoming</div>
          ) : trip.is_trip_completed ? (
            <div className='tripTag completed'>Completed</div>
          ) : today > new Date(trip.end_date) ? (
            <div className='tripTag completed'>Completed</div>
          ) : (
            <div className='tripTag'>Not Complete</div>
          )}
        </div>
        <div>
          <div style={{ fontSize: '17px' }}>
            {moment(trip?.start_date).format('MM/DD/yyyy hh:mm A')} -{' '}
            {moment(trip?.end_date).format('MM/DD/yyyy hh:mm A')}
          </div>
          <div style={{ textAlign: 'right' }}>
            Booking Amount :
            <span style={{ color: '#4fc8e9' }}> $ {trip.price}</span>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='conf_modal'
        aria-describedby='modal-modal-description'
      >
        <div className='editModal reimbursementModal'>
          <div className='editModal_header'>
            <h5>Trip Notes</h5>
            <div
              onClick={() => {
                setOpen(false);
              }}
              className='editModal_close'
            >
              <svg
                width='25'
                height='25'
                viewBox='0 0 110 110'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </div>
          <form action='#' className='card_details'>
            <div className='info_wrapper'>
              <Notes authAxios={authAxios} id={trip.id} user={user} />
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
