import { subfix_domain } from '../api';

const delete_car_unavailablity = async (authAxios, id) => {
  try {
    const res = await authAxios.delete(`/api/cars/car-unavailabilities/${id}`, { params: {subfix_domain}});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const set_car_unavailablity = async (authAxios, data) => {
  try {
    const res = await authAxios.post(`/api/cars/car-unavailabilities/`, {...data, subfix_domain});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const edit_trip_preferences_by_id = async (authAxios, id, data) => {
  try {
    const res = await authAxios.put(`/api/cars/trip-preferences/${id}/`, {...data, subfix_domain});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_trip_preferences_by_id = async (authAxios, id) => {
  try {
    const res = await authAxios.get(`/api/cars/trip-preferences/${id}/`, {params: {subfix_domain}});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_all_trip_preferences = async (authAxios, data) => {
  try {
    const res = await authAxios.get(`/api/cars/trip-preferences/`, {params: {subfix_domain}});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const set_trip_preferences = async (authAxios, data) => {
  try {
    const res = await authAxios.post(`/api/cars/trip-preferences/`, {...data, subfix_domain});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const set_custom_price = async (authAxios, data) => {
  try {
    const res = await authAxios.post(`/api/cars/custom-prices/`, {...data, subfix_domain});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_custom_price_by_id = async (authAxios, id) => {
  try {
    const res = await authAxios.get(`/api/cars/custom-prices/${id}/`, {params: {subfix_domain}});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_bookings = async (authAxios, params) => {
  try {
    const res = await authAxios.get(`/api/trips/`, {params: {subfix_domain, ...params}});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const create_car = async (authAxios, data) => {
  try {
    const res = await authAxios.post(`/api/cars/`, {...data, subfix_domain});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_car_by_id = async (authAxios, id) => {
  try {
    const res = await authAxios.get(`/api/cars/${id}/`, {params: {subfix_domain}});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};
const get_all_cars = async (authAxios, start_date = null, end_date = null) => {
  try {
    const res = await authAxios.get(`/api/cars/`, {params: {subfix_domain, start_date, end_date}});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_csrf_token = async (authAxios, config) => {
  try {
    const res = await authAxios.get(`/api/auth/csrf/`, {params: {subfix_domain}});
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

export {
  get_csrf_token,
  get_all_cars,
  get_car_by_id,
  create_car,
  get_bookings,
  set_custom_price,
  get_custom_price_by_id,
  set_trip_preferences,
  edit_trip_preferences_by_id,
  get_trip_preferences_by_id,
  get_all_trip_preferences,
  set_car_unavailablity,
  delete_car_unavailablity,
};
