import { createSlice } from '@reduxjs/toolkit';
import { add } from 'date-fns';

export const initialState = {
	boolInit: true,
	fromDate: add(new Date(), { days: 2 }),
	fromTime: '10:00',
	untilDate: add(new Date(), { days: 5 }),
	untilTime: '10:00',
	send_confirmation: false
}

export const bookingSlice = createSlice({
	name: 'booking',
	initialState: {
		booking: initialState
	},
	reducers: {
		clearState: (state) => {
			state.booking = initialState;
			return state;
		},
		setBooking: (state, { payload }) => {
			state.booking = payload;
			return state;
		}
	},
	extraReducers: {
	},
})

export const { clearState, setBooking } = bookingSlice.actions;

export const bookingSelector = state => state.booking;
