import { subfix_domain } from '../api';

const get_notes = async (authAxios, trip) => {
	try {
		const res = await authAxios.get(`/api/trip_notes/${trip}/`, { params: { subfix_domain } });
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const create_note = async (authAxios, data) => {
	try {
		const res = await authAxios.post(`/api/trip_notes/`, { ...data, subfix_domain });
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const delete_note = async (authAxios, id) => {
	try {
		const res = await authAxios.delete(`/api/trip_notes/details/${id}/`, { params: { subfix_domain } });
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

const update_note = async (authAxios, data, id) => {
	try {
		const res = await authAxios.put(`/api/trip_notes/details/${id}/`, { ...data, subfix_domain });
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
};

export { get_notes, create_note, delete_note, update_note };