import React, { useState, useContext, useEffect } from 'react';
// import './index.css';
import {
  get_all_delivery_charges,
  create_delivery_charge,
  delete_delivery_charge,
  update_delivery_charge,
} from '../../../../API/setting';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import toast from 'react-hot-toast';

import { confirmAlert } from 'react-confirm-alert'; // Import
import Modal from '@mui/material/Modal';
import { AuthContext } from '../../../../contexts/AuthContext';

export default function Airline() {
  const { authAxios } = useContext(AuthContext);
  const [createAirportModal, setCreateAirportModal] = useState(false);
  const [deliveryCharges, setDeliveryCharges] = useState([]);
  const [airportName, setAirportName] = useState('');
  const [color, setColor] = useState('#000000');
  const [code, setCode] = useState('');
  const [priority, setPriority] = useState(null);
  const [airportDeliveryAmount, setAirportDeliveryAmount] = useState(null);

  useEffect(() => {
    getDelivaryCharges();
  }, []);

  const handleCreateDeliveryCharges = async (e) => {
    let res = await create_delivery_charge(authAxios, {
      airport: airportName,
      delivery_charge: airportDeliveryAmount,
      priority: priority,
      color: color,
      code: code,
    });
    if (res.status === 201) {
      setCreateAirportModal(false);
      await getDelivaryCharges(authAxios);
      toast.success('Created Successfully');
      setAirportName('');
      setCode('');
      setPriority('');
      setColor('#000000');
      setAirportDeliveryAmount('');
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  const getDelivaryCharges = async () => {
    let res = await get_all_delivery_charges(authAxios);
    if (res.status === 200) {
      setDeliveryCharges(res.data);
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  const AirportModal = (delivery) => {
    let airport = delivery.airport;
    let delivery_charge = delivery.delivery_charge;
    let code = delivery.code;
    let color = delivery.color;
    let priority = delivery.priority;
    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className='confModal create_airport_modal'
            style={{ height: '500px', position: 'relative' }}
          >
            <div
              className='close_icon'
              style={{ top: '1.5rem', right: '.5rem' }}
              onClick={() => onClose()}
            >
              <svg
                width='25'
                height='25'
                viewBox='0 0 110 110'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
            <br />
            <h4>Edit Airport</h4>
            <form
              className='add_new_airport'
              onSubmit={(e) => {
                e.preventDefault();
                // console.log(airport, delivery_charge);
                handleUpdateDeliveryCharges(
                  delivery.id,
                  airport,
                  code,
                  color,
                  delivery_charge,
                  priority,
                  onClose
                );
              }}
            >
              <div className='info_wrapper'>
                <div className='info_row '>
                  <div className='field'>
                    Airport Name
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        className='airport_name'
                        labelId='size'
                        id='demo-simple-select'
                        defaultValue={delivery.airport}
                        label='Enter Airport Name'
                        onChange={(e) => (airport = e.target.value)}
                      ></TextField>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field'>
                    Airport Code
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        className='airport_name'
                        labelId='size'
                        id='demo-simple-select'
                        defaultValue={delivery.code}
                        label='Enter Code'
                        onChange={(e) => (code = e.target.value)}
                      ></TextField>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field'>
                    Priority
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        className='airport_name'
                        labelId='size'
                        id='demo-simple-select'
                        defaultValue={delivery.priority}
                        label='Enter Priority'
                        onChange={(e) => (priority = e.target.value)}
                      ></TextField>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field'>
                    Colour
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        className='airport_name'
                        labelId='size'
                        type='color'
                        id='demo-simple-select'
                        defaultValue={delivery.color}
                        label='Enter Colour'
                        onChange={(e) => (color = e.target.value)}
                      ></TextField>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field'>
                    Airport Delivery Charge
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <TextField
                        className='airport_name withSuffix'
                        labelId='size'
                        required
                        id='demo-simple-select'
                        type={'number'}
                        InputProps={{
                          startAdornment: '$',
                        }}
                        defaultValue={delivery.delivery_charge}
                        label='Enter Airport Delivery Charge'
                        onChange={(e) => (delivery_charge = e.target.value)}
                      ></TextField>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className='btns'>
                <button onClick={() => onClose()} className='secondary'>
                  Cancel
                </button>
                <button
                  type='submit'
                  className='primary'
                  // onClick={handleCreateDeliveryCharges}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        );
      },
    });
  };
  const AirportDeleteModal = (id) => {
    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='confModal'>
            <svg
              width='110'
              height='110'
              viewBox='0 0 110 110'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                stroke='#FF2552'
                strokeWidth='11.625'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                stroke='#FF2552'
                strokeWidth='11.625'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>{' '}
            <br />
            <h4>Are You Sure?</h4>
            <p>
              Do you really want to delete this info. This process cannot be
              undo.
            </p>
            <div className='btns'>
              <button onClick={() => onClose()} className='secondary'>
                Cancel
              </button>
              <button
                className='primary'
                onClick={(e) => handleDeleteAirport(e, id, onClose)}
              >
                Delete
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const handleUpdateDeliveryCharges = async (
    id,
    airport,
    code,
    color,
    delivery_charge,
    priority,
    onClose
  ) => {
    let res = await update_delivery_charge(authAxios, id, {
      airport,
      code,
      color,
      delivery_charge,
      priority,
    });
    if (res.status === 201 || res.status === 200) {
      onClose();
      await getDelivaryCharges(authAxios);
      toast.success('Created Successfully');
      // setAirportName('');
      // setAirportDeliveryAmount('');
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  const handleDeleteAirport = async (e, id, onClose) => {
    e.preventDefault();

    let res = await delete_delivery_charge(authAxios, id);
    // console.log(res);
    if (res.status === 204) {
      onClose();
      await getDelivaryCharges(authAxios);
      toast.success('Deleted Successfully');
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  return (
    <div className='carFeatures'>
      <div className='carFeatures_header'>
        <h5>Airports Delivery Charges </h5>
        <div>
          <button
            // style={{ visibility: edit && 'hidden' }}
            className={'carFeatures_edit_button margin-right10'}
            onClick={(e) => {
              e.preventDefault();
              setCreateAirportModal(true);
            }}
          >
            Add New
          </button>
        </div>
      </div>
      <div className='features'>
        <div className='allUsers_table'>
          <table>
            <thead>
              {/* <td>S.No</td> */}
              <td>Airport Name</td>
              <td>Code</td>
              <td>Color</td>
              <td>Priority</td>
              <td>Delivery Charge</td>
              <td className='table_actions'></td>
            </thead>
            {deliveryCharges
              .sort((a, b) => a.priority - b.priority)
              .map((delivery, index) => (
                <tr id={delivery.id}>
                  {/* <td>{index + 1}</td> */}
                  <td>{delivery.airport}</td>
                  <td>{delivery.code}</td>
                  <td>
                    <div
                      style={{
                        background: delivery.color,
                        width: '80px',
                        height: '15px',
                        borderRadius: '5px',
                      }}
                      title={delivery.color}
                    ></div>
                  </td>
                  <td>{delivery.priority}</td>
                  <td>$ {delivery.delivery_charge}</td>
                  <td className='table_actions'>
                    <button onClick={() => AirportModal(delivery)}>
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4.02717 11.75C4.05842 11.75 4.08967 11.7469 4.12092 11.7422L6.74905 11.2812C6.7803 11.275 6.80999 11.2609 6.83186 11.2375L13.4553 4.61406C13.4698 4.59961 13.4813 4.58244 13.4891 4.56354C13.497 4.54463 13.501 4.52437 13.501 4.50391C13.501 4.48344 13.497 4.46318 13.4891 4.44428C13.4813 4.42538 13.4698 4.40821 13.4553 4.39375L10.8584 1.79531C10.8287 1.76563 10.7897 1.75 10.7475 1.75C10.7053 1.75 10.6662 1.76563 10.6365 1.79531L4.01311 8.41875C3.98967 8.44219 3.97561 8.47031 3.96936 8.50156L3.50842 11.1297C3.49322 11.2134 3.49865 11.2995 3.52425 11.3807C3.54984 11.4618 3.59482 11.5355 3.6553 11.5953C3.75842 11.6953 3.88811 11.75 4.02717 11.75V11.75ZM5.0803 9.025L10.7475 3.35938L11.8928 4.50469L6.22561 10.1703L4.83655 10.4156L5.0803 9.025V9.025Z'
                          fill='white'
                        />
                        <path
                          d='M13.75 13.0625H2.25C1.97344 13.0625 1.75 13.2859 1.75 13.5625V14.125C1.75 14.1938 1.80625 14.25 1.875 14.25H14.125C14.1938 14.25 14.25 14.1938 14.25 14.125V13.5625C14.25 13.2859 14.0266 13.0625 13.75 13.0625Z'
                          fill='white'
                        />
                      </svg>
                      Edit
                    </button>
                    <button onClick={() => AirportDeleteModal(delivery.id)}>
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M9.2 3.2002C9.2 2.88194 9.07357 2.57671 8.84853 2.35167C8.62348 2.12662 8.31826 2.0002 8 2.0002C7.68174 2.0002 7.37652 2.12662 7.15147 2.35167C6.92643 2.57671 6.8 2.88194 6.8 3.2002H6C6 2.66976 6.21071 2.16105 6.58579 1.78598C6.96086 1.41091 7.46957 1.2002 8 1.2002C8.53043 1.2002 9.03914 1.41091 9.41421 1.78598C9.78929 2.16105 10 2.66976 10 3.2002H13.6C13.7061 3.2002 13.8078 3.24234 13.8828 3.31735C13.9579 3.39237 14 3.49411 14 3.6002C14 3.70628 13.9579 3.80802 13.8828 3.88304C13.8078 3.95805 13.7061 4.0002 13.6 4.0002H13.1568L12.12 12.9842C12.0749 13.3741 11.888 13.7338 11.5949 13.9949C11.3018 14.256 10.9229 14.4002 10.5304 14.4002H5.4696C5.07708 14.4002 4.69823 14.256 4.40513 13.9949C4.11204 13.7338 3.92514 13.3741 3.88 12.9842L2.8424 4.0002H2.4C2.30638 4.00023 2.21571 3.96742 2.14379 3.90748C2.07187 3.84755 2.02325 3.76429 2.0064 3.6722L2 3.6002C2 3.49411 2.04214 3.39237 2.11716 3.31735C2.19217 3.24234 2.29391 3.2002 2.4 3.2002H9.2ZM12.3504 4.0002H3.6488L4.6744 12.8922C4.69698 13.0872 4.79048 13.2671 4.93712 13.3977C5.08375 13.5282 5.27327 13.6003 5.4696 13.6002H10.5304C10.7266 13.6001 10.9159 13.528 11.0624 13.3974C11.2088 13.2669 11.3022 13.0871 11.3248 12.8922L12.3504 4.0002Z'
                          fill='white'
                        />
                        <path
                          d='M6.79844 6C6.99444 6 7.15844 6.124 7.19204 6.2872L7.19844 6.3504V11.2504C7.19844 11.4432 7.01924 11.6 6.79844 11.6C6.60244 11.6 6.43844 11.476 6.40484 11.3128L6.39844 11.2496V6.3512C6.39844 6.1576 6.57764 6.0008 6.79844 6.0008V6ZM9.19844 6C9.39444 6 9.55844 6.124 9.59204 6.2872L9.59844 6.3504V11.2504C9.59844 11.4432 9.41924 11.6 9.19844 11.6C9.00244 11.6 8.83844 11.476 8.80484 11.3128L8.79844 11.2496V6.3512C8.79844 6.1576 8.97764 6.0008 9.19844 6.0008V6Z'
                          fill='white'
                        />
                      </svg>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
      <Modal
        open={createAirportModal}
        onClose={() => setCreateAirportModal(false)}
        aria-labelledby='conf_modal'
        aria-describedby='modal-modal-description'
      >
        <div
          className='confModal create_airport_modal'
          style={{ height: '500px', position: 'relative' }}
        >
          <br />
          <h4>Add New Airport</h4>
          <div
            className='close_icon'
            style={{ top: '.5rem', right: '0rem' }}
            onClick={() => setCreateAirportModal(false)}
          >
            <svg
              width='25'
              height='25'
              viewBox='0 0 110 110'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                stroke='#FF2552'
                strokeWidth='11.625'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                stroke='#FF2552'
                strokeWidth='11.625'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <form
            className='add_new_airport'
            onSubmit={(e) => {
              e.preventDefault();
              handleCreateDeliveryCharges();
            }}
          >
            <div className='info_wrapper'>
              <div className='info_row '>
                <div className='field'>
                  Airport Name
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      required
                      className='airport_name'
                      labelId='size'
                      id='demo-simple-select'
                      defaultValue={airportName}
                      label='Enter Airport Name'
                      onChange={(e) => setAirportName(e.target.value)}
                    ></TextField>
                  </FormControl>
                </div>
              </div>
              <div className='info_row '>
                <div className='field'>
                  Airport Code
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      required
                      className='airport_name'
                      labelId='size'
                      id='demo-simple-select'
                      defaultValue={code}
                      label='Enter Code Number'
                      onChange={(e) => setCode(e.target.value)}
                    ></TextField>
                  </FormControl>
                </div>
              </div>
              <div className='info_row '>
                <div className='field'>
                  Priority
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      required
                      className='airport_name'
                      labelId='size'
                      id='demo-simple-select'
                      defaultValue={priority}
                      label='Enter Priority'
                      onChange={(e) => setPriority(e.target.value)}
                    ></TextField>
                  </FormControl>
                </div>
              </div>
              <div className='info_row '>
                <div className='field'>
                  Color
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      required
                      className='airport_name'
                      labelId='size'
                      id='demo-simple-select'
                      type='color'
                      defaultValue={color}
                      label='Enter Colour'
                      onChange={(e) => setColor(e.target.value)}
                    ></TextField>
                  </FormControl>
                </div>
              </div>
              <div className='info_row '>
                <div className='field'>
                  Airport Delivery Charge
                  <FormControl
                    className='edit_dropdown'
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <TextField
                      className='airport_name withSuffix'
                      labelId='size'
                      required
                      id='demo-simple-select'
                      type={'number'}
                      defaultValue={airportDeliveryAmount}
                      label='Enter Airport Delivery Charge'
                      placeholder='Enter Airport Delivery Charge *'
                      InputProps={{ startAdornment: '$' }}
                      onChange={(e) => setAirportDeliveryAmount(e.target.value)}
                    ></TextField>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className='btns'>
              <button
                onClick={() => setCreateAirportModal(false)}
                className='secondary'
              >
                Cancel
              </button>
              <button
                type='submit'
                className='primary'
                // onClick={handleCreateDeliveryCharges}
              >
                Create
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
