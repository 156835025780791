import { subfix_domain } from '../api';

const add_vehicle = async (authAxios, formData) => {
	formData.append('subfix_domain', subfix_domain)
	try {
		const res = await authAxios.post(`/api/cars/create/`, formData);
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
}

const update_vehicle = async (authAxios, id, data) => {
	try {
		const res = await authAxios.put(`/api/cars/${id}/`, {...data, subfix_domain});
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
}

const get_vehicle = async (authAxios, booked, q, orderName, filters = {}) => {
	try {
		const res = await authAxios.get(
			`/api/cars/`,
			{
				params: {
					vehicle_status: booked? 'on_rent': undefined,
					title: q,
					subfix_domain: subfix_domain,
					order_name: orderName,
					...filters
				}
			}
		);
		return res;
	} catch (Error) {
		console.log(Error)
		return Error;
	}
}

const remove_features = async (authAxios, car_id) => {
	try {
		const res = await authAxios.post(`/api/cars/remove-features/`,{car_id, subfix_domain});
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
}

const add_features = async (authAxios, car_id, feature_ids) => {
	try {
		const res = await authAxios.post(`/api/cars/add-features/`,{car_id, feature_ids, subfix_domain});
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
}

const delete_vehicle = async (authAxios, id) => {
	try {
		const res = await authAxios.delete(`/api/cars/${id}/`, {params: {subfix_domain}});
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
}

const get_vehicles_with_discount = async (authAxios, start_date, end_date) => {
	try {
		const res = await authAxios.post(`/api/discounts/get-all-cars-discounted-price/`, {subfix_domain, start_date, end_date});
		return res;
	} catch (Error) {
		// const err = Error.response.data.message;
		return Error;
	}
}

export { add_vehicle, update_vehicle, get_vehicle, remove_features, add_features, delete_vehicle, get_vehicles_with_discount };