import React, { useRef, useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import './index.css';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import toast from 'react-hot-toast';
import CalenderHeader from '../../components/CalenderHeader';

import { Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { ReactComponent as Calender } from '../../Assets/svg/calender.svg';

import { format, intervalToDuration, addDays } from 'date-fns';

import { set_custom_price, get_car_by_id } from '../../API/index';
import { AuthContext } from '../../contexts/AuthContext';

export default function SelectPricePerDay() {
  const navigate = useNavigate();
  const { authAxios } = useContext(AuthContext);
  const calendarRef = useRef();
  let calendarApi = calendarRef.current?.getApi();

  const { car_id } = useParams();

  const [carDetail, setCarDetail] = useState(null);
  const [customPriceData, setCustomPriceData] = useState(null);
  const [carUnavailablityData, setCarUnavailablityData] = useState(null);
  const [dailyPrice, setDailyPrice] = useState(null);
  // console.log(dailyPrice);

  const [calenderType, setCalenderType] = useState('dayGridMonth');

  const [fromDate, setFromDate] = useState(null);
  const [untilDate, setUntilDate] = useState(null);
  const [vehiclePrice, setVehiclePrice] = useState(null);

  const [selectedCadData, setSelectedCadData] = useState(
    []
    // carsDummyData.filter((e) => e.id === car_id)[0]?.price_per_day.length === 0
    //   ? []
    //   : carsDummyData.filter((e) => e.id === car_id)[0]?.price_per_day
  );
  // console.log(selectedCadData);

  function renderEventContent(eventInfo) {
    // eventInfo.event.date === '2022-12-27' ? console.log(eventInfo) : console.log('null');
    return (
      <div className='price_per_day_event_block'>
        <div className='event_block_type'>
          {eventInfo.event.extendedProps.unavailable ? (
            eventInfo.event.title ===
            eventInfo.event.extendedProps.unavailablityTitle ? (
              eventInfo.event.title
            ) : (
              <div style={{ textAlign: 'center', fontSize: '11px' }}>
                <span style={{ color: '#f0ce4ad4' }}>
                  {eventInfo.event.extendedProps.unavailablityTitle}
                </span>{' '}
                <br /> {eventInfo.event.title}
              </div>
            )
          ) : (
            eventInfo.event.title
          )}
        </div>
      </div>
    );
  }
  // const getEventsData = (data) => {
  //   return selectedCadData;
  // };
  // console.log(selectedCadData);

  const IncrementDay = (initialDate) => {
    return addDays(initialDate, 1);
  };
  const getInterval = (fromDate, untilDate) => {
    return intervalToDuration({
      start: fromDate,
      end: untilDate,
    }).days;
  };
  // const format_date = (date, time) => {
  //   return date && format(date, 'yyyy-MM-dd') + 'T' + time;
  // };

  const getIntervalDays = (fromDate, untilDate) => {
    return Math.ceil(
      (new Date(untilDate).getTime() - new Date(fromDate).getTime()) /
        (1000 * 60 * 60 * 24)
    );
  };
  const groupByDate = (array) =>
    array.reduce((results, item) => {
      const current = results.find((i) => i.date === item.date);
      if (current) {
        for (let property in item) {
          if (property !== 'date') {
            current[property] = item[property];
          }
        }
      } else {
        results.push({ ...item });
      }
      return results;
    }, []);

  // // Setting data in the Calender
  // useEffect(() => {
  //   // customPriceData?.forEach((customPrice) => {
  //   let carCalenderData = [];
  //   customPriceData?.forEach((customPrice) => {
  //     const interval = getInterval(
  //       new Date(customPrice.start_date),
  //       new Date(customPrice.end_date)
  //     );
  //     let tempDate = new Date(customPrice.start_date);
  //     let tempData = [];
  //     for (var i = 0; i <= interval; i++) {
  //       if (i === 0) {
  //         tempData.push({
  //           title: `$${customPrice.price}`,
  //           date: format(new Date(customPrice.start_date), 'yyyy-MM-dd'),
  //           allDay: false,
  //           classNames: '',
  //         });
  //       } else {
  //         tempDate = IncrementDay(tempDate);
  //         tempData.push({
  //           title: `$${customPrice.price}`,
  //           date: format(tempDate, 'yyyy-MM-dd'),
  //           allDay: false,
  //           classNames: '',
  //         });
  //       }
  //     }
  //     // console.log([selectedCadData, ...tempData]);
  //     // console.log('carCalenderData', carCalenderData);
  //     carCalenderData.push(...tempData);
  //     setSelectedCadData([
  //       {
  //         title: ` $ ${dailyPrice}`,
  //         // title: `US$`,
  //         // date: '2022-07-01',
  //         daysOfWeek: ['0', '1', '2', '3', '4', '5', '6'],
  //         allDay: false,
  //         classNames: 'default_price',
  //       },
  //       ...carCalenderData,
  //     ]);
  //   });
  // }, [customPriceData]);

  useEffect(() => {
    get_car_by_id(authAxios, car_id).then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 304) {
        // console.log(res.data.daily_price);
        setCarDetail(res.data);
        setDailyPrice(res.data.daily_price);
        setCustomPriceData(res.data.custom_prices);
        setCarUnavailablityData(res.data.unavailabilities);
        // console.log(
        //   res.data.custom_prices.map((customPrice) => ({
        //     price: customPrice.price,
        //     date: customPrice.start_date,
        //     end_date: customPrice.end_date,
        //     allDay: false,
        //     classNames: '',
        //   }))
        // );
      } else {
        console.log(res);
      }
    });
  }, []);

  // Setting Unavailibility data in the Calender
  useEffect(() => {
    // customPriceData?.forEach((customPrice) => {
    let carCalenderData = [];

    carUnavailablityData?.forEach((customPrice) => {
      const interval = getInterval(
        new Date(customPrice.start_date),
        new Date(customPrice.end_date)
      );
      let tempDate = new Date(customPrice.start_date);
      let tempData = [];
      for (var i = 0; i <= interval; i++) {
        if (i === 0) {
          tempData.push({
            // date: tempDate.toISOString().split('T')[0],
            // data: {
            title: `NA`,
            unavailable: true,
            unavailablityTitle: `NA`,
            id: customPrice.id,
            date: format(new Date(customPrice.start_date), 'yyyy-MM-dd'),
            allDay: false,
            classNames: 'unavailability_event_block',
            reason: customPrice.reason ?? '',
            start_date: customPrice.start_date,
            end_date: customPrice.end_date,
            // },
          });
        } else {
          tempDate = IncrementDay(tempDate);
          tempData.push({
            // date: tempDate.toISOString().split('T')[0],
            // data: {
            title: `NA`,
            unavailable: true,
            unavailablityTitle: `NA`,
            id: customPrice.id,
            date: format(tempDate, 'yyyy-MM-dd'),
            allDay: false,
            classNames: 'unavailability_event_block',
            reason: customPrice.reason ?? '',
            start_date: customPrice.start_date,
            end_date: customPrice.end_date,
            // },
          });
        }
      }
      carCalenderData = carCalenderData.concat(tempData);
    });

    customPriceData?.forEach((customPrice) => {
      const interval = getIntervalDays(
        new Date(customPrice.start_date),
        new Date(customPrice.end_date)
      );
      console.log(interval);

      const dt = new Date(customPrice.start_date);
      let tempDate = new Date(
        dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
      );
      // let tempDate = new Date(customPrice.start_date);
      let tempData = [];
      for (var i = 0; i <= interval; i++) {
        if (i === 0) {
          tempData.push({
            title: `$${customPrice.price}`,
            date: format(tempDate, 'yyyy-MM-dd'), //customPrice.start_date
            allDay: false,
            classNames: '',
          });
        } else {
          tempDate = IncrementDay(tempDate);
          tempData.push({
            title: `$${customPrice.price}`,
            date: format(tempDate, 'yyyy-MM-dd'),
            allDay: false,
            classNames: '',
          });
        }
      }

      carCalenderData = carCalenderData.concat(tempData);
      // carCalenderData.push(...tempData);
      // setSelectedCadData([
      // {
      //   title: ` $ ${dailyPrice}`,
      //   // title: `US$`,
      //   // date: '2022-07-01',
      //   daysOfWeek: ['0', '1', '2', '3', '4', '5', '6'],
      //   allDay: false,
      //   classNames: 'default_price',
      // },
      //   ...carCalenderData,
      // ]);
    });

    // console.log(carCalenderData);
    // console.log(groupByDate(carCalenderData));

    // // remove duplicate dates
    // const keys = ['date'];
    // carCalenderData = carCalenderData
    //   .filter(
    //     (
    //       (s) => (o) =>
    //         ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join('|'))
    //     )(new Set())
    //   )
    //   .map((item) => item.data);
    if (carUnavailablityData && customPriceData) {
      setSelectedCadData([
        {
          title: ` $ ${dailyPrice}`,
          // title: `US$`,
          // date: '2022-07-01',
          daysOfWeek: ['0', '1', '2', '3', '4', '5', '6'],
          allDay: false,
          classNames: 'default_price',
        },
        ...groupByDate(carCalenderData),
      ]);
    }
    else {
      dailyPrice &&
      customPriceData.length === 0 &&
      setSelectedCadData([
        {
          title: `$ ${dailyPrice}`,
          // title: `US$`,
          // date: '2022-07-01',
          daysOfWeek: ['0', '1', '2', '3', '4', '5', '6'],
          allDay: false,
          classNames: 'default_price',
        },
      ]);
    }
    
  }, [carUnavailablityData, customPriceData, dailyPrice]);

  const handleSave = (e) => {
    e.preventDefault();

    const customPriceData = {
      start_date: format(fromDate, 'yyyy-MM-dd'),
      end_date: format(untilDate, 'yyyy-MM-dd'),
      price: vehiclePrice,
      car: Number(car_id),
    };

    set_custom_price(authAxios, customPriceData).then((res) => {
      console.log(res);
      if (res.status === 200 || res.status === 201 || res.status === 304) {
        get_car_by_id(authAxios, car_id).then((resp) => {
          console.log(resp);
          if (
            resp.status === 200 ||
            resp.status === 201 ||
            resp.status === 304
          ) {
            setCustomPriceData(resp.data.custom_prices);
            toast.success('Price added successully');
          } else {
            console.log(resp);
          }
        });
      }
    });

    setVehiclePrice('');
    setFromDate(null);
    setUntilDate(null);
  };

  // const handleEventClick = (e) => {
  //   calendarApi.gotoDate(e.event._instance.range.start);
  //   calendarApi.changeView('custom_day');
  //   setCalenderType('custom_day');
  // };

  return (
    <div className='dashboard page'>
      <Sidebar page='all_cars' />
      <div className='hero_content'>
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <div className='booking_wrapper'>
            <div className='go_back' onClick={() => navigate(-1)}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5'
                viewBox='0 0 20 20'
                fill='#46a8c8'
                width={30}
              >
                <path
                  fillRule='evenodd'
                  d='M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z'
                  clipRule='evenodd'
                />
              </svg>
            </div>
            <div className='booking_header'>
              <h4>
                Vehicle :{' '}
                <span style={{ fontSize: '22px', color: '#4ec7e8' }}>
                  {' '}
                  {carDetail?.title} - {carDetail?.year} {carDetail?.make}{' '}
                  {carDetail?.model} {carDetail?.trim}
                </span>{' '}
              </h4>
            </div>
            <div className='dailyOverviewCalenderWrapper selectPricePerDayCalenderWrapper'>
              <CalenderHeader
                calenderType={calenderType}
                setCalenderType={setCalenderType}
                calendarRef={calendarRef}
                noSearch={true}
                noFilters={true}
                // filterCalender={filterCalender}
                // setFilterCalender={setFilterCalender}
              />
              <div className='selectPricePerDayContainer'>
                <div className='selectPricePerDayCalenderWrapper'>
                  <FullCalendar
                    ref={calendarRef}
                    height={450}
                    headerToolbar={false}
                    viewClassNames={'dailyOverViewCalender'}
                    dayHeaderClassNames='dayHeaderClassNames'
                    dayCellClassNames={'calender_blocks'}
                    eventClassNames='price_per_day_calender_event'
                    plugins={[dayGridPlugin]}
                    initialView='dayGridMonth'
                    events={selectedCadData}
                    // events={getEventsData(calenderDummyData)}
                    eventContent={renderEventContent}
                    // editable={true}
                    // selectable={true}
                    selectMirror={true}
                    // eventClick={handleEventClick}
                    // eventClick={(e) => calendarApi.gotoDate('2019-10-12')}
                    dayMaxEvents={true}
                    // buttonIcons={}
                    slotEventOverlap={false}
                    // slotDuration={{ hour: 2 }}
                    slotLabelInterval={{ hour: 1 }}
                    allDaySlot={false}
                    dayMaxEventRows={true}
                    eventMaxStack={calenderType === 'timeGridWeek' ? 1 : 8}
                  />
                </div>
                <div className='selectPricePerDay'>
                  <div className='selectPricePerDay_header'>
                    <div className='title'>Edit Price</div>
                    <Link
                      to={`/select_unavailablity/${car_id}`}
                      className='outlined_btn'
                    >
                      Add Unavailablity
                    </Link>
                  </div>
                  {/* <div className='selectPricePerDay_descp'>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s Lorem Ipsum is
                    simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s
                  </div> */}
                  <form
                    action='#'
                    onSubmit={(e) => handleSave(e)}
                    className='card_details'
                  >
                    <div className='info_wrapper'>
                      <div className='info_row '>
                        <div className='field fieldWithPrefix'>
                          Vehicle Price
                          <FormControl
                            className='edit_dropdown'
                            sx={{ m: 1, minWidth: 120 }}
                          >
                            <TextField
                              required
                              type={'number'}
                              className='airport_name'
                              labelid='size'
                              id='demo-simple-select'
                              label='Enter Vehicle Price'
                              value={vehiclePrice}
                              InputProps={{
                                startAdornment: '$',
                                inputProps: {
                                  min: 0,
                                },
                              }}
                              onChange={(e) => setVehiclePrice(e.target.value)}
                            ></TextField>
                          </FormControl>
                          {/* <div className='price_recomm'>
                            We Recommended US$70
                          </div> */}
                        </div>
                      </div>
                      <div className='info_row '>
                        <div className='field'>
                          From
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              // shouldDisableDate={(23, 24, 25) => true}
                              className=''
                              disablePast
                              label='Date'
                              value={fromDate}
                              onChange={(newValue) => {
                                setFromDate(newValue);
                              }}
                              components={{
                                OpenPickerIcon: Calender,
                              }}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  className=' date_trip_mngmnt'
                                  size='small'
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                        <div className='field'>
                          Until
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              // shouldDisableDate={(23, 24, 25) => true}
                              className=''
                              disablePast
                              label='Date'
                              value={untilDate}
                              onChange={(newValue) => {
                                setUntilDate(newValue);
                              }}
                              components={{
                                OpenPickerIcon: Calender,
                              }}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  className=' date_trip_mngmnt'
                                  size='small'
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                    <Button className='editPriceBtn' type='submit'>
                      Save
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
