import { createPlugin, formatDate, sliceEvents } from '@fullcalendar/core';
import { format, intervalToDuration } from 'date-fns';
import moment from 'moment';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Carousel from 'react-material-ui-carousel';
import './index.css';

import Notes from '../../TripNote';

function CustomContent(props) {
  // const navigate = useNavigate();

  // const _calendarApi = new CalendarApi();
  // console.log(this._calendarApi);
  const formattedDate = formatDate(props.dateProfile.currentRange.end, {
    weekday: 'long',
    month: 'long',
    year: 'numeric',
    day: '2-digit',
  });
  let segs = sliceEvents(props, true); // allDay=true
  // console.log(new Date().getTimezoneOffset() / 60);
  // console.log(props.dateProfile.currentRange);
  const todayDate = moment(props.dateProfile.currentRange.end).format(
    'yyyy-MM-DD'
  );

  const formatBookingDate = (date) => {
    return moment(date.substring(0, 16)).format('yyyy-MM-DD');
  };
  const getInterval = (fromDate, untilDate) => {
    // return intervalToDuration({
    //   start: fromDate,
    //   end: untilDate,
    // }).days;

    const start = new Date(fromDate);
    const end = new Date(untilDate);
    const diffInMs = end - start;
    const diffInHours = diffInMs / (1000 * 60 * 60);
    const totalDays = Math.ceil(diffInHours / 24);

    return totalDays;
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  // const getNotes = () => {
  //   get_notes(authAxios, id).then((res) => {
  //     if (res.status === 200) {
  //       setNotes(res.data);
  //     } else {
  //       toast.error(Object.values(res.response.data)[0]);
  //     }
  //   });
  // };

  // const BookingModal = (bookingData) => {
  //   console.log(bookingData);

  //   const interval = getInterval(
  //     new Date(bookingData.start_date),
  //     new Date(bookingData.end_date)
  //   );
  //   const bookingStartDate = format(
  //     new Date(bookingData.start_date),
  //     "yyyy-MM-dd hh:mm aaaaa'm'"
  //   );
  //   const bookingEndDate = format(
  //     new Date(bookingData.end_date),
  //     "yyyy-MM-dd hh:mm aaaaa'm'"
  //   );
  //   const bookedDate = format(new Date(bookingData.booked_date), 'yyyy-MM-dd');

  //   return confirmAlert({
  //     customUI: ({ onClose }) => {
  //       return (
  //         <div className='editModal overviewBooknigModal tripDetailModal'>
  //           <Carousel className='trip_modal_carousal'>
  //             {bookingData?.car.car_images?.map((img, index) => (
  //               <img key={index} src={img.image} alt='img' width={'100%'} />
  //             ))}
  //           </Carousel>
  //           <div className='body_header'>
  //             {/* <div> Trip Management </div> */}
  //           </div>
  //           <div className='tripDetail'>
  //             <div className='tripDetail_col'>
  //               <div>
  //                 Customer : <span>{bookingData.customer.name}</span>
  //               </div>
  //               <div>
  //                 Phone No: <span>{bookingData.customer.phone}</span>
  //               </div>
  //               <div>
  //                 Start Date: <span>{bookingStartDate}</span>
  //               </div>
  //               <div>
  //                 Airline Name:{' '}
  //                 <span>{bookingData.airline_name ?? 'Not Given'}</span>
  //               </div>
  //               <div>
  //                 Booking Price: <span>{bookingData.price}</span>
  //               </div>
  //               <div>
  //                 Insurance Expiry Date:{' '}
  //                 <span>{bookingData.customer.insurance_expiry_date}</span>
  //               </div>
  //               <div>
  //                 Mileage Overage Fee:{' '}
  //                 <span>
  //                   {bookingData.car.mileage_overage_fee ?? 'Not Given'}
  //                 </span>
  //               </div>
  //             </div>
  //             <div className='tripDetail_col'>
  //               <div>
  //                 Product Name: <span>{bookingData.car.title}</span>
  //               </div>
  //               <div>
  //                 Booked On: <span>{bookedDate}</span>
  //               </div>
  //               <div>
  //                 End Date: <span>{bookingEndDate}</span>
  //               </div>
  //               <div>
  //                 Flight No:{' '}
  //                 <span>{bookingData.flight_number ?? 'Not Given'}</span>
  //               </div>
  //               <div>
  //                 Booking Price per day:{' '}
  //                 <span>{bookingData.car.daily_price}</span>
  //               </div>
  //               <div>
  //                 Total Days: <span>{interval === 0 ? 1 : interval} Days</span>
  //               </div>
  //               <div>
  //                 Daily Max Miles:{' '}
  //                 <span>
  //                   {bookingData.car.daily_max_miles ?? 'Not Given'} Days
  //                 </span>
  //               </div>
  //             </div>
  //           </div>

  //           <div className='trip_detail_actions'>
  //             <a
  //               href={'/trips/' + bookingData.id}
  //               className='booking_modal_trip_btn'
  //               onClick={() => {
  //                 // navigate('/trip_information');
  //                 // onClose();
  //                 // dispatch(setTrip(trip));
  //               }}
  //             >
  //               Trip Management
  //             </a>
  //           </div>
  //         </div>
  //       );
  //     },
  //   });
  // };

  const CustomTripDetailModal = (trip) => {
    const interval = getInterval(
      new Date(trip.start_date),
      new Date(trip.end_date)
    );

    const bookedDate = format(new Date(trip.booked_date), 'MM/dd/yyyy');
    console.log(trip);

    return confirmAlert({
      overlayClassName: 'tripDetailModalWrapper',
      customUI: ({ onClose }) => {
        return (
          <div className='editModal tripDetailModal'>
            <div
              className='close_icon'
              style={{ top: '1.5rem', right: '1.5rem' }}
              onClick={() => onClose()}
            >
              <svg
                width='25'
                height='25'
                viewBox='0 0 110 110'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
            <Carousel className='trip_modal_carousal'>
              {trip?.car.car_images.map((img, index) => (
                <img key={index} src={img.image} alt='img' width={'100%'} />
              ))}
            </Carousel>
            <div className='modal_detail_header'>
              <div>
                <div
                  onClick={() => {
                    onClose();
                    // navigate(`/user_detail/${trip.customer.id}`);
                  }}
                  className='title'
                  style={{
                    fontSize: '22px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    color: '#4fc8e9',
                  }}
                >
                  {trip.customer.name}
                </div>
                <div style={{ fontSize: '18px' }}>
                  {formatPhoneNumber(trip.customer.phone)}
                  {/* Booked by{' '}<span style={{ color: '#4fc8e9' }}>{trip.booked_by}</span> */}
                </div>
                <div style={{ fontSize: '18px' }}>$ {trip.price}</div>

                <div>Daily Rate: $ {trip.daily_price}</div>
              </div>
              <div
                style={{
                  textAlign: 'right',
                }}
              >
                <div
                  style={{
                    display: 'none',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: '1rem',
                  }}
                >
                  <div
                    className='req_reimbursement'
                    onClick={() => {
                      // setReimbursementModal(true);
                    }}
                  >
                    + Request Reimbursementcvbnjm
                  </div>
                  <div
                    className='req_reimbursement'
                    onClick={() => {
                      // EditBookingModal(trip);
                    }}
                    style={{ color: '#4fc8e9' }}
                  >
                    Edit
                  </div>
                </div>
                <div style={{ color: 'rgb(79, 200, 233)', fontSize: '18px' }}>
                  Outbound Location: {trip?.pickup_location?.code}
                </div>
                <div style={{ color: 'rgb(79, 200, 233)', fontSize: '18px' }}>
                  Return Location: {trip?.destination_location?.code}
                </div>
                <div style={{ fontSize: '18px' }}>
                  <div>
                    From:{' '}
                    {moment(trip?.start_date).format('MMM DD, yyyy hh:mm A')}
                  </div>
                  <div>
                    Until:{' '}
                    {moment(trip?.end_date).format('MMM DD, yyyy hh:mm A')}
                  </div>
                </div>
                <div>Total Days: {interval === 0 ? 1 : interval}</div>
              </div>
            </div>
            <div className='tripDetail'>
              <div style={{ width: '60%' }} className='tripDetail_col'>
                <div>
                  Year/Make/Model/Trim:{' '}
                  <span>
                    {trip.car.year}/{trip.car.make}/{trip.car.model}/
                    {trip.car.trim}
                  </span>
                </div>
                <div>
                  Airline: <span>{trip.airline_name}</span>
                </div>
                <div>
                  Miles Alloted:{' '}
                  <span>
                    {trip.car.daily_max_miles * (interval === 0 ? 1 : interval)}
                  </span>
                </div>
                <div>
                  Booked On: <span>{bookedDate}</span>
                </div>
              </div>
              <div style={{ width: '40%' }} className='tripDetail_col'>
                <div>
                  Car Id: <span>{trip.car.title}</span>
                </div>
                <div>
                  Flight #: <span>{trip.flight_number}</span>
                </div>
                <div>
                  Miles Overage: <span>$ {trip.car.mileage_overage_fee}</span>
                </div>
                <div>
                  Booked By: <span>{trip.booked_by}</span>
                </div>
              </div>
            </div>
            <div className='trip_detail_actions'>
              <a
                href={'/trips/' + trip.id}
                className='booking_modal_trip_btn'
                onClick={() => {
                  // navigate('/trip_information');
                  // onClose();
                  // dispatch(setTrip(trip));
                }}
              >
                Trip Management
              </a>
            </div>
            <div style={{ marginTop: '20px' }}>
              <Notes authAxios={trip.authAxios} id={trip.id} user={trip.user} />
            </div>
          </div>
        );
      },
    });
  };
  // console.log('🚀OUTPUT --> segs:', segs);

  return (
    <div id='custom_day_view' className='custom_day_view'>
      <div className='custom_day_view_header'>
        {
          //props.dateProfile.currentRange.start.getUTCFullYear()
          formattedDate
        }
      </div>
      <table>
        <thead>
          <th className='serial_no'></th>
          <th>Outbound</th>
          <th>Timing</th>
          <th>Return</th>
        </thead>
        {segs.length > 0 ? (
          <tbody>
            {segs
              .sort(function (a, b) {
                if (a.def.extendedProps?.come && b.def.extendedProps?.come) {
                  // Both are 'come': Compare based on 'comingIn_time'
                  const aTime = new Date(`2000-01-01 ${a.def.extendedProps?.comingIn_time}`).getTime();
                  const bTime = new Date(`2000-01-01 ${b.def.extendedProps?.comingIn_time}`).getTime();
                  return aTime - bTime;
                } else if (!a.def.extendedProps?.come && !b.def.extendedProps?.come) {
                  // Both are 'not come': Compare based on 'time'
                  const aTime = new Date(`2000-01-01 ${a.def.extendedProps?.time}`).getTime();
                  const bTime = new Date(`2000-01-01 ${b.def.extendedProps?.time}`).getTime();
                  return aTime - bTime;
                } else if (!a.def.extendedProps?.come && b.def.extendedProps?.come) {
                  // 'a' is 'not come', 'b' is 'come': Compare based on 'time' and 'comingIn_time'
                  const aTime = new Date(`2000-01-01 ${a.def.extendedProps?.time}`).getTime();
                  const bTime = new Date(`2000-01-01 ${b.def.extendedProps?.comingIn_time}`).getTime();
                  return aTime - bTime;
                } else {
                  // 'a' is 'come', 'b' is 'not come': Compare based on 'comingIn_time' and 'time'
                  const aTime = new Date(`2000-01-01 ${a.def.extendedProps?.comingIn_time}`).getTime();
                  const bTime = new Date(`2000-01-01 ${b.def.extendedProps?.time}`).getTime();
                  return aTime - bTime;
                }
              })
              .map(({ def, ui }, index) =>
                def.extendedProps?.bookingDetail
                  ? (todayDate ===
                      formatBookingDate(
                        def.extendedProps?.bookingDetail?.end_date
                      ) ||
                      todayDate ===
                        formatBookingDate(
                          def.extendedProps?.bookingDetail?.start_date
                        )) && (
                      <tr>
                        {console.log('def => ',def)}
                        <td style={{ width: '5%' }} className='serial_no'>
                          {index + 1}
                        </td>
                        <td
                          style={{ width: '40%' }}
                          className={`going_out ${
                            def.extendedProps?.turn_goingOut &&
                            'event_turn_goingOut'
                          }`}
                        >
                          {todayDate ===
                          formatBookingDate(
                            def.extendedProps?.bookingDetail?.start_date
                          )
                            ? def.extendedProps?.going_out && (
                                <div
                                  onClick={() =>
                                    CustomTripDetailModal(
                                      def.extendedProps?.bookingDetail
                                    )
                                  }
                                  className='day_event_wrapper'
                                >
                                  {def.extendedProps?.turn_goingOut ? (
                                    <div>
                                      {def.extendedProps?.turn_goingOut}
                                    </div>
                                  ) : (
                                    <div className='car_detail'>
                                      {def.extendedProps?.unavailability && (
                                        <div
                                          className='unavailibility'
                                          title='Unavailable'
                                        >
                                          <svg
                                            width={20}
                                            fill='none'
                                            viewBox='0 0 24 24'
                                            className='w-6 h-6'
                                          >
                                            <path
                                              strokeLinecap='round'
                                              strokeLinejoin='round'
                                              d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
                                            />
                                          </svg>
                                        </div>
                                      )}
                                      {def.extendedProps?.bookingDetail
                                        .trip_notes.length > 0 && (
                                        <div
                                          className='unavailibility containNote'
                                          title='Unavailable'
                                        >
                                          <svg
                                            width={20}
                                            // fill='currentColor'
                                            viewBox='0 0 24 24'
                                            strokeWidth={1.5}
                                            stroke='currentColor'
                                            className='w-6 h-6'
                                            style={{ fill: '#f0f04b' }}
                                          >
                                            <path
                                              strokeLinecap='round'
                                              strokeLinejoin='round'
                                              d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
                                            />
                                          </svg>
                                        </div>
                                      )}
                                      <div
                                        style={{
                                          color:
                                            segs.length > 1 &&
                                            segs
                                              .map(
                                                (e) =>
                                                  e?.def.extendedProps
                                                    ?.carDetails?.name ===
                                                  def?.extendedProps?.carDetails
                                                    ?.name
                                              )
                                              .every(
                                                (element) => element === true
                                              ) &&
                                            '#e9c63f',
                                        }}
                                        className='car_name'
                                      >
                                        <span style={{ color: '#4fc8e9' }}>
                                          Customer:
                                        </span>{' '}
                                        {
                                          def.extendedProps?.bookingDetail
                                            ?.customer?.name
                                        }
                                      </div>
                                      <div
                                        style={{
                                          color:
                                            segs.length > 1 &&
                                            segs
                                              .map(
                                                (e) =>
                                                  e?.def.extendedProps
                                                    ?.carDetails?.name ===
                                                  def?.extendedProps?.carDetails
                                                    ?.name
                                              )
                                              .every(
                                                (element) => element === true
                                              ) &&
                                            '#e9c63f',
                                        }}
                                        className='car_name'
                                      >
                                        <span style={{ color: '#4fc8e9' }}>
                                          Phone Number:
                                        </span>{' '}
                                        {formatPhoneNumber(
                                          def.extendedProps?.bookingDetail
                                            ?.customer?.phone
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          color:
                                            segs.length > 1 &&
                                            segs
                                              .map(
                                                (e) =>
                                                  e?.def.extendedProps
                                                    ?.carDetails?.name ===
                                                  def?.extendedProps?.carDetails
                                                    ?.name
                                              )
                                              .every(
                                                (element) => element === true
                                              ) &&
                                            '#e9c63f',
                                        }}
                                        className='car_name'
                                      >
                                        <span style={{ color: '#4fc8e9' }}>
                                          Car Model:
                                        </span>{' '}
                                        {
                                          def.extendedProps?.bookingDetail?.car
                                            ?.make
                                        }{' '}
                                        {
                                          def.extendedProps?.bookingDetail?.car
                                            ?.model
                                        }
                                      </div>
                                      <div
                                        style={{
                                          color:
                                            segs.length > 1 &&
                                            segs
                                              .map(
                                                (e) =>
                                                  e?.def.extendedProps
                                                    ?.carDetails?.name ===
                                                  def?.extendedProps?.carDetails
                                                    ?.name
                                              )
                                              .every(
                                                (element) => element === true
                                              ) &&
                                            '#e9c63f',
                                        }}
                                        className='car_name'
                                      >
                                        <span style={{ color: '#4fc8e9' }}>
                                          Code:{' '}
                                        </span>
                                        {def.extendedProps?.carDetails?.id}
                                        {def.extendedProps?.carDetails?.name}
                                      </div>
                                      {/*  */}
                                      <div
                                        style={{
                                          color:
                                            segs.length > 1 &&
                                            segs
                                              .map(
                                                (e) =>
                                                  e?.def.extendedProps
                                                    ?.carDetails?.name ===
                                                  def?.extendedProps?.carDetails
                                                    ?.name
                                              )
                                              .every(
                                                (element) => element === true
                                              )
                                              ? '#e9c63f'
                                              : def.extendedProps?.bookingDetail
                                                  ?.pickup_location?.color,
                                        }}
                                        className='car_name'
                                      >
                                        <span style={{ color: '#4fc8e9' }}>
                                          Outbound Location:{' '}
                                        </span>
                                        {
                                          def.extendedProps?.bookingDetail
                                            ?.pickup_location?.code
                                        }
                                      </div>
                                      <div
                                        style={{
                                          color:
                                            segs.length > 1 &&
                                            segs
                                              .map(
                                                (e) =>
                                                  e?.def.extendedProps
                                                    ?.carDetails?.name ===
                                                  def?.extendedProps?.carDetails
                                                    ?.name
                                              )
                                              .every(
                                                (element) => element === true
                                              ) &&
                                            '#e9c63f',
                                        }}
                                        className='car_name'
                                      >
                                        <span style={{ color: '#4fc8e9' }}>
                                          Flight Number:{' '}
                                        </span>
                                        {
                                          def.extendedProps?.bookingDetail
                                            ?.flight_number
                                        }
                                      </div>
                                      <div
                                        style={{
                                          textTransform: 'uppercase',
                                          color:
                                            segs.length > 1 &&
                                            segs
                                              .map(
                                                (e) =>
                                                  e?.def.extendedProps
                                                    ?.carDetails?.name ===
                                                  def?.extendedProps?.carDetails
                                                    ?.name
                                              )
                                              .every(
                                                (element) => element === true
                                              ) &&
                                            '#e9c63f',
                                        }}
                                        className={`car_name ${def.extendedProps?.bookingDetail?.car?.vehicle_status}`}
                                      >
                                        <span
                                          style={{
                                            color: '#4fc8e9',
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          Vehicle Status:{' '}
                                        </span>
                                        {
                                          def.extendedProps?.bookingDetail?.car
                                            ?.vehicle_status
                                        }
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )
                            : ''}
                        </td>
                        <td style={{ width: '15%', position: 'relative' }}>
                          <div className='custom_day_view_time_wrapper day_event_wrapper'>
                            <div className='car_detail'>
                              <div style={{ fontSize: '24px' }}>
                                {def.extendedProps?.come ? def.extendedProps?.comingIn_time : def.extendedProps?.time}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td
                          style={{ width: '40%' }}
                          className={`coming_in ${
                            def.extendedProps?.turn && 'event_turn'
                          }`}
                        >
                          <div
                            onClick={() =>
                              def.extendedProps?.bookingDetail &&
                              CustomTripDetailModal(
                                def.extendedProps?.bookingDetail
                              )
                            }
                            className='day_event_wrapper'
                          >
                            {todayDate ===
                            formatBookingDate(
                              def.extendedProps?.bookingDetail?.end_date
                            ) ? (
                              def.extendedProps?.turn ? (
                                <div>{def.extendedProps?.turn}</div>
                              ) : (
                                <div className='car_detail'>
                                  {def.extendedProps?.unavailability && (
                                    <div
                                      className='unavailibility'
                                      title='Unavailable'
                                    >
                                      <svg
                                        width={20}
                                        fill='none'
                                        viewBox='0 0 24 24'
                                        className='w-6 h-6'
                                      >
                                        <path
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
                                        />
                                      </svg>
                                    </div>
                                  )}
                                  {def.extendedProps?.bookingDetail.trip_notes
                                    .length > 0 && (
                                    <div
                                      className='unavailibility containNote'
                                      title='Unavailable'
                                    >
                                      <svg
                                        width={20}
                                        viewBox='0 0 24 24'
                                        strokeWidth={1.5}
                                        stroke='currentColor'
                                        className='w-6 h-6'
                                        style={{ fill: '#f0f04b' }}
                                      >
                                        <path
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
                                        />
                                      </svg>
                                    </div>
                                  )}
                                  <div
                                    style={{
                                      color:
                                        segs.length > 1 &&
                                        segs
                                          .map(
                                            (e) =>
                                              e?.def.extendedProps?.carDetails
                                                ?.name ===
                                              def?.extendedProps?.carDetails
                                                ?.name
                                          )
                                          .every(
                                            (element) => element === true
                                          ) &&
                                        '#e9c63f',
                                    }}
                                    className='car_name'
                                  >
                                    <span style={{ color: '#4fc8e9' }}>
                                      Customer:
                                    </span>{' '}
                                    {
                                      def.extendedProps?.bookingDetail?.customer
                                        ?.name
                                    }
                                  </div>
                                  <div
                                    style={{
                                      color:
                                        segs.length > 1 &&
                                        segs
                                          .map(
                                            (e) =>
                                              e?.def.extendedProps?.carDetails
                                                ?.name ===
                                              def?.extendedProps?.carDetails
                                                ?.name
                                          )
                                          .every(
                                            (element) => element === true
                                          ) &&
                                        '#e9c63f',
                                    }}
                                    className='car_name'
                                  >
                                    <span style={{ color: '#4fc8e9' }}>
                                      Phone Number:
                                    </span>{' '}
                                    {formatPhoneNumber(
                                      def.extendedProps?.bookingDetail?.customer
                                        ?.phone
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      color:
                                        segs.length > 1 &&
                                        segs
                                          .map(
                                            (e) =>
                                              e?.def.extendedProps?.carDetails
                                                ?.name ===
                                              def?.extendedProps?.carDetails
                                                ?.name
                                          )
                                          .every(
                                            (element) => element === true
                                          ) &&
                                        '#e9c63f',
                                    }}
                                    className='car_name'
                                  >
                                    <span style={{ color: '#4fc8e9' }}>
                                      Car Model:
                                    </span>{' '}
                                    {
                                      def.extendedProps?.bookingDetail?.car
                                        ?.make
                                    }{' '}
                                    {
                                      def.extendedProps?.bookingDetail?.car
                                        ?.model
                                    }
                                  </div>
                                  <div
                                    style={{
                                      color:
                                        segs.length > 1 &&
                                        segs
                                          .map(
                                            (e) =>
                                              e?.def.extendedProps?.carDetails
                                                ?.name ===
                                              def?.extendedProps?.carDetails
                                                ?.name
                                          )
                                          .every(
                                            (element) => element === true
                                          ) &&
                                        '#e9c63f',
                                    }}
                                    className='car_name'
                                  >
                                    <span style={{ color: '#4fc8e9' }}>
                                      Code:
                                    </span>{' '}
                                    {def.extendedProps?.carDetails?.id}
                                    {def.extendedProps?.carDetails?.name}
                                  </div>
                                  <div>
                                    {segs.length > 1 &&
                                      segs
                                        .map(
                                          (e) =>
                                            e?.def.extendedProps?.carDetails
                                              ?.name ===
                                            def?.extendedProps?.carDetails?.name
                                        )
                                        .every((element) => element === true) &&
                                      'TURN'}
                                  </div>
                                  {/*  */}
                                  <div
                                    style={{
                                      color:
                                        segs.length > 1 &&
                                        segs
                                          .map(
                                            (e) =>
                                              e?.def.extendedProps?.carDetails
                                                ?.name ===
                                              def?.extendedProps?.carDetails
                                                ?.name
                                          )
                                          .every((element) => element === true)
                                          ? '#e9c63f'
                                          : def.extendedProps?.bookingDetail
                                              ?.destination_location?.color,
                                    }}
                                    className='car_name'
                                  >
                                    <span style={{ color: '#4fc8e9' }}>
                                      Return Location:{' '}
                                    </span>
                                    {
                                      def.extendedProps?.bookingDetail
                                        ?.destination_location?.code
                                    }
                                  </div>
                                  <div
                                    style={{
                                      color:
                                        segs.length > 1 &&
                                        segs
                                          .map(
                                            (e) =>
                                              e?.def.extendedProps?.carDetails
                                                ?.name ===
                                              def?.extendedProps?.carDetails
                                                ?.name
                                          )
                                          .every(
                                            (element) => element === true
                                          ) &&
                                        '#e9c63f',
                                    }}
                                    className='car_name'
                                  >
                                    <span style={{ color: '#4fc8e9' }}>
                                      Flight Number:{' '}
                                    </span>
                                    {
                                      def.extendedProps?.bookingDetail
                                        ?.flight_number
                                    }
                                  </div>
                                  <div
                                    style={{
                                      textTransform: 'uppercase',
                                      color:
                                        segs.length > 1 &&
                                        segs
                                          .map(
                                            (e) =>
                                              e?.def.extendedProps?.carDetails
                                                ?.name ===
                                              def?.extendedProps?.carDetails
                                                ?.name
                                          )
                                          .every(
                                            (element) => element === true
                                          ) &&
                                        '#e9c63f',
                                    }}
                                    className={`car_name ${def.extendedProps?.bookingDetail?.car?.vehicle_status}`}
                                  >
                                    <span
                                      style={{
                                        color: '#4fc8e9',
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      Vehicle Status:{' '}
                                    </span>
                                    {
                                      def.extendedProps?.bookingDetail?.car
                                        ?.vehicle_status
                                    }
                                  </div>
                                  {/* <div className='car_total_cost'>
                                  Total Cost:{' '}
                                  {def.extendedProps?.bookingDetail?.price}
                                </div> */}
                                </div>
                              )
                            ) : (
                              ''
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  : ui?.classNames[0] === 'gcal-event' && (
                      <tr>
                        <td className='serial_no'>{index + 1}</td>
                        <td>{def?.title}</td>
                        <td>
                          <a
                            href={def?.url}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            View in Calendar
                          </a>
                        </td>
                        <td></td>
                      </tr>
                    )
              )}
          </tbody>
        ) : (
          //   <div className='no_car_available'>No Cars Available</div>
          <div className='no_car_available'></div>
        )}
      </table>
    </div>
  );
}

const CustomViewConfig = {
  classNames: ['custom-view'],

  content: (props) => CustomContent(props),
};

export default createPlugin({
  views: {
    custom_day: CustomViewConfig,
  },
});
