import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { get_all_cars, get_car } from './../API/car_booking';

export const useCarsDropdown = (fromDate, untilDate, loader = null, page, prevData, selectedCar=null, bookingId = null) => {
  console.log('🚀OUTPUT --> prevData:', prevData);
  const [cars, setCars] = useState([]);
  const { authAxios } = useContext(AuthContext);

  useEffect(() => {
    get_all_cars(authAxios, fromDate, untilDate, bookingId, page)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          if(selectedCar && !res?.data?.results?.some((car) => car.id === selectedCar)) {
            getCar(selectedCar, page ? {
              data: [...prevData, ...res?.data?.results],
              pagination: {
                count: res?.data?.count,
                previous: res?.data?.previous,
                next: res?.data?.next,
                total_pages: res?.data?.total_pages,
                page_number: res?.data?.page_number,
              },
            }:{
              data: res?.data?.results,
              pagination: {
                count: res?.data?.count,
                previous: res?.data?.previous,
                next: res?.data?.next,
                total_pages: res?.data?.total_pages,
                page_number: res?.data?.page_number,
              },
            },
            fromDate,
            untilDate
          )
          }
          if(page){
            setCars({
              data: [...prevData, ...res?.data?.results],
              pagination: {
                count: res?.data?.count,
                previous: res?.data?.previous,
                next: res?.data?.next,
                total_pages: res?.data?.total_pages,
                page_number: res?.data?.page_number,
              },
            });
          } else {
            setCars({
              data: res?.data?.results,
              pagination: {
                count: res?.data?.count,
                previous: res?.data?.previous,
                next: res?.data?.next,
                total_pages: res?.data?.total_pages,
                page_number: res?.data?.page_number,
              },
            });
          }
        } else {
          setCars([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
      const getCar = async (id, prevCars, fromDate, untilDate) => {
        const res = await get_car(authAxios,id, fromDate, untilDate);
        if (res.status === 200) {
          const item = res.data;
          setCars({
            ...prevCars,
            data: [...prevCars.data, item],
            
          });
          
        }
       }
  }, [authAxios, fromDate, untilDate, loader, page]);

  return [cars];
};
