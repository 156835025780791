import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import './App.css';
import { AuthContextProvider } from './contexts/AuthContext';
import { NotificationContextProvider } from './contexts/NotificationContext';
import ProtectedRoute from './components/ProtectedRoute';
// import 'bootstrap/dist/css/bootstrap.min.css';

import Dashboard from './pages/Dashboard';
import OnRent from './pages/Inventory/OnRent';
import AllCars from './pages/Inventory/AllCars';
import AddVehicles from './pages/AddVehicles';
import AddManualCar from './pages/AddBooking';
import EditTrip from './pages/EditTrip';
import AddPerson from './pages/AddPerson';
import CarDetail from './pages/CarDetailPage/index';
import PreviousCustomer from './pages/PreviousCustomer';
import AllUsers from './pages/AllUsers';
import DailyOverview from './pages/DailyOverview';
import DiscountCoupon from './pages/DiscountCoupon';
import Messages from './pages/Messages';
import CarReportDetail from './pages/CarReportDetail';
import CreateCoupon from './pages/CreateCoupon';
import TripManagement from './pages/TripManagement';
import TripPreferences from './pages/TripPreferences';
import SelectPricePerDay from './pages/SelectPricePerDay';
import SelectUnavailablity from './pages/SelectUnavailablity';
import Payment from './pages/Payment';
import CarPhotos from './pages/CarPhotos';
import TripInformation from './pages/TripInformation';
import Login from './pages/Login';
import Companies from './pages/Companies';
import Packages from './pages/Packages';
import SystemConfigurations from './pages/SystemConfigurations';
import NotificationHistory from './pages/NotificationHistory';
import Customize from './pages/Customize';
import Profile from './pages/Profile';
import Theme from './pages/Theme';
import Settings from './pages/Settings';
import CarBookings from './pages/CarBookings';
import AccessLevels from './pages/AccessLevels';
import AccessLevelUser from './pages/AccessLevelUser';
import Register from './pages/Register';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import UserDetail from './pages/UserDetail';
import CarReportMain from './pages/CarReportMain';

const stripePromise = loadStripe(
  'pk_live_51LXa59CslfodJUTgWc4eopGCF8oNmTMtS5SgmUfdlbeuA9kui2kFxiaSzIRkODJvbkJB24mgVsabciqbrSaiqd0800B8qTbjsO'
);

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path='/inventory/on_rent'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <OnRent />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          exact
          path='/inventory/all_cars'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <AllCars />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          exact
          path='/inventory/all_cars/:car_id'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <AllCars />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          exact
          path='/add_vehicle'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <AddVehicles />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          exact
          path='/add_booking'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <AddManualCar />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          exact
          path='/edit_booking/:customer_id/:id'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <EditTrip />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          exact
          path='/add_booking/:customer_id'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <AddManualCar />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          exact
          path='/add_person'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <AddPerson />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          exact
          path='/add_person/:id/:trip'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <AddPerson />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/car/:id'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <CarDetail />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/prev_customers'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <PreviousCustomer />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        {/* <Route
          path='/view_bookings'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <ViewBookings />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        /> */}
        <Route
          path='/settings'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/all_users'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <AllUsers />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/all_users/:redirect'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <AllUsers />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/all_users/:redirect/:trip'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <AllUsers />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/user_detail/:id'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <UserDetail />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/daily_overview'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <DailyOverview />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/discount_coupon'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <DiscountCoupon />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/messages'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <Messages />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          exact
          path='/messages/:customer_id'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <Messages />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/car_report'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  {/* <CarReport /> */}
                  <CarReportMain />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/car_report/:id'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <CarReportDetail />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/create_coupon'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <CreateCoupon />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/trips'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <TripManagement />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/trips/:trip_id'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <TripManagement />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/car_bookings/:car_id'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <CarBookings />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/trip_preferences/:car_id'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <TripPreferences />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/car_photos'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <CarPhotos />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/trip_information'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <TripInformation />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/payment'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <Elements stripe={stripePromise}>
                    <Payment />
                  </Elements>
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/select_price_per_day/:car_id'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <SelectPricePerDay />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/select_unavailablity/:car_id'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <SelectUnavailablity />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/notification_history'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <NotificationHistory />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/companies'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <Companies />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/register/:pack'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <Register />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/packages'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <Packages />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/access_level'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <AccessLevels />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/access_level_user'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <AccessLevelUser />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/themes'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <Theme />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/system-configurations'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <SystemConfigurations />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/customize'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <Customize />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='/profile'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          exact
          path='/'
          element={
            <AuthContextProvider>
              <NotificationContextProvider>
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              </NotificationContextProvider>
            </AuthContextProvider>
          }
        />
        <Route
          path='login'
          element={
            <AuthContextProvider>
              <Login />
            </AuthContextProvider>
          }
        />
        <Route
          path='login/:buffString'
          element={
            <AuthContextProvider>
              <Login />
            </AuthContextProvider>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
