import React, { useEffect, useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import toast from 'react-hot-toast';
import { AuthContext } from '../../../../contexts/AuthContext';
import {
  get_features,
  create_feature_settings,
  create_feature,
  delete_feature,
} from '../../../../API/setting';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { confirmAlert } from 'react-confirm-alert'; // Import
import Modal from '@mui/material/Modal';

let __loaded_features__ = [];
export default function Features() {
  const { authAxios } = useContext(AuthContext);
  const [car_features, setCarFeatures] = useState([]);
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);

  const [createFeatureName, setCreateFeatureName] = useState('');
  const [createFeatureDescription, setCreateFeatureDescription] = useState('');

  useEffect(() => {
    getFeatures();
  }, []);

  const handleChangeFeature = (e, id) => {
    setCarFeatures(
      car_features.map((feat) => {
        if (feat.id == id) {
          return {
            ...feat,
            isUsed: !feat.isUsed,
          };
        } else return feat;
      })
    );
  };

  const handleSaveFeatures = async (e) => {
    e.preventDefault();
    const feature_ids = car_features
      .filter((feat) => feat.isUsed)
      .map((feat) => feat.id);
    let res = await create_feature_settings(authAxios, { feature_ids });
    if (res.status === 200) {
      toast.success('Created Successfully');
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };
  const handleCreateFeature = async (e) => {
    let res = await create_feature(authAxios, {
      name: createFeatureName,
      description: createFeatureDescription,
      isUsed: true,
    });
    if (res.status === 201) {
      setCreateModal(false);
      setCreateFeatureDescription('');
      setCreateFeatureName('');
      await getFeatures(authAxios);
      toast.success('Created Successfully');
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  const handleDeleteFeature = async (e, id, onClose) => {
    e.preventDefault();

    let res = await delete_feature(authAxios, id);
    if (res.status === 204) {
      setDeleteModal(false);
      await getFeatures(authAxios);
      toast.success('Deleted Successfully');
      onClose();
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  const getFeatures = async () => {
    let res = await get_features(authAxios);
    if (res.status === 200) {
      console.log(res.data);
      setCarFeatures(res.data);
      setEdit(false);
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  const FeatDeleteModal = (featId, featName) => {
    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='confModal'>
            <svg
              width='110'
              height='110'
              viewBox='0 0 110 110'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                stroke='#FF2552'
                strokeWidth='11.625'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                stroke='#FF2552'
                strokeWidth='11.625'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>{' '}
            <br />
            <h4>Are You Sure?</h4>
            <p>
              Do you really want to delete{' '}
              <span style={{ color: '#4fc8e9' }}>{featName}</span>? This process
              cannot be undone.
            </p>
            <div className='btns'>
              <button onClick={() => onClose()} className='secondary'>
                Cancel
              </button>
              <button
                className='primary'
                onClick={(e) => handleDeleteFeature(e, featId, onClose)}
              >
                Delete
              </button>
            </div>
          </div>
        );
      },
    });
  };

  return (
    <div className='carFeatures'>
      <div className='carFeatures_header'>
        <h5>Car Features</h5>
        <div>
          {!edit && (
            <>
              <button
                className='carFeatures_edit_button margin-right10'
                onClick={(e) => {
                  setEdit(true);
                  __loaded_features__ = JSON.parse(
                    JSON.stringify(car_features)
                  );
                }}
              >
                {' '}
                Edit{' '}
              </button>
              <button
                // style={{ visibility: edit && 'hidden' }}
                className={'carFeatures_edit_button margin-right10'}
                onClick={(e) => {
                  e.preventDefault();
                  setCreateModal(true);
                }}
              >
                Create New
              </button>
            </>
          )}
          {edit && (
            <button
              className='all_users_secondary_button'
              onClick={(e) => {
                setEdit(false);
                setCarFeatures(__loaded_features__);
              }}
            >
              {' '}
              Cancel{' '}
            </button>
          )}
        </div>
      </div>
      <div className='features'>
        <FormGroup className='feature_checkboxs'>
          {car_features.map((feat) => (
            <>
              <FormControlLabel
                className='feature_checkbox_wrapper'
                control={
                  <Checkbox
                    hidden
                    className='feature_checkbox'
                    checked={feat.isUsed ? true : false}
                    disabled={!edit}
                    onChange={(e) => handleChangeFeature(e, feat.id)}
                  />
                }
                label={
                  <div className='feature_checkbox_label_wrapper'>
                    <div className='feature_checkbox_label'>{feat.name}</div>
                    {edit && (
                      <div className={`feature_checkbox_del_icon`}>
                        <HighlightOffIcon
                          onClick={() => {
                            FeatDeleteModal(feat.id, feat.name);
                            // setDeleteModal(true);
                            // setSelectedFeature(feat.id);
                          }}
                        />
                      </div>
                    )}
                  </div>
                }
              />
            </>
          ))}
        </FormGroup>
        {edit && (
          <div className='features_actions'>
            <button
              // style={{ visibility: edit && 'hidden' }}
              className={'carFeatures_edit_button margin-right10'}
              onClick={(e) => {
                e.preventDefault();
                setCreateModal(true);
              }}
            >
              Create New
            </button>
            <button
              // style={{ visibility: edit && 'hidden' }}
              className='cancel_button'
              onClick={handleSaveFeatures}
            >
              Save Features
            </button>
          </div>
        )}
        <Modal
          open={createModal}
          onClose={() => setCreateModal(false)}
          aria-labelledby='conf_modal'
          aria-describedby='modal-modal-description'
        >
          <div className='confModal create_feature_modal'>
            <br />
            <h4>Create a Feature</h4>
            <TextField
              label='Feature Name'
              variant='filled'
              className='create_feature_input'
              value={createFeatureName}
              onChange={(e) => {
                e.preventDefault();
                setCreateFeatureName(e.target.value);
              }}
            />
            <TextField
              label='Feature Description'
              variant='filled'
              className='create_feature_input'
              value={createFeatureDescription}
              onChange={(e) => {
                e.preventDefault();
                setCreateFeatureDescription(e.target.value);
              }}
            />
            <div className='btns'>
              <button
                onClick={() => setCreateModal(false)}
                className='secondary'
              >
                Cancel
              </button>
              <button className='primary' onClick={handleCreateFeature}>
                Create
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
